import { Component, Input } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Observable, map } from 'rxjs';
import Alert from '../alert.model';
import { AlertService } from '../alert.service';

@UntilDestroy()
@Component({
  selector: 'mbs-alert-container',
  templateUrl: 'alert-container.component.html',
  host: {
    class: 'mbs-alert-container',
    '[style.width]': 'width',
    '[style.min-width]': 'minWidth',
    '[style.max-width]': 'maxWidth'
  }
})
export class AlertContainerComponent {
  @Input() width: string;
  @Input() minWidth: string;
  @Input() maxWidth: string;

  private maxOverlayAlerts = 2;
  private maxInlineAlerts = 2;

  public get inlineAlerts(): Observable<Array<Alert>> {
    return this.alertService.alerts$.pipe(map((alerts) => alerts.filter((alert) => !alert.overlay).slice(0, this.maxInlineAlerts)));
  }

  public get overlayAlerts(): Observable<Array<Alert>> {
    return this.alertService.alerts$.pipe(map((alerts) => alerts.filter((alert) => alert.overlay).slice(0, this.maxOverlayAlerts)));
  }

  constructor(private alertService: AlertService) {}

  removeAlert(alert: Alert): void {
    this.alertService.close(alert);
  }
}
