import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ODataServiceFactory } from '@common/odata';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ArchivePasswordService {
  constructor(private odataFactory: ODataServiceFactory, private http: HttpClient) {}

  /*
   * Global method
   * Use for setting password to archived files
   */
  exportSettings(id: string, password: string): Observable<void> {
    const odataService = this.odataFactory.CreateService(`/ExportSettings(${id})/ArchivePassword`);

    return this.http.put<void>(odataService.Query().GetUrl(), { password });
  }
}
