import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { PlanInfoOdata, TariffType } from '@common/models';
import { UntilDestroy } from '@ngneat/until-destroy';
import { I18_NAMESPACE_APPS_UI } from 'i18n';
import { DateFormat } from 'mbs-ui-kit';

@UntilDestroy()
@Component({
  selector: 'apps-invoice-plan',
  templateUrl: './invoice-plan.component.html',
  styleUrls: ['./invoice-plan.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InvoicePlanComponent {
  @Input() planInfo: PlanInfoOdata;

  public readonly modulePayments = I18_NAMESPACE_APPS_UI.payments;
  public readonly DateFormat = DateFormat;
  public readonly TariffType = TariffType;

  /**
   * ActivatedPlanId has `id` if activated or activated canceled plan subscription or `null` if plan is subscribed
   * SubscriptionUrl has `url` if user manual canceled plan subscription or `null` if subscribe wasn't canceled. Alternate property `IsCanceled`
   */
  get hasUnsubscribedPlan(): boolean {
    return (
      this.planInfo &&
      this.planInfo.TariffType !== TariffType.trial &&
      this.planInfo.IsCanceled &&
      (this.unExpiredPlan || Boolean(this.planInfo.ActivatedPlanId))
    );
  }

  get unExpiredPlan(): boolean {
    return new Date(this.planInfo.ExpireDate).getTime() > new Date().getTime();
  }
}
