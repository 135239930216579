<div *ngIf="showTopControls"
     [ngClass]="{
       '-sm': size === 'sm' || size === 'small',
       '-lg': size === 'lg' || size === 'large'
     }"
     class="mbs-accordion_controls">

  <div class="row align-items-center justify-content-between">

    <div class="col-auto mbs-accordion_controls-checkbox">
      <mbs-checkbox (change)="topControlsCheckboxChangeHandler($event); handleCountTotalSelected()"
                    [checked]="checkedTop"
                    [indeterminate]="indeterminateTop"
                    [label]="topControlsLabel"></mbs-checkbox>
    </div>

    <div *ngIf="topControlsDescription"
         class="col-auto mbs-accordion_controls-description">
      {{ topControlsDescription }}
    </div>

  </div>

</div>

<ng-template #topControlsLabel>

  <ng-template [ngTemplateOutlet]="topControlsPrependLabel?.template"></ng-template>

  <span *ngIf="topControlsLabelSelected">
    {{ selectedInAllPanels }}
  </span>

  <ng-template [ngTemplateOutlet]="topControlsAppendLabel?.template"></ng-template>

</ng-template>

<ngb-accordion #innerAccordion
               [activeIds]="activeIds"
               [closeOthers]="closeOthers"
               [destroyOnHide]="destroyOnHide"
               (panelChange)="changeHeader.emit({ panelId: $event.panelId, event: $event.nextState })"
               [ngClass]="{
                 'mbs-accordion-onepanel': panels.length === 1,
                 'mbs-accordion-sm': size === 'small',
                 'overflow-auto': !!maxHeight,
                 '-showControls': showTopControls,
                 'mbs-accordion-checkboxes': checkboxes,
                 'mbs-accordion-simple': !isDefaultView,
                 '-without-border': withoutBorder,
                 '-narrow-header': narrowHeader
               }"
               [ngStyle]="{ 'max-height': maxHeight }"
               [type]="type"
               class="mbs-accordion">

  <ng-container *ngFor="let panel of panels; index as idx">

    <ngb-panel *ngIf="panel.shown"
               [disabled]="panel.disabled"
               [id]="panel.id"
               [title]="panel.title"
               [type]="panel.type">

      <ng-template ngbPanelHeader>

        <ng-container *ngIf="isDefaultView; else customViewTemplateRef">

          <!-- TODO: need refactored after update bootstrap to v5.3.0. Will be use [cardClass]='some classes' instead onClickHeaderPanel() -->
          <header [ngClass]="{ 'background-light' : checkboxes }"
                  class="mbs-accordion_header"
                  (click)="onClickHeaderPanel($event, innerAccordion.isExpanded(panel.id))">

            <div *ngIf="arrow && !panel.panelIconHeader"
                 [ngClass]="{
                   '-checkboxes': checkboxes,
                   '-sm': size === 'small',
                   '-disabled': panel.disabled,
                   '-collapsed': (panel.disabled ? !panel.keepOpened && !innerAccordion.isExpanded(panel.id) : !innerAccordion.isExpanded(panel.id))
                 }"
                 class="ctrl mbs-accordion_toggler">

              <button [disabled]="panel.disabled"
                      class="ctrl mbs-accordion_toggler-btn"
                      ngbPanelToggle></button>

            </div>

            <div *ngIf="panel.panelIconHeader"
                 class="mbs-accordion_ico"
                 [class.-align-left]="align === 'left'">

              <span [class.-disabled]="panel.disabled"
                    class="mbs-accordion_ico-img">
                <ng-template [ngTemplateOutlet]="panel.panelIconHeader.template"></ng-template>
              </span>

              <button [disabled]="panel.disabled"
                      class="ctrl mbs-accordion_ico-btn"
                      ngbPanelToggle></button>

            </div>

            <div *ngIf="checkboxes"
                 [class.mbs-accordion_header-checkbox]='!panel.hideCheckboxes'>

              <div class="row flex-nowrap no-gutters">

                <mbs-checkbox (change)="handleChangePanelHeaderCheckbox($event, panel); countTotalSelected(); handleCountTotalSelected()"
                              [checked]="panel.selectedAll"
                              [disabled]="panel.disabled"
                              [id]="panel.id + idx"
                              [indeterminate]="panel.indeterminate"
                              *ngIf='!panel.hideCheckboxes'></mbs-checkbox>

                <button class="ctrl ctrl-dark mbs-accordion_title"
                        [ngClass]="toggleButtonClass"
                        ngbPanelToggle>

                  <ng-container *ngIf="!panel.title">
                    <ng-template [ngTemplateOutlet]="panel.titleTemplate"></ng-template>
                  </ng-container>

                  <ng-container *ngIf="panel.title">
                    {{ panel.title }}
                  </ng-container>

                </button>

                <button class="ctrl d-flex flex-nowrap justify-content-end"
                        *ngIf='!panel.hideCheckboxes'
                        [ngClass]="toggleButtonClass"
                        ngbPanelToggle>

                <span class="small text-muted text-nowrap pl-3">
                  Selected: {{ panel.selectedCount }}
                </span>

                  <span class="small text-muted text-nowrap pl-3">
                  Total: {{ panel.all }}
                </span>

                </button>

              </div>

            </div>

            <ng-container *ngIf="panel.prependHeaderTemplate">
              <ng-template [ngTemplateOutlet]="panel.prependHeaderTemplate"
                           item-panel="panel"></ng-template>
            </ng-container>

            <button *ngIf="!checkboxes"
                    class="ctrl ctrl-dark mbs-accordion_title"
                    [ngClass]="toggleButtonClass"
                    ngbPanelToggle>

              <ng-container *ngIf="!panel.title">
                <ng-template [ngTemplateOutlet]="panel.titleTemplate"></ng-template>
              </ng-container>

              <ng-container *ngIf="panel.title">
                {{ panel.title }}
              </ng-container>

            </button>

            <ng-container *ngIf="panel.appendHeaderTemplate">
              <ng-template [ngTemplateOutlet]="panel.appendHeaderTemplate"
                           item-panel="panel"></ng-template>
            </ng-container>

          </header>

        </ng-container>

        <ng-template #customViewTemplateRef>

          <header class="mbs-accordion_header"
                  (click)="onClickHeaderPanel($event, innerAccordion.isExpanded(panel.id))">

            <ng-container *ngIf="arrowPosition === 'left'">
              <ng-container *ngTemplateOutlet="arrowTmp"></ng-container>
            </ng-container>

            <div *ngIf="panel.panelIconHeader"
                 class="mbs-accordion_ico">

              <span [class.-disabled]="panel.disabled"
                    class="mbs-accordion_ico-img">
                <ng-template [ngTemplateOutlet]="panel.panelIconHeader.template"></ng-template>
              </span>

              <button [disabled]="panel.disabled"
                      class="ctrl mbs-accordion_ico-btn"
                      ngbPanelToggle></button>

            </div>

            <button class="ctrl mbs-accordion_title"
                    [ngClass]="{ 'ctrl-primary': isTitlePrimary, toggleButtonClass: true }"
                    ngbPanelToggle>

              <ng-container *ngIf="!panel.title">
                <ng-template [ngTemplateOutlet]="panel.titleTemplate"></ng-template>
              </ng-container>

              <ng-container *ngIf="panel.title">
                {{ panel.title }}
              </ng-container>

            </button>

            <ng-container *ngIf="arrowPosition === 'right'">
              <ng-container *ngTemplateOutlet="arrowTmp"></ng-container>
            </ng-container>

            <ng-template #arrowTmp>
              <div *ngIf="arrow"
                   [ngClass]="{
                   '-sm': size === 'small',
                   '-disabled': panel.disabled,
                   '-collapsed': (panel.disabled ? !panel.keepOpened && !innerAccordion.isExpanded(panel.id) : !innerAccordion.isExpanded(panel.id)),
                   'pl-2': arrowPosition === 'right',
                   'pr-2': arrowPosition === 'left',
                   'ctrl-primary': isTitlePrimary
                 }"
                   class="ctrl mbs-accordion_toggler">

                <button [disabled]="panel.disabled"
                        class="ctrl mbs-accordion_toggler-btn"
                        ngbPanelToggle></button>
              </div>
            </ng-template>


          </header>

        </ng-template>

      </ng-template>

      <ng-template ngbPanelContent>

        <ng-container *ngIf="isDefaultView; else customViewTemplateRef">

          <ng-container *ngIf="xScroll; else accordionPanelContent">

            <ng-scrollbar autoWidthDisabled="false"
                          autoHeightDisabled="false"
                          track="horizontal"
                          class="w-100">

              <div class="ng-scroll-content">
                <ng-container *ngTemplateOutlet="accordionPanelContent"></ng-container>
              </div>

            </ng-scrollbar>

          </ng-container>

          <ng-template #accordionPanelContent>

            <div [ngClass]="{
                   'mbs-accordion-checkboxes_content': checkboxes,
                   'mbs-accordion_content': !checkboxes
                 }">

              <ng-container *ngIf="panel.rowTemplate">
                <div class="mbs-accordion-checkboxes_row"
                     [ngClass]="{
                       '-disabled': item.disabled,
                       '-selected': item.checked
                     }"
                     *ngFor="let item of panel.rows">

                  <mbs-checkbox *ngIf="checkboxes && !panel.hideCheckboxes"
                                [disabled]="panel.disabled || item.disabled"
                                [checked]="item.checked"
                                (change)="panel.checkPanelCheckboxStatus($event, item); countTotalSelected(); handleCountTotalSelected()"
                                [labelContext]="labelMode === 'check' ? item.data : null"
                                [label]="labelMode === 'check' ? panel.rowTemplate.template : null"></mbs-checkbox>

                  <ng-container *ngIf="labelMode === 'toggle'">
                    <div [ngClass]="{ 'mbs-accordion-checkboxes_row-label overflow-hidden' : !panel.hideCheckboxes, 'w-100': panel.fullWidthLabel}">
                      <ng-container *ngTemplateOutlet="panel.rowTemplate.template; context: {$implicit: item.data}"></ng-container>
                    </div>
                  </ng-container>

                </div>
              </ng-container>

              <ng-template *ngIf="!checkboxes"
                           [ngTemplateOutlet]="panel.bodyTemplate"></ng-template>

            </div>

          </ng-template>

        </ng-container>

        <ng-template #customViewTemplateRef>
          <div class="mbs-accordion_content"
               [class.pl-0]="!panel.panelIconHeader">
            <ng-template [ngTemplateOutlet]="panel.bodyTemplate"></ng-template>
          </div>
        </ng-template>

      </ng-template>

    </ngb-panel>

  </ng-container>

</ngb-accordion>
