import { Pipe, PipeTransform } from '@angular/core';
import { MimeCategorySiteBlock } from '@common';
import { environment } from '@env/environment';

@Pipe({
  name: 'srcMimeCategorySb',
  pure: true
})
export class SrcMimeCategorySbPipe implements PipeTransform {
  transform(type: MimeCategorySiteBlock): string {
    const path = environment.spriteMimeCategorySiteBlocks;
    const map = new Map([
      [MimeCategorySiteBlock.GenericList, path + 'itgen'],
      [MimeCategorySiteBlock.DocumentLibrary, path + 'itdl'],
      [MimeCategorySiteBlock.Itrl, path + 'itrl.png'],
      [MimeCategorySiteBlock.Whereabouts, path + 'itgbwher'],
      [MimeCategorySiteBlock.WorkflowHistory, path + 'itwfh'],
      [MimeCategorySiteBlock.PictureLibrary, path + 'itil'],
      [MimeCategorySiteBlock.WebPageLibrary, path + 'itwp'],
      [MimeCategorySiteBlock.Links, path + 'itlink'],
      [MimeCategorySiteBlock.Announcements, path + 'itann'],
      [MimeCategorySiteBlock.Contacts, path + 'itcontct'],
      [MimeCategorySiteBlock.Events, path + 'itevent'],
      [MimeCategorySiteBlock.Tasks, path + 'ittask'],
      [MimeCategorySiteBlock.DiscussionBoard, path + 'itdisc'],
      [MimeCategorySiteBlock.Users, path + 'users'], // UserInformation
      [MimeCategorySiteBlock.XmlForm, path + 'itfl'],
      [MimeCategorySiteBlock.Grid, path + 'itdatash'], // CustomGrid
      // [MimeCategorySiteBlock.DataConnectionLibrary, path + 'itdcl'],
      [MimeCategorySiteBlock.GanttTasks, path + 'itgantt'],
      [MimeCategorySiteBlock.Agenda, path + 'itagnda'],
      [MimeCategorySiteBlock.MeetingUser, path + 'itcontct'],
      [MimeCategorySiteBlock.MeetingObjective, path + 'itobject'],
      [MimeCategorySiteBlock.Posts, path + 'itposts'],
      [MimeCategorySiteBlock.Comments, path + 'itcommnt'],
      [MimeCategorySiteBlock.Categories, path + 'itcat'],
      [MimeCategorySiteBlock.Facility, path + 'itgbfaci'],
      [MimeCategorySiteBlock.CallTrack, path + 'itgbcall'],
      [MimeCategorySiteBlock.Circulation, path + 'itgbcirc'],
      [MimeCategorySiteBlock.Timecard, path + 'itgbtmcd'],
      [MimeCategorySiteBlock.Issues, path + 'itissue'], // IssueTracking
      [MimeCategorySiteBlock.Survey, path + 'itsurvey'],
      [MimeCategorySiteBlock.Decision, path + 'itdecis'],
      [MimeCategorySiteBlock.Teams, path + 'team'],
      [MimeCategorySiteBlock.AppCatalog, path + 'itappcatalog'],
      [MimeCategorySiteBlock.AppRequests, path + 'itapprequests'],
      [MimeCategorySiteBlock.KpiList, path + 'iKpiList'],
      [MimeCategorySiteBlock.ReportsLibrary, path + 'iReportsLibrary'],
      [MimeCategorySiteBlock.Commcat, path + 'itcommcat'],
      [MimeCategorySiteBlock.Tal, path + 'ital'],
      [MimeCategorySiteBlock.Commem, path + 'itcommem'],
      [MimeCategorySiteBlock.Pwsris, path + 'itpwsris'],
      [MimeCategorySiteBlock.Pwscom, path + 'itpwscom'],
      [MimeCategorySiteBlock.ICXDDOC, path + 'ICXDDOC'],
      [MimeCategorySiteBlock.SlideLibrary, path + 'itslidelibrary'],
      [MimeCategorySiteBlock.WebTemplateExtensionsList, path + 'web-template-extensions']
    ]);

    return map.get(type) || environment.spriteMimeCategory + 'question';
  }
}
