import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import { EditCreateRetentionPolicyModalComponent } from '@common/components/modals/edit-create-retention-policy-modal/edit-create-retention-policy-modal.component';
import { AttachedPolicies, AttachedPolicy, RetentionPolicyOdata, SelectedPolicies, hasAdminRole, hasSingleUserRole } from '@common/models';
import { AuthService, RetentionPolicyService, UserOdataService } from '@common/services';
import { getErrorText, getLoadingState, hasActionsQueue, hasErrorResponseText, isHomeUser } from '@common/utils';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { I18NextPipe } from 'angular-i18next';
import { I18_NAMESPACE_APPS_UI } from 'i18n';
import { isNil, noop } from 'lodash';
import { MbsSize, ModalComponent, ModalService, ModalSettings, ToastService } from 'mbs-ui-kit';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, finalize, map, shareReplay } from 'rxjs/operators';

@UntilDestroy()
@Component({
  templateUrl: './assign-retention-policy-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AssignRetentionPolicyComponent implements OnInit {
  @ViewChild(ModalComponent, { static: true }) baseModal: ModalComponent;

  #initPolicies: SelectedPolicies;
  #attachedPolicies$: BehaviorSubject<AttachedPolicies> = new BehaviorSubject(null);
  #loading$: BehaviorSubject<boolean> = new BehaviorSubject(true);

  public assignLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  readonly #toastTitleSuccess = this.i18nPipe.transform('toast.success.title', { format: 'title' });
  readonly #toastTitleError = this.i18nPipe.transform('toast.error.title', { format: 'title' });

  public loading$: Observable<boolean>;
  public attachedPolicies$: Observable<AttachedPolicies>;

  public readonly MbsSize = MbsSize;
  public readonly modulePolicy = I18_NAMESPACE_APPS_UI.policy;

  public isAvailableCreatePolicy$: Observable<boolean>;

  public driveTitle: string;
  public selectedPolicies: SelectedPolicies;
  public isHomeUser$: Observable<boolean>;

  get policiesChanged(): boolean {
    return (
      this.selectedPolicies.emailPolicyId !== this.#initPolicies.emailPolicyId ||
      this.selectedPolicies.drivePolicyId !== this.#initPolicies.drivePolicyId ||
      this.selectedPolicies.contactPolicyId !== this.#initPolicies.contactPolicyId ||
      this.selectedPolicies.calendarPolicyId !== this.#initPolicies.calendarPolicyId ||
      this.selectedPolicies.sitePolicyId !== this.#initPolicies.sitePolicyId ||
      this.selectedPolicies.teamDriveId !== this.#initPolicies.teamDriveId ||
      this.selectedPolicies.teamsPolicyId !== this.#initPolicies.teamsPolicyId
    );
  }

  get filePolicyId(): string {
    if (this.isGoogle) {
      return this.selectedPolicies.teamDriveId;
    }

    if (this.isOffice) {
      return this.selectedPolicies.sitePolicyId;
    }

    return '';
  }

  set filePolicyId(id: string) {
    if (this.isGoogle) {
      this.selectedPolicies.teamDriveId = id;
    }

    if (this.isOffice) {
      this.selectedPolicies.sitePolicyId = id;
    }
  }

  get userId(): string {
    return this.authService.id;
  }

  get isOffice(): boolean {
    return this.authService?.isOffice;
  }

  get isGoogle(): boolean {
    return this.authService?.isGoogle;
  }

  constructor(
    private i18nPipe: I18NextPipe,
    private retentionPolicyService: RetentionPolicyService,
    private modalService: ModalService,
    private toastService: ToastService,
    private authService: AuthService,
    private userService: UserOdataService
  ) {}

  ngOnInit(): void {
    this.driveTitle = this.getDriveTitle();
    this.selectedPolicies = {
      emailPolicyId: null,
      drivePolicyId: null,
      contactPolicyId: null,
      calendarPolicyId: null,
      sitePolicyId: null,
      teamDriveId: null,
      teamsPolicyId: null
    };

    this.initSteams();
    this.handleRefresh();
  }

  private initSteams(): void {
    this.loading$ = getLoadingState([this.#loading$.pipe(hasActionsQueue())]);
    this.isAvailableCreatePolicy$ = this.authService.getRoles().pipe(map((roles) => hasAdminRole(roles) || hasSingleUserRole(roles)));
    this.isHomeUser$ = this.authService.getAuthUser().pipe(map((user) => isHomeUser(user)));
    this.attachedPolicies$ = this.#attachedPolicies$.asObservable().pipe(
      filter((policy) => !isNil(policy)),
      finalize(() => this.#loading$.next(false)),
      shareReplay(1)
    );
  }

  getSelectedPolicyId(policies: AttachedPolicy[]): string {
    if (!policies) return null;

    return policies.find((p) => p.Selected)?.Value || null;
  }

  getDriveItems(policies: AttachedPolicies): AttachedPolicy[] {
    switch (true) {
      case this.isGoogle:
        return policies.TeamDrivePolicies;
      case this.isOffice:
        return policies.SitePolicies;
      default:
        throw new Error('Caught error manually: Cannot get drive items!');
    }
  }

  getDriveTitle(): string {
    switch (true) {
      case this.isGoogle:
        return this.i18nPipe.transform('retentionPolicy.label.sharedDrives', { format: 'title' });
      case this.isOffice:
        return this.i18nPipe.transform('retentionPolicy.label.sharePoint', { format: 'title' });
      default:
        throw new Error('Caught error manually: Cannot get drive title!');
    }
  }

  handleShowCreateRetention(): void {
    this.modalService
      .openCustom<RetentionPolicyOdata>(EditCreateRetentionPolicyModalComponent, this.getPopupPolicySettings())
      .then((saveResult) => {
        saveResult && this.handleRefresh();
      })
      .catch(noop);
  }

  getPopupPolicySettings(data?: RetentionPolicyOdata): ModalSettings {
    const settings: ModalSettings = {
      responsive: true
    };

    if (data) {
      settings.data = data;
    }

    return settings;
  }

  handleRefresh(): void {
    this.#loading$.next(true);
    this.retentionPolicyService
      .getAttachedPolicies([this.userId])
      .pipe(
        finalize(() => this.#loading$.next(false)),
        untilDestroyed(this)
      )
      .subscribe({
        next: (policies) => {
          this.selectedPolicies.emailPolicyId = this.getSelectedPolicyId(policies.EmailPolicies);
          this.selectedPolicies.drivePolicyId = this.getSelectedPolicyId(policies.DrivePolicies);
          this.selectedPolicies.contactPolicyId = this.getSelectedPolicyId(policies.ContactPolicies);
          this.selectedPolicies.calendarPolicyId = this.getSelectedPolicyId(policies.CalendarPolicies);
          this.selectedPolicies.sitePolicyId = this.getSelectedPolicyId(policies.SitePolicies);
          this.selectedPolicies.teamDriveId = this.getSelectedPolicyId(policies.TeamDrivePolicies);
          this.selectedPolicies.teamsPolicyId = this.getSelectedPolicyId(policies.TeamsPolicies);

          this.#initPolicies = { ...this.selectedPolicies };

          this.#attachedPolicies$.next(policies);
        }
      });
  }

  handleAssign(): void {
    if (!this.policiesChanged) return;

    const bodyUpdated = this.i18nPipe.transform(this.modulePolicy + ':toast.body.assign', { format: 'capitalize' });
    const bodyError = this.i18nPipe.transform(this.modulePolicy + ':toast.error.assign', { format: 'capitalize' });

    this.assignLoading$.next(true);
    this.userService
      .updateAttachedPolicies([this.userId], this.selectedPolicies)
      .pipe(finalize(() => this.assignLoading$.next(false)))
      .subscribe({
        next: () => {
          this.toastService.success(bodyUpdated, this.#toastTitleSuccess);
          this.baseModal.save(true);
        },
        error: (res: HttpErrorResponse) =>
          hasErrorResponseText(res) && this.toastService.error(getErrorText(res, bodyError), this.#toastTitleError)
      });
  }

  handleClose(): void {
    this.baseModal.close();
  }
}
