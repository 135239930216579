import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CreateService } from '@common';
import { OdataObject } from '@common/models';
import { ODataServiceFactory } from '@common/odata';
import { I18NextPipe } from 'angular-i18next';
import { saveAs } from 'file-saver';
import { DateFormat } from 'mbs-ui-kit';
import { SPACE_REGEX } from 'mbs-ui-kit/utils/constants';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

const loadingSymbol = Symbol('loading');

@Injectable({
  providedIn: 'root'
})
export class DownloadService {
  constructor(
    private datePipe: DatePipe,
    private http: HttpClient,
    private i18nPipe: I18NextPipe,
    private odataFactory: ODataServiceFactory
  ) {}

  getTempLink(guidId: string): Observable<string> {
    return this.http
      .get<OdataObject<string>>(this.odataFactory.CreateService(`Artifacts(${guidId})/GetTempLink`).Query().GetUrl())
      .pipe(map((res) => res?.value));
  }

  saveFile(tempLink: string, guidId: string): void {
    const link = document.createElement('a');

    link.href = this.odataFactory.CreateService(`Artifacts(${guidId})/Download(tempLink=${tempLink})`).Query().GetUrl();
    link.target = '_blank';

    link.click();
    link.remove();
  }

  downloadBare(serviceName: string): Observable<Blob> {
    return this.http.get(this.odataFactory.CreateService(serviceName).Query().GetUrl(), { responseType: 'blob' });
  }

  download(id: string, service: CreateService, query?: string): Observable<Blob> {
    const rawUrl = this.odataFactory.CreateService(service).Get(id).GetUrl() + this.getUrlEnd(service);
    const url = query ? rawUrl + query : rawUrl;

    return this.http.get(url, { responseType: 'blob', observe: 'response' }).pipe(map((res) => res.body));
  }

  private getUrlEnd(service: CreateService): string {
    switch (service) {
      case CreateService.SiteItems:
        return '/GetRaw';
      case CreateService.MailMessages:
        return '/DownloadAttachment';
      default:
        return '/Download';
    }
  }

  generateFileName(path: string): string {
    return `${path}_${this.datePipe.transform(new Date(), DateFormat.short).replace(SPACE_REGEX, '_')}`;
  }

  saveAs(file: Blob, name?: string): void {
    const filename = this.getFileName(name);
    const blob = this.getBlob(file);

    saveAs(blob, filename);
  }

  private getFileName(name: string): string {
    return name || this.i18nPipe.transform('common.noFileName').replace(SPACE_REGEX, '_');
  }

  private getBlob(file: Blob): Blob {
    return new Blob([file], { type: file.type });
  }

  updateLoading(item: unknown, state: boolean): void {
    item[loadingSymbol] = state;
  }

  getLoading(item: unknown): boolean {
    return item[loadingSymbol];
  }

  removeLoading(item: unknown): void {
    delete item[loadingSymbol];
  }
}
