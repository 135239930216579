import { Pipe, PipeTransform } from '@angular/core';
import { TaskStatus } from '@common';

@Pipe({
  name: 'statusIcon',
  pure: true
})
export class TaskStatusIconPipe implements PipeTransform {
  private readonly _map: Map<TaskStatus, string> = new Map([
    [TaskStatus.Pending, 'fa fa-clock-o'],
    [TaskStatus.Running, 'fa fa-spinner rotating'],
    [TaskStatus.Finished, 'fa fa-check-circle'],
    [TaskStatus.Failed, 'fa fa-exclamation-circle'],
    [TaskStatus.Paused, 'fa fa-pause-circle'],
    [TaskStatus.LimitExceeded, 'fa fa-exclamation-triangle'],
    [TaskStatus.Cancelled, 'fa fa-times-circle']
  ]);

  transform(status: TaskStatus): any {
    const classes = [];

    if (status) {
      classes.push(this._map.get(status));
    }

    return classes.join(' ');
  }
}
