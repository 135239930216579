import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { DeleteUserBackupComponent } from '@common/components/delete-user-backup/delete-user-backup.component';
import { I18NextModule } from 'angular-i18next';
import { MbsUiKitModule } from 'mbs-ui-kit';

@NgModule({
  imports: [I18NextModule, MbsUiKitModule, ReactiveFormsModule, CommonModule],
  declarations: [DeleteUserBackupComponent],
  exports: [DeleteUserBackupComponent]
})
export class DeleteUserBackupModule {}
