import { Pipe, PipeTransform } from '@angular/core';
import { MimeCategory } from '@common';
import { environment } from '@env/environment';

@Pipe({
  name: 'srcMimeCategory',
  pure: true
})
export class SrcMimeCategoryPipe implements PipeTransform {
  transform(type: MimeCategory, backupOn?: boolean): string {
    const path = environment.spriteMimeCategory;
    const map = new Map([
      [MimeCategory.Archive, path + 'archive'],
      [MimeCategory.Audio, path + 'audio'],
      [MimeCategory.Document, path + 'document'],
      [MimeCategory.Drive, path + 'drive'],
      [MimeCategory.Drawing, path + 'drawing'],
      [MimeCategory.Excel, path + 'excel'],
      [MimeCategory.Folder, path + 'yellow-folder'],
      [MimeCategory.Form, path + 'form'],
      [MimeCategory.Fusion, path + 'fusion'],
      [MimeCategory.File, path + 'generic'],
      [MimeCategory.Generic, path + 'generic'],
      [MimeCategory.Image, path + 'image'],
      [MimeCategory.OneNoteFolder, path + 'one-note-folder'],
      [MimeCategory.OneNoteFile, path + 'one-note-item'],
      [MimeCategory.Pdf, path + 'pdf'],
      [MimeCategory.Powerpoint, path + 'powerpoint'],
      [MimeCategory.Presentation, path + 'presentation'],
      [MimeCategory.Script, path + 'script'],
      [MimeCategory.Site, path + 'site'],
      [MimeCategory.SiteDisabled, path + 'site_disabled'],
      [MimeCategory.SitePage, path + 'spo-aspx'],
      [MimeCategory.Spreadsheet, path + 'spreadsheet'],
      [MimeCategory.Text, path + 'text'],
      [MimeCategory.Video, path + 'video'],
      [MimeCategory.Word, path + 'word']
    ]);

    if (backupOn === false && type === MimeCategory.Site) {
      return map.get(MimeCategory.SiteDisabled);
    }

    return map.get(type) || path + 'question';
  }
}
