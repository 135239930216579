import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { isNil } from 'lodash';
import { filter } from 'rxjs/operators';
import { MbsPopupIcon, MbsPopupType, MbsSize, TextBreak, isTemplate } from '../utils';
import Toast, { Button, DelayConfig } from './toast.model';
import { ToastService } from './toast.service';

@Component({
  selector: 'mbs-toasts',
  templateUrl: './toast.component.html',
  host: {
    '[class.ngb-toasts]': 'true',
    'aria-live': 'polite',
    'aria-atomic': 'true'
  }
})
export class ToastComponent implements OnInit, OnChanges {
  public readonly MbsSize = MbsSize;
  public toasts: Toast[] = [];
  public readonly isTemplate = isTemplate;
  public readonly TextBreak = TextBreak;

  /**
   * Default variants value by color type schema
   * @private
   */
  private defaultConfig: DelayConfig = {
    [MbsPopupType.info]: 3000,
    [MbsPopupType.success]: 3000,
    [MbsPopupType.warning]: 5000,
    [MbsPopupType.danger]: 5000
  };

  /**
   * If `true` - Toast will be hidden after few seconds.
   */
  @Input() autohide = true;

  /**
   * Max number of visible toasts. By default - 3
   */
  @Input() max = 3;

  /**
   * Delay custom config by color type schema. Default `defaultConfig`
   * @private
   */
  @Input() delayConfig: DelayConfig = Object.assign({}, this.defaultConfig);

  /**
   * Toasts for display, but no more than `max` quantity
   */
  public get visibleToasts(): Toast[] {
    return this.toasts.slice(0, this.max);
  }

  constructor(private toastService: ToastService) {}

  ngOnInit(): void {
    this.toastService
      .getById()
      .pipe(filter((t) => !isNil(t)))
      .subscribe((toast: Toast) => this.toasts.push(toast));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.delayConfig) {
      this.delayConfig = Object.assign({}, this.defaultConfig, changes.delayConfig.currentValue) as DelayConfig;
    }
  }

  /**
   * Remove toast from visible
   * @param {Toast} toast
   */
  handleRemove(toast: Toast): void {
    this.toasts = this.toasts.filter((t) => t !== toast);
  }

  /**
   * Custom classes for toast
   * @param {Toast} toast
   * @param {boolean} isLast
   * @return {string}
   */
  getToastClasses(toast: Toast, isLast: boolean): string {
    const type = toast.type || MbsPopupType.info;
    const classes = ['mbs-toast', `mbs-toast-${type}`];

    isLast && classes.push('-old');
    toast.header && !toast.showClose && classes.push('-noClose');
    toast.classname && classes.push(toast.classname);

    return classes.join(' ');
  }

  /**
   * Adding classes for default icon by type or custom icon. Used in header of toast
   * @param {Toast} toast
   * @return {string}
   */
  getIconClasses(toast: Toast): string {
    const type = toast.type || MbsPopupType.info;
    const classes = ['mbs-toast-ico', `text-${MbsPopupType[type]}`];
    const iconClasses = toast.icon && typeof toast.icon === 'string' ? toast.icon : MbsPopupIcon[type];

    classes.push(iconClasses);

    return classes.join(' ');
  }

  public onButtonClick(button: Button, toast: Toast): void {
    button.clickHandler();
    !toast.preventClose && this.handleRemove(toast);
  }
}
