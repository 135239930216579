export enum MbsSize {
  xxs = 'xxs',
  xs = 'xs',
  sm = 'sm',
  md = 'md',
  lmd = 'lmd',
  xmd = 'xmd',
  lg = 'lg',
  xl = 'xl',
  inherit = 'inherit'
}
