import { Pipe, PipeTransform } from '@angular/core';
import { DownloadService } from '@common/services/download.service';

@Pipe({
  name: 'downloadLoading',
  pure: false
})
export class GetDownloadLoadingPipe implements PipeTransform {
  constructor(private downloadService: DownloadService) {}

  transform(item: unknown): boolean | undefined {
    return this.downloadService.getLoading(item);
  }
}
