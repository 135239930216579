<section class="pb-3 mb-3 border-bottom">

  <h6 class="mb-2 font-weight-bold">
    {{ moduleUsers + ':sidepanel.title.totalSummary' | i18next: { format: 'title' } }}
  </h6>

  <table class="table table-sm table-simple mb-0">
    <colgroup>
      <col style="width: 30%;">
      <col style="width: 70%;">
    </colgroup>
    <tbody>
      <tr>
        <th class="font-weight-normal">
          {{ moduleUsers + ':sidepanel.label.lastBackup' | i18next: { format: 'title' } }}
        </th>
        <td>
          {{ user.LastBackupDate | date: DateFormat.short }}
        </td>
      </tr>
      <tr>
        <th class="font-weight-normal">
          {{ moduleUsers + ':sidepanel.label.totalBackupSize' | i18next: { format: 'title' } }}
        </th>
        <td>
          {{ user.TotalSize ? (user.TotalSize | size) : '0 B' }}
        </td>
      </tr>
    </tbody>
  </table>

</section>

<section class="pb-3 mb-3 border-bottom">

  <h6 class="mb-2 font-weight-bold">
    {{ moduleUsers + ':sidepanel.title.backupSize' | i18next: { format: 'title' } }}
  </h6>

  <table class="table table-sm table-simple mb-0">
    <colgroup>
      <col style="width: 30%;">
      <col style="width: 70%;">
    </colgroup>
    <tbody>
      <tr>
        <th class="font-weight-normal">
          {{ moduleUsers + ':sidepanel.label.mail' | i18next: { format: 'title' } }}
        </th>
        <td>
          {{ user.EmailSize ? (user.EmailSize | size) : '0 B' }}
        </td>
      </tr>
      <tr>
        <th class="font-weight-normal">
          {{ moduleUsers + ':sidepanel.label.drive' | i18next: { format: 'title' } }}
        </th>
        <td>
          {{ user.DriveSize ? (user.DriveSize | size) : '0 B' }}
        </td>
      </tr>
      <tr>
        <th class="font-weight-normal">
          {{ moduleUsers + ':sidepanel.label.calendar' | i18next: { format: 'title' } }}
        </th>
        <td>
          {{ user.CalendarSize ? (user.CalendarSize | size) : '0 B' }}
        </td>
      </tr>
    </tbody>
  </table>

</section>

<section *ngIf="tasksState as tasks">

  <h6 class="mb-2 font-weight-bold">
    {{ moduleUsers + ':sidepanel.title.typeLastBackup' | i18next: { format: 'title' } }}
  </h6>

  <table class="table table-sm table-simple mb-0">
    <colgroup>
      <col style="width: 30%;">
      <col style="width: 70%;">
    </colgroup>
    <tbody>
      <tr>
        <th class="font-weight-normal">
          {{ moduleUsers + ':sidepanel.label.mail' | i18next: { format: 'title' } }}
        </th>
        <td>
          <ng-container *ngIf="tasks.LastBackupDates?.MailLastBackupDate; else mailLoad">
            <mbs-status [icon]="StatusIconType.success">
              {{ tasks.LastBackupDates.MailLastBackupDate | date: DateFormat.short }}
            </mbs-status>
          </ng-container>
          <ng-template #mailLoad>
            <span *ngIf="getBackupPercentage(ServiceTypeEnum.Mail) as content"
                  [innerHTML]="content | safe: 'html'"></span>
          </ng-template>
        </td>
      </tr>
      <tr>
        <th class="font-weight-normal">
          {{ moduleUsers + ':sidepanel.label.drive' | i18next: { format: 'title' } }}
        </th>
        <td>
          <ng-container *ngIf="tasks.LastBackupDates?.DriveLastBackupDate; else driveLoad">
            <mbs-status [icon]="StatusIconType.success">
              {{ tasks.LastBackupDates.DriveLastBackupDate | date: DateFormat.short }}
            </mbs-status>
          </ng-container>
          <ng-template #driveLoad>
            <span *ngIf="getBackupPercentage(ServiceTypeEnum.Drive) as content"
                  [innerHTML]="content | safe: 'html'"></span>
          </ng-template>
        </td>
      </tr>
      <tr>
        <th class="font-weight-normal">
          {{ moduleUsers + ':sidepanel.label.contacts' | i18next: { format: 'title' } }}
        </th>
        <td>
          <ng-container *ngIf="tasks.LastBackupDates?.ContactsLastBackupDate; else contactsLoad">
            <mbs-status [icon]="StatusIconType.success">
              {{ tasks.LastBackupDates.ContactsLastBackupDate | date: DateFormat.short }}
            </mbs-status>
          </ng-container>
          <ng-template #contactsLoad>
            <span *ngIf="getBackupPercentage(ServiceTypeEnum.Contacts) as content"
                  [innerHTML]="content | safe: 'html'"></span>
          </ng-template>
        </td>
      </tr>
      <tr>
        <th class="font-weight-normal">
          {{ moduleUsers + ':sidepanel.label.calendar' | i18next: { format: 'title' } }}
        </th>
        <td>
          <ng-container *ngIf="tasks.LastBackupDates?.CalendarLastBackupDate; else calendarLoad">
            <mbs-status [icon]="StatusIconType.success">
              {{ tasks.LastBackupDates.CalendarLastBackupDate | date: DateFormat.short }}
            </mbs-status>
          </ng-container>
          <ng-template #calendarLoad>
            <span *ngIf="getBackupPercentage(ServiceTypeEnum.Calendar) as content"
                  [innerHTML]="content | safe: 'html'"></span>
          </ng-template>
        </td>
      </tr>
    </tbody>
  </table>

</section>

<mbs-loader *ngIf="loading"></mbs-loader>
