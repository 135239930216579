import { Injectable } from '@angular/core';
import { UrlTree } from '@angular/router';
import { hasDomainUserRole } from '@common/models';
import { AuthService } from '@common/services';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DomainUserGuard {
  constructor(private authService: AuthService) {}

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.authService.getRoles().pipe(
      map((roles) => !hasDomainUserRole(roles)),
      catchError(() => of(false))
    );
  }
}
