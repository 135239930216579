import {
  ChangeDetectorRef,
  Component,
  ContentChild,
  Directive,
  EventEmitter,
  Input,
  OnInit,
  Optional,
  Output,
  Self,
  SimpleChanges,
  TemplateRef
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { isNil } from 'lodash';
import { Observable } from 'rxjs';
import { MbsSize, isTemplate } from '../../utils';
import { InputBase } from '../input-base/input-base';
import { DOMEvent } from '../input-base/input-base.model';

@Directive({
  selector: '[switcherDescription]'
})
export class SwitcherDescription {
  @Input() hide = false;
  constructor(public template: TemplateRef<any>) {}
}
enum Switch {
  on = 'On',
  off = 'Off'
}
/**
 * Component that presents a `checkbox`
 */
@Component({
  selector: 'app-switcher,mbs-switcher',
  templateUrl: './switcher.component.html'
})
export class SwitcherComponent extends InputBase<any> implements ControlValueAccessor, OnInit {
  @Input() public boldLabel = true;

  private _statusWidth: string | number = '38px';

  public readonly MbsSize = MbsSize;
  public readonly Switch = Switch;
  public readonly isTemplate = isTemplate;

  public labelSizeClass = '';

  /**
   * if showStatus = true, you have to set custom width, depending on the length of the text in the status
   * By default for text `On/Off` is 38px
   * @param {string | number} value
   */
  @Input() set statusWidth(value: string | number) {
    if (!isNil(value)) {
      this._statusWidth = this.getStatusWidth(value);
    }
  }
  get statusWidth(): string | number {
    return this._statusWidth;
  }
  /**
   * Display status between switcher and label
   */
  @Input() showStatus = false;
  @Input() subtitle: string;
  @Input() subtitleClasses: string;
  @Input() subtitleHide = false;
  @Input() subtitleOutside = true;

  @Input() descriptionOutside = true;
  @Input() descriptionClasses: string;

  @Input() public selected = false;
  /**
   * Visible label for input
   */
  @Input() public label: string | TemplateRef<any>;
  /**
   * Add .text-overflow to label text
   */
  @Input() public textOverflow = false;
  /**
   * context for label template
   */
  @Input() labelContext: any;
  /**
   * `required`
   * Native input's `name` attribute.
   */
  @Input() public name: string;
  /**
   * Id for label binding
   */
  @Input() public id: string;
  /**
   * Classes for label
   */
  @Input() labelClasses = '';
  /**
   * Possible values of switcher. <br>
   * Must contain **exactly** two values. First one for checked and second one for non-checked state; <br>
   */
  @Output() change = new EventEmitter();

  private onTouchedCallback: () => void;
  private onChangeCallback: (_) => void;
  /**
   * Side of the label position
   */
  @Input() public labelPosition: 'left' | 'right' = 'right';
  /**
   * Side of the status position
   */
  @Input() statusPosition: 'left' | 'right' = 'left';
  /**
   * Use 100% width for label
   */
  @Input() expandLabel = false;
  /**
   * Align of the label when expandLabel enabled
   */
  @Input() expandedLabelPosition: 'left' | 'right' = 'left';

  @Output() beforeUpdate: EventEmitter<{ currentValue: boolean; nextValue: boolean; stop: () => void }> = new EventEmitter<{
    currentValue: boolean;
    nextValue: boolean;
    stop: () => boolean;
  }>();
  @Output() afterUpdate: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input() loading$: Observable<boolean>;

  @ContentChild(SwitcherDescription, { static: true, read: SwitcherDescription }) switcherDescription: SwitcherDescription;

  constructor(@Optional() @Self() ngControl: NgControl, protected cd: ChangeDetectorRef) {
    super(ngControl, cd);
  }

  ngOnInit(): void {
    this.updateLabelSizeClass();
  }

  writeValue(value: boolean): void {
    this.selected = value;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.labelSize || this.label) {
      this.updateLabelSizeClass();
    }
  }

  /**
   * Default change method. Can be replaced.
   * @param {DOMEvent<HTMLInputElement>}event - native DOM input event
   * @return {boolean | void}
   */
  handleChange(event: DOMEvent<HTMLInputElement>): boolean | void {
    event.stopPropagation();
    if (!this.readonly) {
      let performUpdate = true;
      this.beforeUpdate.emit({
        currentValue: this.selected,
        nextValue: event.target.checked,
        stop: () => {
          performUpdate = false;
          event.preventDefault();
          event.stopImmediatePropagation();
          event.target.checked = this.selected;
        }
      });
      if (!performUpdate) return false;
      this.selected = !this.selected;
      this.change.emit(event);
      if (this.onChangeCallback) {
        this.onChangeCallback(this.selected);
      }
      this.afterUpdate.emit(this.selected);
    }
  }

  handleClick(event: DOMEvent<HTMLInputElement>): void {
    this.readonly && event.preventDefault();
    event.stopPropagation();
  }

  registerOnChange(fn: (value) => void): void {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouchedCallback = fn;
  }

  private getStatusWidth = (width: string | number): string => {
    const ONLY_NUMBER_REGEX = /^\d+$/g;

    if (typeof width === 'number' || (typeof width === 'string' && new RegExp(ONLY_NUMBER_REGEX).test(width))) {
      return `${width}px`;
    }
    if (width === 'string') {
      return width as string;
    }
    return '';
  };

  updateLabelSizeClass() {
    this.labelSizeClass = this.labelSize ? 'text-' + this.labelSize.toString() : '';
  }
}
