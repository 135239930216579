import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, ValidationErrors, Validators } from '@angular/forms';
import { ServiceTypeStr } from '@common';
import { RetentionPolicyOdata } from '@common/models';
import { AuthService, RetentionPolicyService } from '@common/services';
import { getErrorText, hasErrorResponseText, isHomeUser } from '@common/utils';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { I18NextPipe } from 'angular-i18next';
import { I18_NAMESPACE_APPS_UI } from 'i18n';
import { isNil } from 'lodash';
import { FormsUtil, GuidEmpty, MbsPopupType, MbsValidators, ModalComponent, ToastService } from 'mbs-ui-kit';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged, finalize } from 'rxjs/operators';

@UntilDestroy()
@Component({
  templateUrl: './edit-create-retention-policy-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditCreateRetentionPolicyModalComponent implements OnInit {
  @ViewChild(ModalComponent, { static: true }) baseModal: ModalComponent;

  readonly #toastTitleSuccess = this.i18nPipe.transform('toast.success.title', { format: 'title' });
  readonly #toastTitleError = this.i18nPipe.transform('toast.error.title', { format: 'title' });

  public readonly modulePolicy = I18_NAMESPACE_APPS_UI.policy;
  public readonly MbsPopupType = MbsPopupType;
  public readonly ServiceTypeStr = ServiceTypeStr;
  public readonly prefix = Math.random().toString(36).substring(2) + '_';
  public retentionPolicyForm = this.fb.group({
    Id: GuidEmpty,
    ReadOnly: [{ value: false, disabled: true }],
    ServiceType: [ServiceTypeStr.Mail, [Validators.required]],
    Name: ['', [this.customNameValidator.bind(this)]],
    KeepCntUnits: [1],
    KeepUnit: ['Days'],
    KeepRevisionsCnt: [{ value: 1, disabled: true }],
    DelayPurgeCntUnits: [1],
    DelayPurgeUnit: ['Days'],
    ByModifyOrBackupDate: [true],
    EnableKeepCntUnits: [true],
    EnableKeepRevisionsCnt: [false],
    AlwaysKeepLastRevision: [false],
    HasLegalHold: [false]
  });

  public timePeriods: string[] = [
    this.i18nPipe.transform('retentionPolicy.modal.select-periods.days', { format: 'title' }),
    this.i18nPipe.transform('retentionPolicy.modal.select-periods.weeks', { format: 'title' }),
    this.i18nPipe.transform('retentionPolicy.modal.select-periods.months', { format: 'title' }),
    this.i18nPipe.transform('retentionPolicy.modal.select-periods.years', { format: 'title' })
  ];
  public availableRetentionPolicies: { value: string; label: string }[];
  public bodySuccess = this.i18nPipe.transform(this.modulePolicy + ':toast.body.create', { format: 'capitalize' });
  public bodyError = this.i18nPipe.transform(this.modulePolicy + ':toast.error.create', { format: 'capitalize' });
  public title = this.i18nPipe.transform('retentionPolicy.modal.title.create', { format: 'title' });
  public okButtonText = this.i18nPipe.transform('retentionPolicy.modal.button.create', { format: 'title' });
  public cancelButtonText = this.i18nPipe.transform('retentionPolicy.modal.button.cancel', { format: 'title' });
  public okButtonDisabled = true;
  public policy: RetentionPolicyOdata;
  public isCreate: boolean;

  public loading$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  get enableKeepCntUnitsControl(): FormControl<boolean> {
    return <FormControl>this.retentionPolicyForm.controls['EnableKeepCntUnits'];
  }

  get keepRevisionsCntControl(): FormControl<{ value: string; disabled: boolean }> {
    return <FormControl>this.retentionPolicyForm.controls['KeepRevisionsCnt'];
  }

  get delayPurgeCntUnitsControl(): FormControl<string> {
    return <FormControl>this.retentionPolicyForm.controls['KeepCntUnits'];
  }

  get serviceTypeControl(): FormControl<ServiceTypeStr> {
    return <FormControl>this.retentionPolicyForm.controls['ServiceType'];
  }

  get isOffice(): boolean {
    return this.authService?.isOffice;
  }

  get isGoogle(): boolean {
    return this.authService?.isGoogle;
  }

  get isImmutability(): boolean {
    return this.authService?.isImmutability;
  }

  constructor(
    private i18nPipe: I18NextPipe,
    private fb: FormBuilder,
    private authService: AuthService,
    private toastService: ToastService,
    private retentionPolicyService: RetentionPolicyService
  ) {}

  ngOnInit(): void {
    this.policy = this.baseModal.data as RetentionPolicyOdata;

    if (this.policy) {
      this.retentionPolicyForm.patchValue(this.policy, { emitEvent: false });
      this.title = this.i18nPipe.transform('retentionPolicy.modal.title.edit', { format: 'title' });
      this.okButtonText = this.i18nPipe.transform('retentionPolicy.modal.button.save', { format: 'title' });
      this.bodySuccess = this.i18nPipe.transform(this.modulePolicy + ':toast.body.update', { format: 'capitalize' });
      this.bodyError = this.i18nPipe.transform(this.modulePolicy + ':toast.error.update', { format: 'capitalize' });
    }

    this.initStreams();
    this.setValidators();

    if (this.needTriggerValidationKeepRevisionsCntControl(this.enableKeepCntUnitsControl.value)) {
      FormsUtil.triggerValidation(this.keepRevisionsCntControl);
    }

    if (this.needTriggerValidationDelayPurgeCntUnitsControl(this.enableKeepCntUnitsControl.value)) {
      FormsUtil.triggerValidation(this.delayPurgeCntUnitsControl);
    }

    if (this.serviceTypeControl.value !== ServiceTypeStr.Mail && this.serviceTypeControl.value !== ServiceTypeStr.Teams) {
      this.toggleEnableKeepRevisions();
      this.okButtonDisabled = true;
    }
  }

  private setValidators(): void {
    this.delayPurgeCntUnitsControl.setValidators(this.delayPurgeCntUnitsValidator.bind(this));
    this.keepRevisionsCntControl.setValidators(this.keepRevisionsCntValidator.bind(this));
  }

  private keepRevisionsCntValidator(control: FormControl, minCount = 1): ValidationErrors | null {
    if (!control) return null;

    if (!this.enableKeepCntUnitsControl.value && (isNil(control.value) || control.value < minCount)) {
      return { min: { message: MbsValidators.validatorMessages.min({ min: minCount, actual: control.value || 0 }) } };
    }

    return null;
  }

  private delayPurgeCntUnitsValidator(control: FormControl, minCount = 1): ValidationErrors | null {
    if (!control) return null;

    if (this.enableKeepCntUnitsControl.value && (isNil(control.value) || control.value < minCount)) {
      return { min: { message: MbsValidators.validatorMessages.min({ min: minCount, actual: control.value || 0 }) } };
    }

    return null;
  }

  private needTriggerValidationKeepRevisionsCntControl(isEnableKeepRevisionsCntControl: boolean): boolean {
    return !isEnableKeepRevisionsCntControl && !this.keepRevisionsCntControl.touched;
  }

  private needTriggerValidationDelayPurgeCntUnitsControl(isEnableDelayPurgeCntUnitsControl: boolean): boolean {
    return isEnableDelayPurgeCntUnitsControl && !this.delayPurgeCntUnitsControl.touched;
  }

  private customNameValidator(control: AbstractControl): ValidationErrors | null {
    const errors = Validators.compose([Validators.required, Validators.maxLength(50)])(control);

    if (!errors) return null;

    if (errors.maxlength) {
      return { maxlength: { message: MbsValidators.validatorMessages.maxlength(errors.maxlength) } };
    }

    if (errors.required) {
      return { required: { message: 'The field is required' } };
    }

    return null;
  }

  initStreams(): void {
    this.retentionPolicyForm.valueChanges.pipe(untilDestroyed(this)).subscribe({
      next: () => (this.okButtonDisabled = this.retentionPolicyForm.invalid)
    });

    this.enableKeepCntUnitsControl.valueChanges.pipe(distinctUntilChanged(), untilDestroyed(this)).subscribe({
      next: (value) => {
        if (this.needTriggerValidationKeepRevisionsCntControl(value)) {
          FormsUtil.triggerValidation(this.keepRevisionsCntControl);
        }

        if (this.needTriggerValidationDelayPurgeCntUnitsControl(value)) {
          FormsUtil.triggerValidation(this.delayPurgeCntUnitsControl);
        }
      }
    });

    this.serviceTypeControl.valueChanges.pipe(distinctUntilChanged(), untilDestroyed(this)).subscribe({
      next: (serviceType) => {
        if (serviceType == ServiceTypeStr.Mail || serviceType == ServiceTypeStr.Teams) {
          this.enableKeepCntUnitsControl.patchValue(true);
        }

        this.toggleEnableKeepRevisions();
      }
    });

    this.authService
      .getAuthUser()
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (user) => {
          const policies = [
            { value: 'Mail', label: this.i18nPipe.transform('retentionPolicy.modal.select-for-service.mail', { format: 'title' }) },
            { value: 'Drive', label: this.i18nPipe.transform('retentionPolicy.modal.select-for-service.drive', { format: 'title' }) },
            {
              value: 'Contacts',
              label: this.i18nPipe.transform('retentionPolicy.modal.select-for-service.contacts', { format: 'title' })
            },
            {
              value: 'Calendar',
              label: this.i18nPipe.transform('retentionPolicy.modal.select-for-service.calendar', { format: 'title' })
            }
          ];

          if (!isHomeUser(user) && this.isGoogle) {
            policies.push({
              value: 'TeamDrives',
              label: this.i18nPipe.transform('retentionPolicy.modal.select-for-service.sharedDrives', { format: 'title' })
            });
          }

          if (!isHomeUser(user) && this.isOffice) {
            policies.push({
              value: 'SharePoint',
              label: this.i18nPipe.transform('retentionPolicy.modal.select-for-service.sharePoint', { format: 'title' })
            });
            policies.push({
              value: 'Teams',
              label: this.i18nPipe.transform('retentionPolicy.modal.select-for-service.teams', { format: 'title' })
            });
          }

          this.availableRetentionPolicies = policies;
        }
      });
  }

  toggleEnableKeepRevisions(): void {
    const controls = this.retentionPolicyForm.controls;
    const EnableKeepCntUnits = this.retentionPolicyForm.value.EnableKeepCntUnits;

    if (!EnableKeepCntUnits) {
      controls.AlwaysKeepLastRevision.disable();
      controls.ByModifyOrBackupDate.disable();
      controls.KeepCntUnits.disable();
      controls.KeepRevisionsCnt.enable();
      controls.KeepUnit.disable();
    } else {
      controls.AlwaysKeepLastRevision.enable();
      controls.ByModifyOrBackupDate.enable();
      controls.KeepCntUnits.enable();
      controls.KeepRevisionsCnt.disable();
      controls.KeepUnit.enable();
    }

    controls.EnableKeepRevisionsCnt.setValue(!EnableKeepCntUnits);
  }

  handleSave(): void {
    const policy = this.retentionPolicyForm.value as RetentionPolicyOdata;

    if (policy.ServiceType == ServiceTypeStr.Mail || policy.ServiceType == ServiceTypeStr.Teams) {
      policy.EnableKeepCntUnits = true;
    }

    this.loading$.next(true);
    (this.policy ? this.retentionPolicyService.updatePolicy(policy) : this.retentionPolicyService.createPolicy(policy))
      .pipe(finalize(() => this.loading$.next(false)))
      .subscribe({
        next: () => {
          this.toastService.success(this.bodySuccess, this.#toastTitleSuccess);
          this.baseModal.save(true);
        },
        error: (res: HttpErrorResponse) =>
          hasErrorResponseText(res) && this.toastService.error(getErrorText(res, this.bodyError), this.#toastTitleError)
      });
  }

  handleClose(): void {
    this.baseModal.close();
  }
}
