import { MimeCategory } from '@common';
import { RestoreToAnotherUser } from '@common/models/restore-to-another-user.model';

export interface DriveOdataItem {
  AppName: string;
  CssClass: DriveClasses;
  DriveId?: any;
  ForDomainId?: any;
  HasChildren: boolean;
  Hidden: boolean;
  Id: string;
  MimeTypeId: string;
  MimeCategory: MimeCategory;
  Name: string;
  ParentIdStr?: any;
  ProviderId: string;
  Size: number;
  TreeItemType: string;
  Type: DriveItemType;
  UserId: string;
}

export enum DriveClasses {
  folder = 'folder',
  file = 'file'
}

export interface Revision {
  FileName: string;
  Id: string;
  ModifyDate: Date;
}

export interface RevisionWithRaw extends Revision {
  Raw: string;
}

export interface DriveItemDetails {
  Description: string;
  IsDriveItem: boolean;
  IconM: string;
  Path: string[];
  Revisions: Revision[];
}

export enum DriveItemType {
  Folder,
  File
}

export type ItemDriveItemDetails = { drive: DriveOdataItem; details: DriveItemDetails };

export interface RestoreDriveItems {
  AnotherUser?: RestoreToAnotherUser;
  DriveId: string;
  Ids: string[];
  Timeline?: string; // "2020-08-26T21:00:00.000Z";
  ToRestoreFolder: boolean;
}

export enum DriveODataOption {
  UserId = 'userId',
  ProviderId = 'providerId',
  TeamDrive = 'teamDrive',
  SearchQ = 'searchQ'
}
