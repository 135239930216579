import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { ButtonModule } from '../button';
import { DisableControlDirective } from './disable-control.directive';
import { OverlayDirective } from './overlay.directive';
import { PermanentTooltipDirective, PermanentTooltipInternalComponent } from './permanent-tooltip.directive';
import { SizeObserverDirective } from './size.directive';
import { TrimInputDirective } from './trim-input.directive';
import { TypedTemplateDirective } from './typed-template.directive';

const directives = [
  OverlayDirective,
  TypedTemplateDirective,
  SizeObserverDirective,
  DisableControlDirective,
  TrimInputDirective,
  PermanentTooltipDirective
];

@NgModule({
  imports: [CommonModule, NgbModule, NgbTooltipModule, ButtonModule],
  declarations: [...directives, PermanentTooltipInternalComponent],
  exports: directives
})
export class SharedDirectivesModule {}
