<div class="bg-white">
  <mbs-alert *ngFor="let alert of inlineAlerts | async; index as i; let last = last"
             (close)="removeAlert($event)"
             [class.mb-1]="!last"
             class="content-center mb-0"
             [autoClose]="alert.autoClose"
             [closable]="true"
             [content]="alert.content"
             [context]="alert"
             [icon]="true"
             [ignoreRouteChanges]="alert.ignoreRouteChanges"
             [type]="alert.type"
             [id]="alert.id"
             >
  </mbs-alert>
</div>
<div class="position-fixed mt-2" style="left: 50%;transform: translate(-50%);">
  <mbs-alert (close)="removeAlert($event)"
             *ngFor="let alert of overlayAlerts | async; let last = last"
             [autoClose]="alert.autoClose"
             [class.mb-0]="last"
             [closable]="true"
             [content]="alert.content"
             [context]="alert"
             [icon]="true"
             [ignoreRouteChanges]="alert.ignoreRouteChanges"
             [type]="alert.type"
             [id]="alert.id">
  </mbs-alert>

</div>
