import { DOCUMENT } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { WINDOW } from '@common';
import {
  AuthUser,
  CloudBerryDiscountState,
  CreateInvoicePayload,
  PlanInfoOdata,
  TariffType,
  Tariffs,
  TariffsOdata,
  UnsubscribePayload
} from '@common/models';
import { AuthService, TariffsService } from '@common/services';
import { getOdataOrderBy, isHomeUser } from '@common/utils';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { I18NextPipe } from 'angular-i18next';
import { I18_NAMESPACE_APPS_UI } from 'i18n';
import {
  DateFormat,
  MbsPopupType,
  MbsSize,
  ModalComponent,
  ModalService,
  ModalSettings,
  SharedPersistentStateEnum,
  SortEvent,
  TableHeader
} from 'mbs-ui-kit';
import { BehaviorSubject, EMPTY, Observable, forkJoin, from, of, switchMap } from 'rxjs';
import { catchError, finalize, map, share, shareReplay } from 'rxjs/operators';

export const REDIRECT_TO_EDIT_SUBSCRIPTION_MODAL = 'redirect to edit subscription modal';

export enum ModePlansCompareEnum {
  select = 'select',
  edit = 'edit'
}

@UntilDestroy()
@Component({
  templateUrl: './compare-plans-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ComparePlansModalComponent implements OnInit {
  @ViewChild(ModalComponent, { static: true }) baseModal: ModalComponent;
  @ViewChild('unsubscribeConfirmTemplate', { static: true, read: TemplateRef }) unsubscribeConfirmTemplate: TemplateRef<any>;

  public readonly DateFormat = DateFormat;
  public readonly MbsPopupType = MbsPopupType;
  public readonly MbsSize = MbsSize;
  public readonly modulePayments = I18_NAMESPACE_APPS_UI.payments;
  public readonly viewMode: SharedPersistentStateEnum = SharedPersistentStateEnum.table;
  public readonly planInfo$: Observable<PlanInfoOdata>;
  public readonly tariffs$: BehaviorSubject<Tariffs[]> = new BehaviorSubject([]);
  public readonly loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public readonly discount$: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  public readonly isHomeUser$: Observable<boolean>;
  public readonly user$: Observable<AuthUser>;
  public readonly CloudBerryDiscountState = CloudBerryDiscountState;

  public headers: TableHeader[] = [];
  public orderBy: SortEvent = null;
  public mode: ModePlansCompareEnum;

  public tariffNameConfirmModal = '';
  public tariffDateConfirmModal = '';

  private isHomeUser: boolean;
  private planInfo: PlanInfoOdata;

  get isSelectMode(): boolean {
    return this.mode === ModePlansCompareEnum.select;
  }

  get isEditMode(): boolean {
    return this.mode === ModePlansCompareEnum.edit;
  }

  get hasUnsubscribedPlan(): boolean {
    return (
      this.planInfo &&
      this.planInfo.TariffType !== TariffType.trial &&
      this.planInfo.IsCanceled &&
      (this.unExpiredPlan || Boolean(this.planInfo.ActivatedPlanId))
    );
  }

  get unExpiredPlan(): boolean {
    return new Date(this.planInfo.ExpireDate).getTime() > new Date().getTime();
  }

  constructor(
    @Inject(WINDOW) private window: Window,
    private i18nPipe: I18NextPipe,
    private tariffsService: TariffsService,
    private authService: AuthService,
    private modalService: ModalService,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.planInfo$ = this.tariffsService.getPlanInfo(true).pipe(share());
    this.user$ = authService.getAuthUser().pipe(shareReplay(1));
    this.isHomeUser$ = this.user$.pipe(
      map((user) => isHomeUser(user)),
      shareReplay(1)
    );
  }

  ngOnInit(): void {
    this.mode = this.baseModal.data.mode;
    this.baseModal.loading = true;

    this.initCustomOrderBy();
    this.fetchData();
  }

  private initCustomOrderBy(): void {
    this.tariffsService.orderBy = this.orderBy ? getOdataOrderBy(this.orderBy.column, this.orderBy.direction) : null;
  }

  hasSubscribedPlan(tariffs: Tariffs[]): boolean {
    return tariffs.some((tariff) => tariff.Subscribed);
  }

  fetchData(): void {
    this.loading$.next(true);

    forkJoin([this.isHomeUser$, this.planInfo$, this.tariffsService.getTariffs()])
      .pipe(
        map(([isHomeUser, planInfo, tariffs]) => {
          this.isHomeUser = isHomeUser;
          this.planInfo = planInfo;
          this.tariffDateConfirmModal = this.planInfo.ExpireDate;

          const discount =
            planInfo.CloudBerryDiscountState === CloudBerryDiscountState.approved && planInfo.CloudBerryDiscount > 0
              ? planInfo.CloudBerryDiscount
              : 0;

          this.discount$.next(discount);
          this.headers = this.getTableHeaders({ discountApproved: this.discount$.value });

          return tariffs;
        }),
        map((tariffs) => (this.isEditMode ? tariffs.filter((t) => t.Subscribed || t.Id === this.planInfo.ActivatedPlanId) : tariffs)),
        finalize(() => {
          this.loading$.next(false);

          if (this.baseModal.loading) {
            this.baseModal.loading = false;
          }
        }),
        catchError(() => of([] as TariffsOdata[])),
        untilDestroyed(this)
      )
      .subscribe({
        next: (tariffs) => {
          const tariffTransformed: Tariffs[] = tariffs.map((t) => ({
            ...t,
            ServicePaidTransformed: this.isHomeUser ? false : t.Subscribed ? t.ServicePaid : true,
            AdditionalUsers: this.isEditMode ? 0 : t.CurrentActivatedUsers
          }));

          this.tariffs$.next(tariffTransformed);
        }
      });
  }

  handleRedirectToEditSubscription(): void {
    this.baseModal.save(REDIRECT_TO_EDIT_SUBSCRIPTION_MODAL);
  }

  handleToggleSubscribe(tariff: Tariffs): void {
    if (tariff.Subscribed) {
      this.handleUnsubscribe(tariff);
    } else {
      this.handleSubscribe(tariff);
    }
  }

  private handleUnsubscribe(tariff: Tariffs): void {
    this.tariffNameConfirmModal = tariff.Name;

    const settings: ModalSettings = {
      header: { title: this.i18nPipe.transform(this.modulePayments + ':modal.title.confirmUnsubscribeRequest', { format: 'title' }) },
      footer: {
        okButton: {
          text: this.i18nPipe.transform(this.modulePayments + ':modal.button.unsubscribe', { format: 'title' }),
          type: 'danger'
        }
      }
    };

    from(this.modalService.open(settings, this.unsubscribeConfirmTemplate))
      .pipe(
        catchError(() => EMPTY),
        switchMap(() => {
          const payload: UnsubscribePayload = {
            SubscriptionId: this.planInfo.SubscriptionId
          };

          this.loading$.next(true);
          return this.tariffsService.unsubscribeActivatedPlan(payload);
        })
      )
      .subscribe({
        next: () => {
          this.fetchData();
        }
      });
  }

  private handleSubscribe(tariff: Tariffs): void {
    const payload: CreateInvoicePayload = {
      AdditionalUsersCnt: tariff.AdditionalUsers - tariff.CurrentActivatedUsers,
      ServiceEnable: tariff.ServicePaidTransformed,
      TotalPrice: this.totalPrice(tariff, Boolean(this.discount$.value))
    };

    this.tariffsService.createInvoice(payload, tariff.Id).subscribe({
      next: (url) => {
        this.window.open(url, '_blank'); // redirect to fastspring by GET
      }
    });
  }

  handleAdditionalPurchase(tariff: Tariffs): void {
    const payload: CreateInvoicePayload = {
      AdditionalUsersCnt: tariff.AdditionalUsers,
      PaidUsersCount: this.planInfo.UserLimit,
      ServiceEnable: this.isServicePurchased(tariff) ? false : tariff.ServicePaidTransformed,
      TotalPrice: this.totalPriceAdditionalPurchase(tariff, Boolean(this.discount$.value))
    };

    this.tariffsService.createInvoices(payload).subscribe({
      next: (url: string) => {
        this.window.open(url, '_blank'); // redirect to fastspring by GET
      }
    });
  }

  /*
   * Calc total price for `edit` mode
   * @param tariff
   * @param discount
   */
  totalPriceAdditionalPurchase(tariff: Tariffs, discount = false): number {
    const unitPriceOfPurchasedService =
      tariff.ServicePaidTransformed && !this.isServicePurchased(tariff) ? this.getPriceAvailableServices(tariff) : 0;
    const userPrice = discount ? this.getPriceWithDiscount(tariff.CentPriceUserPerMonth) : tariff.CentPriceUserPerMonth;
    const userPricePerPeriod = this.getAdditionalPurchasePerPeriod(userPrice);
    const servicePrice = discount ? this.getPriceWithDiscount(unitPriceOfPurchasedService) : unitPriceOfPurchasedService;
    const servicePricePerPeriod = this.getAdditionalPurchasePerPeriod(servicePrice);

    return userPricePerPeriod * tariff.AdditionalUsers + servicePricePerPeriod;
  }

  private getAdditionalPurchasePerPeriod(price: number): number {
    return Math.ceil((price * this.planInfo.DaysToEndPlan) / this.planInfo.AverageDaysInMonth);
  }

  private getPriceAvailableServices = (tariff: Tariffs): number => (tariff.ServicePaidTransformed ? tariff.CentPriceServicePerMonth : 0);

  totalPriceNextPeriod(tariffs: Tariffs[], discount = true): number {
    return tariffs.length > 0 ? this.totalPrice(tariffs[0], discount, true) : 0;
  }

  /*
   * Calc total price for `select` mode
   * @param tariff
   * @param discount
   * @param calcNextPeriod
   */
  totalPrice(tariff: Tariffs, discount = false, calcNextPeriod = false): number {
    const userPrice = discount ? this.getPriceWithDiscount(tariff.CentPriceUserPerMonth) : tariff.CentPriceUserPerMonth;
    const userPricePerPeriod = Math.ceil(userPrice) * tariff.PeriodInMonths;

    if (this.isHomeUser) {
      return userPricePerPeriod;
    } else {
      const unitPriceServicePerMonth = tariff.ServicePaidTransformed ? this.getPriceAvailableServices(tariff) : 0;
      const servicePrice = discount ? this.getPriceWithDiscount(unitPriceServicePerMonth) : unitPriceServicePerMonth;
      const servicePricePerPeriod = Math.ceil(servicePrice) * tariff.PeriodInMonths;

      return userPricePerPeriod * (tariff.AdditionalUsers + (calcNextPeriod ? this.planInfo.UserLimit : 0)) + servicePricePerPeriod;
    }
  }

  private getPriceWithDiscount(price: number): number {
    return price - (price * this.discount$.value) / 100;
  }

  isServicePurchased(tariff: Tariffs): boolean {
    return (tariff.Subscribed || (!tariff.Subscribed && this.hasUnsubscribedPlan)) && tariff.ServicePaid;
  }

  disabledPurchaseButton(tariff: Tariffs): boolean {
    return tariff.AdditionalUsers === 0 && (tariff.ServicePaid === tariff.ServicePaidTransformed || !tariff.ServicePaidTransformed);
  }

  private getTableHeaders(options: { discountApproved: number }): TableHeader[] {
    const { discountApproved } = options;
    const textRightClasses = '-end text-right'; // fix prettier
    const textCenterClasses = '-center text-center'; // fix prettier
    const i18totalKey = `:modal.grid.${this.isSelectMode ? 'total' : 'surcharge'}`; // fix prettier
    const defaultHomeUserHeaders: TableHeader[] = [
      {
        name: this.i18nPipe.transform(this.modulePayments + ':modal.grid.period', { format: 'title' }),
        gridColSize: '3fr',
        gridColMin: '100px',
        class: '-stretch',
        overflow: true,
        sort: 'Period'
      },
      {
        name: this.i18nPipe.transform(this.modulePayments + i18totalKey, { format: 'title' }),
        gridColSize: '2fr',
        gridColMin: '100px',
        class: '-end',
        headerClass: textRightClasses,
        overflow: true
      },
      {
        name: '',
        gridColMin: '110px',
        gridColSize: '110px',
        isGridColumn: false,
        class: '-stretch'
      }
    ];
    const headerTotalDiscount = {
      name: this.i18nPipe.transform(this.modulePayments + ':modal.grid.' + (this.isSelectMode ? 'totalDiscount' : 'discountedSurcharge'), {
        format: 'title'
      }),
      gridColSize: '2fr',
      gridColMin: '120px',
      class: '-end',
      headerClass: textRightClasses
    };
    const defaultAdminHeaders: TableHeader[] = [
      {
        name: this.i18nPipe.transform(this.modulePayments + ':modal.grid.period', { format: 'title' }),
        gridColSize: '3fr',
        gridColMin: '100px',
        class: '-stretch',
        overflow: true,
        sort: 'Period'
      },
      {
        name: this.i18nPipe.transform(this.modulePayments + ':modal.grid.price', { format: 'title' }),
        gridColSize: '1.5fr',
        gridColMin: '100px',
        class: '-end',
        headerClass: textRightClasses,
        sort: 'PriceInCents'
      },
      {
        name: this.i18nPipe.transform(
          this.modulePayments + ':modal.grid.' + (this.isEditMode ? 'additionalUsers' : 'currentActivatedUsers'),
          {
            format: 'title'
          }
        ),
        gridColSize: '1fr',
        gridColMin: '90px',
        class: '-stretch',
        headerClass: textCenterClasses
      },
      {
        name: this.i18nPipe.transform(this.modulePayments + ':modal.grid.files.' + (this.authService.isOffice ? 'office' : 'google'), {
          format: 'title'
        }),
        gridColSize: '2fr',
        gridColMin: '100px',
        class: '-center',
        headerClass: textCenterClasses
      },
      {
        name: this.i18nPipe.transform(this.modulePayments + i18totalKey, { format: 'title' }),
        gridColSize: '2fr',
        gridColMin: '100px',
        class: '-end',
        headerClass: textRightClasses,
        overflow: true
      },
      {
        name: '',
        gridColMin: this.isEditMode ? '50px' : '120px',
        gridColSize: this.isEditMode ? '50px' : '120px',
        isGridColumn: false,
        class: '-stretch'
      }
    ];
    const headers = this.isHomeUser ? [...defaultHomeUserHeaders] : [...defaultAdminHeaders];

    if (!this.isHomeUser && discountApproved > 0) {
      const totalColumnIndex: number = headers.findIndex(
        (column) => column.name === this.i18nPipe.transform(this.modulePayments + i18totalKey, { format: 'title' })
      );

      headers.splice(totalColumnIndex + 1, 0, headerTotalDiscount);
    }

    return headers;
  }
}
