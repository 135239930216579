import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { PageData } from '@common/models';
import { AuthService } from '@common/services';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AvailableServicesGuard {
  constructor(private authService: AuthService) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const pageData = next.data as PageData;

    if (pageData.serviceType) {
      return this.authService.getAvailableServices().pipe(
        map((services) => services.includes(pageData.serviceType)),
        catchError(() => of(false))
      );
    }

    return of(true);
  }
}
