import {
  AfterContentInit,
  ContentChildren,
  Directive,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  QueryList,
  SimpleChanges
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { startWith } from 'rxjs/operators';
import { SortDirection, SortEvent } from '../../models/sort';
import { TableSortDirective } from './table-sort.directive';

@UntilDestroy()
@Directive({
  exportAs: 'mbsTableSortGroup',
  selector: '[mbsTableSortGroup]'
})
export class TableSortGroupDirective implements AfterContentInit, OnChanges {
  @Input('mbsTableSortGroup') orderBy: SortEvent;
  @Input() rotateSequence: { [key: string]: SortDirection };
  @Output() sort = new EventEmitter<SortEvent>(true);

  @ContentChildren(TableSortDirective, { descendants: true, read: TableSortDirective }) sortHeaders: QueryList<TableSortDirective>;

  ngAfterContentInit(): void {
    this.sortHeaders.changes.pipe(startWith(this.sortHeaders), untilDestroyed(this)).subscribe((headers: QueryList<TableSortDirective>) => {
      headers.forEach((header) => {
        header.rotateSequence = this.rotateSequence || header.rotateSequence;
        header.sort.subscribe((event: SortEvent) => this.sort.emit(event));
      });
      this.resetSortArrow();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.orderBy.currentValue) {
      this.resetSortArrow();
    }
  }

  private resetSortArrow(): void {
    this.sortHeaders &&
      this.sortHeaders.forEach((header) => {
        if (!this.orderBy || this.orderBy.column !== header.columnName) {
          header.changeState('');
        } else {
          header.changeState(this.orderBy.direction);
        }
      });
  }
}
