import { STAGE_HOSTS } from '@common';

export function getSentryEnvironment(): 'staging' | 'production' | 'local' {
  if (location.hostname.includes('localhost')) {
    return 'local';
  }

  if (location.hostname.includes('stage') || STAGE_HOSTS.some((url) => url === location.hostname)) {
    return 'staging';
  }

  return 'production';
}
