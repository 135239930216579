import { Pipe, PipeTransform } from '@angular/core';
import { I18NextPipe } from 'angular-i18next';

const secsInDay = 86400;
const secsInHour = 3600;
const secsInMinute = 60;

const formatWithLeadingZero = (val: number) => val.toString().padStart(2, '0');

@Pipe({
  name: 'convertSeconds',
  pure: true,
  standalone: true
})
export class ConverterSecondsPipe implements PipeTransform {
  constructor(private i18n: I18NextPipe) {}

  transform(secs: number): string {
    const days = Math.floor(secs / secsInDay);
    const hours = Math.floor((secs % secsInDay) / secsInHour);
    const minutes = Math.floor((secs % secsInHour) / secsInMinute);
    const seconds = secs % secsInMinute;

    let result = '';

    if (days > 0) {
      result += `${days + this.i18n.transform('mbs-ui-kit:time.days')} `;
    }

    if (hours > 0 || days > 0) {
      result += `${formatWithLeadingZero(hours) + this.i18n.transform('mbs-ui-kit:time.hours')} `;
    }

    if (minutes > 0 || hours > 0 || days > 0) {
      result += `${formatWithLeadingZero(minutes) + this.i18n.transform('mbs-ui-kit:time.minutes')} `;
    }

    if (hours === 0 && days === 0) {
      result += `${formatWithLeadingZero(seconds) + this.i18n.transform('mbs-ui-kit:time.seconds')}`;
    }

    return result.trim();
  }
}
