<div class="input-group mbs-advanced-search">

  <div ngbDropdown
       autoClose="inside"
       class="form-control mbs-advanced-search_form-control row no-gutters"
       [ngClass]="{'focus': searchHasFocus}"
       placement="bottom-left">
    <div class="col-auto mbs-advanced-search_input">
      <div contenteditable="true"
           id="advanced-search"
           class="mbs-advanced-search_content single-line"
           placeholder="Enter Search Request"
           spellcheck="false"
           (keyup)="handleKeyboardUpEvent($event)"
           (keydown)="handleKeyboardDownEvent($event)"
           (paste)="handlePasteEvent($event)"
           (drop)="handleDropEvent($event)"
           (click)="getCaretPosition($event)"
           (blur)="handleParseModel()"
           #advancedSearch></div>
      <div class="form-control-placeholder mbs-advanced-search_placeholder"
           [ngClass]="{'d-none': !!advancedSearch.firstChild}">
        {{ placeholder }}
      </div>
    </div>
    <div class="col-auto d-flex align-items-center justify-content-center">
      <div class="px-2"
           *ngIf="searching">
        <span class="loader"></span>
      </div>

      <mbs-button [isCtrl]="true"
                  type="dark"
                  *ngIf="!searching && searchContent.length > 0"
                  title="{{ 'mbs-ui-kit:advanced-search.labels.clear' | i18next }}"
                  (click)="handleClear()"
                  icon="ico ico-Delete-text mr-0 px-2">
      </mbs-button>
    </div>

    <div class="col-auto d-flex align-items-center justify-content-center"
         *ngIf="searchFormTemplate">
      <button class="ctrl ctrl-dark px-2"
              ngbDropdownToggle>
      </button>
    </div>

    <div ngbDropdownMenu
         #ngbDropdownMenuRef
         class="mbs-advanced-search_dropdown">
      <ng-container *ngTemplateOutlet="searchFormTemplate">
      </ng-container>
    </div>
  </div>

  <div aria-labelledby="advanced-search"
       class="dropdown-menu autocomplete-position"
       [ngClass]="{'show': showDropdown}"
       (mouseleave)="selectedItemIndex = -1;"
       [ngStyle]="this.dropdownHeight ? {'overflow-y': 'auto', 'overflow-x': 'hidden', 'maxHeight': this.dropdownHeight + 'px'} : null">
    <ng-container *ngFor="let item of unwrapItems; let i=index">
      <button class="dropdown-item"
              (click)="$event.stopPropagation();handleClickSelectedValue(item);"
              (mouseenter)="selectedItemIndex = i"
              [ngClass]="{'active': selectedItemIndex === i}">
        <ngb-highlight [result]="formatter(item)"
                       [term]="getLastWord(advancedSearch.innerText, caret)"> {{ item }}
        </ngb-highlight>
      </button>
    </ng-container>
  </div>

  <div class="input-group-append">
    <button class="btn btn-secondary"
            (click)="handleEnter($event)">
      <span [ngClass]="searchIcon"></span>
    </button>
  </div>

</div>
