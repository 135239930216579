import { ProviderPath } from '@common';
import { AuthMode } from '@common/components/login/enums';
import { Provider } from '@common/models';

export function getPrefix(type: Provider, isAlternativeSignIn: boolean): string {
  if (isAlternativeSignIn) {
    return `/${ProviderPath.Alternate}/`;
  }

  if (!isAlternativeSignIn && isProviderGoogle(type)) {
    return `/${ProviderPath.Google}/`;
  }

  if (!isAlternativeSignIn && isProviderOffice(type)) {
    return `/${ProviderPath.Office}/`;
  }

  return '/';
}

export function isAlternateSignIn(mode: AuthMode): boolean {
  return mode === AuthMode.AlternativeAccount;
}

export function isProviderGoogle(type: Provider): boolean {
  return type == Provider.GoogleBusiness || type == Provider.GoogleHome;
}

export function isProviderOffice(type: Provider): boolean {
  return type == Provider.OfficeBusiness || type == Provider.OfficeHome;
}
