<div class="mbs-input mbs-input-range form-control"
     mbsFocusBlurHandler
     (onBlur)="isFocus = false"
     (onFocus)="isFocus = true"
     [ngClass]="classes">
  <mbs-input-label *ngIf="label"
                   [label]="label">
    <ng-content select="[prependLabel]"
                ngProjectAs="[prependLabel]"></ng-content>
    <ng-content select="[appendLabel]"
                ngProjectAs="[appendLabel]"></ng-content>
  </mbs-input-label>
  <div class="input-group">
    <mbs-input-pre-append *ngIf="prependButtons"
                          position="prepend"
                          [data]="prependButtons"
                          (buttonClick)="buttonClick.emit($event)">
    </mbs-input-pre-append>
    <div class="input-group_control d-flex align-items-center justify-content-between" [ngClass]="sizeGroupClass">
      <ng-content select="startDate"></ng-content>
      <span>-</span>
      <ng-content select="endDate"></ng-content>
      <div class="form-control_loader"
           *ngIf="loading">
        <span class="loader"></span>
      </div>
    </div>
    <mbs-input-pre-append *ngIf="appendButtons"
                          position="append"
                          [data]="appendButtons"
                          [customClasses]="size && size !== 'md' ? 'border-right-0' : ''"
                          (buttonClick)="buttonClick.emit($event)">
    </mbs-input-pre-append>
  </div>
</div>
