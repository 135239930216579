import { Inject, Injectable, InjectionToken } from '@angular/core';
import { ODataPagedResult, ODataQuery, ODataService, ODataServiceFactory } from '@common/odata';
import { IKeyValue } from 'linq-collections';
import { Observable } from 'rxjs';

const TYPE_NAME = new InjectionToken<string>('type name');

interface State {
  page: number;
  pageSize: number;
  orderBy: string;
  filter: string;
  customQueryOptions: Map<string, any>;
}

@Injectable()
export class SharedOdataService<T> {
  #state: State;
  protected odata: ODataService<T>;

  set state(state: State) {
    this.#state = state;
  }

  get state(): State {
    return this.#state;
  }

  set page(page: number) {
    this.state.page = page;
  }

  get page(): number {
    return this.state.page;
  }

  set pageSize(pageSize: number) {
    this.state.pageSize = pageSize;
  }

  get pageSize(): number {
    return this.state.pageSize;
  }

  set orderBy(orderBy: string) {
    this.state.orderBy = orderBy;
  }

  get orderBy(): string {
    return this.state.orderBy;
  }

  set filter(filter: string) {
    this.state.filter = filter;
  }

  get filter(): string {
    return this.state.filter;
  }

  get customQueryOptions(): Map<string, any> {
    return this.state.customQueryOptions;
  }

  constructor(protected odataFactory: ODataServiceFactory, @Inject(TYPE_NAME) protected typeName: string) {
    this.odata = this.odataFactory.CreateService<T>(typeName);
    this.state = this.getDefaultState();
  }

  protected addCustomQueryOption(key: string, value: any) {
    this.state.customQueryOptions.set(key, value);
  }

  protected removeCustomQueryOption(key: string) {
    this.state.customQueryOptions.delete(key);
  }

  protected getQuery<T>(service: ODataService<T>): ODataQuery<T> {
    const defaultOrderBy = this.getDefaultState().orderBy;
    const orderBy = this.orderBy || defaultOrderBy;
    const skip = (this.page - 1) * this.pageSize;

    return service
      .Query()
      .OrderBy(orderBy)
      .Filter(this.filter)
      .Top(this.pageSize)
      .Skip(skip)
      .CustomQueryOptions(this.getCustomQueryOptions());
  }

  private getCustomQueryOptions(): IKeyValue<string, any>[] {
    if (this.customQueryOptions?.size > 0) {
      return Array.from(this.customQueryOptions)
        .map(([key, value]) => ({ key, value }))
        .filter((i) => i.value !== null);
    }

    return null;
  }

  protected getQueryParams<T>(service: ODataService<T>): string {
    const query = this.getQuery<T>(service);

    return new URL(query.GetUrl() + '&$count=true', location.origin).searchParams.toString();
  }

  protected fetchData<T>(service: ODataService<T>): Observable<ODataPagedResult<T>> {
    return this.getQuery<T>(service).ExecWithCount();
  }

  clearCache(): void {
    this.state = this.getDefaultState();
  }

  private getDefaultState = (): State => ({
    page: 1,
    pageSize: 50,
    orderBy: '',
    filter: '',
    customQueryOptions: new Map<string, any>()
  });
}
