import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { APPEND_BUTTON_PASSWORD_STATE, FormError } from '@common';
import { BackupStatisticGrid, DeleteUserBackupOutput } from '@common/components/delete-user-backup/index';
import { ServiceType } from '@common/models';
import { getAppendButtonsIcon, getAppendButtonsState, hasAppendButtonsPasswordType } from '@common/utils';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { I18NextPipe } from 'angular-i18next';
import { MbsLabelSize, MbsValidators, SharedPersistentStateEnum, TableHeader } from 'mbs-ui-kit';
import { BehaviorSubject, noop } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'app-delete-user-backup',
  templateUrl: './delete-user-backup.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeleteUserBackupComponent implements OnInit {
  @Output() deleteBackupChange: EventEmitter<DeleteUserBackupOutput> = new EventEmitter<DeleteUserBackupOutput>();
  @Output() deleteBackupFormValid: EventEmitter<boolean> = new EventEmitter<boolean>();

  public passwordType$: BehaviorSubject<string> = new BehaviorSubject(APPEND_BUTTON_PASSWORD_STATE.hidden.type);

  #statistics: BackupStatisticGrid[] = [];
  #checkedServices: ServiceType[] = [];
  #formError: FormError = { message: '' };

  public readonly getAppendButtonsIcon = getAppendButtonsIcon;
  public readonly headers: TableHeader[];
  public readonly MbsLabelSize = MbsLabelSize;

  public viewMode: SharedPersistentStateEnum = SharedPersistentStateEnum.table;
  public deleteBackupForm: UntypedFormGroup = new UntypedFormGroup({
    password: new FormControl('', [Validators.required, MbsValidators.passwordValidator, Validators.maxLength(20)])
  });

  @Input() set statistics(s: BackupStatisticGrid[]) {
    const formControls = { password: new FormControl('') } as { password: FormControl };

    this.#statistics = s;
    s.forEach((s: BackupStatisticGrid) => (formControls[s.serviceType] = new FormControl(false)));
  }

  get statistics(): BackupStatisticGrid[] {
    return this.#statistics;
  }

  get passwordControl(): AbstractControl {
    return this.deleteBackupForm.get('password');
  }

  @Input() set formError(error: FormError) {
    if (error?.message) {
      this.#formError = error;
      !this.passwordControl.touched && this.passwordControl.markAsTouched();
      !this.passwordControl.dirty && this.passwordControl.markAsDirty();
      this.setPasswordErrorState();
    } else {
      this.passwordControl.reset('', { emitEvent: false });
    }
  }

  get formError(): FormError {
    return this.#formError;
  }

  constructor(private i18nPipe: I18NextPipe) {
    this.headers = this.getTableHeaders();
  }

  ngOnInit(): void {
    this.deleteBackupForm.statusChanges.pipe(untilDestroyed(this)).subscribe({
      next: () => this.emitValue(),
      error: () => noop
    });
  }

  public handleChecked(s: BackupStatisticGrid[]): void {
    this.#checkedServices = s.map((s: BackupStatisticGrid) => s.serviceType);
    this.emitValue();
  }

  private emitValue(): void {
    const deleteBackupForm = this.deleteBackupForm.value as { password: string };
    const emitValue: DeleteUserBackupOutput = {
      deleteServices: this.#checkedServices,
      password: deleteBackupForm.password
    };

    this.deleteBackupChange.emit(emitValue);
    this.deleteBackupFormValid.emit(this.deleteBackupForm.valid);
  }

  private setPasswordErrorState(): void {
    this.passwordControl.setValue('', { emitEvent: false });
    this.passwordControl.setErrors({ password: { message: this.formError.message } }, { emitEvent: false });
  }

  handleChangePasswordType(): void {
    const isPasswordType: boolean = hasAppendButtonsPasswordType(this.passwordType$.value);

    this.passwordType$.next(APPEND_BUTTON_PASSWORD_STATE[getAppendButtonsState(isPasswordType)].type);
  }

  private getTableHeaders(): TableHeader[] {
    return [
      {
        name: this.i18nPipe.transform('deleteBackup.grid.service', { format: 'title' }),
        overflow: true,
        gridColMin: '91px',
        gridColSize: '1fr'
      },
      {
        name: this.i18nPipe.transform('deleteBackup.grid.size', { format: 'title' }),
        overflow: true,
        gridColMin: '84px',
        gridColSize: '1fr',
        class: '-end'
      },
      {
        name: this.i18nPipe.transform('deleteBackup.grid.count', { format: 'title' }),
        overflow: true,
        gridColMin: '84px',
        gridColSize: '1fr',
        class: '-end'
      }
    ];
  }
}
