import { ChangeDetectorRef, Component, Input, OnInit, Optional, Self, ViewChild } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { IMaskDirective } from 'angular-imask';
import IMask from 'imask';
import { isNil } from 'lodash';
import { InputBase } from '../input-base/input-base';
import { DOMEvent, InputClasses, ValidClasses } from '../input-base/input-base.model';

export type MaskedOptions = IMask.AnyMaskedOptions;
export type MaskElement = IMask.MaskElement;

@Component({
  selector: 'app-number,mbs-number',
  templateUrl: './number.component.html'
})
export class NumberComponent extends InputBase<number> implements OnInit, ControlValueAccessor {
  @Input() public max: number = Number.MAX_SAFE_INTEGER;
  @Input() public min: number = Number.MIN_SAFE_INTEGER;
  @Input() public step = 1;
  @Input() imaskOpts?: MaskedOptions;
  /**
   * HTML tabindex
   */
  @Input() tabindex = 0;
  /**
   * Toggles showing '+' and '-' buttons
   */
  @Input() showButtons = true;
  /**
   * Add font-weight-bold;
   */
  @Input() public boldLabel = true;

  @Input() public containerClasses = '';

  /**
   * @ignore
   */
  public get bindClasses(): string[] {
    const classesObject: InputClasses = Object.assign<Record<string, boolean>, ValidClasses>({}, this.validClasses);
    return Object.entries(classesObject)
      .filter(([k, v]) => !!v)
      .map(([k]) => k)
      .concat([this.sizeClass]);
  }
  public elementSelectors = {
    id: {
      btnUp: 'mbs-button-up',
      btnDown: 'mbs-button-down'
    }
  };

  protected labelContentClassesDefault = 'mbs-number_label-content';

  @ViewChild(IMaskDirective, { read: IMaskDirective, static: true }) maskDirective: IMaskDirective<MaskedOptions>;
  constructor(@Optional() @Self() ngControl: NgControl, protected cd: ChangeDetectorRef) {
    super(ngControl, cd);
  }

  ngOnInit(): void {
    this.maskDirective.writeValue(String(this.myValue));
  }

  writeValue(obj: any): void {
    this.myValue = !isNil(obj) ? +obj : 0;
    this.maskDirective.writeValue(String(this.myValue));
    this.cd.markForCheck();
  }

  changeValueHandler(isIncrement = true): void {
    this.checkRanges(isIncrement);
    this.onTouched && this.onTouched();
    this.maskDirective.writeValue(String(this.value));
  }

  numberChangeHandler(event: Event): void {
    event.stopPropagation();
  }

  checkRanges(isIncrement: boolean): void {
    if (isIncrement) {
      this.value = this.max && +this.value + +this.step > this.max ? +this.max : +this.value + +this.step;
    } else {
      this.value = this.min && this.value - +this.step < this.min ? +this.min : +this.value - +this.step;
    }
  }

  get calculatedContainerClasses(): string {
    return this.containerClasses ? `${this.containerClasses} input-group` : 'input-group';
  }

  numberHandleBlur(event: DOMEvent<HTMLInputElement>): void {
    if (!event?.target || this.imaskOpts) {
      return this.handleBlur(event);
    }

    const num = Number(event.target.value);
    if (isNil(this.imaskOpts) && isNaN(num)) {
      const newValue = this.min || 0;
      this.value = newValue;
      event.target.value = String(newValue);

      return this.handleBlur(event);
    }

    if (num < this.min) {
      event.preventDefault();
      event.target.value = String(this.min);
      this.value = this.min;
    } else if (num > this.max) {
      event.preventDefault();
      event.target.value = String(this.max);
      this.value = this.max;
    } else {
      this.value = num;
    }

    this.handleBlur(event);
  }

  updateMaskValue(): void {
    setTimeout(() => {
      if (!isNaN(+this.value)) {
        if (this.max && this.value > this.max) this.value = +this.max;
        if (this.min && this.value < this.min) this.value = +this.min;
        this.maskDirective.writeValue(String(this.value));
      }
    });
  }

  handleInput(event: DOMEvent<HTMLInputElement>): void {
    if (this.imaskOpts) {
      this.updateMaskValue();
      return;
    }
    // if number
    if (/^-?(\d+(\.|(\.\d+)?))?$/.test(event.target.value)) {
      const num = Number(event.target.value);
      this.value = num > this.max ? this.max : num < this.min ? this.min : num;

      return;
    }

    event.preventDefault();
    event.target.value = String(this.myValue);
  }

  handleAccept(event): void {
    this.value = +event;
  }
}
