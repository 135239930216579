import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { CustomerProblem, RoutePath, WINDOW } from '@common';
import { MyAccountSidepanelService } from '@common/components/my-account/my-account-sidepanel/my-account-sidepanel.service';
import {
  AuthUser,
  ProductMode,
  Provider,
  ServiceStatus,
  ServiceType,
  SystemInfo,
  hasAdminRole,
  hasDomainAdminRole,
  hasDomainUserRole,
  hasLimitedAdminRole,
  isEmergencySignIn
} from '@common/models';
import { AuthService, StatisticsService } from '@common/services';
import { getServiceUiInfo, isHomeUser } from '@common/utils';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { I18NextPipe } from 'angular-i18next';
import { get, isNil } from 'lodash';
import { Alert, AlertService, GuidEmpty, MbsPopupType } from 'mbs-ui-kit';
import { Observable, forkJoin, of } from 'rxjs';
import { filter, first, map, switchMap, tap } from 'rxjs/operators';
import { HeaderItem } from './app-header-item.model';

export type AlertCtrl = { link: string; text: string };
const pageRouterUrl = {
  [RoutePath.Dashboard]: ServiceType.All,
  [RoutePath.RetentionPolicy]: ServiceType.All,
  // [getServiceUiInfo(ServiceType.Users).url()]: ServiceType.Users,
  // [getServiceUiInfo(ServiceType.History).url()]: ServiceType.History,
  [getServiceUiInfo(ServiceType.Mail).url()]: ServiceType.Mail,
  [getServiceUiInfo(ServiceType.Drive).url()]: ServiceType.Drive,
  [getServiceUiInfo(ServiceType.Contacts).url()]: ServiceType.Contacts,
  [getServiceUiInfo(ServiceType.Calendar).url()]: ServiceType.Calendar,
  [getServiceUiInfo(ServiceType.TeamDrives).url()]: ServiceType.TeamDrives,
  [getServiceUiInfo(ServiceType.SharePoint).url()]: ServiceType.SharePoint,
  [getServiceUiInfo(ServiceType.Teams).url()]: ServiceType.Teams,
  [getServiceUiInfo(ServiceType.Export).url()]: ServiceType.Export
};

@UntilDestroy()
@Component({
  selector: 'app-header',
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.scss']
})
export class AppHeaderComponent implements OnInit {
  @ViewChild('accountDropdown', { static: true, read: NgbDropdown }) private accountDropdown: NgbDropdown;

  private systemInfo$: Observable<SystemInfo>;
  private readonly servicesNeedChangeDetectionHack = [
    ServiceType.Mail,
    ServiceType.Drive,
    ServiceType.Contacts,
    ServiceType.Calendar,
    ServiceType.History
  ];

  public readonly ProductMode = ProductMode;
  public readonly RoutePath = RoutePath;

  public isProviderSignIn$: Observable<boolean>;
  public productMode: ProductMode;
  public userId: string;
  public authUser: AuthUser;
  public items: HeaderItem[] = [];
  public isNavbarCollapsed = true;
  public emergencySignIn = false;
  public isAdmin: boolean;
  public isSingleUser: boolean;
  public isHomeUser = false;
  public isDomainUser = false;
  public isBusinessOffice = false;

  get queryParams(): URLSearchParams {
    return new URLSearchParams(this.window.location.search);
  }

  get getRouterUrl(): string {
    return this.router.url;
  }

  get prefix(): string {
    return this.authService?.prefix;
  }

  get isImmutability(): boolean {
    return this.authService?.isImmutability;
  }

  get screenX(): number {
    return this.window.innerWidth;
  }

  get showShortSystemLogo(): boolean {
    return this.screenX < 420;
  }

  get showNavText(): boolean {
    return this.screenX >= 1260;
  }

  get showMargin(): boolean {
    return this.screenX < 1090;
  }

  get isGoogle(): boolean {
    return this.authService.isGoogle;
  }

  constructor(
    @Inject(WINDOW) private window: Window,
    @Inject(DOCUMENT) private doc: Document,
    private alertService: AlertService,
    private authService: AuthService,
    private i18nPipe: I18NextPipe,
    private myAccountSidepanelService: MyAccountSidepanelService,
    private route: ActivatedRoute,
    private router: Router,
    private statisticsService: StatisticsService
  ) {
    this.systemInfo$ = authService.getSystemInfo();
    this.isProviderSignIn$ = authService.isProviderSignIn();
  }

  ngOnInit(): void {
    this.initSteams();
  }

  initSteams(): void {
    forkJoin([
      this.authService.getAuthUser(),
      this.authService.getRoles(),
      this.authService.getAvailableServices(),
      this.authService.getProviderRoles()
    ]).subscribe({
      next: ([authUser, roles, services, providerRoles]) => {
        this.authUser = authUser;
        this.isAdmin = hasAdminRole(roles);
        this.isSingleUser = !hasDomainAdminRole(roles) && !hasLimitedAdminRole(roles, providerRoles); // condition as at user-select
        this.emergencySignIn = isEmergencySignIn(roles);
        this.isBusinessOffice = authUser.Provider == Provider.OfficeBusiness;
        this.isHomeUser = isHomeUser(authUser);
        this.isDomainUser = hasDomainUserRole(roles);
        this.setUserId();

        this.initHeaderItemLinks(services);
      }
    });

    this.systemInfo$
      .pipe(
        tap((systemInfo) => (this.productMode = systemInfo.ProductMode)), // TODO: refactored using `ability` when added to project
        switchMap(() => this.getServiceStatus$(this.router.url)),
        untilDestroyed(this)
      )
      .subscribe((services) => {
        if (services.length > 0) services.forEach((s) => this.showAlertStatus(s));
      });

    this.router.events
      .pipe(
        filter((e) => e instanceof NavigationEnd && this.accessEventByUrl(e)),
        switchMap((e) => forkJoin([of(e), this.getServiceStatus$((e as NavigationEnd).url)])),
        map(([event, services]) => {
          if (services.length > 0) {
            services.forEach((s) => this.showAlertStatus(s));
          }

          return event;
        }),
        untilDestroyed(this)
      )
      .subscribe({
        next: () => {
          this.setUserId();

          if (this.accountDropdown.isOpen()) {
            this.accountDropdown.close();
          }
        }
      });

    this.isProviderSignIn$
      .pipe(
        map((isProvider) => !isProvider && this.needOpenMyAccountSidepanel()),
        filter(Boolean),
        untilDestroyed(this)
      )
      .subscribe({
        next: () => {
          this.myAccountSidepanelService.toggleSidepanel().pipe(untilDestroyed(this)).subscribe();
        }
      });
  }

  private accessEventByUrl(e): boolean {
    const availablePathsWithId = [RoutePath.Mail, RoutePath.Drive, RoutePath.Contacts, RoutePath.Calendar, RoutePath.History];
    const availablePaths = [RoutePath.Dashboard, RoutePath.RetentionPolicy];
    const segments = e.url.replace(this.authService.prefix, '').split('/').filter(Boolean);
    const stabled = this.getStabledSegments(segments, availablePathsWithId);

    return [...availablePathsWithId, ...availablePaths].some((path) => this.lastSegmentEqual(stabled, path));
  }

  private getStabledSegments(segments: string[], paths: RoutePath[]): string[] {
    if (this.isSingleUser) {
      return segments;
    } else {
      const skip = paths.some((path) => this.lastSegmentEqual(segments, path)); // skip URL without :ID though there must be one

      return skip ? [] : this.removeId(segments);
    }
  }

  private removeId(segments: string[]): string[] {
    const regexGUID = new RegExp(/^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/);
    const last = segments.at(-1);

    if (last.length === GuidEmpty.length && regexGUID.test(last)) {
      return segments.slice(0, -1);
    }

    return segments;
  }

  private lastSegmentEqual(segments: string[], path: RoutePath) {
    return segments.at(-1) === path;
  }

  private needOpenMyAccountSidepanel(): boolean {
    const param = this.queryParams.get('myAccount');

    return param?.toLowerCase() === 'true';
  }

  private getServiceStatus$(url: string): Observable<ServiceStatus[]> {
    return this.isNeedShowAlertStatus(url)
      ? this.statisticsService
          .getServiceStatus(this.pageServiceType(this.router.url))
          .pipe(map((services) => services.filter((s) => s.Status != CustomerProblem.NoProblem)))
      : of([]);
  }

  private isNeedShowAlertStatus(url: string): boolean {
    return !isNil(this.pageServiceType(url));
  }

  private pageServiceType(url: string): ServiceType | string {
    const FIRST_URL_SEGMENT_REGEX = new RegExp('^/[^/]+', 'i');
    const routerPathName = FIRST_URL_SEGMENT_REGEX.exec(url)
      ? new URL(url.replace(this.prefix, ''), this.doc.baseURI).pathname.split('/').filter(Boolean).shift()
      : '/';

    return pageRouterUrl[routerPathName];
  }

  private setUserId() {
    this.userId = get(this.route, 'firstChild.snapshot.params.id') || this.authUser.Id;
  }

  private initHeaderItemLinks(services: ServiceType[]): void {
    this.items = services.map((s) => {
      const serviceUiInfo = getServiceUiInfo(s);
      const needChangeDetectionHack: boolean = this.servicesNeedChangeDetectionHack.includes(s);

      return new HeaderItem(serviceUiInfo.displayName, serviceUiInfo.iconCssClass, () => serviceUiInfo.url(), needChangeDetectionHack);
    });
  }

  private showAlertStatus(status: ServiceStatus): void {
    const type: MbsPopupType = [
      CustomerProblem.PlanWillExpired,
      CustomerProblem.AlmostExceededStorageLimit,
      CustomerProblem.TrialRestriction
    ].includes(status.Status)
      ? MbsPopupType.warning
      : MbsPopupType.danger;
    const alertBody = this.getAlertBody(status);
    const alert: Alert = new Alert({
      type,
      content: alertBody,
      id: status.Status
    });

    this.alertService.alert(alert);
  }

  private getAlertBody(status: ServiceStatus): string {
    const { Status: customerProblem, Description: description } = status || {};
    const ctrl: AlertCtrl =
      this.productMode === ProductMode.mbs ? this.getAlertCtrlOffice(customerProblem) : this.getAlertCtrlGoogle(customerProblem);
    let message: string = description || '';

    if (ctrl && !this.isDomainUser) {
      message += `&nbsp;<a href="${ctrl.link}">${ctrl.text}</a>`;
    }

    return message;
  }

  getAlertCtrlOffice(status: CustomerProblem): AlertCtrl {
    if (status === CustomerProblem.StorageImmutabilityActive && !this.router.url.includes(RoutePath.RetentionPolicy)) {
      return {
        link: this.prefix + RoutePath.RetentionPolicy,
        text: this.i18nPipe.transform('header.alert.addPolicy', { format: 'title' })
      };
    }

    return null;
  }

  private getAlertCtrlGoogle(status: CustomerProblem): AlertCtrl {
    if (!status) return null;

    switch (status) {
      case CustomerProblem.LicenseProblem: {
        return {
          link: this.prefix + RoutePath.Payments + '?plan=edit',
          text: this.i18nPipe.transform('header.alert.subscribe', { format: 'capitalize' })
        };
      }
      case CustomerProblem.TrialWillExpired:
      case CustomerProblem.PlanExpired: {
        return {
          link: this.prefix + RoutePath.Payments + '?plan=select',
          text: this.i18nPipe.transform('header.alert.subscribe', { format: 'capitalize' })
        };
      }
      case CustomerProblem.InvoiceProblem: {
        return {
          link: this.prefix + RoutePath.Payments,
          text: this.i18nPipe.transform('header.alert.checkPaymentDetails', { format: 'capitalize' })
        };
      }
      default:
        throw new Error('Caught error manually: not handling value of CustomerProblem enum');
    }
  }

  handleOpenMyAccountSidepanel(): void {
    this.myAccountSidepanelService.toggleSidepanel().pipe(first()).subscribe();
  }

  handleSignAsAdmin(): void {
    this.authService.signAsAdmin();
  }

  handleLogout(): void {
    this.authService.logout();
  }
}
