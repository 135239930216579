import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, TemplateRef, Type } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { isTemplate, MbsPopupIcon, MbsPopupType, MbsSize } from '../../utils';
import Alert from '../alert.model';
import { alertDefaultId, AlertService } from '../alert.service';
import { filter, pairwise } from 'rxjs';
import { map, startWith } from "rxjs/operators";

@UntilDestroy()
@Component({
  selector: 'mbs-alert',
  templateUrl: './alert.component.html',
  host: {
    role: 'alert',
    '[class]': 'cssClasses'
  },
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AlertComponent implements OnInit {
  /**
   * @ignore
   */
  public readonly isTemplate = isTemplate;

  public readonly MbsSize = MbsSize;

  /**
   * Adding classes for default icon by type or custom icon
   */
  public get getAlertIcon(): string {
    return `alert-icon text-${this.type} ` + String(this.icon === true ? MbsPopupIcon[this.type] : this.icon);
  }

  /**
   * Custom classes for root tag `mbs-alert`
   */
  @Input('class') classList: string;
  public get cssClasses(): string {
    const classes = ['alert', `alert-${MbsPopupType[this.type]}`];
    const classList = this.classList && this.classList.length > 0 ? this.classList.trim() : '';

    if (classList) classes.push(classList);

    if (this.size) {
      const alertSizeClass = `alert-${MbsSize[this.size]}`;
      classes.push(alertSizeClass);
    }

    return classes.join(' ');
  }

  /**
   * Alert id. Need for delete or close alert
   */
  @Input() id: string = alertDefaultId;

  /**
   * Hide `Alert` after the preset time (ms)
   */
  @Input() autoClose: number = null;

  /**
   * If need, you can get context in `TemplateRef`. <br />
   * Also used for closing current `Alert` in `AlertContainerComponent`
   */
  @Input() context: Alert;

  /**
   * Alert content. <br />
   * Possible values: `string`, `TemplateRef` or 'HTML'. <br />
   * If `content` is used than ng-content will be hide (not accessible)
   */
  @Input() content: string | TemplateRef<any> | Type<any>;

  /**
   * Implements one `type` of enum `MbsPopupType` <br />
   * Possible values: `MbsPopupType.info` | `MbsPopupType.success` | `MbsPopupType.danger` | `MbsPopupType.warning` <br />
   */
  @Input() type: MbsPopupType = MbsPopupType.info;

  /**
   * Implements one of alert `size` <br />
   * Possible values: `MbsSize.sm` | `MbsSize.lg` <br />
   */
  @Input() size: MbsSize.sm | MbsSize.lg;

  /**
   * Implements alert icon. <br />
   * Possible values: `boolean` | `string`. <br />
   * If `true` - will show `default` icon by alert `type` <br />
   * if `false` or not installed - will hide `<div class="alert-icon_wrapper">` with icon <br />
   * If 'string' - will show `custom` icon. Example: `fa fa-car`
   */
  @Input() icon: boolean | string = false;

  /**
   * Close button. <br />
   * Possible values: `false` | `true`
   * If `true` - will show close button
   * If `false` - will hide close button
   */
  @Input() closable = false;

  @Input() ignoreRouteChanges = false;

  /**
   * An emit event by click close button for close alert
   */
  @Output() close = new EventEmitter();

  constructor(private router: Router, private alertService: AlertService) {}

  ngOnInit(): void {
    this.handleRouteChanges();
  }

  private handleRouteChanges(){
    if(this.ignoreRouteChanges){
      return
    }
    this.router.events.pipe(
      filter(event=> event instanceof NavigationStart),
      map((event: NavigationStart)=> event.url),
      startWith(this.router.url),
      map((url:string)=> url.split('?')[0].split('/')[2]),
      pairwise(),
      filter(([prev, curr]) => prev !== curr),
      untilDestroyed(this)).subscribe(() => this.alertService.clearById(this.id));
  }

  /**
   * Emit event for close `Alert` from parent component
   * @param {Alert} alert
   */
  closeAlert(alert: Alert): void {
    this.close.emit(alert);
  }
}
