import {
  ContentChild,
  Directive,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostBinding,
  HostListener,
  Input,
  Output,
  Renderer2
} from '@angular/core';
import { rotate, SortDirection, SortEvent } from '../../models/sort';
import { TableSortArrowDirective } from './table-sort-arrow.directive';

@Directive({
  exportAs: 'sortable',
  selector: `.table-grid_cell[sortable],[sortable]`
})
export class TableSortDirective {
  @HostBinding('class.asc') get asc(): boolean {
    return this.direction === 'asc';
  }
  @HostBinding('class.desc') get desc(): boolean {
    return this.direction === 'desc';
  }

  @Input() rotateSequence: { [key: string]: SortDirection } = rotate;

  @Input('sortable') columnName: string;

  /**
   * @deprecated
   * @param {string} value
   */
  @Input('columnName') set columnNameLegacy(value: string) {
    this.columnName = value;
  }

  @Input() direction: SortDirection = '';
  @Output() sort = new EventEmitter<SortEvent>();

  @ContentChild(forwardRef(() => TableSortArrowDirective), {
    read: ElementRef,
    static: true
  })
  arrow: ElementRef;

  @HostListener('click') rotate(): void {
    this.changeState(this.rotateSequence[this.direction]);
    this.sort.emit({ column: this.columnName, direction: this.direction });
  }

  constructor(private elem: ElementRef<any>, private render2: Renderer2) {}

  changeState(direction: SortDirection): void {
    this.direction = direction;
    if (direction !== '') {
      this.render2.addClass(this.elem.nativeElement, '-active');
      if (this.arrow) {
        this.render2.removeClass(this.arrow.nativeElement, 'd-none');
        this.render2.removeClass(this.arrow.nativeElement, 'ico-arrow' + (this.asc ? 'Down' : 'Up') + 'Sm');
        this.render2.addClass(this.arrow.nativeElement, 'ico-arrow' + (this.desc ? 'Down' : 'Up') + 'Sm');
      }
    } else {
      if (this.arrow) {
        this.render2.addClass(this.arrow.nativeElement, 'd-none');
      }
      this.render2.removeClass(this.elem.nativeElement, '-active');
    }
  }
}
