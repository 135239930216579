import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BackupOptions, OdataObject, ServiceType } from '@common/models';
import { ODataService, ODataServiceFactory } from '@common/odata';
import { AuthService } from '@common/services/auth.service';
import { hasActionsQueue } from '@common/utils';
import { containsWrapper } from '@common/utils/functions/search';
import { BehaviorSubject, Observable, forkJoin } from 'rxjs';
import { finalize, map, shareReplay, startWith } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class BackupService {
  #loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private backupUsers: ODataService<any>;

  public readonly requestPending$: Observable<boolean>;

  constructor(private odataFactory: ODataServiceFactory, private authService: AuthService) {
    this.backupUsers = this.odataFactory.CreateService<any>('BackupUsers');
    this.requestPending$ = this.#loading$.pipe(hasActionsQueue(), shareReplay(1));
  }

  canBackup(userId: string, serviceType: ServiceType): Observable<boolean> {
    const serviceName = ServiceType[serviceType];

    this.#loading$.next(true);
    return this.backupUsers.ItemProperty(userId, `BackupStatus?service=${serviceName}`).pipe(
      map((res: OdataObject<string>) => res == null),
      finalize(() => this.#loading$.next(false))
    );
  }

  canRestore(userId: string, serviceType: ServiceType): Observable<boolean> {
    const serviceName = ServiceType[serviceType];

    this.#loading$.next(true);
    return forkJoin([
      this.authService.restoreAvailable(),
      this.backupUsers.ItemProperty(userId, `RestoreStatus?service=${serviceName}`)
    ]).pipe(
      map(([restoreAvailable, restoreStatus]) => restoreAvailable && restoreStatus === null),
      finalize(() => this.#loading$.next(false))
    );
  }

  startBackup(userId: string, serviceType: ServiceType): Observable<any> {
    const serviceName = ServiceType[serviceType];

    return this.backupUsers.CustomAction(userId, 'Backup', { ServiceType: serviceName });
  }

  getBackupOptions(userId: string): Observable<BackupOptions> {
    return this.backupUsers
      .CustomFunction(userId, 'BackupOptions')
      .pipe(map((res: HttpResponse<BackupOptions>) => this.prepareBackupOptions(res.body)));
  }

  setBackupOptions(userId: string, options: BackupOptions): Observable<any> {
    this.#loading$.next(true);

    return this.backupUsers.CustomAction(userId, 'SetBackupOptions', options).pipe(finalize(() => this.#loading$.next(false)));
  }

  getArchiveOptions(userId: string): Observable<BackupOptions> {
    return this.backupUsers
      .CustomFunction(userId, 'ArchiveOptions')
      .pipe(map((res: HttpResponse<BackupOptions>) => this.prepareBackupOptions(res.body)));
  }

  setArchiveOptions(userId: string, options: BackupOptions): Observable<any> {
    this.#loading$.next(true);

    return this.backupUsers.CustomAction(userId, 'SetArchiveOptions', options).pipe(finalize(() => this.#loading$.next(false)));
  }

  getFolder(userId: string, search?: string): Observable<string[]> {
    const odataService = this.odataFactory.CreateService<{ Name: string }>(`/BackupUsers(${userId})/GetFolders`);

    return odataService
      .Query()
      .Top(100)
      .Filter(containsWrapper('Name', search))
      .Exec()
      .pipe(
        map((folders) => folders.map((f) => f.Name)),
        startWith([])
      );
  }

  private prepareBackupOptions(options: BackupOptions): BackupOptions {
    if (options) {
      delete options['@odata.context'];
      options.ApplyToAllDomainUsers = false;
    }

    return options;
  }
}
