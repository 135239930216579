import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { APPEND_BUTTON_PASSWORD_STATE, ODataError } from '@common';
import { UserOdataService } from '@common/services';
import { getAppendButtonsIcon, getAppendButtonsState, hasAppendButtonsPasswordType } from '@common/utils';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { I18_NAMESPACE_APPS_UI } from 'i18n';
import { MbsValidators, ModalComponent } from 'mbs-ui-kit';
import { BehaviorSubject } from 'rxjs';
import { finalize } from 'rxjs/operators';

@UntilDestroy()
@Component({
  templateUrl: './two-factor-disable.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TwoFactorDisableComponent implements OnInit {
  @ViewChild(ModalComponent, { static: true }) baseModal: ModalComponent;

  #userId: string;

  public disableLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public loading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public passwordType$: BehaviorSubject<string> = new BehaviorSubject(APPEND_BUTTON_PASSWORD_STATE.hidden.type);

  public readonly moduleAccount = I18_NAMESPACE_APPS_UI.account;
  public readonly getAppendButtonsIcon = getAppendButtonsIcon;

  public formGroup: FormGroup;

  set userId(value: string) {
    this.#userId = value;
  }

  get userId(): string {
    return this.#userId;
  }

  get passwordControl(): FormControl<string> {
    return <FormControl>this.formGroup.get('password');
  }

  constructor(private fb: FormBuilder, private userService: UserOdataService) {}

  ngOnInit(): void {
    this.userId = this.baseModal.data.id;
    this.initForm();
  }

  private initForm(): void {
    this.formGroup = this.fb.group({
      password: [null, [Validators.required, MbsValidators.passwordValidator, Validators.maxLength(20)]]
    });
  }

  handleDisable(): void {
    const password = this.passwordControl.value;

    this.disableLoading$.next(true);
    this.userService
      .disableAlternateTwoStep(this.userId, password)
      .pipe(
        finalize(() => this.disableLoading$.next(false)),
        untilDestroyed(this)
      )
      .subscribe({
        next: () => {
          this.baseModal.save(true);
        },
        error: (res: HttpErrorResponse) => {
          if (res.status === 400) {
            const odataError = res.error as ODataError;
            const errorText = odataError && odataError.value ? odataError.value : null;

            this.setPasswordErrorState(errorText);
          }
        }
      });
  }

  private setPasswordErrorState(message: string): void {
    this.passwordControl.setValue('');
    this.passwordControl.setErrors({ password: { message } });
  }

  handleClose(): void {
    this.baseModal.close();
  }

  handleChangePasswordType(): void {
    const isPasswordType: boolean = hasAppendButtonsPasswordType(this.passwordType$.value);

    this.passwordType$.next(APPEND_BUTTON_PASSWORD_STATE[getAppendButtonsState(isPasswordType)].type);
  }
}
