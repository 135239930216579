import { Location } from '@angular/common';
import { Inject, Injectable, Injector, Type } from '@angular/core';
import { WINDOW } from '@common';
import { MyAccountSidepanelComponent } from '@common/components/my-account/my-account-sidepanel/my-account-sidepanel.component';
import { Sidepanel, SidepanelBase, SidepanelCrudBase, SidepanelService } from 'mbs-ui-kit';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MyAccountSidepanelService {
  private readonly componentInstance: Type<SidepanelBase | SidepanelCrudBase<boolean>>;
  public sidepanel: Sidepanel;

  constructor(
    @Inject(WINDOW) private window: Window,
    private injector: Injector,
    private location: Location,
    private sidepanelService: SidepanelService
  ) {
    this.componentInstance = MyAccountSidepanelComponent;
  }

  toggleSidepanel(open = true): Observable<Sidepanel> {
    if (!this.sidepanel) {
      this.sidepanel = this.sidepanelService.add(this.componentInstance, null, this.injector);
    }

    return this.toggleByType(this.componentInstance, open);
  }

  private toggleByType<T extends Sidepanel>(type: Type<T>, open: boolean): Observable<T> {
    const panel = this.sidepanelService.get(type) || this.sidepanelService.add(type, null, this.injector);

    return this.sidepanelService[open ? 'openByType' : 'closeByType'](type).pipe(
      first(),
      map(() => panel)
    );
  }
}
