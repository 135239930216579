import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { JwtModule } from '@auth0/angular-jwt';
import { BrowserStorage } from '@common';
import { AppLayoutComponent } from '@common/_layout/app-layout/app-layout.component';
import { AppFooterComponent } from '@common/components/app-footer/app-footer.component';
import { AppHeaderComponent } from '@common/components/app-header/app-header.component';
import { DontHavePermissionModule } from '@common/components/dont-have-permission/dont-have-permission.module';
import { ErrorHandlerModule } from '@common/components/error-handler/error-handler.module';
import { AuthCallbackComponent } from '@common/components/login/auth-callback/auth-callback.component';
import { LoginComponent } from '@common/components/login/login.component';
import { SignInAlternateEmailModalComponent } from '@common/components/login/sign-in-alternate-email-modal/sign-in-alternate-email-modal.component';
import { MyAccountModule } from '@common/components/my-account/my-account.module';
import { AuthGuard } from '@common/guards/auth.guard';
import { DataChangeWatcherGuard } from '@common/guards/data-change-watcher.guard';
import { ErrorHandlerInterceptor } from '@common/interceptors/error-handler.interceptor';
import { SentryInterceptor } from '@common/interceptors/sentry.interceptor';
import { SessionInterceptor } from '@common/interceptors/session.interceptor';
import { PipesModule } from '@common/pipes/pipes.module';
import { AppInitService } from '@common/services/app-init.service';
import { GlobalErrorHandler } from '@common/services/global-error-handler.service';
import { environment } from '@env/environment';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { AccountComponent } from '@pages/account/component/account.component';
import { ResetPasswordComponent } from '@pages/account/component/reset-password/reset-password.component';
import { NotFoundComponent } from '@pages/not-found/component/not-found.component';
import { PaymentsModule } from '@pages/payments/payments.module';
import { I18NextModule } from 'angular-i18next';
import { I18N_PROVIDERS, I18_NAMESPACES_APPS_UI, I18_NAMESPACES_MBS_UI_KIT, I18_NAMESPACE_APPS_UI } from 'i18n';
import { AlertModule, MbsUiKitModule, ToastModule } from 'mbs-ui-kit';
import { IconSpriteModule } from 'ng-svg-icon-sprite';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

@NgModule({
  declarations: [
    AccountComponent,
    AppComponent,
    AppLayoutComponent,
    AppHeaderComponent,
    AppFooterComponent,
    AuthCallbackComponent,
    LoginComponent,
    NotFoundComponent,
    SignInAlternateEmailModalComponent,
    ResetPasswordComponent
  ],
  imports: [
    AlertModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    CommonModule,
    DontHavePermissionModule,
    ErrorHandlerModule.forRoot(),
    FormsModule,
    HttpClientModule,
    IconSpriteModule.forRoot({ path: './assets/svg/sprites/sprite.svg' }),
    I18NextModule.forRoot(),
    JwtModule.forRoot({
      config: {
        tokenGetter: () => {
          try {
            return JSON.parse(localStorage.getItem(BrowserStorage.Prefix + BrowserStorage.Token));
          } catch (e) {
            return null;
          }
        }
      }
    }),
    MbsUiKitModule,
    MyAccountModule,
    NgbModule,
    NgSelectModule,
    PaymentsModule,
    PipesModule,
    ReactiveFormsModule,
    ToastModule,
    PipesModule
  ],
  providers: [
    AuthGuard,
    DataChangeWatcherGuard,
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SessionInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHandlerInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SentryInterceptor,
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      deps: [AppInitService],
      useFactory: (appInitService: AppInitService) => () => appInitService.init(),
      multi: true
    },
    I18N_PROVIDERS(environment.localePath, [...I18_NAMESPACES_APPS_UI, ...I18_NAMESPACES_MBS_UI_KIT], I18_NAMESPACE_APPS_UI.app)
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
