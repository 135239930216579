<header class="mbs-form-group_header"
        [ngSwitch]="titleSize"
        *ngIf="title">
  <h1 *ngSwitchCase="'h1'"
      class="mbs-form-group_title"
      [ngClass]="titleClasses">
    {{ title }}
  </h1>
  <h2 *ngSwitchCase="'h2'"
      class="mbs-form-group_title"
      [ngClass]="titleClasses">
    {{ title }}
  </h2>
  <h3 *ngSwitchCase="'h3'"
      class="mbs-form-group_title"
      [ngClass]="titleClasses">
    {{ title }}
  </h3>
  <h4 *ngSwitchCase="'h4'"
      class="mbs-form-group_title"
      [ngClass]="titleClasses">
    {{ title }}
  </h4>
  <h5 *ngSwitchCase="'h5'"
      class="mbs-form-group_title"
      [ngClass]="titleClasses">
    {{ title }}
  </h5>
  <h6 *ngSwitchCase="'h6'"
      class="mbs-form-group_title"
      [ngClass]="titleClasses">
    {{ title }}
  </h6>
  <h5 *ngSwitchDefault
      class="mbs-form-group_title"
      [ngClass]="titleClasses">
    {{ title }}
  </h5>
</header>

<div class="mbs-form-group_content"
     [ngClass]="contentCustomClass">
  <ng-container *ngTemplateOutlet="formGroupContent"></ng-container>
</div>

<ng-template #formGroupContent>
  <ng-content></ng-content>
</ng-template>
