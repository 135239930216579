import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, TemplateRef, ViewChild } from '@angular/core';
import { FormError, RoutePath } from '@common';
import { BackupStatisticGrid, DeleteUserBackupOutput } from '@common/components/delete-user-backup';
import { DeleteBackup, ServiceType } from '@common/models';
import { AuthService, UserOdataService } from '@common/services';
import { getErrorText, hasErrorResponseText, isHomeUser } from '@common/utils';
import { UntilDestroy } from '@ngneat/until-destroy';
import { I18NextPipe } from 'angular-i18next';
import { I18_NAMESPACE_APPS_UI } from 'i18n';
import { MbsPopupType, ModalComponent, ToastService } from 'mbs-ui-kit';
import { BehaviorSubject, Observable, forkJoin, switchMap } from 'rxjs';
import { finalize, map, startWith } from 'rxjs/operators';

@UntilDestroy()
@Component({
  templateUrl: './delete-backup-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeleteBackupModalComponent {
  @ViewChild(ModalComponent, { static: true }) baseModal: ModalComponent;
  @ViewChild('deleteJobTransferredTemplateRef', { static: false, read: TemplateRef }) deleteJobTransferredRef: TemplateRef<any>;

  #payloadForDeleteBackup: DeleteBackup = {} as DeleteBackup;
  readonly #toastTitleSuccess = this.i18nPipe.transform('toast.success.title', { format: 'title' });
  readonly #toastTitleError = this.i18nPipe.transform('toast.error.title', { format: 'title' });

  public readonly deleteLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public readonly loading$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  public readonly disabled$: BehaviorSubject<boolean> = new BehaviorSubject(true);

  public readonly services$: BehaviorSubject<ServiceType[]> = new BehaviorSubject([]);
  public readonly moduleAccount = I18_NAMESPACE_APPS_UI.account;

  public readonly RoutePath = RoutePath;
  public readonly MbsPopupType = MbsPopupType;
  public backupStatistics$: Observable<BackupStatisticGrid[]>;

  public deleteBackupFormError: FormError = { message: '' };

  get userId(): string {
    return this.authService.id;
  }

  get prefix(): string {
    return this.authService?.prefix;
  }

  constructor(
    private authService: AuthService,
    public userService: UserOdataService,
    private toastService: ToastService,
    private i18nPipe: I18NextPipe
  ) {
    this.backupStatistics$ = forkJoin([
      this.authService.getAuthUser().pipe(switchMap((user) => this.userService.getBackupStatistic([this.userId], !isHomeUser(user)))),
      this.authService.getRoles()
    ]).pipe(
      map(([statistic, roles]) => this.userService.mapBackupStatisticToGridList(statistic, roles)),
      startWith([]),
      finalize(() => this.loading$.next(false))
    );
  }

  invalidForms(state: boolean): void {
    this.disabled$.next(!state);
  }

  deleteBackupChange(value: DeleteUserBackupOutput): void {
    this.#payloadForDeleteBackup = {
      UserIds: [this.userId],
      Email: value.deleteServices.includes(ServiceType.Mail),
      Drive: value.deleteServices.includes(ServiceType.Drive),
      Contact: value.deleteServices.includes(ServiceType.Contacts),
      Calendar: value.deleteServices.includes(ServiceType.Calendar),
      TeamDrive: value.deleteServices.includes(ServiceType.TeamDrives),
      SharePoint: value.deleteServices.includes(ServiceType.SharePoint),
      Password: value.password
    };

    this.services$.next(value.deleteServices);
  }

  handleDelete(): void {
    this.deleteLoading$.next(true);
    this.userService
      .deleteBackup(this.#payloadForDeleteBackup)
      .pipe(finalize(() => this.deleteLoading$.next(false)))
      .subscribe({
        next: () => {
          this.toastService.success(this.deleteJobTransferredRef, this.#toastTitleSuccess);
          this.baseModal.save(true);
        },
        error: (res: HttpErrorResponse) => hasErrorResponseText(res) && this.toastService.error(getErrorText(res), this.#toastTitleError) // catching error interceptor
      });
  }

  handleClose(): void {
    this.baseModal.close();
  }
}
