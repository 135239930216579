import { Pipe, PipeTransform } from '@angular/core';
import { InvoiceState } from '@common';

enum InvoiceStatusIconTypeEnum {
  expose = 'fa fa-pause-circle',
  paid = 'fa fa-check-circle',
  error = 'fa fa-times-circle',
  wrongPrice = 'fa fa-exclamation-circle',
  waitPayment = 'fa fa-clock-o',
  sendErrorEmail = 'fa fa-envelope-o'
}

enum InvoiceStatusTypeEnum {
  expose = 'muted',
  paid = 'success',
  error = 'danger',
  wrongPrice = 'danger',
  waitPayment = 'info',
  sendErrorEmail = 'muted'
}

@Pipe({
  name: 'invoiceStatus',
  pure: true
})
export class InvoiceStatusPipe implements PipeTransform {
  private readonly invoiceStatusIconMap: Map<InvoiceState, string> = new Map([
    [InvoiceState.Expose, InvoiceStatusIconTypeEnum.expose],
    [InvoiceState.Paid, InvoiceStatusIconTypeEnum.paid],
    [InvoiceState.Error, InvoiceStatusIconTypeEnum.error],
    [InvoiceState.WrongPrice, InvoiceStatusIconTypeEnum.wrongPrice],
    [InvoiceState.WaitPayment, InvoiceStatusIconTypeEnum.waitPayment],
    [InvoiceState.SendErrorEmail, InvoiceStatusIconTypeEnum.sendErrorEmail]
  ]);

  private readonly invoiceStatusTypeMap: Map<InvoiceState, string> = new Map([
    [InvoiceState.Expose, InvoiceStatusTypeEnum.expose],
    [InvoiceState.Paid, InvoiceStatusTypeEnum.paid],
    [InvoiceState.Error, InvoiceStatusTypeEnum.error],
    [InvoiceState.WrongPrice, InvoiceStatusTypeEnum.wrongPrice],
    [InvoiceState.WaitPayment, InvoiceStatusTypeEnum.waitPayment],
    [InvoiceState.SendErrorEmail, InvoiceStatusTypeEnum.sendErrorEmail]
  ]);

  transform(state: InvoiceState, param: 'icon' | 'type' = 'icon'): unknown {
    if (!state) return '';

    switch (param) {
      case 'icon': {
        const classes = ['text-base'];

        classes.push(this.invoiceStatusIconMap.get(state));

        return classes.join(' ');
      }
      case 'type':
        return this.invoiceStatusTypeMap.get(state);
      default:
        return '';
    }
  }
}
