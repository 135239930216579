import { EventEmitter, Injectable } from '@angular/core';
import { BrowserStorage, CustomerProblem } from '@common';
import {
  GridFiltersLStorageStateBHistory,
  SPanelFiltersLStorageStateBHistory
} from '@common/models/backup-history/filters-backupHistory.model';
import { PersistentStateServiceBase } from 'mbs-ui-kit';

export class PersistentState {
  [BrowserStorage.CustomerProblem]: CustomerProblem[] = [];
  [BrowserStorage.BHistoryGridFilters]: GridFiltersLStorageStateBHistory = null;
  [BrowserStorage.BHistorySPanelFilters]: SPanelFiltersLStorageStateBHistory = null;
  [BrowserStorage.SPointDisabled] = null;
  [BrowserStorage.SPointNoSiteBackup] = null;
  [BrowserStorage.SkippedItemsHistory] = null;
  [BrowserStorage.UpdateInterval] = 30;
}

@Injectable({ providedIn: 'root' })
export class PersistentStateService extends PersistentStateServiceBase<PersistentState> {
  public readonly eventEmitter$: EventEmitter<string>;

  constructor() {
    super(PersistentState, new EventEmitter<string>());
    this.eventEmitter$ = this.getEmitterInstance();
    this.eventEmitter$.emit(BrowserStorage.Prefix);
    this.listenStorageChange();
  }
}
