import { Injectable } from '@angular/core';
import { AuditODataItem, UserActionType, UserActionTypeArea } from '@common/models';
import { ODataPagedResult } from '@common/odata';
import { UserActionTypeAreaPipe, UserActionTypePipe } from '@common/pipes';
import { AuditService } from '@common/services';
import { AuditLogTagsEnum, AuditLogTagsMap, getUniqueListByProp } from '@common/services/smart-search';
import SmartSearchTemplatesBase from '@common/services/smart-search/smart-search-template-base';
import { FilterOptions, getFilterByContains, getFilterByEqFromEnum } from '@common/utils/functions/search';
import { ModelTemplate, SmartSearchState } from 'mbs-ui-kit';
import { Observable, asapScheduler, of, scheduled } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable()
export class SmartSearchModelTemplateAuditLogService extends SmartSearchTemplatesBase {
  readonly #userName = AuditLogTagsMap.get(AuditLogTagsEnum.userName);
  readonly #event = AuditLogTagsMap.get(AuditLogTagsEnum.event);
  readonly #action = AuditLogTagsMap.get(AuditLogTagsEnum.action);
  readonly #from = AuditLogTagsMap.get(AuditLogTagsEnum.from);
  readonly #to = AuditLogTagsMap.get(AuditLogTagsEnum.to);

  private keyCache = 'audit-log-name_';

  private readonly auditLogService$: Observable<ODataPagedResult<AuditODataItem>>;
  private readonly userActionTypePipe = new UserActionTypePipe();
  private readonly userActionTypeAreaPipe = new UserActionTypeAreaPipe();

  constructor(private auditService: AuditService) {
    super();
    this.auditLogService$ = auditService.getAuditLog();
  }

  public readonly UserNameTag: ModelTemplate<AuditODataItem> = {
    tag: this.#userName.tag,
    items: (state: SmartSearchState): Observable<AuditODataItem[]> => {
      const term = state.leftCaretValue;
      const options: FilterOptions = {
        model: [{ value: term }],
        prop: this.#userName.prop
      };

      this.auditService.filter = term ? getFilterByContains(options) : '';

      // if (this.cache[this.keyCache + term]) {
      //   return of(this.cache[this.keyCache + term]) as Observable<AuditODataItem[]>;
      // }

      return scheduled(
        this.auditLogService$.pipe(
          map((res) => res.data),
          map((items: AuditODataItem[]) => {
            const filtered = items.filter((item) => item.UserName.toLowerCase().includes(term));

            return getUniqueListByProp(filtered, this.#userName.prop).slice(0, 10);
          }),
          tap<AuditODataItem[]>(this.writeToCache(this.keyCache + term))
        ),
        asapScheduler
      );
    },
    itemFormatter: ({ UserName }): string => UserName,
    addGroupBrackets: true
  };

  public readonly EventTag: ModelTemplate<AuditODataItem> = {
    tag: this.#event.tag,
    items: (state: SmartSearchState): Observable<AuditODataItem[]> => {
      const term = state.leftCaretValue;
      const options: FilterOptions = {
        model: [{ value: term }],
        prop: this.#event.prop,
        enumItems: UserActionTypeArea
      };

      this.auditService.filter = term ? getFilterByEqFromEnum(options) : '';

      // if (this.cache[this.keyCache + term]) {
      //   return of(this.cache[this.keyCache + term]) as Observable<AuditODataItem[]>;
      // }

      return scheduled(
        this.auditLogService$.pipe(
          map((res) => res.data),
          map((items: AuditODataItem[]) => {
            const filtered = items.filter((item) => item.UserActionTypeArea.toLowerCase().includes(term));

            return getUniqueListByProp(filtered, this.#event.prop).slice(0, 10);
          }),
          tap<AuditODataItem[]>(this.writeToCache(this.keyCache + term))
        ),
        asapScheduler
      );
    },
    itemFormatter: ({ UserActionTypeArea }): string => this.userActionTypeAreaPipe.transform(UserActionTypeArea),
    addGroupBrackets: true
  };

  public readonly ActionTag: ModelTemplate<AuditODataItem> = {
    tag: this.#action.tag,
    items: (state: SmartSearchState): Observable<AuditODataItem[]> => {
      const term = state.leftCaretValue;
      const options: FilterOptions = {
        model: [{ value: term }],
        prop: this.#action.prop,
        enumItems: UserActionType
      };

      this.auditService.filter = term ? getFilterByEqFromEnum(options) : '';

      // if (this.cache[this.keyCache + term]) {
      //   return of(this.cache[this.keyCache + term]) as Observable<ExportResultsOdata[]>;
      // }

      return scheduled(
        this.auditLogService$.pipe(
          map((res) => res.data),
          map((items: AuditODataItem[]) => {
            const filtered = items.filter((item) => item.UserActionType.toLowerCase().includes(term));

            return getUniqueListByProp(filtered, this.#action.prop).slice(0, 10);
          }),
          tap<AuditODataItem[]>(this.writeToCache(this.keyCache + term))
        ),
        asapScheduler
      );
    },
    itemFormatter: ({ UserActionType }): string => this.userActionTypePipe.transform(UserActionType),
    addGroupBrackets: true
  };

  public readonly FromTag: ModelTemplate<AuditODataItem> = {
    tag: this.#from.tag,
    items: (state: SmartSearchState): Observable<AuditODataItem[]> => of([]) as Observable<AuditODataItem[]>,
    addGroupBrackets: true
  };

  public readonly ToTag: ModelTemplate<AuditODataItem> = {
    tag: this.#to.tag,
    items: (state: SmartSearchState): Observable<AuditODataItem[]> => of([]) as Observable<AuditODataItem[]>,
    addGroupBrackets: true
  };
}
