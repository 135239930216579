import { Component, ContentChild, EventEmitter, Input, Output } from '@angular/core';
import { InputButton, InputComponent } from '../index';
import { MbsSize } from '../../utils';

@Component({
  selector: 'mbs-input-range',
  templateUrl: './input-range.component.html'
})
export class InputRangeComponent {
  @Input() disabled = false;
  @Input() loading = false;
  @Input() label: string;
  @Input() appendButtons: InputButton[];
  @Input() prependButtons: InputButton[];
  @Input() size: MbsSize.xs | MbsSize.sm | MbsSize.md | MbsSize.lg = null;

  @Output() buttonClick = new EventEmitter();

  @ContentChild('startDate') startInput: InputComponent;
  @ContentChild('endDate') endInput: InputComponent;

  get sizeClass(): string {
    return this.size ? 'form-control-' + this.size : '';
  }

  get sizeGroupClass(): string {
    return this.size ? 'input-group_control-' + this.size : '';
  }

  get classes(): { [key: string]: boolean } {
    const newClass: { [key: string]: boolean } = { focus: this.isFocus, disabled: this.disabled };

    if (this.size) {
      newClass['form-control-' + this.size] = true;
      newClass['d-flex'] = true;
      newClass['align-items-center'] = true;
    }

    return newClass;
  }

  public isFocus = false;
}
