<mbs-modal [title]="'changeAlternateEmailModal.title.changeAlternateEmail' | i18next: { format: 'title' }">

  <ng-container modal-body>

    <form [formGroup]="changeEmailForm">

      <mbs-form-group>
        <mbs-input [label]="'changeAlternateEmailModal.label.newAlternateEmail' | i18next: { format: 'title' }"
                   [boldLabel]="true"
                   autocomplete="off"
                   type="email"
                   formControlName="Email"
                   mbsAutoFocus></mbs-input>
      </mbs-form-group>

      <mbs-form-group class="mb-0">
        <mbs-input [label]="'changeAlternateEmailModal.label.alternateEmailPassword' | i18next: { format: 'title' }"
                   [boldLabel]="true"
                   [appendButtons]="[
                     {
                       id: 'append-password',
                       type: 'secondary',
                       icon: getAppendButtonsIcon(passwordType$ | async),
                       btnType: 'button',
                       loading: false,
                       disabled: false
                     }
                   ]"
                   (buttonClick)="handleChangePasswordType()"
                   [type]="passwordType$ | async"
                   autocomplete="off"
                   formControlName="Password"></mbs-input>
      </mbs-form-group>

    </form>

  </ng-container>

  <ng-container modalFooter>

    <mbs-button type="primary"
                [loading]="changeLoading$ | async"
                [disabled]="changeEmailForm.invalid"
                (click)="changeButtonClick()">
      {{ 'changeAlternateEmailModal.button.change' | i18next: { format: 'title' } }}
    </mbs-button>

    <mbs-button (click)="cancelButtonClick()">
      {{ 'changeAlternateEmailModal.button.cancel' | i18next: { format: 'title' } }}
    </mbs-button>

  </ng-container>

</mbs-modal>
