import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { MIN_SCREEN_HEIGHT, RoutePath, WINDOW } from '@common';
import { AutoActivationMode, AutoActiveActionType, AzureGroup, OrganizationalUnit } from '@common/models';
import { AuthService, AzureGroupsService, DomainService } from '@common/services';
import { OrganizationalUnitsService } from '@common/services/organizational-units.service';
import {
  SmartSearchModelTemplateUserGroupsService,
  UserGroupsTagsEnum,
  UserGroupsTagsMap
} from '@common/services/smart-search/user-groups';
import {
  canAbilityCdRef,
  getDefaultPaginationOptions,
  getLoadingState,
  getMaxHeight,
  getOdataOrderBy,
  hasActionsQueue
} from '@common/utils';
import { FilterOptions, getFilterByEq } from '@common/utils/functions/search';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { EditGroupSettingModalComponent } from '@pages/users/user-groups/modals/edit-group-setting/edit-group-setting-modal.component';
import { NewGroupSettingsModalComponent } from '@pages/users/user-groups/modals/new-group-settings/new-group-settings-modal.component';
import { I18NextPipe } from 'angular-i18next';
import { I18_NAMESPACE_APPS_UI } from 'i18n';
import { cloneDeep, noop } from 'lodash';
import {
  MbsSize,
  ModalService,
  ModalSettings,
  ModelTemplate,
  PaginationOptions,
  SharedPersistentStateEnum,
  SmartSearchModel,
  SortEvent,
  TableHeader
} from 'mbs-ui-kit';
import { ExtendedTableRow } from 'mbs-ui-kit/table-grid/table/table.component';
import { BehaviorSubject, Observable, from, of, switchMap } from 'rxjs';
import { catchError, filter, finalize, tap } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'app-user-groups',
  templateUrl: './user-groups.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserGroupsComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('mbsTableGridRef', { static: false, read: ElementRef }) mbsTableGridElRef: ElementRef;

  readonly #toastTitleSuccess = this.i18nPipe.transform('toast.success.title', { format: 'title' });
  readonly #loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public readonly loadingNewGroup$ = new BehaviorSubject<boolean>(false);
  public readonly loadingEnabledAllGroupMembers$ = new BehaviorSubject<boolean>(false);
  public readonly loadingEnabledNewlyCreatedUsers$ = new BehaviorSubject<boolean>(false);

  private readonly defaultToastBodySuccessText: string;
  private readonly constSortProp = 'Order';

  public readonly headers: TableHeader[];
  public readonly moduleUsers = I18_NAMESPACE_APPS_UI.users;
  public readonly moduleButton = I18_NAMESPACE_APPS_UI.button;
  public readonly viewMode: SharedPersistentStateEnum = SharedPersistentStateEnum.table;
  public readonly MbsSize = MbsSize;
  public readonly RoutePath = RoutePath;
  public readonly AutoActiveActionType = AutoActiveActionType;

  readonly #allUsers = this.i18nPipe.transform(this.moduleUsers + ':grid.allUsers', { format: 'title' });
  readonly #newUsers = this.i18nPipe.transform(this.moduleUsers + ':grid.newCreated', { format: 'title' });
  readonly #disabled = this.i18nPipe.transform(this.moduleUsers + ':grid.disabled', { format: 'title' });

  public oneEntityKey: string;
  public manyEntitiesKey: string;

  public isSelectAllOnAllPages = false;

  public filterModeModel = null;
  public modes = [this.#allUsers, this.#newUsers, this.#disabled];
  public selectedItems: AzureGroup[] | OrganizationalUnit[] = [];
  public searchTemplates: ModelTemplate<AzureGroup | OrganizationalUnit | string>[] = [];
  public orderBy: SortEvent = { column: this.groupService().name.prop, direction: 'asc' };
  public data$ = new BehaviorSubject<AzureGroup[]>([]);
  public loading$: Observable<boolean>;
  public maxHeight$: BehaviorSubject<string> = new BehaviorSubject('');
  public paginationOptions: PaginationOptions = getDefaultPaginationOptions();

  get isGoogle(): boolean {
    return this.authService.isGoogle;
  }

  get prefix(): string {
    return this.authService?.prefix;
  }

  get selectedCount(): number {
    return this.isSelectAllOnAllPages ? this.paginationOptions.dataSize : this.selectedItems?.length;
  }

  get screenX(): number {
    return this.window.innerWidth;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    if (event.target instanceof Window) {
      this.updateMaxHeight();
    }
  }

  constructor(
    @Inject(WINDOW) private window: Window,
    private i18nPipe: I18NextPipe,
    private azureGroupsService: AzureGroupsService,
    private orgUnitsService: OrganizationalUnitsService,
    private domainService: DomainService,
    private authService: AuthService,
    private cdRef: ChangeDetectorRef,
    private modalService: ModalService,
    templates: SmartSearchModelTemplateUserGroupsService
  ) {
    this.searchTemplates = [templates.NameTag];
    this.loading$ = getLoadingState([this.#loading$.pipe(hasActionsQueue())]);
    this.headers = this.getTableHeaders();
    this.defaultToastBodySuccessText = i18nPipe.transform(this.moduleUsers + ':toast.body.requestWasSentSuccessfully', {
      format: 'capitalize'
    });
    this.oneEntityKey = this.isGoogle ? this.moduleUsers + ':grid.unit' : this.moduleUsers + ':grid.group';
    this.manyEntitiesKey = this.isGoogle ? this.moduleUsers + ':grid.units' : this.moduleUsers + ':grid.groups';
  }

  ngOnInit(): void {
    this.initCustomOrderBy();
    this.fetchData();
  }

  ngAfterViewInit(): void {
    queueMicrotask(() => {
      this.updateMaxHeight();
      canAbilityCdRef.call(this) && this.cdRef.detectChanges();
    });
  }

  ngOnDestroy(): void {
    this.groupService().clearCache();
  }

  private groupService(): OrganizationalUnitsService | AzureGroupsService {
    return this.isGoogle ? this.orgUnitsService : this.azureGroupsService;
  }

  private initCustomOrderBy(): void {
    this.groupService().orderBy = getOdataOrderBy(this.orderBy.column, this.orderBy.direction, this.constSortProp);
  }

  fetchData(): void {
    this.#loading$.next(true);

    this.groupService()
      .fetchGroups()
      .pipe(
        catchError(() => of({ count: 0, data: [] })),
        finalize(() => this.#loading$.next(false)),
        untilDestroyed(this)
      )
      .subscribe({
        next: ({ data, count }) => {
          this.updatePaginationOptionsDataSize(count);
          this.data$.next(data);
        }
      });
  }

  private updatePaginationOptionsDataSize(count: number): void {
    this.paginationOptions = {
      ...this.paginationOptions,
      dataSize: count
    };
  }

  handleGroupChange(group: AzureGroup | OrganizationalUnit): void {
    const settings: ModalSettings = {
      size: MbsSize.sm,
      data: {
        selected: group
      }
    };

    from(this.modalService.openCustom(EditGroupSettingModalComponent, settings))
      .pipe(
        filter(Boolean),
        switchMap(() => this.domainService.getFetchDomain()),
        untilDestroyed(this)
      )
      .subscribe({
        next: () => this.fetchData()
      });
  }

  private updateGroupProperty(group: AzureGroup, options?: { isError: boolean; property?: string }): void {
    const { isError = false, property = 'AutoAddUserToBackup' } = options || {};
    const founded = this.data$.value.find((g) => g.Id === group.Id);

    founded[property] = isError ? !group[property] : group[property];
  }

  handlePageChange(page: number): void {
    this.paginationOptions.page = page;
    this.groupService().page = page;

    this.fetchData();
  }

  handlePageSizeChange(options: PaginationOptions): void {
    this.paginationOptions = { ...options, page: 1 };
    this.groupService().page = 1;
    this.groupService().pageSize = this.paginationOptions.pageSize;

    this.fetchData();
  }

  handleSort({ column, direction }: SortEvent): void {
    this.orderBy = { column, direction };
    this.groupService().orderBy = getOdataOrderBy(column, direction, this.constSortProp);

    this.fetchData();
  }

  handleUpdateFilter(searchObj: SmartSearchModel): void {
    if (!searchObj) return;

    this.groupService().updateFilter(this.normalizeAutoActivation(searchObj));
    this.resetPagePagination();
    this.fetchData();
  }

  private normalizeAutoActivation(model: SmartSearchModel): SmartSearchModel {
    const clone = cloneDeep(model);
    const autoActivation = UserGroupsTagsMap.get(UserGroupsTagsEnum.AutoActivation);
    const autoActivateNewUsersOnlyTag = this.i18nPipe.transform(this.moduleUsers + autoActivation.tagKey, {
      format: 'title'
    });

    if (clone[autoActivateNewUsersOnlyTag]) {
      switch (clone[autoActivateNewUsersOnlyTag][0].value) {
        case this.#allUsers:
          clone[autoActivateNewUsersOnlyTag][0].value = AutoActivationMode.AllUsers;
          break;
        case this.#newUsers:
          clone[autoActivateNewUsersOnlyTag][0].value = AutoActivationMode.NewUsers;
          break;
        case this.#disabled:
          clone[autoActivateNewUsersOnlyTag][0].value = AutoActivationMode.Disabled;
          break;
        default:
          clone[autoActivateNewUsersOnlyTag][0].value = '';
      }
    }

    return clone;
  }

  handleSearch(): void {
    this.resetPagePagination();
    this.fetchData();
  }

  trackBy(index: number, item: ExtendedTableRow): string {
    return item?.item?.Id;
  }

  private resetPagePagination(): void {
    this.paginationOptions.page = 1;
    this.groupService().page = 1;
  }

  private updateMaxHeight(): void {
    const screenHeight: number = this.window.screen.height;

    this.maxHeight$.next(
      screenHeight > MIN_SCREEN_HEIGHT && this.mbsTableGridElRef ? getMaxHeight(this.mbsTableGridElRef.nativeElement) : ''
    );
  }

  handleSelect(items: AzureGroup[] | OrganizationalUnit[]): void {
    this.selectedItems = items;
  }

  handleChangeMode(mode: string): void {
    const autoActivation = UserGroupsTagsMap.get(UserGroupsTagsEnum.AutoActivation);

    switch (mode) {
      case this.#allUsers: {
        const options: FilterOptions = {
          model: [{ value: AutoActivationMode.AllUsers }],
          prop: autoActivation.prop
        };
        this.groupService().filter = getFilterByEq(options, true);
        break;
      }
      case this.#newUsers: {
        const options: FilterOptions = {
          model: [{ value: AutoActivationMode.NewUsers }],
          prop: autoActivation.prop
        };
        this.groupService().filter = getFilterByEq(options, true);
        break;
      }
      case this.#disabled: {
        const options: FilterOptions = {
          model: [{ value: AutoActivationMode.Disabled }],
          prop: autoActivation.prop
        };
        this.groupService().filter = getFilterByEq(options, true);
        break;
      }
      default:
        this.groupService().filter = '';
    }

    this.resetPagePagination();
    this.fetchData();
  }

  handleAutoActiveAction(action: AutoActiveActionType): void {
    switch (action) {
      case AutoActiveActionType.All: {
        const settings: ModalSettings = {
          size: MbsSize.sm,
          data: null
        };

        this.loadingNewGroup$.next(true);
        this.groupService()
          .getNewGroupSettings()
          .pipe(
            finalize(() => this.loadingNewGroup$.next(false)),
            switchMap((res) => {
              settings.data = res;

              return this.modalService.openCustom(NewGroupSettingsModalComponent, settings).catch(noop);
            }),
            filter(Boolean),
            switchMap(() => this.domainService.getFetchDomain()),
            untilDestroyed(this)
          )
          .subscribe({
            next: () => {
              this.selectedItems = [];
              this.fetchData();
            }
          });

        break;
      }
      case AutoActiveActionType.EditAutoActivationMode: {
        const settings: ModalSettings = {
          size: MbsSize.sm,
          data: {
            selected: this.selectedItems,
            selectedCount: this.selectedCount
          }
        };

        if (!this.isSelectAllOnAllPages) {
          settings.data.ids = this.selectedItems.map((i) => i.Id);
        }

        from(this.modalService.openCustom(EditGroupSettingModalComponent, settings))
          .pipe(
            filter(Boolean),
            tap(() => this.loadingEnabledNewlyCreatedUsers$.next(true)),
            switchMap(() => this.domainService.getFetchDomain()),
            finalize(() => this.loadingEnabledNewlyCreatedUsers$.next(false)),
            untilDestroyed(this)
          )
          .subscribe({
            next: () => {
              this.selectedItems = [];
              this.fetchData();
            }
          });

        break;
      }
      default:
        throw new Error('Caught error manually: Unknown action type');
    }
  }

  private getTableHeaders(): TableHeader[] {
    return [
      {
        name: this.isGoogle
          ? this.i18nPipe.transform(this.moduleUsers + ':grid.path', { format: 'title' })
          : this.i18nPipe.transform(this.moduleUsers + ':grid.name', { format: 'title' }),
        gridColMin: '150px',
        gridColSize: '2fr',
        class: 'text-overflow',
        sort: this.groupService().name.prop
      },
      {
        name: this.i18nPipe.transform(this.moduleUsers + ':grid.autoActivate', { format: 'title' }),
        gridColMin: '120px',
        gridColSize: '3fr',
        sort: 'AutoActivationMode'
      },
      {
        name: '',
        gridColSize: '80px',
        gridColMin: '40px',
        class: '-end'
      }
    ];
  }
}
