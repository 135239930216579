<mbs-table-grid [headers]="headers"
                [data]="statistics"
                [switcherView]="false"
                [stripedTable]="false"
                [showNav]="false"
                bindSelected="serviceType"
                [showTableCheckboxes]="true"
                [multipleSelect]="true"
                (changeSelected)="handleChecked($event)"
                class="mb-3"
                [viewMode]="viewMode">

  <ng-template mbsTableCell
               let-service>
    {{ service.name }}
  </ng-template>

  <ng-template mbsTableCell
               let-service>
    {{ service.size ? (service.size | size) : '0 B' }}
  </ng-template>

  <ng-template mbsTableCell
               let-service>
    {{ service.count || '0' }}
  </ng-template>

</mbs-table-grid>

<form [formGroup]="deleteBackupForm">
  <mbs-input [label]="'deleteBackup.label.alternatePassword' | i18next: { format: 'title' }"
             [placeholder]="'deleteBackup.placeholder.password' | i18next: { format: 'title' }"
             formControlName="password"
             [boldLabel]="true"
             [labelSize]="MbsLabelSize.sm"
             [type]="passwordType$ | async"
             [appendButtons]="[
                {
                  id: 'append-password',
                  type: 'secondary',
                  icon: getAppendButtonsIcon(passwordType$ | async),
                  btnType: 'button',
                  loading: false,
                  disabled: false
                }
             ]"
             (buttonClick)="handleChangePasswordType()"
             autocomplete="off"></mbs-input>
</form>
