import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, HostListener, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { WINDOW } from '@common';
import { SignInAlternateEmailModalComponent } from '@common/components/login/sign-in-alternate-email-modal/sign-in-alternate-email-modal.component';
import { Provider } from '@common/models';
import { AuthUrlOData } from '@common/models/auth/auth.model';
import { AuthService } from '@common/services';
import { getLoadingState, hasActionsQueue } from '@common/utils';
import { UntilDestroy } from '@ngneat/until-destroy';
import { noop } from 'lodash';
import { MbsSize, ModalService, ModalSettings } from 'mbs-ui-kit';
import { BehaviorSubject, EMPTY, Observable, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';

@UntilDestroy()
@Component({
  templateUrl: './login.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginComponent implements OnInit {
  #loading$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  @HostListener('window:unload', ['$event'])
  unloadHandler() {
    this.#loading$.next(false);
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHandler() {
    this.#loading$.next(true);
  }

  public readonly Provider = Provider;
  public readonly MbsSize = MbsSize;
  public readonly loading$: Observable<boolean>;

  get prefix(): string {
    return this.authService?.prefix;
  }

  constructor(
    @Inject(WINDOW) private window: Window,
    private authService: AuthService,
    private router: Router,
    private modalService: ModalService
  ) {
    this.loading$ = getLoadingState([this.#loading$.pipe(hasActionsQueue()), this.authService.requestPending$]);
  }

  ngOnInit(): void {
    if (this.authService.loggedIn$.value) {
      void this.router.navigate([this.prefix]);
    }
  }

  handleSignIn(provider: Provider): void {
    this.#loading$.next(true);
    this.authService
      .login(provider)
      .pipe(
        catchError((err: HttpErrorResponse) => (err instanceof HttpErrorResponse ? throwError(() => err) : EMPTY)),
        finalize(() => this.#loading$.next(false))
      )
      .subscribe({
        next: (data: AuthUrlOData) => {
          this.window.open(data.RedirectUrl, '_top');
        },
        error: () => {
          this.#loading$.next(false);
          this.authService.logout();
        }
      });
  }

  handleOpenSignInAlternativeModal(): void {
    const setting: ModalSettings = {
      size: MbsSize.sm,
      responsive: false
    };

    this.modalService.openCustom(SignInAlternateEmailModalComponent, setting).catch(noop);
  }
}
