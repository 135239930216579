import { Pipe, PipeTransform } from '@angular/core';
import { ChannelItem, TeamFile, TeamItem, TeamItemType } from '@common/models';

@Pipe({
  name: 'foldersLayout',
  pure: true
})
export class FoldersLayoutPipe implements PipeTransform {
  transform(item: TeamFile | TeamItem | ChannelItem): boolean {
    return [TeamItemType.Root, TeamItemType.Team, TeamItemType.Channel, TeamItemType.File, TeamItemType.FilesRoot].includes(item.ItemType);
  }
}
