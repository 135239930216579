import { AsyncPipe, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { RouterLink } from '@angular/router';
import { APPEND_BUTTON_PASSWORD_STATE, RoutePath } from '@common';
import { DownloadToPSTFileBaseModalData, hasAdminRole, hasLimitedAdminRole, hasSingleUserRole } from '@common/models';
import { ArchivePasswordService, AuthService } from '@common/services';
import { getAppendButtonsIcon, getAppendButtonsState, hasAppendButtonsPasswordType, isConfirmPasswordValidator } from '@common/utils';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { I18NextModule } from 'angular-i18next';
import { I18_NAMESPACE_APPS_UI } from 'i18n';
import { camelCase, isNil } from 'lodash';
import { ButtonModule, FormGroupModule, InputButton, InputModule, MbsSize, ModalComponent, ModalModule } from 'mbs-ui-kit';
import { IconSpriteModule } from 'ng-svg-icon-sprite';
import { BehaviorSubject, EMPTY, Observable, forkJoin, switchMap } from 'rxjs';
import { filter, finalize, map } from 'rxjs/operators';

type FormGroupType = {
  password: AbstractControl<string>;
  confirmPassword: AbstractControl<string>;
};

@UntilDestroy()
@Component({
  templateUrl: './download-to-pst-file.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ModalModule,
    AsyncPipe,
    ButtonModule,
    I18NextModule,
    InputModule,
    FormGroupModule,
    ReactiveFormsModule,
    NgIf,
    RouterLink,
    IconSpriteModule
  ]
})
export class DownloadToPstFileComponent implements OnInit {
  @ViewChild(ModalComponent, { static: true }) baseModal: ModalComponent;

  public formGroup: FormGroup<FormGroupType>;

  public loadingData$ = new BehaviorSubject<boolean>(false);
  public loadingSaveButton$ = new BehaviorSubject<boolean>(false);
  public isAdmin$: Observable<boolean>;

  public readonly RoutePath = RoutePath;
  public readonly MbsSize = MbsSize;
  public readonly moduleButton = I18_NAMESPACE_APPS_UI.button;
  public readonly getAppendButtonsIcon = getAppendButtonsIcon;

  public passwordType$: BehaviorSubject<string> = new BehaviorSubject(APPEND_BUTTON_PASSWORD_STATE.hidden.type);
  public confirmPasswordType$: BehaviorSubject<string> = new BehaviorSubject(APPEND_BUTTON_PASSWORD_STATE.hidden.type);

  get prefix(): string {
    return this.authService?.prefix;
  }

  get domainName(): string {
    return this.authService?.domainName;
  }

  get baseModalData(): DownloadToPSTFileBaseModalData {
    return this.baseModal.data;
  }

  get id(): string {
    return this.baseModalData.id;
  }

  get taskHasStarted(): boolean {
    return this.baseModalData.taskHasStarted;
  }

  get func(): () => Observable<any> {
    return this.baseModalData.func;
  }

  get countRequired(): string {
    return this.baseModalData.countRequired;
  }

  constructor(private fb: FormBuilder, private authService: AuthService, private archivePasswordService: ArchivePasswordService) {}

  ngOnInit(): void {
    if (this.func) {
      this.loadingData$.next(true);
      this.func()
        .pipe(
          finalize(() => this.loadingData$.next(false)),
          untilDestroyed(this)
        )
        .subscribe();
    }

    this.formInit();
    this.initStreams();
  }

  private formInit(): void {
    this.formGroup = this.fb.group(
      {
        password: ['', [Validators.required, Validators.minLength(7), Validators.maxLength(20)]],
        confirmPassword: ['', [Validators.required]]
      },
      {
        validators: [isConfirmPasswordValidator.bind({}, { password: 'password', confirmPassword: 'confirmPassword' })]
      }
    );
  }

  private initStreams(): void {
    this.isAdmin$ = forkJoin([this.authService.getAuthUser(), this.authService.getRoles(), this.authService.getProviderRoles()]).pipe(
      filter(([user, roles]) => !isNil(user) || !isNil(roles)),
      map(([user, roles, providerRoles]) => hasAdminRole(roles) || hasSingleUserRole(roles) || hasLimitedAdminRole(roles, providerRoles))
    );
  }

  handleSave(): void {
    this.loadingSaveButton$.next(true);
    this.isAdmin$
      .pipe(
        switchMap((isAdmin) =>
          isAdmin ? this.archivePasswordService.exportSettings(this.id, this.formGroup.get('password').value) : EMPTY
        ),
        finalize(() => this.loadingSaveButton$.next(false))
      )
      .subscribe({
        next: () => {
          this.baseModal.save(true);
        }
      });
  }

  handleClose(): void {
    this.baseModal.close();
  }

  handleChangePasswordType(event: InputButton): void {
    if (!event) return;

    const id = camelCase(event.id.replace('append', ''));
    const subjectById: BehaviorSubject<string> = this[id + 'Type$'];
    const isPasswordType: boolean = hasAppendButtonsPasswordType(subjectById.getValue());

    subjectById.next(APPEND_BUTTON_PASSWORD_STATE[getAppendButtonsState(isPasswordType)].type);
  }
}
