import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DownloadToPstFileComponent } from '@common/components/modals/download-to-pst-file/download-to-pst-file.component';
import { DownloadToPSTFileBaseModalData, hasAdminRole, hasDomainUserRole, hasLimitedAdminRole, hasSingleUserRole } from '@common/models';
import { AuthService } from '@common/services';
import { formatWithSpaces } from '@common/utils';
import { isNil } from 'lodash';
import { MbsSize, ModalService, ModalSettings } from 'mbs-ui-kit';
import { Observable, forkJoin, from, throwError } from 'rxjs';
import { catchError, filter, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ExportToPstHelper {
  private readonly errorRole$: Observable<string> = throwError(() => 'Forbidden role');

  constructor(private modalService: ModalService, private authService: AuthService) {}

  catchErrorDownloadFile(error: HttpErrorResponse, func: () => Observable<any>): Observable<never | any> {
    // 513 - error means that don't selected checkbox 'Direct PST Download' in My Account -> General Tab
    if (error.status === 513) {
      return forkJoin([this.authService.getRoles(), this.authService.getProviderRoles()]).pipe(
        filter(([roles, providerRoles]) => !isNil(roles) || !isNil(providerRoles)),
        switchMap(([roles, providerRoles]) => {
          const isAdmin = hasAdminRole(roles);
          const isSingleUser = hasSingleUserRole(roles);
          const isLimitedAdmin = hasLimitedAdminRole(roles, providerRoles);
          const isDomainUser = hasDomainUserRole(roles);
          const settings: Partial<ModalSettings> = {
            responsive: true,
            size: MbsSize.sm,
            data: {
              taskHasStarted: false,
              countRequired: formatWithSpaces(error?.error?.minItemsCountRequiredPasswordProtection || 9999)
            } as Partial<DownloadToPSTFileBaseModalData>
          };

          if (isAdmin || isLimitedAdmin) {
            settings.data = { ...settings.data, id: this.authService.domainId } as DownloadToPSTFileBaseModalData;

            return this.handleCatchError(settings, func);
          }

          if (isSingleUser || isDomainUser) {
            settings.data = { ...settings.data, id: this.authService.id } as DownloadToPSTFileBaseModalData;

            return this.handleCatchError(settings, func);
          }

          return this.errorRole$;
        }),
        catchError((err) => throwError(() => err))
      );
    }

    return throwError(() => error);
  }

  private handleCatchError(settings: ModalSettings, func: () => Observable<any>): Observable<any> {
    return from(this.modalService.openCustom(DownloadToPstFileComponent, settings)).pipe(
      switchMap(() => {
        settings.data = { ...settings.data, taskHasStarted: true, func };

        return this.modalService.openCustom(DownloadToPstFileComponent, settings);
      }),
      catchError((err) => throwError(() => err))
    );
  }
}
