import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, UntypedFormGroup, Validators } from '@angular/forms';
import { APPEND_BUTTON_PASSWORD_STATE } from '@common';
import { ChangeEmailModalComponent } from '@common/components/modals/change-email-modal/change-email-modal.component';
import { ChangePasswordModalComponent } from '@common/components/modals/change-password-modal/change-password-modal.component';
import { AddAlternateEmail, AddAlternateEmailCamelCase, AlternateEmail, EditAlternate, TwoFactor, User } from '@common/models';
import { UserOdataService } from '@common/services';
import { getAppendButtonsIcon, getAppendButtonsState, hasAppendButtonsPasswordType, isConfirmPasswordValidator } from '@common/utils';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { I18NextPipe } from 'angular-i18next';
import { I18_NAMESPACE_APPS_UI } from 'i18n';
import { camelCase } from 'lodash';
import { InputButton, MbsSize, ModalService, ModalSettings, ToastService } from 'mbs-ui-kit';
import { BehaviorSubject, noop } from 'rxjs';

enum Panels {
  AddNew = 'add-new',
  TwoFactor = 'two-factor'
}

@UntilDestroy()
@Component({
  selector: 'app-alternate-account-settings',
  templateUrl: './alternate-account-settings.component.html',
  styleUrls: ['./alternate-account-settings.component.scss']
})
export class AlternateAccountSettingsComponent implements OnInit {
  @Input() alternateSelected: boolean;

  @Output() alternateSelectedChange = new EventEmitter<boolean>();
  @Output() formsInvalid = new EventEmitter<boolean>();
  @Output() newAlternateEmailModel = new EventEmitter<AddAlternateEmail>();
  @Output() editAlternateEmailModel = new EventEmitter<EditAlternate>();

  readonly #toastTitleSuccess = this.i18nPipe.transform('toast.success.title', { format: 'title' });

  #user: User;
  #alternateEmailInfo: AlternateEmail;

  public passwordType$: BehaviorSubject<string> = new BehaviorSubject(APPEND_BUTTON_PASSWORD_STATE.hidden.type);
  public confirmPasswordType$: BehaviorSubject<string> = new BehaviorSubject(APPEND_BUTTON_PASSWORD_STATE.hidden.type);

  public readonly MbsSize = MbsSize;
  public readonly moduleUsers = I18_NAMESPACE_APPS_UI.users;
  public readonly getAppendButtonsIcon = getAppendButtonsIcon;

  public showTwoStepResponseCode = false;
  public twoStepQRURL = '';
  public secret = '';
  public twoStepPasswordShow = false;

  public addForm = new UntypedFormGroup(
    {
      Email: new FormControl('', [Validators.required, Validators.email]),
      Password: new FormControl('', [Validators.required, Validators.minLength(7), Validators.maxLength(20)]),
      ConfirmPassword: new FormControl('', Validators.required)
    },
    isConfirmPasswordValidator.bind({}, { password: 'Password', confirmPassword: 'ConfirmPassword' })
  );

  public twoFactorForm = new UntypedFormGroup({
    password: new FormControl('', [Validators.required, Validators.maxLength(20)]),
    enabled: new FormControl(false),
    responseCode: new FormControl('', [Validators.required])
  });

  private invalidPanels: string[] = [];
  private editAlternate = {} as EditAlternate;

  @Input() set user(u: User) {
    this.#user = u;

    if (Object.keys(u).length !== 0) {
      this.clearAll();
    }
  }

  get user(): User {
    return this.#user;
  }

  @Input() set alternateEmailInfo(info: AlternateEmail) {
    this.addForm[info?.Email ? 'disable' : 'enable']();

    this.#alternateEmailInfo = info;
    this.clearAll();

    if (this.alternateEmailInfo) {
      this.twoFactorForm.controls.enabled.setValue(this.alternateEmailInfo.TwoStepEnabled);
    }
  }

  get alternateEmailInfo(): AlternateEmail {
    return this.#alternateEmailInfo;
  }

  get password2faControl(): FormControl<string> {
    return <FormControl>this.twoFactorForm.get('password');
  }

  get enabled2faControl(): FormControl<boolean> {
    return <FormControl>this.twoFactorForm.get('enabled');
  }

  get responseCode2faControl(): FormControl<string> {
    return <FormControl>this.twoFactorForm.get('responseCode');
  }

  constructor(
    public userService: UserOdataService,
    private modalService: ModalService,
    private toastService: ToastService,
    private i18nPipe: I18NextPipe
  ) {}

  ngOnInit(): void {
    const forms: { form: FormGroup; validEmit: () => void; invalidEmit: () => void; panelName: Panels }[] = [
      {
        form: this.addForm,
        validEmit: () => {
          const data = this.addForm.value as AddAlternateEmailCamelCase;
          this.newAlternateEmailModel.emit({
            userId: this.user.Id,
            email: data.Email,
            password: data.Password,
            confirmPassword: data.ConfirmPassword
          });
        },
        panelName: Panels.AddNew,
        invalidEmit: () => undefined
      },
      {
        form: this.twoFactorForm,
        validEmit: () => {
          const values = this.twoFactorForm.value as TwoFactor;

          if (values.enabled) {
            this.editAlternate.editTwoStep = {
              password: values.password,
              responseCode: values.responseCode
            };
          } else if (this.alternateEmailInfo && this.alternateEmailInfo.TwoStepEnabled) {
            this.editAlternate.deleteTwoStep = {
              password: values.password
            };
          }

          this.editAlternateEmailModel.emit(this.editAlternate);
        },
        invalidEmit: () => {
          this.editAlternate.editTwoStep = null;
          this.editAlternate.deleteTwoStep = null;
          this.editAlternateEmailModel.emit(this.editAlternate);
        },
        panelName: Panels.TwoFactor
      }
    ];

    forms.forEach((data) => {
      data.form.statusChanges.pipe(untilDestroyed(this)).subscribe({
        next: (status) => {
          if (status === 'VALID') {
            data.validEmit();
            this.invalidPanels = this.invalidPanels.filter((v) => v !== data.panelName && data.form.enabled);

            this.emitInvalidStatus();
          } else if (data.form.touched) {
            data.invalidEmit();

            if (!this.invalidPanels.includes(data.panelName)) {
              this.invalidPanels.push(data.panelName);
              this.emitInvalidStatus();
            }
          } else {
            this.invalidPanels = this.invalidPanels.filter((v) => v !== data.panelName && data.form.enabled);
          }
        }
      });
    });
  }

  openChangeEmailModal(): void {
    const settings: ModalSettings = {
      size: MbsSize.sm,
      data: this.user.Id
    };

    this.modalService
      .openCustom(ChangeEmailModalComponent, settings)
      .then((save) => {
        if (!save) return;

        this.userService.getAlternateEmail(this.user.Id).subscribe({
          next: (a) => (this.alternateEmailInfo = a)
        });
        this.toastService.success('Request was sent successfully', this.#toastTitleSuccess);
      })
      .catch(() => noop);
  }

  openChangePasswordModal(): void {
    const settings: ModalSettings = {
      size: MbsSize.sm,
      data: this.user.Id
    };

    this.modalService
      .openCustom(ChangePasswordModalComponent, settings)
      .then((save) => {
        if (!save) return;

        this.toastService.success('Request was sent successfully', this.#toastTitleSuccess);
      })
      .catch(() => noop);
  }

  handleFormError(response: HttpErrorResponse, form: UntypedFormGroup): void {
    if (response.status === 400) {
      // response.error.error.details.forEach((input) => {
      // const p = form.get(input.target);
      // p.reset();
      // p.setErrors({ [`${input.target}Incorrect`]: { message: input.message } });
      // });
      form.get('Password').setValue('');
      form.get('Password').setErrors({ [`Password Incorrect`]: { message: (response.error as { value: string }).value } });
    }
  }

  clearFormIfControlsEmpty(form: UntypedFormGroup): void {
    const values = Object.values(form.controls);
    const allClear = values.every((v) => !v.value);

    if (allClear) {
      form.reset();
    }
  }

  emitInvalidStatus(): void {
    this.formsInvalid.emit(this.invalidPanels.length > 0);
  }

  useAlternateSwitcherChange(checked: boolean): void {
    if (checked) {
      if (this.user.AlternativeAccountExists) {
        this.enabled2faControl.setValue(this.alternateEmailInfo.TwoStepEnabled, { emitEvent: false });
        this.twoStepPasswordShow = false;
        this.emitInvalidStatus();
      } else {
        this.formsInvalid.emit(true);
      }
    } else {
      this.twoFactorForm.reset();
      this.addForm.reset();
      this.formsInvalid.emit(false);
    }

    this.alternateSelectedChange.emit(checked);
  }

  twoStepEnable(checked: boolean): void {
    this.twoStepPasswordShow = checked && !this.alternateEmailInfo.TwoStepEnabled;
    this.showTwoStepResponseCode = checked && !this.alternateEmailInfo.TwoStepEnabled;

    this.twoFactorForm.markAsTouched();
    this.twoFactorForm.setErrors(null);
    this.twoStepQRURL = '';

    if (checked) {
      this.password2faControl.setValidators([Validators.required, Validators.maxLength(20)]);

      this.emitInvalidStatus();
      this.userService.getSecretForAlternate(this.user.Id).subscribe({
        next: (secret) => {
          this.secret = secret;
          this.twoStepQRURL = `otpauth://totp/MSP360:${this.user.Email}?secret=${secret}`;
        },
        error: () => {
          this.twoFactorForm.setErrors({ noSecret: true });
          this.secret = 'Error while getting secret';
          this.formsInvalid.emit(true);
        }
      });
    } else {
      /* fix 2FA start */
      if (this.alternateEmailInfo.TwoStepEnabled) {
        this.password2faControl.setValidators([Validators.required, Validators.maxLength(20)]);
        this.responseCode2faControl.setValidators(null);
        this.twoStepPasswordShow = true;
        this.formsInvalid.emit(true);
      } else {
        this.password2faControl.setValidators(null);
        this.twoStepPasswordShow = false;
        this.formsInvalid.emit(false);
      }
      /* fix 2FA end */
      this.twoFactorForm.reset();
      this.twoFactorForm.markAsTouched();
    }
  }

  clearAll(): void {
    this.showTwoStepResponseCode = false;
    this.twoStepPasswordShow = false;
    this.addForm.reset();
    this.twoFactorForm.reset();
    this.newAlternateEmailModel.emit(null);
    this.editAlternateEmailModel.emit(null);
  }

  handleChangePasswordType(event: InputButton): void {
    if (!event) return;

    const id = camelCase(event.id.replace('append', ''));
    const subjectById: BehaviorSubject<string> = this[id + 'Type$'];
    const isPasswordType: boolean = hasAppendButtonsPasswordType(subjectById.getValue());

    subjectById.next(APPEND_BUTTON_PASSWORD_STATE[getAppendButtonsState(isPasswordType)].type);
  }
}
