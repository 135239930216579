export interface AuditODataItem {
  AlternateSignIn: boolean;
  BuildedHtml: string;
  Date: string;
  Description: string;
  Id: string;
  Json: boolean;
  UserActionType: UserActionTypes;
  UserActionTypeArea: UserActionTypeAreas;
  UserName: string;
}

export type UserActionTypes =
  | UserActionTypesPageVisited
  | UserActionTypesRestoreInstalled
  | UserActionTypesCompleted
  | UserActionTypesViewed
  | UserActionTypesDeletion
  | UserActionTypesExport
  | UserActionTypesRestore
  | UserActionTypesBackupConfigured
  | UserActionTypesBackupDeleted
  | UserActionTypesPermissionsSet
  | UserActionTypesRetentionPolicyChanged
  | UserActionTypesAlternateEmailChanged
  | UserActionTypesBackupOptionsChanged
  | UserActionTypesArchiveOptionsChanged
  | UserActionTypesTaskPerformed
  | UserActionTypesDownloaded;

export enum UserActionTypeAreas {
  AutoActivationMode = 'AutoActivationMode',
  SettingsChanged = 'SettingsChanged',
  PageVisited = 'PageVisited',
  Restore = 'Restore',
  BackupConfigured = 'BackupConfigured',
  BackupDeleted = 'BackupDeleted',
  PermissionsSet = 'PermissionsSet',
  RetentionPolicyChanged = 'RetentionPolicyChanged',
  AlternateEmailChanged = 'AlternateEmailChanged',
  BackupOptionsChanged = 'BackupOptionsChanged',
  ArchiveOptionsChanged = 'ArchvieOptionsChanged',
  StorageChanged = 'StorageChanged',
  TaskPerformed = 'TaskPerformed',
  Downloaded = 'Downloaded',
  ContentViewed = 'ContentViewed',
  Deletion = 'Deletion',
  Export = 'Export',
  ExportSettingsChanged = 'ExportSettingsChanged',
  DefaultRetentionPolicySet = 'DefaultRetentionPolicySet',
  EditEmailReportSettings = 'EditEmailReportSettings',
  EmailReportSettingsChanged = 'EmailReportSettingsChanged',
  TeamsItemsRestoreInitiated = 'TeamsItemsRestoreInitiated',
  TeamsItemsRestoreCompleted = 'TeamsItemsRestoreCompleted'
}

export enum UserActionTypesPageVisited {
  MailVisited = 'MailVisited',
  DriveVisited = 'DriveVisited',
  ContactsVisited = 'ContactsVisited',
  CalendarVisited = 'CalendarVisited',
  SharePointVisited = 'SharePointVisited',
  TeamDrivesVisited = 'TeamDrivesVisited'
}

export enum UserActionTypesRestoreInstalled {
  MailAllRestoreInitiated = 'MailAllRestoreInitiated',
  MailFolderRestoreInitiated = 'MailFolderRestoreInitiated',
  MailMessagesRestoreInitiated = 'MailMessagesRestoreInitiated',
  ContactsAllRestoreInitiated = 'ContactsAllRestoreInitiated',
  ContactGroupsRestoreInitiated = 'ContactGroupsRestoreInitiated',
  ContactsRestoreInitiated = 'ContactsRestoreInitiated',
  CalendarAllRestoreInitiated = 'CalendarAllRestoreInitiated',
  CalendarGroupsRestoreInitiated = 'CalendarGroupsRestoreInitiated',
  CalendarsRestoreInitiated = 'CalendarsRestoreInitiated',
  CalendarEventsRestoreInitiated = 'CalendarEventsRestoreInitiated',
  DriveItemsRestoreInitiated = 'DriveItemsRestoreInitiated',
  SharePointItemsRestoreInitiated = 'SharePointItemsRestoreInitiated',
  SharedDrivesItemsRestoreInitiated = 'SharedDrivesItemsRestoreInitiated'
}

export enum UserActionTypesRestore {
  MailAllRestored = 'MailAllRestored',
  MailFolderRestored = 'MailFolderRestored',
  MailMessagesRestored = 'MailMessagesRestored',
  ContactsAllRestored = 'ContactsAllRestored',
  ContactsGroupRestored = 'ContactsGroupRestored',
  ContactsRestored = 'ContactsRestored',
  CalendarAllRestored = 'CalendarAllRestored',
  CalendarGroupsRestored = 'CalendarGroupsRestored',
  CalendarsRestored = 'CalendarsRestored',
  CalendarEventRestored = 'CalendarEventRestored'
}

export enum UserActionTypesCompleted {
  MailAllRestoreCompleted = 'MailAllRestoreCompleted',
  MailMessagesRestoreCompleted = 'MailMessagesRestoreCompleted',
  MailFolderRestoreCompleted = 'MailFolderRestoreCompleted',
  ContactsAllRestoreCompleted = 'ContactsAllRestoreCompleted',
  ContactGroupsRestoreCompleted = 'ContactGroupsRestoreCompleted',
  ContactsRestoreCompleted = 'ContactsRestoreCompleted',
  CalendarAllRestoreCompleted = 'CalendarAllRestoreCompleted',
  CalendarGroupsRestoreCompleted = 'CalendarGroupsRestoreCompleted',
  CalendarsRestoreCompleted = 'CalendarsRestoreCompleted',
  CalendarEventsRestoreCompleted = 'CalendarEventsRestoreCompleted',
  DriveItemsRestoreCompleted = 'DriveItemsRestoreCompleted',
  SharePointRestoreCompleted = 'SharePointRestoreCompleted',
  SharedDrivesRestoreCompleted = 'SharedDrivesRestoreCompleted'
}

export enum UserActionTypesBackupConfigured {
  UsersConfiguredBackup = 'UsersConfiguredBackup',
  DomainConfiguredBackup = 'DomainConfiguredBackup',
  UserTurnedOnOff = 'UserTurnedOnOff',
  AllUsersTurnedOn = 'AllUsersTurnedOn'
}

export enum UserActionTypesBackupDeleted {
  BackupDeleted = 'BackupDeleted'
}

export enum UserActionTypesPermissionsSet {
  PermissionsSet = 'PermissionsSet'
}

export enum UserActionTypesViewed {
  MailMessageViewed = 'MailMessageViewed'
}

export enum UserActionTypesRetentionPolicyChanged {
  AddedRetentionPolicy = 'AddedRetentionPolicy',
  EditedRetentionPolicy = 'EditedRetentionPolicy',
  DeletedRetentionPolicy = 'DeletedRetentionPolicy',
  AssignedRetentionPolicy = 'AssignedRetentionPolicy',
  EditedArtifactRetentionPolicy = 'EditedArtifactRetentionPolicy'
}

export enum UserActionTypesAlternateEmailChanged {
  AddedAlternateEmail = 'AddedAlternateEmail',
  EditedAlternateEmail = 'EditedAlternateEmail',
  DeletedAlternateEmail = 'DeletedAlternateEmail'
}

export enum UserActionTypesBackupOptionsChanged {
  AddedBackupOptions = 'AddedBackupOptions',
  EditedBackupOptions = 'EditedBackupOptions',
  DeletedBackupOptions = 'DeletedBackupOptions',
  ChangedSpamTrash = 'ChangedSpamTrash'
}

export enum UserActionTypesArchiveOptionsChanged {
  AddedArchiveOptions = 'AddedArchiveOptions',
  EditedArchiveOptions = 'EditedArchiveOptions',
  DeletedArchiveOptions = 'DeletedArchiveOptions'
}

export enum UserActionTypesTaskPerformed {
  PausedTask = 'PausedTask',
  CanceledTask = 'CanceledTask',
  CanceledTasks = 'CanceledTasks'
}

export enum UserActionTypesDownloaded {
  DriveFileDownloaded = 'DriveFileDownloaded',
  SharePointFileDownloaded = 'SharePointFileDownloaded',
  TeamDrivesFileDownloaded = 'TeamDrivesFileDownloaded',
  MailFileDownloaded = 'MailFileDownloaded'
}

export enum UserActionTypesDeletion {
  MailMessagesDeleted = 'MailMessagesDeleted',
  MailFoldersDeleted = 'MailFoldersDeleted',
  ContactGroupsDeleted = 'ContactGroupsDeleted',
  ContactsDeleted = 'ContactsDeleted',
  CalendarGroupsDeleted = 'CalendarGroupsDeleted',
  CalendarsDeleted = 'CalendarsDeleted',
  CalendarEventsDeleted = 'CalendarEventsDeleted',
  DriveItemsDeleted = 'DriveItemsDeleted',
  SharePointItemsDeleted = 'SharePointItemsDeleted',
  TeamDrivesItemsDeleted = 'TeamDrivesItemsDeleted'
}

export enum UserActionTypesExport {
  ExportInitiated = 'ExportInitiated',
  ExportCompleted = 'ExportCompleted',
  ExportFileDownloaded = 'ExportFileDownloaded',
  ExportFileDeleted = 'ExportFileDeleted',
  EditedExportSettings = 'EditedExportSettings'
}

export type Areas = { id: string; label: string };

export type DisplayNameItemType = {
  [key: string]: {
    displayName: string;
    [key: string]: unknown;
  };
};

/**
 * `UserActionType` is `action` in model and form
 */
export const UserActionType: DisplayNameItemType = {
  [UserActionTypesPageVisited.MailVisited]: {
    displayName: 'Mail Visited',
    type: 0
  },
  [UserActionTypesPageVisited.DriveVisited]: {
    displayName: 'Drive Visited',
    type: 1
  },
  [UserActionTypesPageVisited.ContactsVisited]: {
    displayName: 'Contacts Visited',
    type: 2
  },
  [UserActionTypesPageVisited.CalendarVisited]: {
    displayName: 'Calendar Visited',
    type: 3
  },
  [UserActionTypesPageVisited.SharePointVisited]: {
    displayName: 'SharePoint Visited',
    type: 4
  },
  [UserActionTypesPageVisited.TeamDrivesVisited]: {
    displayName: 'Shared Drives Visited',
    type: 5
  },
  [UserActionTypesRestoreInstalled.MailAllRestoreInitiated]: {
    displayName: 'Initiated Restore for All Mails',
    type: 6
  },
  [UserActionTypesRestoreInstalled.MailFolderRestoreInitiated]: {
    displayName: 'Initiated Restore for Mail Folder',
    type: 7
  },
  [UserActionTypesRestoreInstalled.MailMessagesRestoreInitiated]: {
    displayName: 'Initiated Restore for Mail Messages',
    type: 8
  },
  [UserActionTypesRestoreInstalled.ContactsAllRestoreInitiated]: {
    displayName: 'Initiated Restore for All Contacts',
    type: 9
  },
  [UserActionTypesRestoreInstalled.ContactGroupsRestoreInitiated]: {
    displayName: 'Initiated Restore for Contact Groups',
    type: 10
  },
  [UserActionTypesRestoreInstalled.ContactsRestoreInitiated]: {
    displayName: 'Initiated Restore for Contacts',
    type: 11
  },
  [UserActionTypesRestoreInstalled.CalendarAllRestoreInitiated]: {
    displayName: 'Initiated Restore for All Calendars',
    type: 12
  },
  [UserActionTypesRestoreInstalled.CalendarGroupsRestoreInitiated]: {
    displayName: 'Initiated Restore for Calendar Groups',
    type: 13
  },
  [UserActionTypesRestoreInstalled.CalendarsRestoreInitiated]: {
    displayName: 'Initiated Restore for Calendars',
    type: 14
  },
  [UserActionTypesRestoreInstalled.CalendarEventsRestoreInitiated]: {
    displayName: 'Initiated Restore for Calendar Events',
    type: 15
  },
  [UserActionTypesBackupConfigured.UsersConfiguredBackup]: {
    displayName: 'User(s) Configured Backup',
    type: 16
  },
  [UserActionTypesBackupConfigured.DomainConfiguredBackup]: {
    displayName: 'Domain Configured Backup',
    type: 17
  },
  [UserActionTypesBackupConfigured.UserTurnedOnOff]: {
    displayName: 'User(s) Turned ON/OFF',
    type: 18
  },
  [UserActionTypesBackupConfigured.AllUsersTurnedOn]: {
    displayName: 'All Users Turned ON',
    type: 19
  },
  [UserActionTypeAreas.BackupDeleted]: {
    displayName: 'Backup Deleted',
    type: 20
  },
  [UserActionTypeAreas.PermissionsSet]: {
    displayName: 'Permissions Set',
    type: 21
  },
  [UserActionTypesRetentionPolicyChanged.AddedRetentionPolicy]: {
    displayName: 'Added Retention Policy',
    type: 22
  },
  [UserActionTypesRetentionPolicyChanged.EditedRetentionPolicy]: {
    displayName: 'Edited Retention Policy',
    type: 23
  },
  [UserActionTypesRetentionPolicyChanged.DeletedRetentionPolicy]: {
    displayName: 'Deleted Retention Policy',
    type: 24
  },
  [UserActionTypesRetentionPolicyChanged.AssignedRetentionPolicy]: {
    displayName: 'Assigned Retention Policy',
    type: 25
  },
  [UserActionTypesAlternateEmailChanged.AddedAlternateEmail]: {
    displayName: 'Added Alternate Email',
    type: 26
  },
  [UserActionTypesAlternateEmailChanged.EditedAlternateEmail]: {
    displayName: 'Edited Alternate Email',
    type: 27
  },
  [UserActionTypesAlternateEmailChanged.DeletedAlternateEmail]: {
    displayName: 'Deleted Alternate Email',
    type: 28
  },
  [UserActionTypesBackupOptionsChanged.AddedBackupOptions]: {
    displayName: 'Added Backup Options',
    type: 29
  },
  [UserActionTypesBackupOptionsChanged.EditedBackupOptions]: {
    displayName: 'Edited Backup Options',
    type: 30
  },
  [UserActionTypesBackupOptionsChanged.DeletedBackupOptions]: {
    displayName: 'Deleted Backup Options',
    type: 31
  },
  [UserActionTypesBackupOptionsChanged.ChangedSpamTrash]: {
    displayName: 'Changed Folders',
    type: 32
  },
  [UserActionTypesArchiveOptionsChanged.AddedArchiveOptions]: {
    displayName: 'Added Archive Options',
    type: 33
  },
  [UserActionTypesArchiveOptionsChanged.EditedArchiveOptions]: {
    displayName: 'Edited Archive Options',
    type: 34
  },
  [UserActionTypesArchiveOptionsChanged.DeletedArchiveOptions]: {
    displayName: 'Deleted Archive Options',
    type: 35
  },
  [UserActionTypesTaskPerformed.PausedTask]: {
    displayName: 'Paused Task',
    type: 36
  },
  [UserActionTypesTaskPerformed.CanceledTask]: {
    displayName: 'Canceled Task',
    type: 37
  },
  [UserActionTypesDownloaded.DriveFileDownloaded]: {
    displayName: 'Drive File Downloaded',
    type: 38
  },
  [UserActionTypesDownloaded.SharePointFileDownloaded]: {
    displayName: 'SharePoint File Downloaded',
    type: 39
  },
  [UserActionTypesDownloaded.TeamDrivesFileDownloaded]: {
    displayName: 'Shared Drives File Downloaded',
    type: 40
  },
  [UserActionTypesDownloaded.MailFileDownloaded]: {
    displayName: 'Mail Attachment Downloaded',
    type: 41
  },
  [UserActionTypesViewed.MailMessageViewed]: {
    displayName: 'Mail Message Viewed',
    type: 42
  },
  [UserActionTypesDeletion.MailMessagesDeleted]: {
    displayName: 'Mail Messages Deleted',
    type: 43
  },
  [UserActionTypesDeletion.MailFoldersDeleted]: {
    displayName: 'Mail Folders Deleted',
    type: 44
  },
  [UserActionTypesDeletion.ContactGroupsDeleted]: {
    displayName: 'Contact Groups Deleted',
    type: 45
  },
  [UserActionTypesDeletion.ContactsDeleted]: {
    displayName: 'Contacts Deleted',
    type: 46
  },
  [UserActionTypesDeletion.CalendarGroupsDeleted]: {
    displayName: 'Calendar Groups Deleted',
    type: 47
  },
  [UserActionTypesDeletion.CalendarsDeleted]: {
    displayName: 'Calendars Deleted',
    type: 48
  },
  [UserActionTypesDeletion.CalendarEventsDeleted]: {
    displayName: 'Calendar Events Deleted',
    type: 49
  },
  [UserActionTypesDeletion.DriveItemsDeleted]: {
    displayName: 'Drive Items Deleted',
    type: 50
  },
  [UserActionTypesDeletion.SharePointItemsDeleted]: {
    displayName: 'SharePoint Items Deleted',
    type: 51
  },
  [UserActionTypesDeletion.TeamDrivesItemsDeleted]: {
    displayName: 'Shared Drives Items Deleted',
    type: 52
  },
  [UserActionTypesRestoreInstalled.DriveItemsRestoreInitiated]: {
    displayName: 'Initiated Restore for Drive Items',
    type: 53
  },
  [UserActionTypesCompleted.MailAllRestoreCompleted]: {
    displayName: 'All Mails Restore Completed',
    type: 54
  },
  [UserActionTypesCompleted.MailFolderRestoreCompleted]: {
    displayName: 'Mail Folder Restore Completed',
    type: 55
  },
  [UserActionTypesCompleted.MailMessagesRestoreCompleted]: {
    displayName: 'Mail Messages Restore Completed',
    type: 56
  },
  [UserActionTypesCompleted.ContactsAllRestoreCompleted]: {
    displayName: 'All Contacts Restore Completed',
    type: 57
  },
  [UserActionTypesCompleted.ContactGroupsRestoreCompleted]: {
    displayName: 'Contact Groups Restore Completed',
    type: 58
  },
  [UserActionTypesCompleted.ContactsRestoreCompleted]: {
    displayName: 'Contacts Restore Completed',
    type: 59
  },
  [UserActionTypesCompleted.CalendarAllRestoreCompleted]: {
    displayName: 'All Calendars Restore Completed',
    type: 60
  },
  [UserActionTypesCompleted.CalendarGroupsRestoreCompleted]: {
    displayName: 'Calendar Groups Restore Completed',
    type: 61
  },
  [UserActionTypesCompleted.CalendarsRestoreCompleted]: {
    displayName: 'Calendars Restore Completed',
    type: 62
  },
  [UserActionTypesCompleted.CalendarEventsRestoreCompleted]: {
    displayName: 'Calendar Events Restore Completed',
    type: 63
  },
  [UserActionTypesCompleted.DriveItemsRestoreCompleted]: {
    displayName: 'Drive Items Restore Completed',
    type: 64
  },
  [UserActionTypesExport.ExportInitiated]: {
    displayName: 'Export Initiated',
    type: 65
  },
  [UserActionTypesExport.ExportCompleted]: {
    displayName: 'Export Completed',
    type: 66
  },
  [UserActionTypesExport.ExportFileDownloaded]: {
    displayName: 'Export File Downloaded',
    type: 67
  },
  [UserActionTypesExport.ExportFileDeleted]: {
    displayName: 'Export File Deleted',
    type: 68
  },
  [UserActionTypesExport.EditedExportSettings]: {
    displayName: 'Edited Export Settings',
    type: 69
  },
  [UserActionTypeAreas.DefaultRetentionPolicySet]: {
    displayName: 'Default Retention Policy Set',
    type: 70
  },
  [UserActionTypesRestoreInstalled.SharePointItemsRestoreInitiated]: {
    // for Office
    displayName: 'Initiated Restore for SharePoint Items',
    type: 71
  },
  [UserActionTypesCompleted.SharePointRestoreCompleted]: {
    displayName: 'Completed Restore for SharePoint Items',
    type: 72
  },
  [UserActionTypesRestoreInstalled.SharedDrivesItemsRestoreInitiated]: {
    // for Google
    displayName: 'Initiated Restore for Shared Drives Items',
    type: 73
  },
  [UserActionTypesCompleted.SharedDrivesRestoreCompleted]: {
    displayName: 'Completed Restore for Shared Drives Items',
    type: 74
  },
  [UserActionTypeAreas.EditEmailReportSettings]: {
    displayName: 'Edit Email Report Settings',
    type: 75
  },
  [UserActionTypesTaskPerformed.CanceledTasks]: {
    displayName: 'Canceled Tasks',
    type: 76
  },
  [UserActionTypeAreas.StorageChanged]: {
    displayName: 'Backup Storage Change',
    type: 77
  },
  [UserActionTypeAreas.TeamsItemsRestoreInitiated]: {
    displayName: 'Teams items: restore started',
    type: 78
  },
  [UserActionTypeAreas.TeamsItemsRestoreCompleted]: {
    displayName: 'Teams items: restore completed',
    type: 79
  },
  [UserActionTypeAreas.SettingsChanged]: {
    displayName: 'Settings Changed',
    type: 80
  }
};
/**
 * `UserActionTypeArea` is `event` in model and form
 */
export const UserActionTypeArea: Partial<DisplayNameItemType> = {
  [UserActionTypeAreas.AutoActivationMode]: {
    displayName: 'Auto-Activation Mode'
  },
  [UserActionTypeAreas.AlternateEmailChanged]: {
    displayName: 'Alternate Email Changed'
  },
  [UserActionTypeAreas.ArchiveOptionsChanged]: {
    displayName: 'Archive Options Changed'
  },
  [UserActionTypeAreas.BackupConfigured]: {
    displayName: 'Backup Configured'
  },
  [UserActionTypeAreas.BackupDeleted]: {
    displayName: 'Backup Deleted'
  },
  [UserActionTypeAreas.BackupOptionsChanged]: {
    displayName: 'Backup Options Changed'
  },
  [UserActionTypeAreas.ContentViewed]: {
    displayName: 'Content Viewed'
  },
  [UserActionTypeAreas.Deletion]: {
    displayName: 'Change'
  },
  [UserActionTypeAreas.Downloaded]: {
    displayName: 'Downloaded'
  },
  [UserActionTypeAreas.Export]: {
    displayName: 'Export'
  },
  [UserActionTypeAreas.PageVisited]: {
    displayName: 'Page Visited'
  },
  [UserActionTypeAreas.PermissionsSet]: {
    displayName: 'Permissions Set'
  },
  [UserActionTypeAreas.Restore]: {
    displayName: 'Restore'
  },
  [UserActionTypeAreas.RetentionPolicyChanged]: {
    displayName: 'Retention Policy Changed'
  },
  [UserActionTypeAreas.TaskPerformed]: {
    displayName: 'Task Performed'
  },
  [UserActionTypeAreas.ExportSettingsChanged]: {
    displayName: 'Export Settings Changed'
  },
  [UserActionTypeAreas.EmailReportSettingsChanged]: {
    displayName: 'Email Report Settings Changed'
  }
};
