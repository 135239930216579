import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ExportToPST, ExportToPSTEvents } from '@common/models';
import { ODataService, ODataServiceFactory } from '@common/odata';
import { ExportToPstHelper } from '@common/utils/helper/export-to-pst.helper';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ExportToPSTService {
  private odataMethods: ODataService<void>;

  constructor(odataFactory: ODataServiceFactory, private exportToPstHelper: ExportToPstHelper) {
    this.odataMethods = odataFactory.CreateService('');
  }

  /*
   * Use for Users page
   * @param payload
   */
  exportUserData(payload: ExportToPST): Observable<ExportToPST> {
    return this.odataMethods.CustomCollectionAction('ExportUserData', payload).pipe(
      map((res: HttpResponse<ExportToPST>) => res.body),
      catchError((err) => this.exportToPstHelper.catchErrorDownloadFile(err, () => this.exportUserData(payload)))
    );
  }

  /*
   * Use for Mail page
   * @param payload
   */
  exportMailMsgs(payload: ExportToPSTEvents): Observable<ExportToPSTEvents> {
    return this.odataMethods.CustomCollectionAction('ExportMailMsgs', payload).pipe(
      map((res: HttpResponse<ExportToPSTEvents>) => res.body),
      catchError((err) => this.exportToPstHelper.catchErrorDownloadFile(err, () => this.exportMailMsgs(payload)))
    );
  }

  /*
   * Use for Mail page
   * @param payload
   */
  exportMailFolders(payload: ExportToPSTEvents): Observable<ExportToPSTEvents> {
    return this.odataMethods.CustomCollectionAction('ExportMailFolders', payload).pipe(
      map((res: HttpResponse<ExportToPSTEvents>) => res.body),
      catchError((err) => this.exportToPstHelper.catchErrorDownloadFile(err, () => this.exportMailFolders(payload)))
    );
  }

  /*
   * Use for Contacts page
   * @param payload
   */
  exportContactItems(payload: ExportToPSTEvents): Observable<ExportToPSTEvents> {
    return this.odataMethods.CustomCollectionAction('ExportContactItems', payload).pipe(
      map((res: HttpResponse<ExportToPSTEvents>) => res.body),
      catchError((err) => this.exportToPstHelper.catchErrorDownloadFile(err, () => this.exportContactItems(payload)))
    );
  }

  /*
   * Use for Contacts page
   * @param payload
   */
  exportContactGroups(payload: ExportToPSTEvents): Observable<ExportToPSTEvents> {
    return this.odataMethods.CustomCollectionAction('ExportContactGroups', payload).pipe(
      map((res: HttpResponse<ExportToPSTEvents>) => res.body),
      catchError((err) => this.exportToPstHelper.catchErrorDownloadFile(err, () => this.exportContactGroups(payload)))
    );
  }

  /*
   * Use for Calendar page
   * @param payload
   */
  exportCalendarEvents(payload: ExportToPSTEvents): Observable<ExportToPSTEvents> {
    return this.odataMethods.CustomCollectionAction('ExportCalendarEvents', payload).pipe(
      map((res: HttpResponse<ExportToPSTEvents>) => res.body),
      catchError((err) => this.exportToPstHelper.catchErrorDownloadFile(err, () => this.exportCalendarEvents(payload)))
    );
  }

  /*
   * Use for Calendar page
   * @param payload
   */
  exportCalendarItems(payload: ExportToPSTEvents): Observable<ExportToPSTEvents> {
    return this.odataMethods.CustomCollectionAction('ExportCalendarItems', payload).pipe(
      map((res: HttpResponse<ExportToPSTEvents>) => res.body),
      catchError((err) => this.exportToPstHelper.catchErrorDownloadFile(err, () => this.exportCalendarItems(payload)))
    );
  }

  /*
   * Use for Calendar page
   * @param payload
   */
  exportCalendarGroups(payload: ExportToPSTEvents): Observable<ExportToPSTEvents> {
    return this.odataMethods.CustomCollectionAction('ExportCalendarGroups', payload).pipe(
      map((res: HttpResponse<ExportToPSTEvents>) => res.body),
      catchError((err) => this.exportToPstHelper.catchErrorDownloadFile(err, () => this.exportCalendarGroups(payload)))
    );
  }
}
