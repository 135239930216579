<mbs-modal [title]="taskHasStarted ? null : 'modal.title.exportPstFile' | i18next: { format: 'title' }"
           [loading]="loadingData$ | async">

  <ng-container modal-body>

    <ng-container *ngIf="taskHasStarted; else unsetPasswordStateTRef">

      <div class="text-center">
        <span class="fa fa-check-circle text-success"
              [style]="{ fontSize: '70px' }"></span>
      </div>

      <h3 class="text-center my-2 font-weight-semibold">
        {{ 'modal.title.exportPstFile' | i18next: { format: 'capitalize' } }}
      </h3>

      <p class="mb-0 text-center">
        {{ 'modal.body.exportPstFile' | i18next: { format: 'capitalize' } }}
      </p>

    </ng-container>

    <ng-template #unsetPasswordStateTRef>

      <ng-container *ngIf="isAdmin$ | async; else user">

        <p>
          {{ 'modal.body.description.exportPstFile_admin.part_1' | i18next: { format: 'capitalize', countRequired: countRequired } }}
          <span class="font-weight-semibold">
            {{ 'modal.body.description.directPSTDownloadSettings' | i18next: { format: 'title' } }}
          </span>
          {{ 'modal.body.description.exportPstFile_admin.part_2' | i18next: { format: 'capitalize' } }}
          <span class="font-weight-semibold">
            {{ domainName }}.
          </span>
          {{ 'modal.body.description.exportPstFile_admin.part_3' | i18next: { format: 'capitalize' } }}
          <span class="font-weight-semibold">
            {{ 'modal.body.description.directPSTDownload' | i18next: { format: 'title' } }}
          </span>
          {{ 'modal.body.description.exportPstFile_admin.part_4' | i18next: { format: 'capitalize' } }}
        </p>

        <form [formGroup]="formGroup">

          <mbs-form-group>
            <mbs-input [label]="'modal.label.passwordForArchivesWithYourData' | i18next: { format: 'capitalize' }"
                       [placeholder]="'modal.placeholder.enterPassword' | i18next: { format: 'capitalize' }"
                       formControlName="password"
                       [boldLabel]="true"
                       [labelClasses]="'text-base'"
                       (buttonClick)="handleChangePasswordType($event)"
                       [appendButtons]="[
                          {
                            id: 'append-password',
                            type: 'secondary',
                            icon: getAppendButtonsIcon(passwordType$ | async),
                            btnType: 'button',
                            loading: false,
                            disabled: false
                          }
                        ]"
                       [type]="passwordType$ | async"></mbs-input>
          </mbs-form-group>

          <mbs-form-group class="mb-0">
            <mbs-input [label]="'modal.label.confirmPassword' | i18next: { format: 'capitalize' }"
                       [placeholder]="'modal.placeholder.reEnterPassword' | i18next: { format: 'capitalize' }"
                       formControlName="confirmPassword"
                       [boldLabel]="true"
                       [labelClasses]="'text-base'"
                       (buttonClick)="handleChangePasswordType($event)"
                       [appendButtons]="[
                          {
                            id: 'append-confirm-password',
                            type: 'secondary',
                            icon: getAppendButtonsIcon(confirmPasswordType$ | async),
                            btnType: 'button',
                            loading: false,
                            disabled: false
                          }
                        ]"
                       [type]="confirmPasswordType$ | async"></mbs-input>
          </mbs-form-group>

        </form>

      </ng-container>

      <ng-template #user>

        <p class="mb-0">
          {{ 'modal.body.description.exportPstFile_user.part_1' | i18next: { format: 'capitalize', countRequired: countRequired } }}
          <span class="font-weight-semibold">
            {{ 'modal.body.description.directPSTDownload' | i18next: { format: 'title' } }}
          </span>
          {{ 'modal.body.description.exportPstFile_user.part_2' | i18next }}
        </p>

      </ng-template>

    </ng-template>

  </ng-container>

  <ng-container modalFooter>

    <ng-container *ngIf="taskHasStarted; else enableDownloadButtonTRef">

      <mbs-button [routerLink]="[prefix + RoutePath.TaskManager]"
                  type="primary"
                  (click)="handleClose()">
        {{ moduleButton + ':button.trackExport' | i18next: { format: 'title' } }}
      </mbs-button>

    </ng-container>

    <ng-template #enableDownloadButtonTRef>

      <mbs-button *ngIf="isAdmin$ | async"
                  type="primary"
                  (click)="handleSave()"
                  [loading]="loadingSaveButton$ | async"
                  [disabled]="!formGroup.touched || formGroup.invalid">
        {{ moduleButton + ':button.enableDirectPSTDownload' | i18next: { format: 'title' } }}
      </mbs-button>

    </ng-template>

    <mbs-button *ngIf="!taskHasStarted"
                (click)="handleClose()">
      {{
        (isAdmin$ | async)
          ? (moduleButton + ':button.cancel' | i18next: { format: 'title' })
          : (moduleButton + ':button.close' | i18next: { format: 'title' })
      }}
    </mbs-button>

  </ng-container>

</mbs-modal>
