import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { BrowserStorage, RoutePath, WINDOW } from '@common';
import { AuthMode, AuthState } from '@common/components/login/enums';
import { RestoreToStorage, SignInAsAdmin } from '@common/models';
import { AnotherAccountResponse, AuthResponse, AuthUrlOData } from '@common/models/auth/auth.model';
import { AuthService, LocalStorageService, SessionStorageService } from '@common/services';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { HttpResponseError } from 'mbs-ui-kit';
import { EMPTY, Observable, of, switchMap, throwError } from 'rxjs';
import { catchError, filter } from 'rxjs/operators';

@UntilDestroy()
@Component({
  template: `<mbs-loader></mbs-loader>`,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AuthCallbackComponent implements OnInit {
  get queryParams(): URLSearchParams {
    return new URLSearchParams(this.window.location.search);
  }

  constructor(
    @Inject(WINDOW) private window: Window,
    private authService: AuthService,
    private jwtService: JwtHelperService,
    private localStorageService: LocalStorageService,
    private router: Router,
    private route: ActivatedRoute,
    private sessionStorageService: SessionStorageService
  ) {}

  ngOnInit(): void {
    this.checkAndRedirectToHost();
  }

  private sendAuthCallback(): void {
    const payload = {
      Code: this.queryParams.get('code'),
      State: this.queryParams.get('state')
    };

    this.authService
      .authCallback(payload)
      .pipe(
        filter(Boolean),
        switchMap((res) => this.stateProcessing(res)),
        catchError((err: HttpResponseError) => throwError(() => err)),
        untilDestroyed(this)
      )
      .subscribe({
        next: (response) => {
          if (response.Mode === AuthMode.AnotherAccount) {
            const restoreToFrom: RestoreToStorage = this.sessionStorageService.get(BrowserStorage.RestoreToAnotherUser);
            const url = this.router.createUrlTree([restoreToFrom.redirectUrl], {
              relativeTo: this.route
            });

            this.updateRestoreToAnotherUserSessionStorage(restoreToFrom.redirectUrl, response);
            void this.router.navigateByUrl(url);
          } else if (response.Mode === AuthMode.SecondSignIn && this.sessionStorageService.get(BrowserStorage.RestoreFileTeams)) {
            const restoreToFrom: SignInAsAdmin = this.sessionStorageService.get(BrowserStorage.RestoreFileTeams);
            const url = this.router.createUrlTree([restoreToFrom.redirectUrl], {
              relativeTo: this.route
            });

            void this.router.navigateByUrl(url);
          } else {
            const prefix = this.authService.getPath(response as AuthResponse);
            const redirectPage = this.queryParams.get('redirectPage');
            const page = redirectPage ? RoutePath[redirectPage] : '';

            this.localStorageService.set<string>(BrowserStorage.AuthPrefix, prefix);
            this.localStorageService.set<string>(BrowserStorage.Token, (response as AuthResponse).Token);
            this.authService.loggedIn$.next(true);

            void this.router.navigate([prefix + page]);
          }
        },
        error: () => {
          this.authService.logout();
        }
      });
  }

  private checkAndRedirectToHost(): void {
    try {
      const host: string = JSON.parse(this.jwtService.urlBase64Decode(this.queryParams.get('state')))?.host?.toLowerCase();

      if (this.window.location.host !== host) {
        const url = new URL(this.window.location.protocol + '//' + host + this.window.location.pathname + this.window.location.search);

        this.window.location.assign(url);
      } else {
        this.sendAuthCallback();
      }
    } catch (e) {
      throw new Error('Caught error manually: Cannot decode base64 data');
    }
  }

  private updateRestoreToAnotherUserSessionStorage(route: string, data: AuthResponse | AnotherAccountResponse): void {
    this.sessionStorageService.set(BrowserStorage.RestoreToAnotherUser, { route, data });
  }

  private stateProcessing(res: AuthResponse | AnotherAccountResponse | AuthUrlOData): Observable<any> {
    switch (res.State) {
      case AuthState.Success:
        return of(res);
      case AuthState.Approve:
      case AuthState.AccessDenied:
      case AuthState.AccessRequest:
      case AuthState.Blocked:
      case AuthState.DomainNotFound:
      case AuthState.NotGrant:
      case AuthState.ServiceUnavailable:
      case AuthState.SignInDisabled:
      case AuthState.UserAccountDisabled:
        this.authService.modelBasel = res.Model;
        return of(this.router.navigate([RoutePath.Account], { queryParams: { state: res.State } })).pipe(switchMap(() => EMPTY));
      case AuthState.Redirect: {
        const redirectUrl = res['RedirectUrl'];

        if (redirectUrl) {
          this.window.open(redirectUrl, '_self');

          return EMPTY;
        } else {
          throw new Error(`State: ${res.State}. ERROR: Not found redirect url`);
        }
      }
      case AuthState.AuthCodeInvalid:
      case AuthState.TokenInvalid:
      default:
        return throwError(() => res.State);
    }
  }
}
