import { Pipe, PipeTransform } from '@angular/core';
import { ServiceTypeStr } from '@common';
import { StatusFilterBackup } from '@common/enums/users/status-filter-backup.enum';
import { ServiceType } from '@common/models';
import { I18NextPipe } from 'angular-i18next';
import { I18_NAMESPACE_APPS_UI } from 'i18n';

@Pipe({
  name: 'filterServiceBackupText',
  pure: true
})
export class FilterServiceBackupTextPipe implements PipeTransform {
  public readonly moduleUsers = I18_NAMESPACE_APPS_UI.users;

  constructor(private i18nPipe: I18NextPipe) {}

  transform(status: StatusFilterBackup, serviceType: ServiceType): string {
    const serviceMap = {
      [ServiceType.Mail]: ServiceTypeStr.Mail,
      [ServiceType.Drive]: ServiceTypeStr.Drive,
      [ServiceType.Contacts]: ServiceTypeStr.Contacts,
      [ServiceType.Calendar]: ServiceTypeStr.Calendar
    };

    const statusMapKey = {
      [StatusFilterBackup.Disabled]: ':tooltip.serviceBackup.disabled',
      [StatusFilterBackup.Enabled]: ':tooltip.serviceBackup.enabled',
      [StatusFilterBackup.NotAvailable]: ':tooltip.serviceBackup.notAvailable'
    };

    const servicePrefix = serviceMap[serviceType];
    const statusSuffix = statusMapKey[status];

    if (!servicePrefix) {
      throw new Error('Caught error manually: unable to process ServiceType enum, unknown value');
    }

    if (!statusSuffix) {
      throw new Error('Caught error manually: unable to process StatusFilterBackup enum, unknown value');
    }

    return this.i18nPipe.transform(this.moduleUsers + statusSuffix, { format: 'capitalize', service: servicePrefix });
  }
}
