import {
  Component,
  ContentChild,
  ContentChildren,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  SimpleChange
} from '@angular/core';
import { isNil } from 'lodash';
import { SharedPersistentStateEnum } from '../../services/storage/shared-persistent-state-enum';
import { SHARED_PERSISTENT_STATE } from '../../services/storage/shared-persistent-state.provider';
import { SharedPersistentStateService, ViewMode } from '../../services/storage/shared-persistent-state.service';
import { TableControlDirective } from '../directives/table-control.directive';
import { TableFilterDirective } from '../directives/table-filter.directive';
import { FiltersGroupDirective } from '../directives/table-filters-group.directive';
import { TableNavContentDirective } from '../directives/table-nav-content.directive';
import { PaginationOptions } from '../models/pagination-options';

@Component({
  selector: 'mbs-table-nav',
  templateUrl: './table-nav.component.html'
})
export class TableNavComponent implements OnChanges, OnInit {
  public readonly sharedPersistentStateEnum = SharedPersistentStateEnum;
  /**
   * Pagination options. For disable pagination set `pageSize=0` or `dataSize=0`
   * * `maxSize` - Visible pages number. Default: `3`
   * * `rotate` - Whether to rotate pages when `maxSize` > number of pages.
   * The current page always stays in the middle if true. Default: `false`
   * * `pageSize` - The number of items per page. Default: `20`. -1 means 'Show all'.
   * * `dataSize` - Total number of items.
   * * `pageSizeList` - Array of numbers to choose `pageSize` from. Default: `null`.
   * *  It is possible to use '-1' in 'pageSizeList'.  -1 means 'Show all'.
   * * `page` - current page. Default: `1`;
   */
  @Input() paginationOptions: PaginationOptions;
  public enableSwitcher = true;
  public paginationOptionsDefault: PaginationOptions = {
    maxSize: 3,
    rotate: false,
    pageSize: 20,
    dataSize: 0,
    pageSizeList: null,
    page: 1
  };
  public paginationOptionsInternal: PaginationOptions = this.paginationOptionsDefault;
  /**
   * Disable switching from selected view to different 'table' or 'grid'
   */
  @Input() disableViewSwitch = false;
  public myViewMode: ViewMode = this.sharedPersistent.data.viewTableGridMode || SharedPersistentStateEnum.table;
  public lastViewMode: ViewMode = this.myViewMode;

  /**
   * Can manually switch view mode;
   */
  @Input() switcherView = false;
  @Input() showExportToCSVButton = false;
  @Input() showRefreshButton = false;
  @Input() refreshing = false;
  @Input() exportButtonName = 'Export to CSV';

  @Input() public showNav =
    (this.paginationOptionsInternal.pageSize !== 0 && this.paginationOptionsInternal.dataSize > 0) ||
    (this.enableSwitcher && this.switcherView);

  @Input() wideContentMode = false;
  @Input() xlWideContentMode = false;
  @Input() fullContentWidth = false;

  @ContentChildren(TableFilterDirective, {
    read: TableFilterDirective,
    descendants: false
  })
  filterTemplates: QueryList<TableFilterDirective>;
  @Input() filters: QueryList<TableFilterDirective>;

  get myFilters(): QueryList<TableFilterDirective> {
    return this.filterTemplates.length > 0 ? this.filterTemplates : this.filters;
  }

  get hasFilters(): boolean {
    return !!(this.myFilters?.length > 0 || this.myContent || this.filtersGroup);
  }

  @ContentChild(TableNavContentDirective, { read: TableNavContentDirective, static: false })
  customContentTemplate: TableNavContentDirective;

  @ContentChild(FiltersGroupDirective, { read: FiltersGroupDirective, static: true })
  filtersGroup: FiltersGroupDirective;

  @Input() customContent: TableNavContentDirective;

  public get myContent(): TableNavContentDirective {
    return this.customContentTemplate ? this.customContentTemplate : this.customContent;
  }

  @ContentChildren(TableControlDirective, {
    read: TableControlDirective,
    descendants: false
  })
  controlTemplates: QueryList<TableControlDirective>;
  @Input() customControls: QueryList<TableControlDirective>;

  @Output() viewChange: EventEmitter<SharedPersistentStateEnum> = new EventEmitter<SharedPersistentStateEnum>();
  @Output() pageSizeChange: EventEmitter<PaginationOptions> = new EventEmitter<PaginationOptions>();
  @Output() pageSwitch: EventEmitter<PaginationOptions> = new EventEmitter<PaginationOptions>();

  /**
   * Emits on refresh button click with no data;
   */
  @Output() refresh: EventEmitter<any> = new EventEmitter<any>();
  /**
   * Emits on refresh button click with no data;
   */
  @Output() export: EventEmitter<any> = new EventEmitter<any>();
  /**
   * @deprecated
   **/
  @Output() pageChange: EventEmitter<number> = new EventEmitter<number>();

  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    if (event.target instanceof Window && !this.disableViewSwitch) {
      queueMicrotask(() => {
        if ((event.target as Window).innerWidth < 576) {
          this.enableSwitcher = false;
          this.myViewMode = SharedPersistentStateEnum.grid;
          this.viewChange.emit(this.lastViewMode);
        } else {
          this.enableSwitcher = true;
          this.myViewMode = this.lastViewMode;
          this.viewChange.emit(this.myViewMode);
        }
      });
    }
  }

  /**
   * @default ['table']
   * @param {ViewMode} mode
   */
  @Input() set viewMode(mode: ViewMode) {
    this.myViewMode = mode;
  }

  public get needToShowPaginationColumn(): boolean {
    return (
      this.showRefreshButton ||
      this.showExportToCSVButton ||
      this.customAppendControls.length > 0 ||
      this.customPrependControls.length > 0 ||
      (this.paginationOptionsInternal.pageSize < this.paginationOptionsInternal.dataSize && this.paginationOptionsInternal.dataSize > 0) ||
      (this.enableSwitcher && this.switcherView)
    );
  }

  private myControls(): QueryList<TableControlDirective> {
    return this.controlTemplates.length > 0 ? this.controlTemplates : this.customControls;
  }

  public get customPrependControls(): TableControlDirective[] {
    return this.myControls() ? this.myControls().filter((item) => item.mode === 'prepend') : [];
  }

  public get customAppendControls(): TableControlDirective[] {
    return this.myControls() ? this.myControls().filter((item) => item.mode === 'append') : [];
  }

  public get showPagination(): boolean {
    return (
      this.paginationOptionsInternal.pageSize > 0 &&
      this.paginationOptionsInternal.dataSize > 0 &&
      (this.paginationOptionsInternal.pageSize < this.paginationOptionsInternal.dataSize || this.paginationOptionsInternal.page > 1)
    );
  }

  constructor(@Inject(SHARED_PERSISTENT_STATE) private sharedPersistent: SharedPersistentStateService) {}

  ngOnChanges(changes: { [propKey: string]: SimpleChange }): void {
    if (changes.paginationOptions) {
      this.paginationOptionsInternal = Object.assign({}, this.paginationOptionsDefault, this.paginationOptions);
    }
  }

  ngOnInit(): void {
    if (
      this.paginationOptions &&
      this.paginationOptions.pageSize &&
      !isNil(this.paginationOptions.pageSizeList) &&
      !this.paginationOptions.pageSizeList.includes(this.paginationOptions.pageSize)
    ) {
      this.paginationOptions.pageSize = this.paginationOptions.pageSizeList[0];
    }
    if (window.innerWidth < 576) {
      this.enableSwitcher = false;
      this.myViewMode = SharedPersistentStateEnum.grid;
    }
  }

  /**
   * @ignore
   * @param {number} page
   */
  onPageChange(page: number): void {
    this.pageChange.emit(page);
    this.paginationOptionsInternal.page = page;
    this.pageSwitch.emit(this.paginationOptionsInternal);
  }

  /**
   * @ignore
   * @param {SharedPersistentStateEnum} mode
   */
  onViewChange(mode: SharedPersistentStateEnum): void {
    this.sharedPersistent.data.viewTableGridMode = mode;
    this.myViewMode = mode;
    this.lastViewMode = mode;
    this.viewChange.emit(this.myViewMode);
  }

  handleChangePageSize(pageSize: number): void {
    this.paginationOptionsInternal = Object.assign({}, this.paginationOptionsDefault, this.paginationOptions, { pageSize });
    this.pageSizeChange.emit(this.paginationOptionsInternal);
  }

  getPageSizeText(pageSize: number): string {
    return this.getDefaultPageSizeText(pageSize);
  }

  getPageSizeMenuText(pageSize: number): string {
    return this.paginationOptionsInternal.pageSizeMenuFormatter
      ? this.paginationOptionsInternal.pageSizeMenuFormatter(pageSize)
      : this.getDefaultPageSizeText(pageSize);
  }

  private getDefaultPageSizeText(pageSize: number): string {
    return pageSize === -1 ? 'Show all' : `${pageSize} per page`;
  }
}
