<form [formGroup]="formGroup"
      autocomplete="off">

  <mbs-form-section>

    <ng-container *ngIf="!(initialAlternateEmail$.asObservable() | async); else elseTemplateRef">

      <mbs-form-group>
        <mbs-input [label]="moduleAccount + ':sidepanel.label.alternateEmail' | i18next: { format: 'title' }"
                   [boldLabel]="true"
                   formControlName="alternateEmail"
                   autocomplete="off"
                   type="email"></mbs-input>
      </mbs-form-group>

      <mbs-form-group>
        <mbs-input [label]="moduleAccount + ':sidepanel.label.password' | i18next: { format: 'title' }"
                   formControlName="password"
                   [boldLabel]="true"
                   (buttonClick)="handleChangePasswordType($event)"
                   [appendButtons]="[
                     {
                       id: 'append-password',
                       type: 'secondary',
                       icon: getAppendButtonsIcon(passwordType$.asObservable() | async),
                       btnType: 'button',
                       loading: false,
                       disabled: false
                     }
                   ]"
                   [type]="passwordType$.asObservable() | async"
                   autocomplete="off"></mbs-input>
      </mbs-form-group>

      <mbs-form-group class="mb-0">
        <mbs-input [label]="moduleAccount + ':sidepanel.label.confirmPassword' | i18next: { format: 'title' }"
                   formControlName="confirmPassword"
                   [boldLabel]="true"
                   (buttonClick)="handleChangePasswordType($event)"
                   [appendButtons]="[
                     {
                       id: 'append-confirm-password',
                       type: 'secondary',
                       icon: getAppendButtonsIcon(confirmPasswordType$.asObservable() | async),
                       btnType: 'button',
                       loading: false,
                       disabled: false
                     }
                   ]"
                   [type]="confirmPasswordType$.asObservable() | async"
                   autocomplete="off"></mbs-input>
      </mbs-form-group>

    </ng-container>

    <ng-template #elseTemplateRef>

      <mbs-form-group>

        <div class="mbs-form_label">
          {{ moduleAccount + ':sidepanel.label.alternateEmail' | i18next: { format: 'title' } }}
          <ng-container *ngIf="verifyEmail$.asObservable() | async; else notVerifiedRef">
            <span class="fa fa-check-circle text-success text-sm ml-2 mr-1"></span>
            <span class="text-success text-sm">
              {{ moduleAccount + ':sidepanel.text.verified' | i18next: { format: 'title' } }}
            </span>
          </ng-container>
          <ng-template #notVerifiedRef>
            <span [ngbTooltip]="moduleAccount + ':sidepanel.tooltip.notVerified' | i18next: { format: 'capitalize' }"
                  container="body"
                  placement="top-left auto"
                  tooltipClass="tooltip-lg"
                  class="cursor-help">
              <span class="fa fa-exclamation-circle text-muted text-sm ml-2 mr-1"></span>
              <span class="text-muted text-sm">
                {{ moduleAccount + ':sidepanel.text.notVerified' | i18next: { format: 'title' } }}
              </span>
            </span>
          </ng-template>
        </div>

        <mbs-button [isCtrl]="true"
                    [textOverflow]="true"
                    [size]="MbsSize.md"
                    [disabled]="verifyEmailLoading$.asObservable() | async"
                    [ngbTooltip]="moduleAccount + ':sidepanel.tooltip.changeAlternateEmail' | i18next: { format: 'title' }"
                    triggers="mouseenter:mouseleave"
                    tooltipClass="tooltip-lg"
                    placement="top-left auto"
                    customClasses="lh-base"
                    (click)="handleChangeEmail()">
          {{ alternateEmailControl.value }}
          <span class="fa fa-pencil ml-2"></span>
        </mbs-button>

      </mbs-form-group>

      <mbs-form-group class="mb-0">

        <div class="mbs-form_label">
          {{ moduleAccount + ':sidepanel.label.alternatePassword' | i18next: { format: 'title' } }}
        </div>

        <div class="row align-items-end">

          <div class="col-auto">
            <mbs-button [isCtrl]="true"
                        [size]="MbsSize.md"
                        (click)="handleResetPassword()">
              {{ moduleAccount + ':sidepanel.button.resetPassword' | i18next: { format: 'title' } }}
            </mbs-button>
          </div>

          <div class="col-auto">
            <mbs-button [isCtrl]="true"
                        [size]="MbsSize.md"
                        [loading]="changePasswordLoading$.asObservable() | async"
                        (click)="handleChangePassword()">
              {{ moduleAccount + ':sidepanel.button.changePassword' | i18next: { format: 'title' } }}
            </mbs-button>
          </div>

        </div>

      </mbs-form-group>

    </ng-template>

  </mbs-form-section>

  <mbs-form-section *ngIf="initialAlternateEmail$.asObservable() | async">
    <h5 class="mbs-form-section_title text-md mb-2">
      <span class="fa fa-qrcode mr-2"></span>
      {{ moduleAccount + ':sidepanel.text.twoFactorSubTitle' | i18next: { format: 'title' } }}

      <ng-container *ngIf="twoStepEnabled$.asObservable() | async">
        <span class="mbs-form-section_header-ico fa fa-check-circle text-success ml-3"></span>
        <span class="text-success text-sm font-weight-base">{{ moduleAccount + ':sidepanel.text.enabled' | i18next: { format: 'title' } }}</span>
      </ng-container>

    </h5>

    <ng-container *ngIf="(twoStepEnabled$.asObservable() | async); else elseBlock">
      <mbs-button [loading]="twoFALoading$.asObservable() | async"
                  (click)="handleDisableTwoFA()">
        {{ moduleAccount + ':sidepanel.button.disable' | i18next: { format: 'title' } }}
      </mbs-button>
    </ng-container>

    <ng-template #elseBlock>
      <div class="row">
        <div class="col-12 col-md-10">
          <p class="text-sm">
            {{ moduleAccount + ':sidepanel.text.twoFactorText' | i18next: { format: 'capitalize' } }}
          </p>
        </div>
      </div>

      <mbs-button type="primary"
                  [loading]="twoFALoading$.asObservable() | async"
                  (click)="handleEnableTwoFA()">
        {{ moduleAccount + ':sidepanel.button.enableTwoFactorAuthentication' | i18next: { format: 'title' } }}
      </mbs-button>
    </ng-template>

  </mbs-form-section>

  <mbs-form-section *ngIf="initialAlternateEmail$.asObservable() | async">
    <mbs-button type="outline-danger"
                [loading]="deleteEmailLoading$.asObservable() | async"
                (click)="handleDeleteAlternateAccount()">
      {{ moduleAccount + ':sidepanel.button.deleteAlternateAccount' | i18next: { format: 'title' } }}
    </mbs-button>
  </mbs-form-section>

</form>
