import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BOOLEAN_OPERATOR, SINGLE_QUOTES_REGEX } from '@common';
import { AuthUser, DeleteArtifacts, ExportResultsOdata, ServiceTypeByAPIEnum } from '@common/models';
import { ODataPagedResult, ODataService, ODataServiceFactory } from '@common/odata';
import { AuthService, SharedOdataService } from '@common/services';
import { ExportResultsTagsEnum, ExportResultsTagsMap, sizeFilterEnum } from '@common/services/smart-search';
import { filterByWords } from '@common/utils';
import {
  FilterOptions,
  containsWrapper,
  getFilterByContains,
  getFilterByDateRange,
  getFilterByEqFromEnum,
  getFilterBySize
} from '@common/utils/functions/search';
import { isNil } from 'lodash';
import { SmartSearchModel, SmartSearchModelField } from 'mbs-ui-kit';
import { Observable } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ExportResultsService extends SharedOdataService<ExportResultsOdata> {
  readonly #userName = ExportResultsTagsMap.get(ExportResultsTagsEnum.userName);
  readonly #fileName = ExportResultsTagsMap.get(ExportResultsTagsEnum.fileName);
  readonly #serviceType = ExportResultsTagsMap.get(ExportResultsTagsEnum.serviceType);
  readonly #dateFrom = ExportResultsTagsMap.get(ExportResultsTagsEnum.dateFrom);
  readonly #dateTo = ExportResultsTagsMap.get(ExportResultsTagsEnum.dateTo);
  readonly #sizeMore = ExportResultsTagsMap.get(ExportResultsTagsEnum.sizeMore);
  readonly #sizeLess = ExportResultsTagsMap.get(ExportResultsTagsEnum.sizeLess);

  private user$: Observable<AuthUser>;
  private odataArtifact: ODataService<any>;

  constructor(odataFactory: ODataServiceFactory, private http: HttpClient, private authService: AuthService) {
    super(odataFactory, 'ExportResults');

    this.odataArtifact = this.odataFactory.CreateService('Artifacts');
    this.user$ = this.authService.getAuthUser();
  }

  getExportResults(): Observable<ODataPagedResult<ExportResultsOdata>> {
    return this.user$.pipe(
      filter((user) => !isNil(user)),
      switchMap((user) => this.userExportResults(user.Id))
    );
  }

  private userExportResults(id: string): Observable<ODataPagedResult<ExportResultsOdata>> {
    const odataService = this.odataFactory.CreateService<ExportResultsOdata>(`/BackupUsers(${id})/Artifacts`);

    return this.fetchData<ExportResultsOdata>(odataService);
  }

  deleteArtifact(id: string): Observable<unknown> {
    return <Observable<unknown>>this.odataArtifact.Delete(id).Exec();
  }

  deleteSelected(Ids: string[]): Observable<boolean> {
    const payload: DeleteArtifacts = { Ids };

    return this.deleteArtifacts(payload);
  }

  private deleteArtifacts(payload: DeleteArtifacts): Observable<boolean> {
    const odataService = this.odataFactory.CreateService<DeleteArtifacts>(`/DeleteArtifacts`);

    return this.http.post<void>(odataService.Query().GetUrl(), payload).pipe(map(() => true));
  }

  updateFilter(searchObj: SmartSearchModel): void {
    const filter: string[] = [];

    if (searchObj[this.#userName.tag]) {
      const options: FilterOptions = {
        model: searchObj[this.#userName.tag] as SmartSearchModelField[],
        prop: this.#userName.prop
      };

      filter.push(getFilterByContains(options));
    }

    if (searchObj[this.#fileName.tag]) {
      const options: FilterOptions = {
        model: searchObj[this.#fileName.tag] as SmartSearchModelField[],
        prop: this.#fileName.prop
      };

      filter.push(getFilterByContains(options));
    }

    if (searchObj[this.#serviceType.tag]) {
      const options: FilterOptions = {
        model: searchObj[this.#serviceType.tag] as SmartSearchModelField[],
        prop: this.#serviceType.prop,
        enumItems: ServiceTypeByAPIEnum
      };

      filter.push(getFilterByEqFromEnum(options));
    }

    if (searchObj[this.#dateFrom.tag]) {
      const options: FilterOptions = {
        model: searchObj[this.#dateFrom.tag] as SmartSearchModelField[],
        prop: this.#dateFrom.prop
      };
      const value = getFilterByDateRange(options, 'from');

      value && filter.push(value);
    }

    if (searchObj[this.#dateTo.tag]) {
      const options: FilterOptions = {
        model: searchObj[this.#dateTo.tag] as SmartSearchModelField[],
        prop: this.#dateTo.prop
      };
      const value = getFilterByDateRange(options, 'to');

      value && filter.push(value);
    }

    if (searchObj[this.#sizeMore.tag]) {
      const options: FilterOptions & { filter: sizeFilterEnum } = {
        model: searchObj[this.#sizeMore.tag] as SmartSearchModelField[],
        prop: this.#sizeMore.prop,
        filter: sizeFilterEnum.more
      };

      filter.push(getFilterBySize(options));
    }

    if (searchObj[this.#sizeLess.tag]) {
      const options: FilterOptions & { filter: sizeFilterEnum } = {
        model: searchObj[this.#sizeLess.tag] as SmartSearchModelField[],
        prop: this.#sizeLess.prop,
        filter: sizeFilterEnum.less
      };

      filter.push(getFilterBySize(options));
    }

    if (searchObj.words?.filter(Boolean)) {
      const term = filterByWords(searchObj).replace(SINGLE_QUOTES_REGEX, '');
      const keys = [this.#userName.prop, this.#fileName.prop];
      const values = keys.map((k) => `(${containsWrapper(k, term)})`);

      filter.push('('.concat(values.join(` ${BOOLEAN_OPERATOR.or} `), ')'));
    }

    this.filter = filter.length > 0 ? filter.join(` ${BOOLEAN_OPERATOR.and} `) : '';
  }
}
