import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { FilterActionType } from '@common';
import { UserReport } from '@common/models';
import { SortOrderByPipe } from '@common/pipes';
import { ReportsService } from '@common/services';
import { getUniqueListByProp, ReportsUserTagsEnum, ReportsUserTagsMap } from '@common/services/smart-search';
import SmartSearchTemplatesBase from '@common/services/smart-search/smart-search-template-base';
import { cloneDeep, isNil } from 'lodash';
import { ModelTemplate, SmartSearchState } from 'mbs-ui-kit';
import { asapScheduler, Observable, scheduled } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable()
export class SmartSearchModelTemplateReportsService extends SmartSearchTemplatesBase {
  readonly #userName = ReportsUserTagsMap.get(ReportsUserTagsEnum.userName);
  readonly #license = ReportsUserTagsMap.get(ReportsUserTagsEnum.license);
  readonly #userState = ReportsUserTagsMap.get(ReportsUserTagsEnum.userState);

  private keyCache = 'reports-name_';
  private userReport$: Observable<UserReport[]>;

  constructor(private reportsService: ReportsService, private datePipe: DatePipe, private sortOrderByPipe: SortOrderByPipe<UserReport>) {
    super();
    this.userReport$ = reportsService.userReports().pipe(map((res) => res.data));
  }

  public readonly UserName: ModelTemplate<UserReport> = {
    tag: this.#userName.tag,
    items: (state: SmartSearchState): Observable<UserReport[]> => {
      const term = state.leftCaretValue;

      this.reportsService.filter = term || '';

      // if (this.cache[this.keyCache + term]) {
      //   return of(this.cache[this.keyCache + term]) as Observable<UserReport[]>;
      // }

      return scheduled(
        this.userReport$.pipe(
          map((reports: UserReport[]) => {
            const filtered = reports.filter((report) => !isNil(report.UserName) && report.UserName.includes(term));
            const uniqueList = getUniqueListByProp(filtered, this.#userName.prop).slice(0, 10);

            return this.sortOrderByPipe.transform(uniqueList, this.#userName.prop);
          }),
          tap<UserReport[]>(this.writeToCache(this.keyCache + term))
        ),
        asapScheduler
      );
    },
    itemFormatter: ({ UserName }): string => UserName,
    addGroupBrackets: true
  };

  public readonly License: ModelTemplate<UserReport> = {
    tag: this.#license.tag,
    items: (state: SmartSearchState): Observable<UserReport[]> => {
      const term = state.leftCaretValue;

      this.reportsService.filter = term || '';

      // if (this.cache[this.keyCache + term]) {
      //   return of(this.cache[this.keyCache + term]) as Observable<UserReport[]>;
      // }

      return scheduled(
        this.userReport$.pipe(
          map((reports: UserReport[]) => {
            const cloneInvoices = cloneDeep(reports).map((report) => ({
              ...report,
              LicenseExpireDate: this.datePipe.transform(report.LicenseExpireDate)
            }));
            const filtered = cloneInvoices.filter((report) => report.LicenseExpireDate?.includes(term));
            const uniqueList = getUniqueListByProp(filtered, this.#license.prop).slice(0, 10);

            return this.sortOrderByPipe.transform(uniqueList, this.#license.prop, 'Date');
          }),
          tap<UserReport[]>(this.writeToCache(this.keyCache + term))
        ),
        asapScheduler
      );
    },
    itemFormatter: ({ LicenseExpireDate }): string => this.datePipe.transform(LicenseExpireDate, 'mediumDate'),
    addGroupBrackets: true
  };

  public readonly UserState: ModelTemplate<UserReport> = {
    tag: this.#userState.tag,
    items: (state: SmartSearchState): Observable<UserReport[]> => {
      const term = state.leftCaretValue;

      this.reportsService.filter = term || '';

      // if (this.cache[this.keyCache + term]) {
      //   return of(this.cache[this.keyCache + term]) as Observable<UserReport[]>;
      // }

      return scheduled(
        this.userReport$.pipe(
          map((reports: UserReport[]) => {
            const filtered = reports.filter((report) => !isNil(report.InBackup));

            return getUniqueListByProp(filtered, this.#userState.prop).slice(0, 10);
          }),
          tap<UserReport[]>(this.writeToCache(this.keyCache + term))
        ),
        asapScheduler
      );
    },
    itemFormatter: ({ InBackup }): string => (InBackup ? FilterActionType.enabledUsers : FilterActionType.disabledUsers),
    addGroupBrackets: true
  };
}
