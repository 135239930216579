import { HttpErrorResponse } from '@angular/common/http';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Inject,
  Injector,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { BackupLicenseStatuses, MIN_SCREEN_HEIGHT, PolicyType, RoutePath, TextColor, WINDOW } from '@common';
import { AzureGroup, BackupSetting, DomainStatistic, ServiceType, User } from '@common/models';
import { AuthService, DashboardService, DomainService, UserFiltersEnum, UserOdataService } from '@common/services';
import { DownloadService } from '@common/services/download.service';
import { OrganizationalUnitsService } from '@common/services/organizational-units.service';
import {
  canAbilityCdRef,
  getDefaultPaginationOptions,
  getErrorText,
  getLoadingState,
  getMaxHeight,
  getOdataOrderBy,
  hasActionsQueue,
  hasErrorResponseText,
  showPagination
} from '@common/utils';
import { addTypeResetToInputSearch } from '@common/utils/functions/search';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  GroupActionType,
  SaveEmit,
  UserGroupActionSidepanelComponent
} from '@pages/users/user-group-action-sidepanel/user-group-action-sidepanel.component';
import { I18NextPipe } from 'angular-i18next';
import { I18_NAMESPACE_APPS_UI } from 'i18n';
import { assign, cloneDeep, noop } from 'lodash';
import {
  DateFormat,
  MbsSize,
  ModalService,
  ModalSettings,
  PaginationOptions,
  SharedPersistentStateEnum,
  SidepanelService,
  SortEvent,
  StatusIconType,
  TableHeader,
  ToastService
} from 'mbs-ui-kit';
import { BehaviorSubject, EMPTY, Observable, Subject, forkJoin, from, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, finalize, startWith, switchMap, take, tap } from 'rxjs/operators';

enum BackupFilterText {
  All = 'All',
  Enabled = 'Backup enabled',
  Disabled = 'Backup disabled'
}

enum TableFilters {
  All,
  InBackup,
  NotInBackup,
  InBackupNotInDomain,
  Suspended
}

enum ApplyTo {
  All = 'all',
  Selected = 'selected'
}

@UntilDestroy()
@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UsersComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('mbsTableGridRef', { static: false, read: ElementRef }) mbsTableElRef: ElementRef;
  @ViewChild('mbsInputSearchRef', { static: false, read: ElementRef }) mbsInputSearchRef: ElementRef;
  @ViewChild('deleteJobTransferredTemplateRef', { static: false, read: TemplateRef }) deleteJobTransferredRef: TemplateRef<any>;

  readonly #toastTitleSuccess = this.i18nPipe.transform('toast.success.title', { format: 'title' });
  readonly #toastTitleError = this.i18nPipe.transform('toast.error.title', { format: 'title' });
  readonly #toastTitleInfo = this.i18nPipe.transform('toast.info.title', { format: 'title' });

  public readonly ServiceType = ServiceType;
  public readonly PolicyType = PolicyType;
  public readonly GroupActionType = GroupActionType;
  public readonly isProviderSignIn$: Observable<boolean>;
  public readonly moduleUsers = I18_NAMESPACE_APPS_UI.users;
  public readonly moduleButton = I18_NAMESPACE_APPS_UI.button;
  public readonly viewMode: SharedPersistentStateEnum = SharedPersistentStateEnum.table;
  public readonly BackupFilterText = BackupFilterText;
  public readonly MbsSize = MbsSize;
  public readonly RoutePath = RoutePath;
  public readonly DateFormat = DateFormat;
  public readonly StatusIconType = StatusIconType;
  public readonly TableFiltersEnum = TableFilters;
  public readonly ApplyToEnum = ApplyTo;
  public readonly users$: Observable<User[]>;
  public readonly domainStatistic$: Observable<DomainStatistic>;

  public orderBy: SortEvent;
  public filter: TableFilters = TableFilters.All;
  public selectedItems: User[] = [];
  public headers: TableHeader[];
  public autoAddUserToBackup = false;
  public maxHeight = '';
  public handleSearchChange: (events: string) => void;
  public paginationOptions: PaginationOptions = getDefaultPaginationOptions();

  public groupActionsSidepanel: UserGroupActionSidepanelComponent;
  public wideContentMode$ = new BehaviorSubject<boolean>(false);

  private groupActionUserSettings = {} as User;
  protected disabledServices: ServiceType[] = [];

  public userGroups$ = new BehaviorSubject<AzureGroup[]>([]);
  public searchLoading$ = new BehaviorSubject<boolean>(false);
  public typeahead$ = new Subject<string>();

  private _loading$ = new BehaviorSubject<boolean>(false);
  public loading$: Observable<boolean>;

  get panelWidth(): number {
    return this.groupActionsSidepanel?.genericPanel.show ? this.panelService.getPanelOffsetWidthByType(this.groupActionsSidepanel) : 0;
  }

  get pageSize(): number {
    return this.userService.pageSize;
  }

  set pageSize(size: number) {
    this.paginationOptions = assign({}, this.paginationOptions, { dataSize: 0 });
    this.paginationOptions.pageSize = size;
    this.userService.pageSize = size;
  }

  get authUserId(): string {
    return this.authService.id;
  }

  get showDeleteUser(): boolean {
    return this.selectedItems.every((i) => i.UserProviderState === UserFiltersEnum.notInDomain);
  }

  get prefix(): string {
    return this.authService?.prefix;
  }

  get isGoogle(): boolean {
    return this.authService.isGoogle;
  }

  get isOffice(): boolean {
    return this.authService.isOffice;
  }

  get isImmutability(): boolean {
    return this.authService?.isImmutability;
  }

  get wideContentMode(): boolean {
    return this.wideContentMode$.value && showPagination(this.paginationOptions);
  }

  get screenX(): number {
    return this.window.innerWidth;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    if (event.target instanceof Window) {
      this.updateMaxHeight();
      this.headers = this.getTableHeaders();
    }
  }

  constructor(
    @Inject(WINDOW) private window: Window,
    private cdRef: ChangeDetectorRef,
    private i18nPipe: I18NextPipe,
    public userService: UserOdataService,
    private orgUnitsService: OrganizationalUnitsService,
    private authService: AuthService,
    private domainService: DomainService,
    private downloadService: DownloadService,
    private panelService: SidepanelService,
    private toastService: ToastService,
    private modalService: ModalService,
    private dashboardService: DashboardService,
    private injector: Injector
  ) {
    userService.total.pipe(untilDestroyed(this)).subscribe((dataSize) => {
      this.paginationOptions = assign({}, this.paginationOptions, { dataSize });
    });
    this.paginationOptions.pageSize = userService.pageSize;
    this.users$ = userService.users;
    this.domainStatistic$ = domainService.domainStatistic$;
    this.loading$ = getLoadingState([
      this._loading$.pipe(hasActionsQueue()),
      this.userService.requestPending$,
      this.domainService.requestPending$
    ]);
    this.headers = this.getTableHeaders();
    this.isProviderSignIn$ = authService.isProviderSignIn();
  }

  ngOnInit(): void {
    this.domainService.currentDomain$.pipe(filter(Boolean), untilDestroyed(this)).subscribe((d) => {
      this.userService.domainId = d.Id;
      this.autoAddUserToBackup = d.AutoAddUserToBackup;
      this.userService.getUsers();
    });
    this.userService.clearCache();
    this.domainService.fetchDomainStatistic();
    this.groupActionsSidepanel = this.getPanel();
    this.groupActionsSidepanel.open.pipe(untilDestroyed(this)).subscribe(() => this.wideContentMode$.next(true));
    this.groupActionsSidepanel.close
      .pipe(
        tap(() => this.cdRef.markForCheck()),
        debounceTime(500),
        untilDestroyed(this)
      )
      .subscribe(() => {
        this.wideContentMode$.next(false);
        this.cdRef.markForCheck();
      });
    this.groupActionsSidepanel.save.pipe(untilDestroyed(this)).subscribe((data) => this.handleGroupActionEvent(data));
    this.groupActionsSidepanel.delete.pipe(untilDestroyed(this)).subscribe(() => {
      this.toastService.success(this.deleteJobTransferredRef, this.#toastTitleSuccess);
      this.selectedItems = [];
    });

    this.dashboardService
      .getServiceStatistics()
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (serviceStatistics) => (this.disabledServices = serviceStatistics.filter((s) => !s.enabled).map((s) => s.serviceType))
      });

    this.typeahead$
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        switchMap((search) => {
          this.searchLoading$.next(true);
          return this.userService.getOrganizationalUnitsGroups(search).pipe(finalize(() => this.searchLoading$.next(false)));
        }),
        untilDestroyed(this)
      )
      .subscribe({
        next: (res) => {
          this.userGroups$.next(res);
        }
      });

    this.groupService()
      .pipe(startWith([]), untilDestroyed(this))
      .subscribe({
        next: (res) => {
          this.userGroups$.next(res);
        }
      });
  }

  getPanel(): UserGroupActionSidepanelComponent {
    return this.panelService.contains(UserGroupActionSidepanelComponent)
      ? this.panelService.get(UserGroupActionSidepanelComponent)
      : this.panelService.add(UserGroupActionSidepanelComponent, null, this.injector);
  }

  private groupService(): Observable<AzureGroup[]> {
    return this.isOffice ? this.userService.getUserGroups() : this.userService.getOrganizationalUnitsGroups();
  }

  ngOnDestroy(): void {
    this.setFilterAll();

    this.userService.usersGroup = '';
    this.userService.searchTerm = '';
    this.orgUnitsService.clearCache();
    this.panelService.removeByType(UserGroupActionSidepanelComponent);
  }

  ngAfterViewInit(): void {
    this.handleSearchChange = addTypeResetToInputSearch(this.mbsInputSearchRef);

    queueMicrotask(() => {
      this.updateMaxHeight();
      canAbilityCdRef.call(this) && this.cdRef.detectChanges();
    });
  }

  private updateMaxHeight(): void {
    const screenHeight: number = this.window.screen.height;

    this.maxHeight = screenHeight > MIN_SCREEN_HEIGHT && this.mbsTableElRef ? getMaxHeight(this.mbsTableElRef.nativeElement) : '';
  }

  enableBackupForAll(): void {
    this.userService.addAllUsersToBackup().subscribe({
      next: () => {
        this.domainService.fetchDomainStatistic();
        this.userService.getUsers();
        this.toastService.success(
          this.i18nPipe.transform(this.moduleUsers + ':toast.body.addAllToBackup', { format: 'capitalize' }),
          this.#toastTitleSuccess
        );
      },
      error: (res: HttpErrorResponse) => hasErrorResponseText(res) && this.toastService.error(getErrorText(res), this.#toastTitleError)
    });
  }

  handleCheckUsers(users: User[]): void {
    this.selectedItems = users;

    if (this.groupActionsSidepanel.genericPanel.show) {
      users.length > 0
        ? this.handleGroupAction(ApplyTo.Selected, GroupActionType.General)
        : this.groupActionsSidepanel.genericPanel.close();
    }
  }

  handleSelectUser(user: User): void {
    this.handleOpenSidepanelForSingleUser(assign({}, user));
  }

  handleSort({ column, direction }: SortEvent): void {
    this.orderBy = { column, direction };
    this.userService.clearCache();
    this.userService.orderBy = getOdataOrderBy(column, direction);
  }

  handleFiltering(value: TableFilters): void {
    if (this.filter == value) return;

    this.filter = value;

    switch (this.filter) {
      case TableFilters.All:
        this.setFilterAll();
        break;
      case TableFilters.InBackup:
        this.setFilterInBackup();
        break;
      case TableFilters.NotInBackup:
        this.setFilterNotInBackup();
        break;
      case TableFilters.InBackupNotInDomain:
        this.setFilterInBackupNotInDomain();
        break;
      case TableFilters.Suspended:
        this.setFilterSuspended();
        break;
    }
  }

  private setFilterAll(): void {
    this.userService.inBackup = null;
    this.userService.userProviderState = UserFiltersEnum.all;
  }

  private setFilterInBackup(): void {
    this.userService.inBackup = true;
    this.userService.userProviderState = UserFiltersEnum.all;
  }

  private setFilterNotInBackup(): void {
    this.userService.inBackup = false;
    this.userService.userProviderState = UserFiltersEnum.inDomain;
  }

  private setFilterInBackupNotInDomain(): void {
    this.userService.inBackup = null;
    this.userService.userProviderStateWithTotalSize = { userProviderState: UserFiltersEnum.notInDomain, totalSize: true };
  }

  private setFilterSuspended(): void {
    this.userService.inBackup = null;
    this.userService.userProviderState = UserFiltersEnum.suspended;
  }

  handleSyncDomain(): void {
    const bodyError = this.i18nPipe.transform(this.moduleUsers + ':toast.body.syncDomainError', { format: 'capitalize' });
    const bodySuccess = this.i18nPipe.transform(this.moduleUsers + ':toast.body.syncDomainStarted', { format: 'capitalize' });

    this.domainService
      .syncDomain()
      .pipe(untilDestroyed(this))
      .subscribe({
        next: () => this.toastService.success(bodySuccess, this.#toastTitleSuccess),
        error: (res: HttpErrorResponse) =>
          hasErrorResponseText(res) && this.toastService.error(getErrorText(res, bodyError), this.#toastTitleError)
      });
  }

  // TODO: refactor saving in interaction with sidepanel components
  // eslint-disable-next-line sonarjs/cognitive-complexity
  handleGroupActionEvent(data: SaveEmit): void {
    const requests: {
      backup?: Observable<any>;
      services?: Observable<any>;
      permissions?: Observable<any>;
      policies?: Observable<any>;
      addAlternate?: Observable<any>;
      editAlternateEmail?: Observable<any>;
      changeAlternatePassword?: Observable<any>;
      deleteTwoStep?: Observable<any>;
      editTwoStep?: Observable<any>;
    } = {};
    this._loading$.next(true);
    const toastUsers = data.userIds.length > 1 ? this.selectedItems.map((i) => i.Name).join(', ') : data.settings.Name;
    let successMessage = `Changes are saved successfully for the following user(s): <span class="font-weight-semibold">${toastUsers}</span>`;

    if (data.newAlternateEmail) {
      successMessage = this.i18nPipe.transform(this.moduleUsers + ':toast.body.alternateEmailAdded', { format: 'capitalize' });
      requests.addAlternate = this.userService.addAlternateEmail(data.newAlternateEmail);
    }

    if (data.editAlternate) {
      if (data.editAlternate.deleteTwoStep) {
        requests.deleteTwoStep = this.userService.disableAlternateTwoStep(data.userIds[0], data.editAlternate.deleteTwoStep.password);
      }

      if (data.editAlternate.editTwoStep) {
        const twoStepData = data.editAlternate.editTwoStep;

        requests.editTwoStep = this.userService.enableAlternateTwoStep(data.userIds[0], twoStepData.password, twoStepData.responseCode);
      }
    }

    if (
      data.userIds.length > 1 ||
      this.groupActionUserSettings.InBackup !== data.settings.InBackup ||
      this.groupActionUserSettings.MailEnable !== data.settings.MailEnable ||
      this.groupActionUserSettings.DriveEnable !== data.settings.DriveEnable ||
      this.groupActionUserSettings.ContactsEnable !== data.settings.ContactsEnable ||
      this.groupActionUserSettings.CalendarEnable !== data.settings.CalendarEnable
    ) {
      const settings: BackupSetting[] = [
        { serviceType: ServiceType.All, enabled: data.settings.InBackup },
        { serviceType: ServiceType.Mail, enabled: data.settings.MailEnable },
        { serviceType: ServiceType.Drive, enabled: data.settings.DriveEnable },
        { serviceType: ServiceType.Contacts, enabled: data.settings.ContactsEnable },
        { serviceType: ServiceType.Calendar, enabled: data.settings.CalendarEnable }
      ];

      requests.services = this.userService.configureServices(data.userIds, settings);
    }

    if (
      this.groupActionUserSettings.SignInEnable !== data.settings.SignInEnable ||
      this.groupActionUserSettings.RestoreEnable !== data.settings.RestoreEnable
    ) {
      requests.permissions = this.userService.updatePermissions(data.settings, data.userIds);
      this.groupActionUserSettings.SignInEnable = data.settings.SignInEnable;
      this.groupActionUserSettings.RestoreEnable = data.settings.RestoreEnable;
    }

    if (data.attachedPolicies) {
      requests.policies = this.userService.updateAttachedPolicies(data.userIds, data.attachedPolicies);
    }

    forkJoin(requests)
      .pipe(
        switchMap((result) => this.expandSavingRequests(result, requests)) as never,
        finalize(() => this._loading$.next(false))
      )
      .subscribe({
        next: ([result]) => {
          if ('addAlternate' in result || 'editAlternateEmail' in result) {
            this.groupActionsSidepanel.fetchAlternate();
            this.groupActionsSidepanel.user.AlternativeAccountExists = true;
          }

          this.groupActionUserSettings.SignInEnable = data.settings.SignInEnable;
          this.groupActionUserSettings.RestoreEnable = data.settings.RestoreEnable;
          this.groupActionUserSettings.InBackup = data.settings.InBackup;
          this.groupActionUserSettings.MailEnable = data.settings.MailEnable;
          this.groupActionUserSettings.DriveEnable = data.settings.DriveEnable;
          this.groupActionUserSettings.ContactsEnable = data.settings.ContactsEnable;
          this.groupActionUserSettings.CalendarEnable = data.settings.CalendarEnable;
          this.domainService.fetchDomainStatistic();
          this.userService.getUsers();
          this.toastService.success(successMessage, this.#toastTitleSuccess);
        },
        error: (res: HttpErrorResponse) => {
          hasErrorResponseText(res) && this.toastService.error(getErrorText(res), this.#toastTitleError);

          this.groupActionsSidepanel.resetSingleUserDataToPrevious();
        }
      });
  }

  private expandSavingRequests(result, requests): Observable<unknown[]> {
    const requestPool$: Observable<unknown>[] = [of(result)];
    const restoreEnableChanged = requests.permissions;

    if (restoreEnableChanged) {
      requestPool$.push(this.authService.getAuthUser(true));
    }

    return forkJoin(requestPool$);
  }

  exportToCSV(): void {
    this.downloadService.downloadBare('/BackupUsers/ExportToCsv').subscribe({
      next: (file) => {
        this.downloadService.saveAs(file, this.downloadService.generateFileName('users'));
      }
    });
  }

  private handleOpenSidepanelForSingleUser(user: User, type = GroupActionType.General): void {
    const isUserDeleted = this.getIsUserDeleted(user);

    this.groupActionsSidepanel.disabledServices = this.disabledServices;
    this.groupActionUserSettings.InBackup = user.InBackup;
    this.groupActionUserSettings.MailEnable = user.MailEnable;
    this.groupActionUserSettings.DriveEnable = user.DriveEnable;
    this.groupActionUserSettings.ContactsEnable = user.ContactsEnable;
    this.groupActionUserSettings.CalendarEnable = user.CalendarEnable;
    this.groupActionUserSettings.SignInEnable = user.SignInEnable;
    this.groupActionUserSettings.RestoreEnable = user.RestoreEnable;

    this.groupActionsSidepanel.type = type;
    this.groupActionsSidepanel.singleUser = true;
    this.groupActionsSidepanel.user$.next(user);
    this.groupActionsSidepanel.userIcon = this.getUserIconClass(user);
    this.groupActionsSidepanel.userIconCssClass = this.getUserColorClass(user);
    this.groupActionsSidepanel.isUserDeleted = isUserDeleted;
    this.groupActionsSidepanel.isGlobalAdmin = this.getIsGlobalAdmin(user, isUserDeleted);

    this.panelService.closeAll().pipe(take(1)).subscribe();
    this.groupActionsSidepanel.genericPanel.open();
  }

  handleGroupAction(applyTo: ApplyTo, type: GroupActionType): void {
    if (applyTo === ApplyTo.All) {
      this.users$.pipe(take(1)).subscribe((u) => {
        this.selectedItems = u;
      });
    }

    if (this.selectedItems.length === 0) {
      const body = this.i18nPipe.transform(this.moduleUsers + ':toast.body.noUserSelected', { format: 'capitalize' });

      this.toastService.info(body, this.#toastTitleInfo);

      return;
    } else if (this.selectedItems.length == 1) {
      this.handleOpenSidepanelForSingleUser(this.selectedItems[0], type);

      return;
    }

    const userIds: string[] = [];

    let inBackupCount = 0;
    let notInBackupCount = 0;

    this.selectedItems.forEach((u) => {
      userIds.push(u.Id);

      if (u.InBackup) {
        inBackupCount++;
      } else {
        notInBackupCount++;
      }
    });

    this.groupActionsSidepanel.type = type;
    this.groupActionsSidepanel.userIds = userIds;
    this.groupActionsSidepanel.singleUser = false;
    this.groupActionsSidepanel.userIcon = 'fa fa-users';
    this.groupActionsSidepanel.userIconCssClass = TextColor.info;
    this.groupActionsSidepanel.isUserDeleted = false;
    this.groupActionsSidepanel.isGlobalAdmin = false;

    if (type !== GroupActionType.DeleteBackup) {
      const inBackup = inBackupCount > notInBackupCount;

      this.groupActionsSidepanel.loading = true;
      forkJoin([this.userService.getUsersServices(userIds), this.userService.getUsersPermissions(userIds)])
        .pipe(finalize(() => (this.groupActionsSidepanel.loading = false)))
        .subscribe(([general, permissions]) => {
          this.groupActionsSidepanel.disabledServices = this.disabledServices;
          this.groupActionUserSettings.InBackup = inBackup;
          this.groupActionUserSettings.MailEnable = general.MailOn;
          this.groupActionUserSettings.DriveEnable = general.DriveOn;
          this.groupActionUserSettings.ContactsEnable = general.ContactsOn;
          this.groupActionUserSettings.CalendarEnable = general.CalendarOn;
          this.groupActionUserSettings.SignInEnable = permissions.SignInEnable;
          this.groupActionUserSettings.RestoreEnable = permissions.RestoreEnable;

          this.groupActionsSidepanel.user = cloneDeep(this.groupActionUserSettings);
        });
    }

    this.panelService.closeAll().pipe(take(1)).subscribe();
    this.groupActionsSidepanel.genericPanel.open();
  }

  deleteSelectedUser(template: TemplateRef<any>): void {
    const title = this.i18nPipe.transform(this.moduleUsers + ':modal.title.deleteUser', { format: 'title' });
    const btnText = this.i18nPipe.transform(this.moduleUsers + ':modal.button.delete', { format: 'title' });
    const settings: ModalSettings = {
      size: MbsSize.sm,
      header: { title },
      footer: { okButton: { text: btnText, type: 'danger' } }
    };

    from(this.modalService.open(settings, template).catch(noop))
      .pipe(
        switchMap((result) =>
          result
            ? this.userService.deleteUser(this.selectedItems.length === 1 ? this.selectedItems[0].Id : this.selectedItems.map((i) => i.Id))
            : EMPTY
        ),
        untilDestroyed(this)
      )
      .subscribe({
        next: () => this.toastService.success(this.deleteJobTransferredRef, this.#toastTitleSuccess),
        error: (res: HttpErrorResponse) => hasErrorResponseText(res) && this.toastService.error(getErrorText(res), this.#toastTitleError)
      });
  }

  dropDownFilter(prop: string, value: boolean): void {
    this.userService.clearCache();
    this.userService[prop] = value;
  }

  pageChange(page: number): void {
    this.paginationOptions.page = page;
    this.userService.page = page;
  }

  pageSizeChange(options: PaginationOptions): void {
    this.paginationOptions = { ...options, page: 1 };
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.userService.state.page = 1;
    this.userService.pageSize = this.paginationOptions.pageSize;
  }

  getUserClasses(user: User): string[] {
    const classes: string[] = [];

    classes.push(this.getUserColorClass(user));
    classes.push(this.getUserIconClass(user));

    return classes;
  }

  private getUserColorClass = (user: User): string => {
    const { InBackup, UserProviderState, TotalSize } = user;

    switch (UserProviderState) {
      case UserFiltersEnum.inDomain:
        return InBackup ? TextColor.success : TextColor.info;
      case UserFiltersEnum.notInDomain:
        return TotalSize > 0 ? TextColor.dark : TextColor.muted;
      case UserFiltersEnum.suspended:
        return TextColor.danger;
      default:
        return null;
    }
  };

  private getUserIconClass(user: User): string {
    return user.Id === this.authUserId ? 'fa fa-user-o' : 'fa fa-user';
  }

  getUserTooltip(user: User): string {
    const { InBackup, UserProviderState, TotalSize } = user;
    const isUserDeleted = this.getIsUserDeleted(user);
    const role = this.getUserTooltipRole(user, isUserDeleted);

    switch (UserProviderState) {
      case UserFiltersEnum.inDomain:
        return InBackup
          ? role + this.i18nPipe.transform(this.moduleUsers + ':grid-nav.includedInBackup', { format: 'title' })
          : role + this.i18nPipe.transform(this.moduleUsers + ':grid-nav.notInBackup', { format: 'title' });
      case UserFiltersEnum.notInDomain:
        return InBackup || (isUserDeleted && TotalSize > 0)
          ? role + this.i18nPipe.transform(this.moduleUsers + ':grid-nav.withBackupButNotInDomain', { format: 'title' })
          : role + this.i18nPipe.transform(this.moduleUsers + ':grid-nav.notInBackupAndNotInDomain', { format: 'title' });
      case UserFiltersEnum.suspended:
        return role + this.i18nPipe.transform(this.moduleUsers + ':grid-nav.blockedSuspended', { format: 'title' });
      default:
        return null;
    }
  }

  private getUserTooltipRole(user: User, isUserDeleted: boolean): string {
    const end = ': ';

    if (user.Id === this.authUserId) {
      return this.i18nPipe.transform(this.moduleUsers + ':grid.you', { format: 'capitalize' }) + end;
    } else if (isUserDeleted) {
      return this.i18nPipe.transform(this.moduleUsers + ':grid.userDeleted', { format: 'capitalize' }) + end;
    } else {
      return this.i18nPipe.transform(this.moduleUsers + ':grid.user', { format: 'capitalize' }) + end;
    }
  }

  public readonly getIsUserDeleted = (user: User): boolean =>
    user.UserProviderState === UserFiltersEnum.notInDomain && user.InBackup === false;

  public readonly getIsGlobalAdmin = (user: User, isUserDeleted: boolean): boolean => !isUserDeleted && user.IsMainUser;

  getBackupLicenseStatus(user: User): BackupLicenseStatuses {
    if (!user.InBackup) {
      return BackupLicenseStatuses.Inactive;
    }

    if (!this.servicesAvailable(user)) {
      return BackupLicenseStatuses.NotAvailable;
    }

    return BackupLicenseStatuses.Assigned;
  }

  servicesAvailable(user: User): boolean {
    const NoLicensesAvailable = 'NoLicensesAvailable';

    return (
      (user.MailEnable ? user.EmailLastBackupStopReason !== NoLicensesAvailable : true) &&
      (user.DriveEnable ? user.DriveLastBackupStopReason !== NoLicensesAvailable : true) &&
      (user.ContactsEnable ? user.ContactLastBackupStopReason !== NoLicensesAvailable : true) &&
      (user.CalendarEnable ? user.CalendarLastBackupStopReason !== NoLicensesAvailable : true)
    );
  }

  backupLicenseColorCssClass(user: User): 'success' | 'danger' | 'muted' {
    const status = this.getBackupLicenseStatus(user);

    switch (status) {
      case BackupLicenseStatuses.Assigned:
        return 'success';
      case BackupLicenseStatuses.NotAvailable:
        return 'danger';
      default:
        return 'muted';
    }
  }

  handleSearchClear(): void {
    this.userService.searchTerm = '';
    this.userService.getUsers();
  }

  handleChangeSelect(): void {
    this.filter = null;
  }

  handleSortPolicyType(type: PolicyType | 'All'): void {
    this.userService.policyType = type;
  }

  private getTableHeaders(): TableHeader[] {
    return [
      {
        name: '',
        headerClass: '-center',
        class: '-center',
        gridColMin: '35px',
        gridColSize: '35px'
      },
      {
        name:
          this.i18nPipe.transform(this.moduleUsers + ':grid.user', { format: 'title' }) +
          '/' +
          this.i18nPipe.transform(this.moduleUsers + ':grid.email', { format: 'title' }),
        overflow: true,
        gridColMin: '98px',
        gridColSize: '3fr',
        sort: 'Name'
      },
      {
        name: this.i18nPipe.transform(this.moduleUsers + ':grid.lastBackup', { format: 'title' }),
        gridColMin: '147px',
        gridColSize: '2fr',
        sort: 'LastBackupDate'
      },
      {
        name: this.i18nPipe.transform(this.moduleUsers + ':grid.backupSize', { format: 'title' }),
        overflow: true,
        class: this.getRightAlignClass(),
        headerClass: this.getRightAlignClass(),
        gridColMin: '91px',
        gridColSize: '1fr',
        sort: 'TotalSize'
      },
      {
        name: this.i18nPipe.transform(this.moduleUsers + ':grid.backupLicense', { format: 'title' }),
        gridColMin: '120px',
        gridColSize: '2fr'
      },
      {
        name: '',
        gridColMin: '125px',
        gridColSize: '1.5fr',
        class: '-stretch',
        headerName: 'policyType'
      },
      {
        name: '',
        gridColMin: '45px',
        gridColSize: '45px',
        class: 'h5 my-0',
        headerName: 'mail'
      },
      {
        name: '',
        gridColMin: '45px',
        gridColSize: '45px',
        class: 'h5 my-0',
        headerName: 'drive'
      },
      {
        name: '',
        gridColMin: '45px',
        gridColSize: '45px',
        class: 'h5 my-0',
        headerName: 'contact'
      },
      {
        name: '',
        gridColMin: '45px',
        gridColSize: '45px',
        class: 'h5 my-0',
        headerName: 'calendar'
      }
    ];
  }

  private getRightAlignClass(): string {
    return this.screenX >= 1600 ? '-right pr-5' : '-right';
  }
}
