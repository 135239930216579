import { Location } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomCVAccessor, CustomControl, SECRET_KEY_MASK, WINDOW } from '@common';
import {
  AlternateAccountTabForm,
  BackupDestinationTabForm,
  GeneralTabForm,
  MyAccountForm,
  MyAccountTab
} from '@common/components/my-account/my-account-sidepanel/my-account-sidepanel.model';
import {
  AddAlternateEmail,
  AlternateEmail,
  AuthUser,
  BackupDestination,
  DateUnitEnum,
  ExportSettings,
  MyAccountContactInfo,
  MyAccountPhone,
  ProductMode,
  StorageStateEnum,
  hasDomainAdminRole,
  hasSingleUserRole
} from '@common/models';
import { AuthService, MyAccountService, UserOdataService } from '@common/services';
import { canAbilityCdRef, hasActionsQueue, isHomeUser } from '@common/utils';
import { ToastFactory } from '@common/utils/helper/show-toast.helper';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { I18NextPipe } from 'angular-i18next';
import { I18_NAMESPACE_APPS_UI } from 'i18n';
import { SidepanelInfoEvent } from 'libs/mbs-ui-kit/sidepanel/sidepanel.service';
import { get, isEqual, isNil } from 'lodash';
import {
  DataChangeWatcherService,
  FormsUtil,
  MbsPopupType,
  MbsSize,
  ModalService,
  ModalSettings,
  SidepanelCrudBase,
  SidepanelService,
  TabsService,
  TabsetDirective
} from 'mbs-ui-kit';
import { BehaviorSubject, Observable, combineLatest, forkJoin, from, merge, of } from 'rxjs';
import { catchError, distinctUntilChanged, filter, finalize, first, map, share, shareReplay, switchMap, tap } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'app-my-account-sidepanel',
  templateUrl: './my-account-sidepanel.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MyAccountSidepanelComponent extends SidepanelCrudBase<any> implements OnInit {
  @ViewChild(TabsetDirective, { static: false }) public tabset: TabsetDirective;
  @ViewChild('deleteAccountConfirmTemplate', { static: true, read: TemplateRef }) deleteAccountConfirmTemplate: TemplateRef<any>;
  @ViewChild('backupDestinationConfirmTemplate', { static: true, read: TemplateRef }) backupDestinationConfirmTemplate: TemplateRef<any>;

  private isStandaloneMode: boolean;

  public deleteLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public saveLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  private _loading$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  public loading$: Observable<boolean>;

  public isSuperAdmin$: Observable<boolean>;
  public isStandaloneMode$: Observable<boolean>;
  public isAvailableDeleteAccount$: Observable<boolean>;
  public user$: Observable<AuthUser>;
  public contactInfo$: Observable<MyAccountContactInfo>;
  public formGroup: UntypedFormGroup;

  public addedStorage: boolean;
  public twoStepEnabled: boolean;
  public alternateEmailVerified: boolean;
  public initialAlternateEmail: string; // for fix browser autocomplete email

  public readonly MyAccountTab = MyAccountTab;
  public readonly MbsPopupType = MbsPopupType;
  public readonly moduleAccount = I18_NAMESPACE_APPS_UI.account;
  public readonly isProviderSignIn$: Observable<boolean>;

  get queryParams(): URLSearchParams {
    return new URLSearchParams(this.window.location.search);
  }

  get generalTabControl(): CustomControl<GeneralTabForm> {
    return <CustomControl<GeneralTabForm>>this.formGroup.controls[MyAccountTab.general];
  }

  get alternateAccountTabControl(): CustomControl<AlternateAccountTabForm> {
    return <CustomControl<AlternateAccountTabForm>>this.formGroup.controls[MyAccountTab.alternateAccount];
  }

  get backupDestinationTabControl(): CustomControl<BackupDestinationTabForm> {
    return <CustomControl<BackupDestinationTabForm>>this.formGroup.controls[MyAccountTab.backupDestination];
  }

  get isImmutability(): boolean {
    return this.authService?.isImmutability;
  }

  constructor(
    @Inject(WINDOW) private window: Window,
    private toastFactory: ToastFactory,
    private cdRef: ChangeDetectorRef,
    private location: Location,
    private fb: FormBuilder,
    private i18nPipe: I18NextPipe,
    private modalService: ModalService,
    private tabsService: TabsService,
    private route: ActivatedRoute,
    private router: Router,
    private myAccountService: MyAccountService,
    private authService: AuthService,
    private userService: UserOdataService,
    private sidepanelService: SidepanelService,
    cdNew: DataChangeWatcherService
  ) {
    super(cdNew);
    this.mergeClose.unsubscribe();
    this.mergeClose = merge(this.close, this.delete).subscribe(() => this.genericPanel.close());

    this.user$ = authService.getAuthUser().pipe(shareReplay(1));
    this.loading$ = this._loading$.pipe(hasActionsQueue(), shareReplay(1));

    this.isSuperAdmin$ = combineLatest([this.user$, authService.getRoles()]).pipe(
      map(([user, roles]) => isHomeUser(user) || hasDomainAdminRole(roles))
    );

    this.isStandaloneMode$ = authService.getSystemInfo().pipe(map((systemInfo) => systemInfo.ProductMode === ProductMode.resale));

    this.isAvailableDeleteAccount$ = authService.getRoles().pipe(
      switchMap((roles) => {
        const isDomainAdmin = hasDomainAdminRole(roles);
        const isSingleUser = hasSingleUserRole(roles);

        return of(isDomainAdmin || isSingleUser);
      })
    );

    this.formGroup = fb.group({
      [MyAccountTab.general]: [null, this.controlValidator.bind(this)],
      [MyAccountTab.alternateAccount]: [null, this.controlValidator.bind(this)],
      [MyAccountTab.backupDestination]: [null, this.controlValidator.bind(this)]
    });

    this.loadingData = true;
    this.contactInfo$ = myAccountService.getContactInfo().pipe(
      finalize(() => {
        this._loading$.next(false);
        this.loadingData = false;
      }),
      share()
    );

    this.isProviderSignIn$ = authService.isProviderSignIn();
  }

  controlValidator(control: CustomControl<GeneralTabForm | AlternateAccountTabForm | BackupDestinationTabForm>): ValidationErrors | null {
    const ctrl = control.value;

    if (ctrl && !ctrl.valid) {
      return { tab: { message: 'Invalid Control' } };
    }

    return null;
  }

  ngOnInit(): void {
    this.formGroup.valueChanges
      .pipe(
        distinctUntilChanged((prev, next) => isEqual(prev, next)),
        untilDestroyed(this)
      )
      .subscribe(() => this.cdRef.detectChanges());

    this.isStandaloneMode$.pipe(untilDestroyed(this)).subscribe((isStandaloneMode) => {
      this.isStandaloneMode = isStandaloneMode;
    });

    this.sidepanelService.onClose
      .pipe(
        filter((event: SidepanelInfoEvent) => event.id === this.genericPanel.id),
        untilDestroyed(this)
      )
      .subscribe(() => this.setRouteSlug(false));

    this.sidepanelService.onOpen
      .pipe(
        filter((event: SidepanelInfoEvent) => event.id === this.genericPanel.id),
        untilDestroyed(this)
      )
      .subscribe(() => {
        this.setRouteSlug(true);

        this.formGroup.reset(this.defaultFormValue(), { emitEvent: false });
        this.initialAlternateEmail = null;
        this.hold();
        this.selectActivateTab();
        this.initTabs();
      });
  }

  private setRouteSlug(needQueryParams: boolean, tabId?: string): void {
    const tab = tabId || this.queryParams.get('tab') || MyAccountTab.general;
    const url: string = this.router
      .createUrlTree([], {
        relativeTo: this.route,
        queryParams: {
          myAccount: needQueryParams ? true : null,
          tab: needQueryParams ? tab : null
        },
        queryParamsHandling: 'merge'
      })
      .toString();

    this.location.go(url);
  }

  private selectActivateTab(): void {
    const activatedTabId = this.queryParams.get('tab') || MyAccountTab.general;

    this.tabset.select(activatedTabId);
  }

  public updateGeneralTab(): void {
    this._loading$.next(true);

    forkJoin([this.myAccountService.getExportSettings(), this.contactInfo$, this.authService.getSystemInfo()])
      .pipe(
        filter(([exportSettings, contactInfo, systemInfo]) => !isNil(exportSettings) || !isNil(contactInfo) || !isNil(systemInfo)),
        finalize(() => this._loading$.next(false)),
        first()
      )
      .subscribe({
        next: ([exportSettings, contactInfo, systemInfo]) => {
          if (!contactInfo || !systemInfo) {
            this.generalTabControl.setValue(null);
          } else {
            const formGroup = this.getGeneralTabFormGroup({ exportSettings, contactInfo, systemInfo });

            this.generalTabControl.setValue(formGroup, { emitEvent: false });
            this.generalTabControl.markAsUntouched();
          }
          this.attachObject(this.generalTabControl, (obj: CustomControl<GeneralTabForm>) => obj.value);
          queueMicrotask(() => canAbilityCdRef.call(this) && this.cdRef.detectChanges());
        }
      });
  }

  private getGeneralTabFormGroup = ({ exportSettings, contactInfo, systemInfo }): CustomCVAccessor<GeneralTabForm> => {
    const defaultRetentionSettings = {
      num: 30,
      unit: DateUnitEnum.Days
    };
    return {
      valid: true,
      data: {
        email: contactInfo.Email,
        phone: contactInfo.Phone || '',
        version: systemInfo.AssemblyVersion,
        exportSettings: {
          keepAllowed: {
            checkbox: Boolean(exportSettings && exportSettings.ProtectedWithPassword),
            password: exportSettings && exportSettings.ProtectedWithPassword ? SECRET_KEY_MASK : ''
          },
          keepCntUnits: {
            checkbox: Boolean(exportSettings && exportSettings.RetentionSettings.Enabled),
            number:
              exportSettings && exportSettings.RetentionSettings.Enabled
                ? exportSettings.RetentionSettings.DateNum
                : defaultRetentionSettings.num,
            filter:
              exportSettings && exportSettings.RetentionSettings.Enabled
                ? exportSettings.RetentionSettings.DateUnit
                : defaultRetentionSettings.unit
          }
        }
      }
    };
  };

  public updateAlternateAccountTab(): void {
    this._loading$.next(true);

    this.user$
      .pipe(
        switchMap((user) => this.userService.getAlternateEmail(user.Id)),
        finalize(() => this._loading$.next(false)),
        first()
      )
      .subscribe({
        next: (info: AlternateEmail) => {
          this.twoStepEnabled = info?.TwoStepEnabled || null;
          this.alternateEmailVerified = info?.Verified || null;
          this.initialAlternateEmail = info?.Email || null;

          const formGroup = this.getAlternateAccountTabFormGroup(info || null);

          this.alternateAccountTabControl.setValue(formGroup, { emitEvent: false });
          this.alternateAccountTabControl.markAsUntouched();

          this.attachObject(this.alternateAccountTabControl, (obj: CustomControl<AlternateAccountTabForm>) => obj.value);
          queueMicrotask(() => canAbilityCdRef.call(this) && this.cdRef.detectChanges());
        }
      });
  }

  private getAlternateAccountTabFormGroup = (info?: AlternateEmail): CustomCVAccessor<AlternateAccountTabForm> => ({
    valid: true,
    data: {
      alternateEmail: info?.Email || '',
      password: '',
      confirmPassword: ''
    }
  });

  public updateBackupDestinationTab(): void {
    this._loading$.next(true);

    this.myAccountService
      .getStorage()
      .pipe(
        finalize(() => this._loading$.next(false)),
        first()
      )
      .subscribe({
        next: (backupDestination: BackupDestination) => {
          if (!backupDestination) {
            this.backupDestinationTabControl.setValue(null);
          } else {
            const formGroup = this.getBackupDestinationFormGroup(backupDestination);

            if (backupDestination.Endpoint) formGroup.data['endpoint'] = backupDestination.Endpoint;
            if (backupDestination.EndpointSuffix) formGroup.data['endpointSuffix'] = backupDestination.EndpointSuffix;

            this.backupDestinationTabControl.setValue(formGroup, { emitEvent: false });
            this.backupDestinationTabControl.markAsUntouched();
          }
          this.updateReadonly(backupDestination);
          this.attachObject(this.backupDestinationTabControl, (obj: CustomControl<BackupDestinationTabForm>) => obj.value);
          queueMicrotask(() => canAbilityCdRef.call(this) && this.cdRef.detectChanges());
        }
      });
  }

  private updateReadonly(settings: BackupDestination): void {
    this.addedStorage = !isNil(settings) && [settings.Name, settings.BucketName, settings.RootFolder, settings.AccessKey].every(Boolean);
  }

  private getBackupDestinationFormGroup = (backupDestination: BackupDestination): CustomCVAccessor<BackupDestinationTabForm> => ({
    valid: true,
    data: {
      storageType: backupDestination.StorageType,
      name: backupDestination.Name,
      bucketName: backupDestination.BucketName,
      rootFolder: backupDestination.RootFolder,
      accessKey: backupDestination.AccessKey,
      secretKey: SECRET_KEY_MASK
    }
  });

  private defaultFormValue = (): MyAccountForm => ({
    [MyAccountTab.general]: null,
    [MyAccountTab.alternateAccount]: null,
    [MyAccountTab.backupDestination]: null
  });

  handleDelete(): Observable<boolean> {
    return from(
      this.modalService.open(
        {
          header: { title: this.i18nPipe.transform(this.moduleAccount + ':modal.title.confirmDeleteAccount', { format: 'title' }) },
          footer: {
            okButton: {
              text: this.i18nPipe.transform(this.moduleAccount + ':modal.button.delete', { format: 'title' }),
              type: 'danger'
            }
          }
        },
        this.deleteAccountConfirmTemplate
      )
    ).pipe(
      switchMap((confirmed: boolean) => {
        this.deleteLoading$.next(true);
        return confirmed
          ? this.myAccountService.deleteAccount().pipe(
              map((result: boolean) => {
                if (result) {
                  this.authService.logout();
                }

                this.genericPanel.panelClosed.emit();
              }),
              map(() => true),
              catchError(() => of(false))
            )
          : of(false);
      }),
      finalize(() => this.deleteLoading$.next(false)),
      catchError(() => of(false))
    );
  }

  handleSave(): Observable<boolean> {
    if (!this.isValidSidepanel()) return of(false);

    const requestPool$: Observable<boolean | boolean[]>[] = [];

    if (this.isAvailableSaveGeneralTab()) requestPool$.push(this.handleSaveGeneral());
    if (this.isAvailableSaveAlternateAccountTab()) requestPool$.push(this.handleSaveAlternateEmail());
    if (this.isAvailableSaveBackupDestinationTab()) requestPool$.push(this.handleSaveBackupDestination());

    if (requestPool$.length == 0) {
      this.toastFactory.showToast({
        type: MbsPopupType.info,
        nameSpace: this.moduleAccount,
        body: { key: ':toast.body.nothingToSave' }
      });

      return of(true);
    }

    this.saveLoading$.next(true);
    return forkJoin(requestPool$).pipe(
      map((success: boolean[]) => {
        const isSavedSuccessfully = success.every((val) => Boolean(val));

        if (isSavedSuccessfully) {
          this.hold();
          this.genericPanel.panelClosed.emit();
          success.length > 1 &&
            this.toastFactory.showToast({
              type: MbsPopupType.success,
              nameSpace: this.moduleAccount,
              body: { key: ':toast.body.allSavedSuccessfully' }
            });
        }

        return isSavedSuccessfully;
      }),
      finalize(() => this.saveLoading$.next(false))
    );
  }

  public isValidSidepanel(): boolean {
    if (!this.cdNew.tabsService.allTabsValid) {
      if (this.generalTabControl.invalid) {
        FormsUtil.triggerValidation(this.generalTabControl);
      }

      if (this.alternateAccountTabControl.invalid) {
        FormsUtil.triggerValidation(this.alternateAccountTabControl);
      }

      if (this.backupDestinationTabControl.invalid) {
        FormsUtil.triggerValidation(this.backupDestinationTabControl);
      }

      this.tabsService.openFirstError();

      return false;
    }

    if (this.formGroup.invalid) {
      FormsUtil.triggerValidation(this.formGroup);

      return false;
    }

    return true;
  }

  private isAvailableSaveGeneralTab(): boolean {
    return (
      this.generalTabControl.dirty &&
      this.generalTabControl.touched &&
      !isNil(this.generalTabControl.value) &&
      this.isControlChanged(this.generalTabControl)
    );
  }

  private isAvailableSaveAlternateAccountTab(): boolean {
    return (
      this.alternateAccountTabControl.dirty &&
      this.alternateAccountTabControl.touched &&
      !isNil(this.alternateAccountTabControl.value) &&
      this.alternateAccountTabControl.value.data.alternateEmail &&
      this.alternateAccountTabControl.value.data.password &&
      this.alternateAccountTabControl.value.data.confirmPassword &&
      this.isControlChanged(this.alternateAccountTabControl)
    );
  }

  private isAvailableSaveBackupDestinationTab(): boolean {
    return (
      this.backupDestinationTabControl.dirty &&
      this.backupDestinationTabControl.touched &&
      !isNil(this.backupDestinationTabControl.value) &&
      this.isControlChanged(this.backupDestinationTabControl)
    );
  }

  private isControlChanged(control: FormControl): boolean {
    return this.observableObjects.length > 0 && !this.observableObjects.some((ob) => isEqual(control.value, ob.previousData));
  }

  private handleSaveGeneral(): Observable<boolean[]> {
    const requestPool: Observable<boolean>[] = [];
    const isContactChanged = !this.observableObjects.some((obj) =>
      isEqual(get(obj, 'previousData.data.phone'), get(this.generalTabControl, 'value.data.phone'))
    );
    const isExportSettingsChanged = !this.observableObjects.some((obj) =>
      isEqual(get(obj, 'previousData.data.exportSettings'), get(this.generalTabControl, 'value.data.exportSettings'))
    );

    if (isContactChanged) requestPool.push(this.handleSaveContacts());
    if (isExportSettingsChanged) requestPool.push(this.handleSaveExportSettings());

    return combineLatest([...requestPool]).pipe(
      tap((success: boolean[]) => {
        if (success.every(Boolean)) {
          this.toastFactory.showToast({
            type: MbsPopupType.success,
            nameSpace: this.moduleAccount,
            body: { key: ':toast.body.saveGeneralTab' }
          });
        }
      })
    );
  }

  private handleSaveContacts(): Observable<boolean> {
    const formData = this.generalTabControl.value.data;
    const payloadContact = {
      Phone: formData.phone || ''
    } as MyAccountPhone;

    return this.myAccountService.setContactInfo(payloadContact).pipe(
      map(() => true),
      catchError(() => of(false))
    );
  }

  private handleSaveExportSettings(): Observable<boolean> {
    const formData = this.generalTabControl.value.data;
    const payloadExportSettings = {
      ProtectedWithPassword: formData.exportSettings.keepAllowed.checkbox,
      Password: formData.exportSettings.keepAllowed.password ? formData.exportSettings.keepAllowed.password : null,
      RetentionSettings: {
        Enabled: formData.exportSettings.keepCntUnits.checkbox,
        DateNum: formData.exportSettings.keepCntUnits.number,
        DateUnit: formData.exportSettings.keepCntUnits.filter
      }
    } as ExportSettings;

    return this.myAccountService.setExportSettings(payloadExportSettings).pipe(
      map(() => true),
      catchError(() => of(false))
    );
  }

  private handleSaveAlternateEmail(): Observable<boolean> {
    return this.user$.pipe(
      switchMap((user) => {
        const model: AlternateAccountTabForm = this.alternateAccountTabControl.value.data;
        const payload = {
          userId: user.Id,
          email: model.alternateEmail,
          password: model.password,
          confirmPassword: model.confirmPassword
        } as AddAlternateEmail;

        return this.userService.addAlternateEmail(payload).pipe(
          map(() => true),
          catchError(() => of(false)),
          tap((success: boolean) => {
            if (success) {
              this.toastFactory.showToast({
                type: MbsPopupType.success,
                nameSpace: this.moduleAccount,
                body: { key: ':toast.body.saveAlternateEmail' }
              });
            }
          })
        );
      })
    );
  }

  private handleSaveBackupDestination(): Observable<boolean> {
    const model: BackupDestinationTabForm = this.backupDestinationTabControl.value.data;
    const payload = {
      AccessKey: model.accessKey,
      BucketName: model.bucketName,
      Name: model.name,
      RootFolder: model.rootFolder,
      SecretKey: model.secretKey === SECRET_KEY_MASK ? null : model.secretKey,
      StorageType: model.storageType,
      Endpoint: model.endpoint,
      EndpointSuffix: model.endpointSuffix
    } as BackupDestination;

    if (model.endpoint) payload.Endpoint = model.endpoint;
    if (model.endpointSuffix) payload.EndpointSuffix = model.endpointSuffix;

    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this.testSavingStorage(payload).pipe(
      switchMap((testResult: boolean) => {
        if (!testResult) {
          return from(this.showBackupDestinationConfirmModal()).pipe(
            catchError(() => of(false)),
            switchMap((confirmed: boolean) => (confirmed ? this.setStorage(payload) : of(null)))
          );
        } else {
          return this.setStorage(payload);
        }
      })
    );
  }

  private setStorage(payload: BackupDestination): Observable<boolean> {
    return this.myAccountService.setStorage(payload).pipe(
      map(() => true),
      catchError(() => of(false)),
      tap((success: boolean) => {
        if (success) {
          this.toastFactory.showToast({
            type: MbsPopupType.success,
            nameSpace: this.moduleAccount,
            body: { key: ':toast.body.saveBackupDestination' }
          });
        }
      })
    );
  }

  testSavingStorage(payload: BackupDestination): Observable<boolean> {
    return this.myAccountService.testStorage(payload).pipe(
      map((result) => result.value === StorageStateEnum.ok),
      untilDestroyed(this)
    );
  }

  private showBackupDestinationConfirmModal(): Promise<boolean> {
    const settings: ModalSettings = {
      header: {
        title: this.i18nPipe.transform(this.moduleAccount + ':modal.title.backupDestinationToSaveNotFound', { format: 'title' })
      },
      size: MbsSize.sm,
      responsive: true,
      footer: {
        okButton: {
          text: this.i18nPipe.transform(this.moduleAccount + ':modal.button.save', { format: 'title' })
        },
        cancelButton: {
          text: this.i18nPipe.transform(this.moduleAccount + ':modal.button.discard', { format: 'title' })
        }
      }
    };

    return this.modalService.open(settings, this.backupDestinationConfirmTemplate);
  }

  handleBeforeUpdateTabset({ nextId, currentId }): void {
    if (nextId && currentId) {
      this.setRouteSlug(this.genericPanel.show, nextId);
      this.initTabs();
    }
  }

  private initTabs(): void {
    if (this.needInitGeneralTab()) this.updateGeneralTab();
    if (this.needInitAlternateAccountTab()) this.updateAlternateAccountTab();
    if (this.needInitBackupDestinationTab()) this.updateBackupDestinationTab();
  }

  private needInitGeneralTab(): boolean {
    return this.queryParams.get('tab') === MyAccountTab.general && isNil(this.generalTabControl.value);
  }

  private needInitAlternateAccountTab(): boolean {
    return (
      this.queryParams.get('tab') === MyAccountTab.alternateAccount &&
      (isNil(this.alternateAccountTabControl.value) || !this.initialAlternateEmail)
    );
  }

  private needInitBackupDestinationTab(): boolean {
    return (
      this.isStandaloneMode &&
      this.queryParams.get('tab') === MyAccountTab.backupDestination &&
      isNil(this.backupDestinationTabControl.value)
    );
  }

  updateData(): void {
    // void.
    // The method is required for implementation
  }
}
