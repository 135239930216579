import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormControl, FormGroup, UntypedFormGroup, Validators } from '@angular/forms';
import { APPEND_BUTTON_PASSWORD_STATE, ChangePassword, WINDOW } from '@common';
import { AuthService, UserOdataService } from '@common/services';
import { getAppendButtonsIcon, getAppendButtonsState, hasAppendButtonsPasswordType, isConfirmPasswordValidator } from '@common/utils';
import { I18NextPipe } from 'angular-i18next';
import { I18_NAMESPACE_APPS_UI } from 'i18n';
import { camelCase } from 'lodash';
import { InputButton, MbsPopupType, MbsSize } from 'mbs-ui-kit';
import { BehaviorSubject } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'mbs-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResetPasswordComponent {
  public isSuccessChanged: boolean;
  public readonly moduleButton = I18_NAMESPACE_APPS_UI.button;
  public readonly getAppendButtonsIcon = getAppendButtonsIcon;
  public readonly MbsSize = MbsSize;
  public readonly MbsPopupType = MbsPopupType;
  public formGroup: FormGroup = new UntypedFormGroup(
    {
      NewPassword: new FormControl('', [Validators.required, Validators.minLength(7), Validators.maxLength(20)]),
      ConfirmPassword: new FormControl('', Validators.required)
    },
    isConfirmPasswordValidator.bind({}, { password: 'NewPassword', confirmPassword: 'ConfirmPassword' })
  );

  public resetLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public newPasswordType$: BehaviorSubject<string> = new BehaviorSubject(APPEND_BUTTON_PASSWORD_STATE.hidden.type);
  public confirmPasswordType$: BehaviorSubject<string> = new BehaviorSubject(APPEND_BUTTON_PASSWORD_STATE.hidden.type);

  get URLSearchParams(): URLSearchParams {
    return new URLSearchParams(this.window.location.search);
  }

  get token(): string {
    return this.URLSearchParams?.get('token') || this.URLSearchParams?.get('Token');
  }

  constructor(
    @Inject(WINDOW) private window: Window,
    public userService: UserOdataService,
    private authService: AuthService,
    private i18nPipe: I18NextPipe
  ) {}

  handleResetPassword(): void {
    const data = this.formGroup.value as ChangePassword;

    this.resetLoading$.next(true);
    this.userService
      .confirmAlternatePassword(this.token, data.NewPassword)
      .pipe(finalize(() => this.resetLoading$.next(false)))
      .subscribe({
        next: () => (this.isSuccessChanged = true),
        error: (e: HttpErrorResponse) => {
          this.handleFormError(e, this.formGroup);
        }
      });
  }

  private handleFormError(response: HttpErrorResponse, form: UntypedFormGroup): void {
    if (response.status === 400) {
      // response.error.error.details.forEach((input) => {
      //   const p = form.get(input.target);
      //   p.setValue('');
      //   p.setErrors({ [`${input.target}Incorrect`]: { message: input.message } });
      // });
      form.get('Password').setValue('');
      form.get('Password').setErrors({ [`Password Incorrect`]: { message: (response.error as { value: string }).value } });
    } else {
      form.reset();
      form.setErrors({
        validation:
          response.error?.value ||
          response.error?.error?.message ||
          this.i18nPipe.transform('common.validation.somethingWrong', { format: 'capitalize' })
      });
    }
  }

  handleChangePasswordType(event: InputButton): void {
    if (!event) return;

    const id = camelCase(event.id.replace('append', ''));
    const subjectById: BehaviorSubject<string> = this[id + 'Type$'];
    const isPasswordType: boolean = hasAppendButtonsPasswordType(subjectById.getValue());

    subjectById.next(APPEND_BUTTON_PASSWORD_STATE[getAppendButtonsState(isPasswordType)].type);
  }

  handleLogOut(): void {
    this.authService.logout();
  }
}
