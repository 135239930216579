import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { RoutePath } from '@common';
import { hasAdminRole, OdataObject, ServiceStatus, ServiceType } from '@common/models';
import { ODataService, ODataServiceFactory } from '@common/odata';
import { AuthService } from '@common/services';
import { isNil } from 'lodash';
import { forkJoin, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class StatisticsService {
  private readonly backupMethods: ODataService<any>;
  private readonly domainMethods: ODataService<any>;

  constructor(odataFactory: ODataServiceFactory, private authService: AuthService, private router: Router) {
    this.backupMethods = odataFactory.CreateService('BackupUsers');
    this.domainMethods = odataFactory.CreateService('Domains');
  }

  /*
   * @returns list of problems with services (payment, storage limit)
   */
  getServiceStatus(serviceType?: ServiceType | string): Observable<ServiceStatus[]> {
    let methodName = 'ServiceStatus';

    if (!isNil(serviceType) && serviceType !== '') {
      methodName += `?service=${ServiceType[serviceType]}`;
    }

    if (this.router.url.includes(RoutePath.RetentionPolicy)) {
      methodName += `&isRetentionPage=true`;
    }

    return forkJoin([this.authService.getAuthUser(), this.authService.getRoles()]).pipe(
      switchMap(([user, roles]) => {
        const isAdmin: boolean = hasAdminRole(roles);
        const id: string = isAdmin ? user.DomainId : user.Id;
        const service$: ODataService<any> = isAdmin ? this.domainMethods : this.backupMethods;

        return service$.ItemProperty<OdataObject<ServiceStatus[]>>(id, methodName).pipe(map((res) => res.value));
      })
    );
  }
}
