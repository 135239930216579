<ngb-toast (hidden)="handleRemove(toast)"
           (mouseenter)="autohide = false"
           (mouseleave)="autohide = true"
           *ngFor="let toast of visibleToasts; last as isLast"
           [autohide]="autohide"
           [ngClass]="getToastClasses(toast, isLast)"
           [delay]="toast.delay || delayConfig[toast.type]">

  <div class="mbs-toast-icon pr-1 mr-2"
       *ngIf="toast.icon || toast.type">
    <span [ngClass]="getIconClasses(toast)"></span>
  </div>

  <div class="mbs-toast-content">

    <!-- header of toast -->
    <header *ngIf="toast.header"
            class="mbs-toast-header"
            [ngClass]="{'text-break': toast.textBreak === TextBreak.header || toast.textBreak === TextBreak.both }">

      <ng-template #defaultHeader>
        <ng-container *ngIf="toast.header">
          <span class="mbs-toast-title pb-1"
                [innerHTML]="toast.header"></span>
        </ng-container>
      </ng-template>

      <ng-container *ngTemplateOutlet="isTemplate(toast.header) ? templateRef : defaultHeader;
                  context: { $implicit: toast, content: toast.header }"></ng-container>


    </header>

    <!-- body of toast -->
    <div class="mbs-toast-body"
         [ngClass]="{'text-break': toast.textBreak === TextBreak.content || toast.textBreak === TextBreak.both }">

      <ng-container *ngTemplateOutlet="isTemplate(toast.content) ? templateRef : innerHTMLTemplate;
                  context: { $implicit: toast, content: toast.content }"></ng-container>
    </div>

    <!-- footer of toast -->
    <footer *ngIf="toast.footer || toast.buttons && toast.buttons.length"
            class="mbs-toast-footer mt-1">

      <ng-template #defaultFooter>
        <div class="form-row">
          <div class="col-auto"
               *ngFor="let button of toast.buttons">
            <mbs-button [isCtrl]="button.isCtrl || true"
                        [type]="button.type || 'primary-light'"
                        [size]="MbsSize.inherit"
                        (click)="onButtonClick(button, toast)">
              {{ button.label || 'Show more...' }}
            </mbs-button>
          </div>
        </div>
      </ng-template>

      <ng-container *ngIf="!toast.buttons; else defaultFooter">
        <ng-container *ngTemplateOutlet="isTemplate(toast.footer) ? templateRef : innerHTMLTemplate;
                    context: { $implicit: toast, content: toast.footer }"></ng-container>
      </ng-container>

    </footer>

  </div>

  <div class="mbs-toast-close pl-1 ml-2">
    <mbs-button [isCtrl]="true"
                (click)="handleRemove(toast)"
                icon="ico ico-Close"
                customClasses="text-xxs text-white">
    </mbs-button>
  </div>

</ngb-toast>

<!-- Templates -->
<ng-template #innerHTMLTemplate
             let-toast
             let-content="content">
  <div [outerHTML]="content | safe: 'html'"></div>
</ng-template>

<ng-template #templateRef
             let-toast
             let-content="content">
  <ng-container *ngTemplateOutlet="content; context: { $implicit: toast }"></ng-container>
</ng-template>
