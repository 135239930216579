import { ApiServiceStatusEnum, ServiceTypeStr } from '@common';
import { OdataServiceStatistics, ServiceType } from '@common/models';
import { getServiceUiInfo } from '@common/utils';

export class ServiceStatistics {
  api: ApiServiceStatus;
  available: boolean;
  count: number;
  displayName: string;
  enabled: boolean;
  iconCssClass: string;
  lastBackupDate: Date;
  servicePageUrl: (id: string) => string;
  serviceType: ServiceType;
  size: number;
  users: number;

  constructor(odataObj: OdataServiceStatistics) {
    this.serviceType = ServiceType[odataObj.Service] as ServiceType;
    const serviceUiInfo = getServiceUiInfo(this.serviceType);

    this.api = {
      status: odataObj.Api.Status,
      url: odataObj.Api.InstallUrl
    };
    this.available = odataObj.Available;
    this.count = odataObj.Count;
    this.displayName = serviceUiInfo.displayName;
    this.enabled = odataObj.Enabled;
    this.iconCssClass = serviceUiInfo.iconCssClass;
    this.lastBackupDate = odataObj.LastBackupDate ? new Date(odataObj.LastBackupDate) : null;
    this.servicePageUrl = serviceUiInfo.url;
    this.size = odataObj.Size;
    this.users = odataObj.ProtectedUsers;
  }
}

export interface ServiceStatisticsSaveObject {
  services: ServiceStatistics[];
  forAllEnabledUsers?: boolean;
}

interface ApiServiceStatus {
  status: ApiServiceStatusEnum;
  url: string;
}

export interface ConfigureServices {
  Service: ServiceTypeStr;
  Enabled: boolean;
}
