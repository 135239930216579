import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { RoutePath } from '@common';
import { OtherState } from '@common/components/login/enums/auth-state.enum';
import { BrowserService } from '@common/services/browser.service';

@Injectable({
  providedIn: 'root'
})
export class AppInitService {
  constructor(private browserService: BrowserService, private router: Router) {}

  init(): Promise<any> {
    if (!this.browserService.isSupportedBrowser()) {
      void this.router.navigate([RoutePath.Account], { queryParams: { state: OtherState.UnsupportedBrowser } });
    }

    return Promise.resolve();
  }
}
