<!-- mbs-form-filter -->
<form class="mbs-form mbs-form-filter">

  <div class="form-row justify-content-between pt-2">

    <div class="col-12 col-sm-10 col-lg-11 col-xxl-9">

      <div class="form-row">

        <div class="col-12 col-lg-6 col-xl-4 col-xxl-5 pb-2">

          <div class="form-row align-items-center flex-nowrap">

            <div class="col-auto">
              <label class="mbs-form_label mb-0"
                     for="userGroup">
                {{ isOffice ? (moduleUsers + ':label.userGroup' | i18next: { format: 'title' }) : (moduleUsers + ':label.userGroupsGoogle' | i18next: { format: 'title' }) }}:
              </label>
            </div>

            <div class="col overflow-hidden">
              <mbs-select labelForId="userGroup"
                          [notFoundText]="this.isOffice ? (moduleUsers + ':notFoundText.users' | i18next: { format: 'capitalize' }) : (moduleUsers + ':notFoundText.units' | i18next: { format: 'capitalize' })"
                          [typeahead]="this.isOffice ? null : typeahead$"
                          [loading]="searchLoading$ | async"
                          [id]="'userGroup'"
                          [items]="userGroups$ | async"
                          [(ngModel)]="userService.usersGroup"
                          [ngModelOptions]="{ standalone: true }"
                          [bindLabel]="this.isOffice ? 'Name' : 'Path'"
                          bindValue="Id"
                          appendTo="body"
                          [searchable]="true"
                          [clearable]="false">

                <ng-template ng-option-tmp
                             let-group="item">

                  <mbs-text-ellipsis class="text-dark"
                                     container="body"
                                     placement="top-left auto"
                                     tooltipClass="tooltip-lg text-left"
                                     [tooltip]="isOffice ? group.Name : group.Path">
                    {{ isOffice ? group.Name : group.Path }}
                  </mbs-text-ellipsis>

                </ng-template>

              </mbs-select>
            </div>

          </div>

        </div>

        <div class="col-12 col-lg-6 col-xl-4 col-xxl-3 pb-2">

          <div class="form-row align-items-center flex-nowrap">

            <div class="col-auto">
              <label class="mbs-form_label mb-0"
                     for="userDomain">
                {{ moduleUsers + ':label.user' | i18next: { format: 'title' } }}/{{ moduleUsers + ':label.domain' | i18next: { format: 'title' } }}:
              </label>
            </div>

            <div class="col overflow-hidden">
              <mbs-select labelForId="userDomain"
                          [id]="'userDomain'"
                          [items]="userService.availableFilters"
                          [clearable]="false"
                          [searchable]="false"
                          bindLabel="text"
                          bindValue="value"
                          appendTo="body"
                          [(ngModel)]="userService.userProviderState"
                          (ngModelChange)="handleChangeSelect()"
                          [ngModelOptions]="{ standalone: true }">
              </mbs-select>
            </div>

          </div>

        </div>

        <div class="col-12 col-lg-6 col-xl-4 col-xxl-4 pb-2">

          <mbs-input [placeholder]="moduleUsers + ':placeholder.search' | i18next: { format: 'title' }"
                     [disabled]="loading$ | async"
                     [(ngModel)]="userService.searchTerm"
                     [ngModelOptions]="{ standalone: true }"
                     (keyup.enter)="userService.getUsers()"
                     (buttonClick)="userService.getUsers()"
                     [clearButton]="true"
                     (clear)="handleSearchClear()"
                     [appendButtons]="[{ icon:'ico ico-Search', type: 'secondary', btnType: 'submit'}]"
                     (change)="handleSearchChange($event)"
                     #mbsInputSearchRef>
          </mbs-input>

        </div>

      </div>

    </div>

    <div ngbDropdown
         #groupActDropdown="ngbDropdown"
         display="dynamic"
         container="body"
         class="col-12 col-sm-auto pb-2 text-right"
         placement="bottom-right"
         id="groupActions"
         [autoClose]="'outside'">

      <button class="btn btn-secondary"
              type="submit"
              ngbDropdownToggle>
        <img src="assets/group-user-actions.svg"
             width="20"
             alt="actions"/>
      </button>

      <div ngbDropdownMenu
           aria-labelledby="groupActions">

        <button ngbDropdownItem
                (click)="handleSyncDomain(); groupActDropdown.close()">
          {{ moduleUsers + ':dropdown.syncDomain' | i18next: { format: 'title' } }}
        </button>

        <button ngbDropdownItem
                (click)="enableBackupForAll(); groupActDropdown.close()">
          {{ moduleUsers + ':dropdown.addAllToBackup' | i18next: { format: 'title' } }}
        </button>

        <button ngbDropdownItem
                (click)="exportToCSV(); groupActDropdown.close()">
          {{ moduleUsers + ':dropdown.exportToCSV' | i18next: { format: 'title' } }}
        </button>

        <a ngbDropdownItem
           [routerLink]="[prefix + RoutePath.Users, RoutePath.UserGroups]"
           (click)="groupActDropdown.close()">
          {{
            isGoogle
              ? (moduleUsers + ':dropdown.userGroupsGoogle' | i18next: { format: 'title' })
              : (moduleUsers + ':dropdown.userGroupsOffice' | i18next: { format: 'title' })
          }}
        </a>

      </div>

    </div>

  </div>

</form>
<!-- mbs-form-filter end -->

<mbs-table-grid [data]="users$ | async"
                [stickyHeader]="true"
                bindSelected="Id"
                [headers]="headers"
                [changeSortState]="orderBy"
                [serverSidePagination]="true"
                [paginationOptions]="paginationOptions"
                [showTableCheckboxes]="true"
                [multipleSelect]="true"
                [selectable]="true"
                [selectedItems]="selectedItems"
                [loading]="loading$ | async"
                [viewMode]="viewMode"
                [switcherView]="false"
                [disableViewSwitch]="true"
                (sort)="handleSort($event)"
                (changeSelected)="handleCheckUsers($event)"
                (pageChange)="pageChange($event)"
                (pageSizeChange)="pageSizeChange($event)"
                [maxHeight]="maxHeight"
                [style.padding-right.px]="panelWidth"
                [wideContentMode]="wideContentMode"
                #mbsTableGridRef>

  <!-- mbs-table-nav -->
  <ng-container *ngIf="selectedItems.length; else statisticTemplate">

    <ng-template mbsTableFilter="label"
                 *ngIf="domainStatistic$ | async as stat">
      {{ moduleUsers + ':grid-nav.selected' | i18next: { format: 'title' } }}: {{ selectedItems.length }} of {{ stat.AllCnt }}
    </ng-template>

    <ng-template mbsTableFilter>
      <mbs-button [isCtrl]="true"
                  type="primary"
                  [size]="MbsSize.sm"
                  icon="ico ico-Gear"
                  customClasses="mbs-table_sort-ctrl"
                  (click)="handleGroupAction(ApplyToEnum.Selected, GroupActionType.General)">
        {{ moduleUsers + ':grid-nav.configureBackup' | i18next: { format: 'title' } }}
      </mbs-button>
    </ng-template>

    <ng-template mbsTableFilter>
      <mbs-button [isCtrl]="true"
                  type="primary"
                  [size]="MbsSize.sm"
                  icon="ico ico-Lock2"
                  customClasses="mbs-table_sort-ctrl"
                  (click)="handleGroupAction(ApplyToEnum.Selected, GroupActionType.Permissions)">
        {{ moduleUsers + ':grid-nav.permissions' | i18next: { format: 'title' } }}
      </mbs-button>
    </ng-template>

    <ng-template mbsTableFilter>
      <mbs-button [isCtrl]="true"
                  type="primary"
                  [size]="MbsSize.sm"
                  icon="ico ico-boxClock"
                  customClasses="mbs-table_sort-ctrl"
                  (click)="handleGroupAction(ApplyToEnum.Selected, GroupActionType.Policies)">
        {{ moduleUsers + ':grid-nav.retentionPolicy' | i18next: { format: 'title' } }}
      </mbs-button>
    </ng-template>

    <ng-template *ngIf="!(isProviderSignIn$ | async)"
                 mbsTableFilter>
      <mbs-button [isCtrl]="true"
                  type="primary"
                  [size]="MbsSize.sm"
                  icon="ico ico-file"
                  customClasses="mbs-table_sort-ctrl"
                  (click)="handleGroupAction(ApplyToEnum.Selected, GroupActionType.ExportToPST)">
        {{ moduleUsers + ':grid-nav.exportToPST' | i18next: { format: 'title' } }}
      </mbs-button>
    </ng-template>

    <ng-template *ngIf="!(isProviderSignIn$ | async)"
                 mbsTableFilter>
      <mbs-button [isCtrl]="true"
                  type="primary"
                  [size]="MbsSize.sm"
                  icon="fa fa-trash"
                  customClasses="mbs-table_sort-ctrl"
                  tooltipClass="tooltip-lg tooltip-shift text-left"
                  [disabled]="isImmutability"
                  [tooltipData]="isImmutability ? [moduleUsers + ':toast.body.immutability' | i18next: { format: 'capitalize' }] : null"
                  [tooltipAlways]="true"
                  [tooltipPlacement]="['top-left', 'auto']"
                  [tooltipTriggers]="'mouseenter:mouseleave'"
                  (click)="handleGroupAction(ApplyToEnum.Selected, GroupActionType.DeleteBackup)">
        {{ moduleUsers + ':grid-nav.backupDelete' | i18next: { format: 'title' } }}
      </mbs-button>
    </ng-template>

    <ng-template mbsTableFilter>
      <mbs-button *ngIf="showDeleteUser"
                  [isCtrl]="true"
                  type="primary"
                  icon="fa fa-user"
                  [size]="MbsSize.sm"
                  customClasses="mbs-table_sort-ctrl"
                  (click)="deleteSelectedUser(deleteUserTempRef)">
        {{ moduleUsers + ':grid-nav.deleteUser' | i18next: { format: 'title' } }}
      </mbs-button>

      <ng-template #deleteUserTempRef>
        <ng-container *ngIf="selectedItems.length === 1; else multiDeleteUserTempRef">
          {{ moduleUsers + ':modal.body.confirmDeleteUser' | i18next: { format: 'capitalize' } }}
          <span class="font-weight-semibold">{{ selectedItems[0].Email }}</span>?
        </ng-container>
        <ng-template #multiDeleteUserTempRef>
          {{ moduleUsers + ':modal.body.confirmDeleteUsers_begin' | i18next: { format: 'capitalize' } }}
          <span class="font-weight-semibold">{{ selectedItems.length }}</span>
          {{ moduleUsers + ':modal.body.confirmDeleteUsers_end' | i18next }}?
        </ng-template>
      </ng-template>
    </ng-template>

  </ng-container>

  <ng-template #statisticTemplate>
    <ng-container *ngIf="domainStatistic$ | async; let stat; ">

      <ng-template mbsTableFilter>
        <mbs-button [isCtrl]="true"
                    [size]="MbsSize.sm"
                    [type]="filter === TableFiltersEnum.All ? 'dark' : 'primary'"
                    customClasses="mbs-table_sort-ctrl"
                    (click)="handleFiltering(TableFiltersEnum.All)">
          {{ moduleUsers + ':grid-nav.all' | i18next: { format: 'title' } }}: {{ stat.AllCnt }}
        </mbs-button>
      </ng-template>

      <ng-template mbsTableFilter>
        <mbs-button [isCtrl]="true"
                    [size]="MbsSize.sm"
                    icon="fa fa-user mr-1 text-sm text-success"
                    [type]="filter === TableFiltersEnum.InBackup ? 'dark' : 'primary'"
                    customClasses="mbs-table_sort-ctrl"
                    (click)="handleFiltering(TableFiltersEnum.InBackup)">
          {{ moduleUsers + ':grid-nav.includedInBackup' | i18next: { format: 'title' } }}: ({{ stat.BackupCnt }})
        </mbs-button>
      </ng-template>

      <ng-template mbsTableFilter>
        <mbs-button [isCtrl]="true"
                    [size]="MbsSize.sm"
                    [type]="filter === TableFiltersEnum.NotInBackup ? 'dark' : 'primary'"
                    icon="fa fa-user mr-1 text-sm text-info"
                    customClasses="mbs-table_sort-ctrl"
                    (click)="handleFiltering(TableFiltersEnum.NotInBackup)">
          {{ moduleUsers + ':grid-nav.notInBackup' | i18next: { format: 'title' } }}: ({{ stat.NotInBackupCnt }})
        </mbs-button>
      </ng-template>

      <ng-template mbsTableFilter>
        <mbs-button [isCtrl]="true"
                    [size]="MbsSize.sm"
                    [type]="filter === TableFiltersEnum.InBackupNotInDomain ? 'dark' : 'primary'"
                    icon="fa fa-user mr-1 text-sm text-dark"
                    customClasses="mbs-table_sort-ctrl"
                    (click)="handleFiltering(TableFiltersEnum.InBackupNotInDomain)">
          {{ moduleUsers + ':grid-nav.withBackupButNotInDomain' | i18next: { format: 'title' } }}: ({{ stat.InBackupNotInDomainCnt }})
        </mbs-button>
      </ng-template>

      <ng-template mbsTableFilter>
        <mbs-button [isCtrl]="true"
                    [size]="MbsSize.sm"
                    [type]="filter === TableFiltersEnum.Suspended ? 'dark' : 'primary'"
                    icon="fa fa-user mr-1 text-sm text-danger"
                    customClasses="mbs-table_sort-ctrl"
                    (click)="handleFiltering(TableFiltersEnum.Suspended)">
          {{ moduleUsers + ':grid-nav.blocked' | i18next: { format: 'title' } }}/{{ moduleUsers + ':grid-nav.suspended' | i18next: { format: 'title' } }}: ({{ stat.SuspendedCnt }})
        </mbs-button>
      </ng-template>

    </ng-container>
  </ng-template>
  <!-- mbs-table-nav end -->

  <!-- mbs-table -->
  <ng-template mbsTableCell
               let-user>

    <ng-container *ngLet="getIsUserDeleted(user) as isUserDeleted">

      <div class="mbs-card_ico i-Card">

        <span [ngClass]="getUserClasses(user)"
              [class.mbs-user_ico]="isUserDeleted"
              [ngbTooltip]="getUserTooltip(user)"
              [style.font-size.px]="user.Id === authUserId ? 19 : null"
              container="body"
              placement="top-left auto"
              tooltipClass="tooltip-lg tooltip-shift">

          <span *ngIf="isUserDeleted"
                class="fa fa-times"></span>

        </span>

      </div>

    </ng-container>

  </ng-template>

  <ng-template mbsTableCell
               let-user>

    <mbs-button [isCtrl]="true"
                [block]="true"
                [customClasses]="'text-left mw-100'"
                (click)="handleSelectUser(user)"
                [size]="MbsSize.sm">

      <mbs-text-ellipsis *ngIf="user.Name"
                         class="text-dark"
                         container="body"
                         placement="top-left auto"
                         tooltipClass="tooltip-lg tooltip-shift"
                         [tooltip]="user.Name">
        {{ user.Name }}
      </mbs-text-ellipsis>

      <mbs-text-ellipsis container="body"
                         placement="top-left auto"
                         tooltipClass="tooltip-lg"
                         [tooltip]="user.Email">
        {{ user.Email }}

        <ng-template [hide]="!user.IsMainUser"
                     mbsTextEllipsisAppend>

          <span [placement]="'top-left auto'"
                [tooltipClass]="'tooltip-lg tooltip-shift'"
                [container]="'body'"
                [ngbTooltip]="isOffice
                  ? (moduleUsers + ':grid.globalAdministrator' | i18next: { format: 'capitalize' })
                  : (moduleUsers + ':grid.superAdministrator' | i18next: { format: 'capitalize' })"
                class="ml-1 text-lg fa fa-star text-purple"></span>

        </ng-template>
      </mbs-text-ellipsis>

    </mbs-button>

  </ng-template>

  <ng-template mbsTableCell
               let-user>
    {{ user.LastBackupDate ? (user.LastBackupDate | date: DateFormat.short) : (moduleUsers + ':emptyValue' | i18next) }}
  </ng-template>

  <ng-template mbsTableCell
               let-user>

    <mbs-text-ellipsis container="body"
                       placement="top-left auto"
                       tooltipClass="tooltip-lg"
                       [tooltip]="user.TotalSize ? (user.TotalSize | size) : '0 B'">
      {{ user.TotalSize ? (user.TotalSize | size) : '0 B' }}
    </mbs-text-ellipsis>

  </ng-template>

  <ng-template mbsTableCell
               let-user>

    <mbs-status [icon]="StatusIconType.default"
                [type]="backupLicenseColorCssClass(user)">
      {{ getBackupLicenseStatus(user) }}
    </mbs-status>

  </ng-template>

  <ng-template mbsTableCell
               let-user>
    {{ user | policyType }}
  </ng-template>

  <ng-template mbsTableHeader="policyType">
    <mbs-button [isCtrl]="true"
                type="dark">
      <span class="font-weight-semibold">Retention Policy</span>

      <ng-template mbsButtonDropdown
                   [size]="MbsSize.sm"
                   display="dynamic">
        <button ngbDropdownItem
                (click)="handleSortPolicyType('All')">
          {{ moduleButton + ':button.all' | i18next: { format: 'title' } }}
        </button>

        <button ngbDropdownItem
                (click)="handleSortPolicyType(PolicyType.none)">
          {{ PolicyType.none }}
        </button>

        <button ngbDropdownItem
                (click)="handleSortPolicyType(PolicyType.default)">
          {{ PolicyType.default }}
        </button>

        <button ngbDropdownItem
                (click)="handleSortPolicyType(PolicyType.custom)">
          {{ PolicyType.custom }}
        </button>
      </ng-template>

    </mbs-button>
  </ng-template>

  <ng-template mbsTableHeader="mail">
    <mbs-button [isCtrl]="true"
                type="dark"
                title="Mail Filter">
      <span class="ico ico-Envelope"
            [class.text-success]="userService.mailFilter"
            [class.text-gray-500]="userService.mailFilter !== null && !userService.mailFilter"></span>

      <ng-template mbsButtonDropdown
                   [size]="MbsSize.sm"
                   display="dynamic">
        <button ngbDropdownItem
                (click)="dropDownFilter('mailFilter', null)">
          <span class="ico ico-Envelope mr-2"></span>
          {{ BackupFilterText.All }}
        </button>

        <button ngbDropdownItem
                (click)="dropDownFilter('mailFilter', true)">
          <span class="ico ico-Envelope text-success mr-2"></span>
          {{ BackupFilterText.Enabled }}
        </button>

        <button ngbDropdownItem
                (click)="dropDownFilter('mailFilter', false)">
          <span class="ico ico-Envelope text-gray-500 mr-2"></span>
          {{ BackupFilterText.Disabled }}
        </button>
      </ng-template>

    </mbs-button>
  </ng-template>

  <ng-template mbsTableCell
               let-user>
    <a *ngLet="user | statusFilterBackup: ServiceType.Mail: disabledServices as status"
       [routerLink]="[prefix + RoutePath.Mail, user.Id]"
       [ngbTooltip]="status | filterServiceBackupText: ServiceType.Mail"
       [container]="'body'"
       [placement]="'auto'"
       [class]="'text-decoration-none' + ' ' + (status | filterServiceBackupColor)">
      <span class="ico ico-Envelope"></span>
    </a>
  </ng-template>

  <ng-template mbsTableHeader="drive">
    <mbs-button [isCtrl]="true"
                type="dark"
                title="Drive Filter">
      <span class="ico ico-Drive"
            [class.text-success]="userService.driveFilter"
            [class.text-gray-500]="userService.driveFilter !== null && !userService.driveFilter"></span>

      <ng-template mbsButtonDropdown
                   [size]="MbsSize.sm"
                   display="dynamic">
        <button ngbDropdownItem
                (click)="dropDownFilter('driveFilter', null)">
          <span class="ico ico-Drive mr-2"></span>
          {{ BackupFilterText.All }}
        </button>

        <button ngbDropdownItem
                (click)="dropDownFilter('driveFilter', true)">
          <span class="ico ico-Drive text-success mr-2"></span>
          {{ BackupFilterText.Enabled }}
        </button>

        <button ngbDropdownItem
                (click)="dropDownFilter('driveFilter', false)">
          <span class="ico ico-Drive text-gray-500 mr-2"></span>
          {{ BackupFilterText.Disabled }}
        </button>
      </ng-template>

    </mbs-button>

  </ng-template>

  <ng-template mbsTableCell
               let-user>
    <a *ngLet="user | statusFilterBackup: ServiceType.Drive: disabledServices as status"
       [routerLink]="[prefix + RoutePath.Drive, user.Id]"
       [ngbTooltip]="status | filterServiceBackupText: ServiceType.Drive"
       [container]="'body'"
       [placement]="'auto'"
       [class]="'text-decoration-none' + ' ' + (status | filterServiceBackupColor)">
      <span class="ico ico-Drive"></span>
    </a>
  </ng-template>

  <ng-template mbsTableHeader="contact">

    <mbs-button [isCtrl]="true"
                type="dark"
                title="Contact Filter">

      <span class="ico ico-Contact"
            [class.text-success]="userService.contactFilter"
            [class.text-gray-500]="userService.contactFilter !== null && !userService.contactFilter"></span>

      <ng-template mbsButtonDropdown
                   [size]="MbsSize.sm"
                   display="dynamic">
        <button ngbDropdownItem
                (click)="dropDownFilter('contactFilter', null)">
          <span class="ico ico-Contact mr-2"></span>
          {{ BackupFilterText.All }}
        </button>

        <button ngbDropdownItem
                (click)="dropDownFilter('contactFilter', true)">
          <span class="ico ico-Contact text-success mr-2"></span>
          {{ BackupFilterText.Enabled }}
        </button>

        <button ngbDropdownItem
                (click)="dropDownFilter('contactFilter', false)">
          <span class="ico ico-Contact text-gray-500 mr-2"></span>
          {{ BackupFilterText.Disabled }}
        </button>
      </ng-template>

    </mbs-button>

  </ng-template>

  <ng-template mbsTableCell
               let-user>
    <a *ngLet="user | statusFilterBackup: ServiceType.Contacts: disabledServices as status"
       [routerLink]="[prefix + RoutePath.Contacts, user.Id]"
       [ngbTooltip]="status | filterServiceBackupText: ServiceType.Contacts"
       [container]="'body'"
       [placement]="'auto'"
       [class]="'text-decoration-none' + ' ' + (status | filterServiceBackupColor)">
      <span class="ico ico-Contact"></span>
    </a>
  </ng-template>

  <ng-template mbsTableHeader="calendar">

    <mbs-button [isCtrl]="true"
                type="dark"
                title="Calendar Filter">

      <span class="ico ico-Calendar"
            [class.text-success]="userService.calendarFilter"
            [class.text-gray-500]="userService.calendarFilter !== null && !userService.calendarFilter"></span>

      <ng-template mbsButtonDropdown
                   [size]="MbsSize.sm"
                   display="dynamic">
        <button ngbDropdownItem
                (click)="dropDownFilter('calendarFilter', null)">
          <span class="ico ico-Calendar mr-2"></span>
          {{ BackupFilterText.All }}
        </button>

        <button ngbDropdownItem
                (click)="dropDownFilter('calendarFilter', true)">
          <span class="ico ico-Calendar text-success mr-2"></span>
          {{ BackupFilterText.Enabled }}
        </button>

        <button ngbDropdownItem
                (click)="dropDownFilter('calendarFilter', false)">
          <span class="ico ico-Calendar text-gray-500 mr-2"></span>
          {{ BackupFilterText.Disabled }}
        </button>
      </ng-template>

    </mbs-button>

  </ng-template>

  <ng-template mbsTableCell
               let-user>
    <a *ngLet="user | statusFilterBackup: ServiceType.Calendar: disabledServices as status"
       [routerLink]="[prefix + RoutePath.Calendar, user.Id]"
       [ngbTooltip]="status | filterServiceBackupText: ServiceType.Calendar"
       [container]="'body'"
       [placement]="'auto'"
       [class]="'text-decoration-none' + ' ' + (status | filterServiceBackupColor)">
      <span class="ico ico-Calendar"></span>
    </a>
  </ng-template>
  <!-- mbs-table end -->

</mbs-table-grid>

<ng-template #deleteJobTransferredTemplateRef>
  {{ moduleUsers + ':toast.body.deleteJobTransferred' | i18next: { format: 'capitalize' } }}
  <a [routerLink]="[prefix + RoutePath.TaskManager]"
     class="d-inline">{{ moduleUsers + ':toast.body.taskManager' | i18next: { format: 'title' } }}</a>
</ng-template>
