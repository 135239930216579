<aside #sidePanel
       class="mbs-sidepanel"
       [ngClass]="sidebarSideClass()">

  <header class="mbs-sidepanel_header"
          [ngClass]="headerClass">

    <!-- Row -->
    <div class="mbs-sidepanel_header-row row">

      <div class="mbs-sidepanel_header-content col">

        <ng-template #headerTitle>
          <ng-content select="[header-title]"></ng-content>
        </ng-template>

        <ng-template #loadingDataTitle>
          <ng-content select="[loading-data-title]"></ng-content>
        </ng-template>

        <ng-container *ngTemplateOutlet="loadingData ? loadingDataTitle : headerTitle"></ng-container>

      </div>

      <div class="mbs-sidepanel_header-controls col-auto"
           *ngIf="showExpandedCross">
        <mbs-button [isCtrl]="true"
                    [type]="'dark'"
                    [customClasses]="'mbs-sidepanel_ctrl'"
                    (click)="toggleExpand()"
                    [icon]="isExpanded? 'fa fa-compress':'fa fa-expand'"></mbs-button>
      </div>

      <div class="mbs-sidepanel_header-controls col-auto">
        <mbs-button [isCtrl]="true"
                    [type]="'dark'"
                    [customClasses]="'mbs-sidepanel_ctrl'"
                    (click)="handleClose()"
                    [icon]="'ico ico-Close'"></mbs-button>
      </div>

    </div>
    <!-- /Row -->

    <ng-content select="[header-content]"></ng-content>

  </header>

  <div class="mbs-sidepanel_content"
       [ngClass]="{'mbs-form': innerContentType !== 'inner'}">

    <ng-template #body>
      <ng-content select="[body]"></ng-content>
    </ng-template>

    <ng-container *ngIf="innerContentType !== 'tabs'">
      <mbs-loader *ngIf="loadingData"
                  class="mbs-sidepanel_content-loader"></mbs-loader>

      <div *ngIf="!loadingData"
           class="mbs-sidepanel_content-scrolling">

        <ng-container *ngIf="innerContentType === 'nowrap'; else wrapBody"
                      [ngTemplateOutlet]="body"></ng-container>

        <ng-template #wrapBody>
          <div [class]="innerContentType === 'inner' ? 'mbs-sidepanel_content-inner' : 'mbs-form_content'">
            <ng-container [ngTemplateOutlet]="body"></ng-container>
          </div>
        </ng-template>

      </div>

      <!-- TODO: Replace previous black with next commented block when custom scrollbar will be implemented for all sidepanels -->
      <!--ng-scrollbar *ngIf="!loadingData"
                       class="d-block w-100 h-100">
        <div class="mbs-sidepanel_content-scrolling">
          <ng-container *ngIf="innerContentType === 'nowrap'; else wrapBody"
                        [ngTemplateOutlet]="body"></ng-container>
          <ng-template #wrapBody>
            <div [class]="innerContentType === 'inner' ? 'mbs-sidepanel_content-inner' : 'mbs-form_content'">
              <ng-container [ngTemplateOutlet]="body"></ng-container>
            </div>
          </ng-template>
        </div>
      </ng-scrollbar-->
    </ng-container>

    <ng-container *ngIf="innerContentType === 'tabs'"
                  [ngTemplateOutlet]="body">
    </ng-container>

    <footer class="mbs-sidepanel_footer"
            *ngIf="showFooter">

      <div class="mbs-sidepanel_footer-row"
           [ngClass]="[!isCreate && showDelete ? 'justify-content-between': 'justify-content-end']">

        <ng-template #footerDefault>

          <div class="mbs-sidepanel_footer-col"
               *ngIf="!isCreate && showDelete">
            <mbs-button type="outline-danger"
                        [disabled]="disabledDelete || loadingData"
                        [loading]="loadingForDelete$ | async"
                        (click)="deleteEvent.emit()">
              {{ actionNames.delete ? actionNames.delete : 'Delete' }}
            </mbs-button>
          </div>

          <div class="d-flex">

            <div class="mbs-sidepanel_footer-col"
                 [ngbTooltip]="saveButtonTooltip"
                 [placement]="saveButtonTooltipPosition"
                 *ngIf="!hideSave">
              <mbs-button type="primary"
                          [disabled]="disabledSave || loadingData"
                          [loading]="loadingForSave$ | async"
                          (click)="saveEvent.emit()">
                {{ actionNames.save ? actionNames.save : (!isCreate ? 'Save' : 'Create') }}
              </mbs-button>
            </div>

            <div class="mbs-sidepanel_footer-col">
              <mbs-button type="secondary"
                          (click)="panelClosed.emit()">
                {{ actionNames.close ? actionNames.close : 'Close' }}
              </mbs-button>
            </div>

          </div>

        </ng-template>

        <ng-container *ngTemplateOutlet="footerTemplateContent ? footerTemplateContent : footerDefault"></ng-container>

      </div>

    </footer>

  </div>

</aside>
