import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, forwardRef, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormControl, NG_VALUE_ACCESSOR, UntypedFormGroup, Validators } from '@angular/forms';
import { CustomCVAccessor, SECRET_KEY_MASK } from '@common';
import { BackupDestinationTabForm } from '@common/components/my-account/my-account-sidepanel/my-account-sidepanel.model';
import { MyAccountSidepanelService } from '@common/components/my-account/my-account-sidepanel/my-account-sidepanel.service';
import { BackupDestination, StorageStateEnum, StorageTypes } from '@common/models';
import { MyAccountService } from '@common/services';
import { convertFirstChartObjectKeyToUpperCase, getErrorText, hasErrorResponseText } from '@common/utils';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { I18NextPipe } from 'angular-i18next';
import { I18_NAMESPACE_APPS_UI } from 'i18n';
import { isEqual, isNil } from 'lodash';
import { MbsPopupType, ModalService, TabBase, TabsetItemDirective, TabsService, ToastService } from 'mbs-ui-kit';
import { BehaviorSubject, from, Observable, of } from 'rxjs';
import { distinctUntilChanged, finalize, map, switchMap } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'app-my-account-backup-destination-tab',
  templateUrl: './backup-destination-tab.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MyAccountBackupDestinationTabComponent),
      multi: true
    }
  ]
})
export class MyAccountBackupDestinationTabComponent extends TabBase implements ControlValueAccessor, OnInit {
  @ViewChild('deleteBDestinationConfirmTempRef', { static: true, read: TemplateRef }) deleteConfirmTempRef: TemplateRef<any>;

  @Input() readonly = false;

  readonly #toastTitleSuccess = this.i18nPipe.transform('toast.success.title', { format: 'title' });
  readonly #toastTitleError = this.i18nPipe.transform('toast.error.title', { format: 'title' });
  readonly #toastTitleWarning = this.i18nPipe.transform('toast.warning.title', { format: 'title' });

  private readonly close$: Observable<boolean>;

  public readonly moduleAccount = I18_NAMESPACE_APPS_UI.account;
  public readonly MbsPopupType = MbsPopupType;
  public readonly storageTypes: string[];
  public readonly secretKeyIconClasses = {
    lock: 'fa fa-lock',
    unlock: 'fa fa-unlock-alt'
  };

  public readonly disableDelete$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public readonly disabledSecretKey$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public readonly verified$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public readonly testLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public readonly deleteLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  public formGroup: UntypedFormGroup;

  private onChange: (value: CustomCVAccessor<BackupDestinationTabForm>) => void;
  private onTouched: () => void;

  private _settings: BackupDestinationTabForm;
  set settings(settings: BackupDestinationTabForm) {
    this._settings = settings;
    this.notifyValueChange();
  }

  get settings(): BackupDestinationTabForm {
    return this._settings;
  }

  get storageTypeControl(): FormControl {
    return <FormControl>this.formGroup.get('storageType');
  }

  get secretKeyControl(): FormControl {
    return <FormControl>this.formGroup.get('secretKey');
  }

  get bucketNameLabelKey(): string {
    switch (this.storageTypeControl.value) {
      case StorageTypes.azureStack:
      case StorageTypes.azure:
        return 'container';
      default:
        return 'bucketName';
    }
  }

  get accessKeyLabelKey(): string {
    switch (this.storageTypeControl.value) {
      case StorageTypes.azureStack:
      case StorageTypes.azure:
        return 'storageAccountName';
      case StorageTypes.backBlaze:
        return 'accountID';
      default:
        return 'accessKey';
    }
  }

  get secretKeyLabelKey(): string {
    switch (this.storageTypeControl.value) {
      case StorageTypes.azureStack:
      case StorageTypes.azure:
        return 'key1';
      case StorageTypes.backBlaze:
        return 'applicationKey';
      default:
        return 'secretKey';
    }
  }

  get isAvailableEndpoint(): boolean {
    return (
      this.storageTypeControl.value === StorageTypes.s3compatible ||
      this.storageTypeControl.value === StorageTypes.minio ||
      this.storageTypeControl.value === StorageTypes.swift ||
      this.storageTypeControl.value === StorageTypes.wasabi
    );
  }

  get isAvailableEndpointSuffix(): boolean {
    return this.storageTypeControl.value === StorageTypes.azureStack;
  }

  get verifiedTooltip(): string {
    return `${this.i18nPipe.transform(this.moduleAccount + ':sidepanel.tooltip.currentDestination', { format: 'title' })}: ${
      this.formGroup.get('name').value as string
    }`;
  }

  constructor(
    private fb: FormBuilder,
    private i18nPipe: I18NextPipe,
    private myAccountService: MyAccountService,
    private myAccountSidepanelService: MyAccountSidepanelService,
    private toastService: ToastService,
    private modalService: ModalService,
    tabService: TabsService,
    tabsetItem: TabsetItemDirective
  ) {
    super(tabService, tabsetItem);
    this.close$ = myAccountSidepanelService.sidepanel.close;
    this.storageTypes = Object.values(StorageTypes);
  }

  ngOnInit(): void {
    this.initForm();

    this.formGroup.valueChanges
      .pipe(
        distinctUntilChanged((prev, curr) => isEqual(prev, curr)),
        untilDestroyed(this)
      )
      .subscribe({
        next: (settings: BackupDestinationTabForm) => {
          this.settings = settings;
          this.valid = this.formGroup.valid;
        }
      });

    this.storageTypeControl.valueChanges.pipe(distinctUntilChanged(), untilDestroyed(this)).subscribe({
      next: () => {
        const exceptions = {
          storageType: this.storageTypeControl.value,
          secretKey: null
        };
        this.formGroup.reset(this.defaultFormValue(exceptions), { emitEvent: false });
        this.checkExtraControls();
      }
    });

    this.close$.pipe(untilDestroyed(this)).subscribe({
      next: () => {
        const exceptions = {
          storageType: StorageTypes.amazonS3
        };
        this.formGroup.reset(this.defaultFormValue(exceptions), { emitEvent: false });
        this.checkExtraControls();
      }
    });
  }

  private initForm(): void {
    this.formGroup = this.fb.group({
      storageType: [StorageTypes.amazonS3, [Validators.required]],
      name: ['', [Validators.required, Validators.maxLength(50)]],
      bucketName: ['', [Validators.required, Validators.maxLength(50)]],
      rootFolder: ['', [Validators.required, Validators.maxLength(50)]],
      accessKey: ['', [Validators.required]],
      secretKey: ['', [Validators.required, Validators.maxLength(150)]],
      endpoint: ['', [Validators.required]],
      endpointSuffix: ['', [Validators.required]]
    });

    this.checkExtraControls();
    this.form = this.formGroup;
  }

  private checkExtraControls(): void {
    const action = (condition: boolean) => (condition ? 'enable' : 'disable');
    const options = { emitEvent: false };

    this.formGroup.get('endpoint')[action(this.isAvailableEndpoint)](options);
    this.formGroup.get('endpointSuffix')[action(this.isAvailableEndpointSuffix)](options);
  }

  registerOnChange(fn: (value: CustomCVAccessor<BackupDestinationTabForm>) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  writeValue(settings: CustomCVAccessor<BackupDestinationTabForm>): void {
    this.disableDelete$.next(isNil(settings));
    if (!settings) return;

    this.updateFormValue(settings.data);
    this.settings = settings.data;
    this.checkExtraControls();
  }

  notifyValueChange(): void {
    const value: CustomCVAccessor<BackupDestinationTabForm> = {
      valid: this.formGroup.valid,
      data: this.settings
    };

    this.onChange && this.onChange(value);
    this.onTouched && this.onTouched();
  }

  updateFormValue(settings: BackupDestinationTabForm): void {
    this.verified$.next(false);
    this.disabledSecretKey$.next(Boolean(settings.secretKey));

    this.resetFormGroup();

    if (settings.storageType) this.formGroup.get('storageType').setValue(settings.storageType, { emitEvent: false });
    if (settings.name) this.formGroup.get('name').setValue(settings.name, { emitEvent: false });
    if (settings.bucketName) this.formGroup.get('bucketName').setValue(settings.bucketName, { emitEvent: false });
    if (settings.rootFolder) this.formGroup.get('rootFolder').setValue(settings.rootFolder, { emitEvent: false });
    if (settings.accessKey) this.formGroup.get('accessKey').setValue(settings.accessKey, { emitEvent: false });
    if (settings.secretKey) this.formGroup.get('secretKey').setValue(settings.secretKey, { emitEvent: false });
    if (settings.endpoint) this.formGroup.get('endpoint').setValue(settings.endpoint, { emitEvent: false });
    if (settings.endpointSuffix) this.formGroup.get('endpointSuffix').setValue(settings.endpointSuffix, { emitEvent: false });

    this.afterFormInit();
  }

  private resetFormGroup(options: { onlySelf?: boolean; emitEvent?: boolean } = { emitEvent: false }): void {
    this.formGroup.reset(this.defaultFormValue(), options);
  }

  private defaultFormValue = (exceptions: Partial<BackupDestinationTabForm> = {}): BackupDestinationTabForm => {
    return {
      storageType: exceptions.storageType || StorageTypes.amazonS3,
      name: exceptions.name || '',
      bucketName: exceptions.bucketName || '',
      rootFolder: exceptions.rootFolder || '',
      accessKey: exceptions.accessKey || '',
      secretKey: exceptions.secretKey || '',
      endpoint: exceptions.endpoint || '',
      endpointSuffix: exceptions.endpointSuffix || ''
    };
  };

  handleDelete(): void {
    from(
      this.modalService.open(
        {
          header: { title: this.i18nPipe.transform(this.moduleAccount + ':modal.title.confirmBackupDeletion', { format: 'title' }) },
          footer: {
            okButton: { text: this.i18nPipe.transform(this.moduleAccount + ':modal.button.delete', { format: 'title' }), type: 'danger' }
          }
        },
        this.deleteConfirmTempRef
      )
    )
      .pipe(
        switchMap((confirmed) => {
          this.deleteLoading$.next(true);
          return confirmed
            ? this.myAccountService.deleteStorage().pipe(
                finalize(() => this.deleteLoading$.next(false)),
                map(() => true)
              )
            : of(false);
        })
      )
      .subscribe({
        next: (result: boolean) => {
          if (!result) return;

          this.resetFormGroup({ emitEvent: true });
          this.readonly = false;

          this.toastService.success(
            this.i18nPipe.transform(this.moduleAccount + ':toast.body.confirmBackupDeletion', { format: 'capitalize' }),
            this.#toastTitleSuccess
          );
        },
        error: (res: HttpErrorResponse) => hasErrorResponseText(res) && this.toastService.error(getErrorText(res), this.#toastTitleError)
      });
  }

  handleTest(): void {
    const payload = { ...this.formGroup.value };
    payload.secretKey = payload.secretKey === SECRET_KEY_MASK ? null : payload.secretKey;
    // TODO: need delete after fix in mbs-select (shared 1.3)
    payload.storageType = payload.storageType || this.storageTypeControl.value;
    const convertedPayload = convertFirstChartObjectKeyToUpperCase<BackupDestination>(payload);

    this.testLoading$.next(true);
    this.myAccountService
      .testStorage(convertedPayload)
      .pipe(
        map((result) => result.value === StorageStateEnum.ok),
        finalize(() => this.testLoading$.next(false)),
        untilDestroyed(this)
      )
      .subscribe({
        next: (state) => {
          this.verified$.next(state);
          const storage = this.formGroup.get('name').value;

          if (state) {
            this.toastService.success(
              this.i18nPipe.transform(this.moduleAccount + ':toast.body.test.success', { format: 'capitalize', storage }),
              this.#toastTitleSuccess
            );
          } else {
            this.toastService.warn(
              this.i18nPipe.transform(this.moduleAccount + ':toast.body.test.warning', { format: 'capitalize', storage }),
              this.#toastTitleWarning
            );
          }
        },
        error: () => {
          this.toastService.error(
            this.i18nPipe.transform(this.moduleAccount + ':toast.body.test.error', { format: 'capitalize' }),
            this.#toastTitleError
          );
        }
      });
  }

  handleToggleDisabledSecretKey(): void {
    this.disabledSecretKey$.next(!this.disabledSecretKey$.value);

    if (!this.disabledSecretKey$.value && this.secretKeyControl.value === SECRET_KEY_MASK) {
      this.secretKeyControl.setValue('');
    }

    if (this.disabledSecretKey$.value && this.secretKeyControl.value === '') {
      this.secretKeyControl.setValue(SECRET_KEY_MASK);
    }
  }
}
