import { Pipe, PipeTransform } from '@angular/core';
import { TextColor } from '@common';
import { StatusFilterBackup } from '@common/enums/users/status-filter-backup.enum';

@Pipe({
  name: 'filterServiceBackupColor',
  pure: true
})
export class FilterServiceBackupColorPipe implements PipeTransform {
  transform(status: StatusFilterBackup): TextColor.success | TextColor.danger | TextColor.gray500 {
    switch (status) {
      case StatusFilterBackup.Disabled:
        return TextColor.gray500;
      case StatusFilterBackup.Enabled:
        return TextColor.success;
      case StatusFilterBackup.NotAvailable:
        return TextColor.danger;
      default:
        throw new Error('Caught error manually: unable to process StatusFilterBackup enum, unknown value');
    }
  }
}
