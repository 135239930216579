import { Directive, ElementRef, EventEmitter, NgZone, OnDestroy, OnInit, Output } from '@angular/core';
import { ElementSize } from '../models';

@Directive({
  selector: '[mbsSizeObserver]'
})
export class SizeObserverDirective implements OnInit, OnDestroy {
  @Output() sizeChanged = new EventEmitter<ElementSize>();
  private observer: ResizeObserver;

  constructor(private host: ElementRef, private zone: NgZone) {}

  ngOnInit() {
    this.observer = new ResizeObserver((entries) => {
      this.zone.run(() => {
        this.sizeChanged.emit({
          height: entries[0]?.contentRect?.height ?? 0,
          width: entries[0]?.contentRect?.width ?? 0,
          hasVerticalScroll: entries[0]?.target?.scrollHeight > entries[0]?.target?.clientHeight,
          hasHorizontalScroll: entries[0]?.target?.scrollWidth > entries[0]?.target?.clientWidth
        });
      });
    });

    this.observer.observe(this.host.nativeElement);
  }

  ngOnDestroy() {
    this.observer?.unobserve(this.host.nativeElement);
  }
}
