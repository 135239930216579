import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { EditCreateRetentionPolicyModalComponent } from '@common/components/modals/edit-create-retention-policy-modal/edit-create-retention-policy-modal.component';
import {
  AttachedPolicies,
  AttachedPolicy,
  RetentionPolicyOdata,
  SelectedPolicies,
  ServiceType,
  hasUserAccountAdminRole
} from '@common/models';
import { AuthService, RetentionPolicyService } from '@common/services';
import { getErrorText, getServiceUiInfo, hasErrorResponseText, isHomeUser } from '@common/utils';
import { UntilDestroy } from '@ngneat/until-destroy';
import { I18NextPipe } from 'angular-i18next';
import { I18_NAMESPACE_APPS_UI } from 'i18n';
import { cloneDeep, isNil } from 'lodash';
import { MbsSize, ModalService, ModalSettings, ToastService, naturalSort } from 'mbs-ui-kit';
import { BehaviorSubject, Observable, noop, of } from 'rxjs';
import { filter, finalize, map, shareReplay, tap } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'app-user-retention-policies-control',
  templateUrl: './user-retention-policies-control.component.html'
})
export class UserRetentionPoliciesControlComponent implements OnInit {
  #userIds: string[] = [];
  @Input() public set userIds(ids: string[]) {
    if (ids?.length > 0) {
      this.#userIds = ids;

      this.fetchAttachedPolicies(ids);
      this.showSharedPointOrSharedDrivesSelect$ = ids.length === 1 ? this.isGlobalAdmin(ids) : of(false);
    }
  }

  get userIds(): string[] {
    return this.#userIds;
  }

  readonly #toastTitleSuccess = this.i18nPipe.transform('toast.success.title', { format: 'title' });
  readonly #toastTitleError = this.i18nPipe.transform('toast.error.title', { format: 'title' });

  public policiesLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public attachedPoliciesLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  public readonly MbsSize = MbsSize;
  public readonly modulePolicy = I18_NAMESPACE_APPS_UI.policy;
  public readonly moduleUsers = I18_NAMESPACE_APPS_UI.users;
  public isHomeUser$: Observable<boolean>;
  public policies$: Observable<RetentionPolicyOdata[]>;
  public attachedPolicies$: Observable<AttachedPolicies> = of({
    EmailPolicies: [],
    DrivePolicies: [],
    ContactPolicies: [],
    CalendarPolicies: [],
    SitePolicies: [],
    TeamDrivePolicies: [],
    TeamsPolicies: []
  });
  public showSharedPointOrSharedDrivesSelect$: Observable<boolean>;
  public driveTitle: string;
  public retentionListShown: boolean;
  // used for unique id
  public prefix = Math.random().toString(36).substring(2) + '_';
  public selectedPolicies: SelectedPolicies;

  private initPolicies: SelectedPolicies;

  constructor(
    private i18nPipe: I18NextPipe,
    private retentionPolicyService: RetentionPolicyService,
    private modalService: ModalService,
    private toastService: ToastService,
    private authService: AuthService
  ) {
    this.isHomeUser$ = authService.getAuthUser().pipe(
      filter(Boolean),
      map((user) => isHomeUser(user)),
      shareReplay(1)
    );
  }

  get policiesChanged(): boolean {
    if (!this.selectedPolicies || !this.initPolicies) return false;

    return (
      this.selectedPolicies.emailPolicyId !== this.initPolicies.emailPolicyId ||
      this.selectedPolicies.drivePolicyId !== this.initPolicies.drivePolicyId ||
      this.selectedPolicies.contactPolicyId !== this.initPolicies.contactPolicyId ||
      this.selectedPolicies.calendarPolicyId !== this.initPolicies.calendarPolicyId ||
      this.selectedPolicies.sitePolicyId !== this.initPolicies.sitePolicyId ||
      this.selectedPolicies.teamDriveId !== this.initPolicies.teamDriveId ||
      this.selectedPolicies.teamsPolicyId !== this.initPolicies.teamsPolicyId
    );
  }

  get filePolicyId(): string {
    if (this.isGoogle) {
      return this.selectedPolicies.teamDriveId;
    }
    if (this.isOffice) {
      return this.selectedPolicies.sitePolicyId;
    }
    return '';
  }

  set filePolicyId(id: string) {
    if (this.isGoogle) {
      this.selectedPolicies.teamDriveId = id;
    }
    if (this.isOffice) {
      this.selectedPolicies.sitePolicyId = id;
    }
  }

  get isOffice(): boolean {
    return this.authService?.isOffice;
  }

  get isGoogle(): boolean {
    return this.authService?.isGoogle;
  }

  ngOnInit(): void {
    this.fetchPolicies();

    this.selectedPolicies = {
      emailPolicyId: null,
      drivePolicyId: null,
      contactPolicyId: null,
      calendarPolicyId: null,
      sitePolicyId: null,
      teamDriveId: null,
      teamsPolicyId: null
    };

    this.driveTitle = this.getDriveTitle();
  }

  fetchPolicies(): void {
    this.policiesLoading$.next(true);
    this.policies$ = this.retentionPolicyService.getPolicies().pipe(
      map((res) => res?.data.sort((a, b) => naturalSort(a.Name, b.Name)) || []),
      finalize(() => {
        this.policiesLoading$.next(false);
      }),
      shareReplay(1)
    );
  }

  fetchAttachedPolicies(ids: string[]): void {
    this.attachedPoliciesLoading$.next(true);
    this.attachedPolicies$ = this.retentionPolicyService.getAttachedPolicies(ids).pipe(
      tap((policies) => {
        this.selectedPolicies.emailPolicyId = this.getSelectedPolicyId(policies.EmailPolicies);
        this.selectedPolicies.drivePolicyId = this.getSelectedPolicyId(policies.DrivePolicies);
        this.selectedPolicies.contactPolicyId = this.getSelectedPolicyId(policies.ContactPolicies);
        this.selectedPolicies.calendarPolicyId = this.getSelectedPolicyId(policies.CalendarPolicies);
        this.selectedPolicies.sitePolicyId = this.getSelectedPolicyId(policies.SitePolicies);
        this.selectedPolicies.teamDriveId = this.getSelectedPolicyId(policies.TeamDrivePolicies);
        this.selectedPolicies.teamsPolicyId = this.getSelectedPolicyId(policies.TeamsPolicies);

        this.initPolicies = cloneDeep(this.selectedPolicies) as SelectedPolicies;
      }),
      finalize(() => this.attachedPoliciesLoading$.next(false)),
      shareReplay(1)
    );
  }

  private getSelectedPolicyId = (policies: AttachedPolicy[]): string => {
    if (!policies) return null;

    const selected = policies.find((p: AttachedPolicy) => p.Selected);

    return selected?.Value || null;
  };

  toggleRetentionListShown(): void {
    this.retentionListShown = !this.retentionListShown;
  }

  handleShowEditCreateRetention(policy?: RetentionPolicyOdata): void {
    this.modalService
      .openCustom(EditCreateRetentionPolicyModalComponent, this.getPopupPolicySettings(policy))
      .then((saveResult) => {
        if (!saveResult) return;

        this.handleFetchPolicies();
      })
      .catch(() => noop);
  }

  private handleFetchPolicies(): void {
    this.fetchPolicies();
    this.fetchAttachedPolicies(this.userIds);
  }

  handleDeleteRetentionPolicy(policy: RetentionPolicyOdata): void {
    const bodyDeleted = this.i18nPipe.transform(this.modulePolicy + ':toast.body.delete', { format: 'capitalize' });
    const bodyError = this.i18nPipe.transform(this.modulePolicy + ':toast.error.delete', { format: 'capitalize' });

    this.modalService
      .open(
        {
          header: { title: this.i18nPipe.transform(this.modulePolicy + ':modal.title.delete', { format: 'title' }) },
          footer: {
            okButton: { text: this.i18nPipe.transform(this.modulePolicy + ':modal.button.delete', { format: 'title' }), type: 'danger' }
          }
        },
        `${this.i18nPipe.transform(this.modulePolicy + ':modal.body.delete')} <b>${policy.Name}</b>?`
      )
      .then((isDeletedPolicy) => {
        if (isDeletedPolicy) {
          this.retentionPolicyService.deletePolicy(policy.Id).subscribe({
            next: () => {
              this.handleFetchPolicies();
              this.toastService.success(bodyDeleted, this.#toastTitleSuccess);
            },
            error: (res: HttpErrorResponse) =>
              hasErrorResponseText(res) && this.toastService.error(getErrorText(res, bodyError), this.#toastTitleError)
          });
        }
      })
      .catch(() => noop);
  }

  getPopupPolicySettings(data?: RetentionPolicyOdata): ModalSettings {
    const settings: ModalSettings = {
      responsive: true
    };

    if (data) {
      settings.data = data;
    }

    return settings;
  }

  getDriveItems(policies: AttachedPolicies): AttachedPolicy[] {
    switch (true) {
      case this.isGoogle:
        return policies.TeamDrivePolicies;
      case this.isOffice:
        return policies.SitePolicies;
      default:
        return null;
    }
  }

  getDriveTitle(): string {
    switch (true) {
      case this.isGoogle:
        return this.i18nPipe.transform(this.modulePolicy + ':sidepanel.label.sharedDrives', { format: 'title' });
      case this.isOffice:
        return this.i18nPipe.transform(this.modulePolicy + ':sidepanel.label.sharePoint', { format: 'title' });
      default:
        return '';
    }
  }

  policyIconCssClass(policyTypeName: string): string {
    const serviceType = ServiceType[policyTypeName] as ServiceType;

    return getServiceUiInfo(serviceType).iconCssClass;
  }

  private isGlobalAdmin(ids: string[]): Observable<boolean> {
    return (this.showSharedPointOrSharedDrivesSelect$ = this.authService.getRoles().pipe(
      map((roles) => {
        const isGlobalAdmin: boolean = ids[0] === this.authService.id;
        const isUserAccountAdmin: boolean = hasUserAccountAdminRole(roles);

        return isGlobalAdmin && !isUserAccountAdmin;
      })
    ));
  }

  showClearButton(policies: AttachedPolicy[]): boolean {
    return policies.every((police) => !isNil(police.Value));
  }
}
