import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { APPEND_BUTTON_PASSWORD_STATE } from '@common';
import { getAppendButtonsIcon, getAppendButtonsState, hasAppendButtonsPasswordType, isConfirmPasswordValidator } from '@common/utils';
import { UntilDestroy } from '@ngneat/until-destroy';
import { I18_NAMESPACE_APPS_UI } from 'i18n';
import { camelCase } from 'lodash';
import { InputButton, MbsPopupType, ModalComponent } from 'mbs-ui-kit';
import { BehaviorSubject } from 'rxjs';

@UntilDestroy()
@Component({
  templateUrl: './allowed-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AllowedModalComponent implements OnInit {
  @ViewChild(ModalComponent, { static: true }) baseModal: ModalComponent;

  public loading$: BehaviorSubject<boolean> = new BehaviorSubject(true);

  public passwordType$: BehaviorSubject<string> = new BehaviorSubject(APPEND_BUTTON_PASSWORD_STATE.hidden.type);
  public confirmPasswordType$: BehaviorSubject<string> = new BehaviorSubject(APPEND_BUTTON_PASSWORD_STATE.hidden.type);

  public readonly moduleAccount = I18_NAMESPACE_APPS_UI.account;
  public readonly MbsPopupType = MbsPopupType;
  public readonly getAppendButtonsIcon = getAppendButtonsIcon;

  public formGroup: FormGroup;

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.initForm();
    this.loading$.next(false);
  }

  private initForm(): void {
    this.formGroup = this.fb.group({
      password: ['', [Validators.required, Validators.minLength(7), Validators.maxLength(20)]],
      confirmPassword: ['', Validators.required]
    });
    this.formGroup.setValidators(isConfirmPasswordValidator.bind({}, { password: 'password', confirmPassword: 'confirmPassword' }));
  }

  handleApply(): void {
    this.baseModal.save(this.formGroup.value);
  }

  handleClose(): void {
    this.baseModal.close();
  }

  handleChangePasswordType(event: InputButton): void {
    if (!event) return;

    const id = camelCase(event.id.replace('append', ''));
    const subjectById: BehaviorSubject<string> = this[id + 'Type$'];
    const isPasswordType: boolean = hasAppendButtonsPasswordType(subjectById.getValue());

    subjectById.next(APPEND_BUTTON_PASSWORD_STATE[getAppendButtonsState(isPasswordType)].type);
  }
}
