import { HttpErrorResponse } from '@angular/common/http';
import { ODataError, ODataErrorExtended } from '@common';

export function getErrorText(error: HttpErrorResponse, defaultText: string = null): string {
  const odataError: ODataError | ODataErrorExtended = error?.error;

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return odataError?.value || odataError?.error?.message || defaultText;
}
