export const I18_NAMESPACES_MBS_UI_KIT = ['mbs-ui-kit'];

export const I18_NAMESPACES_MBS_UI_SHARED = ['shared-tfa'];

export const I18_NAMESPACES_MBS_UI = [
  'app',
  'access',
  'validation',
  'wizards',
  'overdue',
  'immutability',
  'error',
  'agent-options',
  'feedback',
  'sidepanel-online-access',
  'sidepanel-downloads',
  'download-connect.module',
  'sidepanel-preinstall-settings',
  'builds',
  'bandwidth-settings',
  'licenses',
  'rmm.module',
  'quick-restore-modal',
  'download-connect.module',
  'computers.module',
  'sidepanel-backup',
  'unable-emails-modal',
  'maintenance',
  'alerts'
];

export const I18_NAMESPACES_ONLINE_ACCESS = ['app', 'computers.module', 'sidepanel-backup'];

export enum I18_NAMESPACE_APPS_UI {
  account = 'my-account.module',
  app = 'app',
  audit = 'audit.module',
  backupHistory = 'backup-history.module',
  button = 'button',
  calendar = 'calendar.module',
  chart = 'chart.module',
  contacts = 'contacts.module',
  dashboard = 'dashboard.module',
  dontHavePermission = 'dont-have-permission.module',
  drive = 'drive.module', // base module for Drive, SharePoint, Shared Drives, Teams pages
  history = 'history.module',
  mail = 'mail.module',
  payments = 'payments.module',
  policy = 'retention-policy.module',
  reports = 'reports.module',
  results = 'export-results.module',
  skipItems = 'skipped-items-history.module',
  tasks = 'task-manager.module',
  users = 'users.module'
}

export const I18_NAMESPACES_APPS_UI = [
  I18_NAMESPACE_APPS_UI.app,
  I18_NAMESPACE_APPS_UI.account,
  I18_NAMESPACE_APPS_UI.button,
  I18_NAMESPACE_APPS_UI.dontHavePermission,
  I18_NAMESPACE_APPS_UI.policy
];
