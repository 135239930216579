export enum AuthState {
  AccessDenied = 'AccessDenied', // user not found in db or user is not in backup
  AccessRequest = 'AccessRequest', // ContactInfo page
  AltAccountNotVerified = 'AltAccountNotVerified',
  Approve = 'Approve', // Google domain exists but API->directory.users.get failed
  AuthCodeInvalid = 'AuthCodeInvalid',
  Blocked = 'Blocked', // User or domain locked in db
  DomainNotFound = 'DomainNotFound',
  DomainOrUserIsNotSpecified = 'DomainOrUserIsNotSpecified', // Mbs token has not contains 'customer'
  NoLicensesAvailable = 'NoLicensesAvailable',
  NotGrant = 'NotGrant', // API->directory.users.get failed and Google domain doesn't exists
  OwnerNameIsNotSpecified = 'OwnerNameIsNotSpecified', // Mbs token has not contains 'name'
  OwnerNotIdentified = 'OwnerNotIdentified', // Mbs token has not contains 'owner_id'
  Redirect = 'Redirect',
  ResetPassword = 'ResetPassword',
  RequiredTwoStepAuthCode = 'RequiredTwoStepAuthCode',
  ServiceUnavailable = 'ServiceUnavailable',
  SignInDisabled = 'SignInDisabled', // !user.SignInEnable
  Success = 'Success',
  TokenInvalid = 'TokenInvalid', // Google token is invalid or expired
  VerificationAlternateAccount = 'VerificationAlternateAccount',
  WrongAccount = 'WrongAccount', // WrongGoogleHomeAccount or WrongGoogleBusinessAccount
  WrongTwoStepAuthCode = 'WrongTwoStepAuthCode',
  Unknown = 'Unknown',
  UserAccountDisabled = 'UserAccountDisabled' // users not included in Backup
}

export enum OtherState {
  UnsupportedBrowser = 'UnsupportedBrowser'
}
