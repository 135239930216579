import { registerLocaleData } from '@angular/common';
import localeEn from '@angular/common/locales/en';
import { APP_INITIALIZER, LOCALE_ID } from '@angular/core';
import { FOMAT_TOKEN, formatTitle } from './i18-format-title';
import { I18NEXT_SERVICE, ITranslationService, defaultInterpolationFormat } from 'angular-i18next';
import Backend from 'i18next-http-backend';
import moment from 'moment';
import { Observable, Subject } from 'rxjs';
import { startWith } from 'rxjs/operators';

const LOCALE_DATA = {
  en: localeEn
};

/**
 * Detect lang be html lang="" attr async
 * @return {Observable<string>}
 */
export function documentLangObserver(): Observable<string> {
  const subject: Subject<string> = new Subject();
  const mutationObserver = new MutationObserver((mutationsList, _observer) => {
    for (const mutation of mutationsList) {
      if (mutation.type === 'attributes' && mutation.attributeName === 'lang') {
        subject.next((mutation.target as HTMLElement).getAttribute('lang'));
      }
    }
  });

  mutationObserver.observe(document.documentElement, { attributes: true });
  return subject.pipe(startWith(document.documentElement.getAttribute('lang')));
}
/**
 * Lang detect ngApp integration
 */
const LANGUAGE_DETECTOR = {
  type: 'languageDetector',
  async: true,
  init: function (services, detectorOptions, i18nextOptions) {
    /* use services and options */
  },
  detect: function (callback) {
    documentLangObserver().subscribe((lang) => {
      const data = LOCALE_DATA[lang] || LOCALE_DATA.en;
      registerLocaleData(data, lang);
      callback(lang);
    });
  },
  cacheUserLanguage: function (lng) {
    /* cache language */
  }
};

function getI18nextOptions(path:string, namespaces?: Array<string>, defaultNS?: string) {
  return {
    whitelist: ['en'],
    fallbackLng: 'en',
    debug: false,
    returnEmptyString: true,
    defaultNS,
    interpolation: {
      format: (value, format, lng) => {
        if (format === FOMAT_TOKEN) return formatTitle(value, lng);
        if (value instanceof Date) return moment(value).format(format);
        return defaultInterpolationFormat(value, format, lng);
      }
    },
    backend: {
      loadPath: path,
      addPath: path
    },
    ns: namespaces
  };
}

function i18NextInit(i18next: ITranslationService, path: string, namespaces: Array<string>, defaultNS?: string) {
  return () => {
    return i18next.use<any>(Backend).use<any>(LANGUAGE_DETECTOR).init(getI18nextOptions(path, namespaces, defaultNS));
  };
}

/*
 * get i18next instance ID
 */
export function localeIdFactory(i18next: ITranslationService) {
  return i18next.language;
}

export function I18N_PROVIDERS(path: string, namespaces?: Array<string>, defaultNS = 'app') {
  return [
    {
      provide: APP_INITIALIZER,
      useFactory: (i18next: ITranslationService)=>{
        return i18NextInit(i18next, path, namespaces, defaultNS)
      },
      deps: [I18NEXT_SERVICE],
      multi: true
    },
    {
      provide: LOCALE_ID,
      deps: [I18NEXT_SERVICE],
      useFactory: localeIdFactory
    }
  ];
}
