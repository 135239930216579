import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { RoutePath } from '@common';
import { ExportToPST, ExportToPSTServices } from '@common/models';
import { AuthService, ExportToPSTService } from '@common/services';
import { getErrorText, hasErrorResponseText } from '@common/utils';
import { I18NextPipe } from 'angular-i18next';
import { I18_NAMESPACE_APPS_UI } from 'i18n';
import { ModalComponent, ToastService } from 'mbs-ui-kit';
import { BehaviorSubject, EMPTY, throwError } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';

@Component({
  templateUrl: './export-to-pst-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExportToPstModalComponent implements OnInit {
  @ViewChild(ModalComponent, { static: true }) baseModal: ModalComponent;
  @ViewChild('exportJobTransferredTemplateRef', { static: false, read: TemplateRef }) exportJobTransferredRef: TemplateRef<any>;

  readonly #toastTitleSuccess = this.i18nPipe.transform('toast.success.title', { format: 'title' });
  readonly #toastTitleError = this.i18nPipe.transform('toast.error.title', { format: 'title' });

  public readonly exportLoading$ = new BehaviorSubject<boolean>(false);
  public readonly loading$ = new BehaviorSubject<boolean>(true);
  public readonly disabled$ = new BehaviorSubject<boolean>(true);

  public readonly RoutePath = RoutePath;
  public readonly moduleAccount = I18_NAMESPACE_APPS_UI.account;

  private payloadForExportToPST: ExportToPST;

  get userId(): string {
    return this.authService.id;
  }

  get prefix(): string {
    return this.authService?.prefix;
  }

  constructor(
    private authService: AuthService,
    private exportToPSTService: ExportToPSTService,
    private toastService: ToastService,
    private i18nPipe: I18NextPipe
  ) {}

  ngOnInit(): void {
    this.loading$.next(false);
  }

  invalidForms(state: boolean): void {
    this.disabled$.next(!state);
  }

  handleUpdatePayloadForExportToPST(services: ExportToPSTServices[]): void {
    this.payloadForExportToPST = {
      UserIds: [this.userId],
      Services: services
    };
  }

  handleExport(): void {
    this.exportLoading$.next(true);
    this.exportToPSTService
      .exportUserData(this.payloadForExportToPST)
      .pipe(
        map(() => true),
        catchError((err: HttpErrorResponse) => (err.status !== 403 ? throwError(() => err) : EMPTY)),
        finalize(() => this.exportLoading$.next(false))
      )
      .subscribe({
        next: () => {
          this.toastService.success(this.exportJobTransferredRef, this.#toastTitleSuccess);
          this.baseModal.save(true);
        },
        error: (res: HttpErrorResponse) => hasErrorResponseText(res) && this.toastService.error(getErrorText(res), this.#toastTitleError)
      });
  }

  handleClose(): void {
    this.baseModal.close();
  }
}
