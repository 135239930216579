import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RoutePath } from '@common';
import { partialRoutingDataByTitle } from '@common/utils';
import { UsersComponent } from '@pages/users/component/users.component';
import { UserGroupsComponent } from '@pages/users/user-groups/user-groups.component';

const routes: Routes = [
  {
    path: '',
    component: UsersComponent
  },
  {
    path: RoutePath.UserGroups,
    component: UserGroupsComponent,
    data: {
      ...partialRoutingDataByTitle('Users Group')
    }
  }
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UserRoutingModule {}
