<mbs-modal [title]="title">

  <ng-container modal-body>

    <ng-container>

      <mbs-alert *ngIf="isImmutability"
                 [type]="MbsPopupType.warning"
                 [icon]="true">
        {{
          policy
            ? ('retentionPolicy.alert.update' | i18next: { format: 'capitalize' })
            : ('retentionPolicy.alert.create' | i18next: { format: 'capitalize' })
        }}
      </mbs-alert>

    </ng-container>

    <form class="mbs-form"
          novalidate
          [formGroup]="retentionPolicyForm">

      <mbs-form-section>

        <mbs-form-group>
          <mbs-input [label]="'retentionPolicy.label.name' | i18next: { format: 'title' }"
                     [boldLabel]="true"
                     name="name"
                     formControlName="Name"></mbs-input>
        </mbs-form-group>

        <mbs-form-group class="mb-0">
          <mbs-select [label]="'retentionPolicy.label.forService' | i18next: { format: 'title' }"
                      [items]="availableRetentionPolicies"
                      [boldLabel]="true"
                      bindLabel="label"
                      bindValue="value"
                      [clearable]="false"
                      [searchable]="false"
                      [disabled]="!!policy"
                      formControlName="ServiceType"></mbs-select>
        </mbs-form-group>

      </mbs-form-section>

      <mbs-form-section>

        <mbs-form-group *ngIf="retentionPolicyForm.controls.ServiceType.value === ServiceTypeStr.Mail
                            || retentionPolicyForm.controls.ServiceType.value === ServiceTypeStr.Teams"
                        class="mb-0">

          <label class="mbs-form_label font-weight-bold"
                 for="label-for-KeepCntUnits">
            {{ 'retentionPolicy.label.deleteOlderThan' | i18next: { format: 'title' } }}
          </label>

          <ng-container [ngTemplateOutlet]="deleteOlderTemplate"
                        [ngTemplateOutletContext]="{ $implicit: retentionPolicyForm.controls }"></ng-container>

        </mbs-form-group>

        <mbs-form-group *ngIf="retentionPolicyForm.controls.ServiceType.value !== ServiceTypeStr.Mail
                            && retentionPolicyForm.controls.ServiceType.value !== ServiceTypeStr.Teams">

          <mbs-radio [label]="'retentionPolicy.label.deleteOlderThan' | i18next: { format: 'title' }"
                     [value]="true"
                     name="EnableKeepCntUnits"
                     formControlName="EnableKeepCntUnits"
                     [descriptionOutside]="false"
                     (change)="toggleEnableKeepRevisions()">

            <ng-template radioDescription>
              <ng-container [ngTemplateOutlet]="deleteOlderTemplate"
                            [ngTemplateOutletContext]="{ $implicit: retentionPolicyForm.controls }"></ng-container>
            </ng-template>

          </mbs-radio>

        </mbs-form-group>

        <mbs-form-group class="mb-0"
                        *ngIf="!retentionPolicyForm.controls.ServiceType.errors
                             && retentionPolicyForm.controls.ServiceType.value !== ServiceTypeStr.Mail
                             && retentionPolicyForm.controls.ServiceType.value !== ServiceTypeStr.Teams">

          <mbs-radio [label]="'retentionPolicy.label.revisionsToKeep' | i18next: { format: 'title' }"
                     name="EnableKeepCntUnits"
                     formControlName="EnableKeepCntUnits"
                     [descriptionOutside]="false"
                     [value]="false"
                     (change)="toggleEnableKeepRevisions()">

            <ng-template radioDescription>
              <div class="row">
                <div class="col-3">
                  <mbs-number min="1"
                              max="32767"
                              formControlName="KeepRevisionsCnt"
                              [isErrorsShow]="false"
                              #keepRevisionsCntRef></mbs-number>
                </div>
                <div *ngIf="retentionPolicyForm.controls?.['KeepRevisionsCnt'].errors as errors"
                     class="col-12">
                  <mbs-input-errors [errors]="keepRevisionsCntRef?.mapErrors(errors)"></mbs-input-errors>
                </div>
              </div>
            </ng-template>

          </mbs-radio>

        </mbs-form-group>

      </mbs-form-section>

      <mbs-form-section>

        <mbs-form-group class="mb-0">

          <div class="row align-items-end">

            <div class="col-3">
              <mbs-number [id]="'purgeDelayInput'"
                          [label]="'retentionPolicy.label.purgeDelay' | i18next: { format: 'title' }"
                          [boldLabel]="true"
                          min="0"
                          formControlName="DelayPurgeCntUnits"></mbs-number>
            </div>

            <div class="col-3">
              <mbs-select formControlName="DelayPurgeUnit"
                          dropdownPosition="top"
                          [items]="timePeriods"
                          [clearable]="false"
                          [searchable]="false"></mbs-select>
            </div>

          </div>

        </mbs-form-group>

      </mbs-form-section>

      <mbs-form-section>

        <mbs-form-group class="mb-0">
          <div class="d-flex align-items-center">
            <mbs-checkbox [label]="'retentionPolicy.label.legalHold' | i18next: { format: 'title' }"
                          [labelPosition]="'right'"
                          [id]="'legalHoldId'"
                          formControlName="HasLegalHold"></mbs-checkbox>

            <span class="ico ico-InfoCircle cursor-help text-primary text-base ml-2"
                  container="body"
                  placement="top-left auto"
                  tooltipClass="tooltip-lg tooltip-shift"
                  [ngbTooltip]="'retentionPolicy.label.descriptionLegalHold' | i18next: { format: 'capitalize' }"></span>
          </div>
        </mbs-form-group>

      </mbs-form-section>

    </form>

  </ng-container>

  <ng-container modalFooter>
    <mbs-button type="primary"
                (click)="handleSave()"
                [loading]="loading$ | async"
                [disabled]="okButtonDisabled">
      {{ okButtonText }}
    </mbs-button>

    <mbs-button (click)="handleClose()">
      {{ cancelButtonText }}
    </mbs-button>
  </ng-container>

</mbs-modal>

<ng-template #deleteOlderTemplate
             let-controls>

  <div class="row">

    <div class="col-3">
      <mbs-number min="1"
                  max="32767"
                  [id]="'label-for-KeepCntUnits'"
                  [formControl]="controls['KeepCntUnits']"></mbs-number>
    </div>

    <div class="col-3">
      <mbs-select [formControl]="controls['KeepUnit']"
                  [items]="timePeriods"
                  [clearable]="false"
                  [searchable]="false"></mbs-select>
    </div>

    <div class="col">

      <div class="form-row">

        <div class="mbs-form_label font-weight-bold col-auto">
          {{ 'retentionPolicy.label.from' | i18next: { format: 'title' } }}:
        </div>

        <div class="col">

          <mbs-radio [label]="'retentionPolicy.label.dateModified' | i18next: { format: 'title' }"
                     [value]="false"
                     name="ByModifyOrBackupDate"
                     [formControl]="controls['ByModifyOrBackupDate']"></mbs-radio>

          <mbs-radio [label]="'retentionPolicy.label.dateOfBackup' | i18next: { format: 'title' }"
                     [value]="true"
                     name="ByModifyOrBackupDate"
                     [formControl]="controls['ByModifyOrBackupDate']"></mbs-radio>

        </div>

      </div>

    </div>

    <div class="col-12"
         *ngIf="!controls.ServiceType.errors
              && controls.ServiceType.value !== ServiceTypeStr.Mail
              && controls.ServiceType.value !== ServiceTypeStr.Teams">

      <mbs-checkbox [label]="'retentionPolicy.label.alwaysKeepLastRevision' | i18next: { format: 'title' }"
                    name="AlwaysKeepLastRevision"
                    [formControl]="controls['AlwaysKeepLastRevision']"></mbs-checkbox>

    </div>

  </div>

</ng-template>
