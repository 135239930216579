import { Component, ElementRef, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { isTemplate } from '../../utils';
import { noDataMessageDefault } from '../../utils/text-constants';
import { TableHeader } from '../models/table-header';

enum MultipleSelectType {
  Mouse = 'mouse',
  Keyboard = 'keyboard'
}

interface Card {
  [key: string]: any;
  event: MouseEvent | KeyboardEvent;
}

@Component({
  selector: 'app-grid, mbs-grid',
  templateUrl: './grid.component.html'
})
export class GridComponent implements OnInit {
  @Input() data: any[];
  @Input() loading = false;
  @Input() loaderType = 'light';
  @Input() noDataMessage: string | TemplateRef<any>;
  gridHeaders: TableHeader[];
  @Input()
  set headers(value: TableHeader[]) {
    this.gridHeaders = value.map((h, index) => Object.assign({ id: index }, h)).filter((h) => h.isGridColumn !== false);
  }
  @Input() headerTemplate: ElementRef;
  @Input() templates: ElementRef[];
  @Input() tileTemplate: TemplateRef<any>;
  @Input() isNeedSelectCard: boolean;
  @Input() multipleSelect = false;
  @Input() selectCardClass = '-selected';
  @Input() multipleSelectType = MultipleSelectType.Mouse;
  @Input() bindSelected = 'id';
  @Input() bindSelectedChildren = 'id';
  @Input() selectedCards: Card[] = [];
  @Input() maxHeight: string;
  @Input() minHeight: string;

  /**
   *  function used in every rows "for in".
   */
  @Input() myTrackBy: <T>(index: number, item: T) => unknown;

  @Output() changeSelectedCards: EventEmitter<any> = new EventEmitter();
  @Output() clickTableElement: EventEmitter<any> = new EventEmitter();

  public selectedCardIndex: number;

  private shiftSelectedIndex = -1;
  public noDataMessageDefault = noDataMessageDefault;

  constructor() {
    // empty
  }

  ngOnInit(): void {
    // empty
  }

  noDataIsTemplate(): boolean {
    return isTemplate(this.noDataMessage);
  }

  cardClickHandler(card: Card, cardIndex: number): void {
    this.clickTableElement.emit(card);
    if (this.isNeedSelectCard) {
      if (this.multipleSelectType == MultipleSelectType.Mouse) {
        this.updateSelectedCards(card, cardIndex);
        return;
      }
      if (this.multipleSelectType == MultipleSelectType.Keyboard) {
        const isMac = navigator.platform.startsWith('Mac');
        const ctrlOrCmd = card.event.ctrlKey || (isMac && card.event.metaKey);
        if (ctrlOrCmd) {
          this.updateSelectedCards(card, cardIndex);
        } else if (card.event.shiftKey) {
          if (this.selectedCards.length == 0) {
            this.updateSelectedCards(card, cardIndex);
          } else {
            const start = Math.min(this.shiftSelectedIndex, cardIndex);
            const end = Math.max(this.shiftSelectedIndex, cardIndex) + 1;
            this.updateSelectedCards(card, start, end);
          }
        } else {
          this.selectedCards = [];
          this.updateSelectedCards(card, cardIndex);
        }
      }
      // this.updateSelectedCards(card, cardIndex);
    } else {
      this.changeSelectedCards.emit(card);
    }
    this.shiftSelectedIndex = cardIndex;
  }

  updateSelectedCards(card: Card, cardIndex: number, lastRowIndex = 0): void {
    if (this.multipleSelect) {
      if (lastRowIndex > 0) this.selectedCards = this.data.slice(cardIndex, lastRowIndex) as Card[];
      else {
        const unselectIndex = this.selectedCards.findIndex((i) => i[this.bindSelected] === card[this.bindSelected]);
        if (unselectIndex !== -1) {
          this.selectedCards.splice(unselectIndex, 1);
        } else {
          this.selectedCards.push(card);
        }
      }
      this.changeSelectedCards.emit(this.selectedCards);
    } else {
      const unselect = this.selectedCardIndex === cardIndex;
      this.selectedCardIndex = unselect ? null : cardIndex;
      this.changeSelectedCards.emit(!unselect ? card : null);
    }
  }

  checkCardInSelected(card: Card) {
    return this.selectedCards.find((i) => i[this.bindSelected] === card[this.bindSelected]);
  }
}
