import { CustomTreeElement, DriveTreeElement, SiteItemTreeElement, TeamTreeElement } from '@common';
import { DriveOdataItem, SiteItemODataModel, TeamItem } from '@common/models';

export function createTreeFromDrives(items: DriveOdataItem[]): DriveTreeElement[] {
  return items.map((item) => ({
    id: item.Id,
    label: item.Name,
    value: item,
    checked: true
  }));
}

export function createTreeFromSiteItem(items: SiteItemODataModel[]): SiteItemTreeElement[] {
  return items.map((item) => ({
    id: item.Id,
    label: item.Name,
    value: item,
    checked: item.BackupOn
  }));
}

export function createTreeFromTeamItem(items: TeamItem[]): TeamTreeElement[] {
  return items.map((item) => ({
    id: item.Id,
    label: item.Name,
    value: item
  }));
}

export function getTreeElementById(tree: CustomTreeElement<any>[], id: string): CustomTreeElement<any> | undefined {
  for (const item of tree) {
    const temp = getTreeElementInChildren(item, id);

    if (temp != undefined) {
      return temp;
    }
  }

  return void 0;
}

function getTreeElementInChildren(tree: CustomTreeElement<any>, id: string): CustomTreeElement<any> | null {
  if (tree.id === id) {
    return tree;
  }

  if (tree.children) {
    for (const item of tree.children) {
      if (item.id === id) {
        return item;
      } else {
        const temp = getTreeElementInChildren(item, id);

        if (temp != undefined) {
          return temp;
        }
      }
    }
  }

  return null;
}

export function getArrayParentsForTreeElement<T = any>(treeElement: CustomTreeElement<T>): CustomTreeElement<T>[] {
  let temp = treeElement;
  const pathArray: CustomTreeElement<T>[] = [];

  while (temp.parent) {
    pathArray.push(temp);
    temp = temp.parent;
  }

  pathArray.push(temp);

  return pathArray;
}

export function getDriveIdOfTreeElement(treeElement: DriveTreeElement): string | null {
  let temp = treeElement;

  while (temp.parent && !temp.value?.DriveId) {
    temp = temp.parent;
  }

  return temp.value?.DriveId || null;
}
