import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { FlatTreeControl } from '@angular/cdk/tree';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { MatTreeFlatDataSource } from '@angular/material/tree';
import { SafeStyle } from '@angular/platform-browser';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { cloneDeep, isNil, unionBy } from 'lodash';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { TreeAppendDirective } from './directives/treeAppend.directive';
import { TreeElementDirective } from './directives/treeElement.directive';
import { TreeLoadMoreDirective } from './directives/treeLoadMore.directive';
import { getIsNodeExpandable } from './helpers/getIsNodeExpandable';
import { getNodeLevel } from './helpers/getNodeLevel';
import { treeFlattener } from './helpers/treeFlattener';
import { FlatTreeElement } from './models/flatTreeElement.model';
import { TreeElement } from './models/treeElement.model';

// Component containing virtual scrolling flat tree
@UntilDestroy()
@Component({
  selector: 'mbs-tree',
  templateUrl: './tree.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  exportAs: 'mbsTree'
})
export class TreeComponent implements OnInit {
  // Tree control to feed to the cdk tree
  readonly treeControl: FlatTreeControl<FlatTreeElement> = new FlatTreeControl<FlatTreeElement>(getNodeLevel, getIsNodeExpandable);
  // Data source fed into the cdk tree control
  readonly dataSource: MatTreeFlatDataSource<TreeElement, FlatTreeElement>;
  private dataChange = new BehaviorSubject<TreeElement[]>([]);

  /**
   * @ignore
   */
  @Input() root: TreeElement = null;

  @Input() classesForRoot = '';

  get rootClasses(): string {
    const result = isNil(this.maxHeight) ? '' : 'overflow-auto';
    return `${result} ${this.classesForRoot}`;
  }

  /**
   * If tree items can be hidden
   */
  @Input() itemsCanBeHidden = false;

  @Input() onlyOneSelect = false;

  /**
   * Padding difference (in px) between 2 nested levels;
   */
  @Input() nestedPadding = 24;

  @Input() clickEmitBeforeLoad = false;
  @Input() selectedItemId = '';

  #nestedData: TreeElement[] = [];
  #expandAll = false;

  @Input() set data(value: TreeElement[]) {
    this.#nestedData = value;
    this.dataSource.data = value;
    if (this.virtualScroll) {
      this.updateVisibleData();
    }
    this.updateHashItemsClasses();
  }

  get data(): TreeElement[] {
    return this.#nestedData;
  }

  get flatData(): FlatTreeElement[] {
    return this.treeControl.dataNodes;
  }

  set flatData(nodeList: FlatTreeElement[]) {
    this.treeControl.dataNodes = nodeList;
    if (this.virtualScroll) {
      this.updateVisibleData();
    }
  }

  public visibleFlatData: FlatTreeElement[] = [];

  public loadingSubtree = false;

  @Input() disabledTree = false;
  @Input() disableIfLoadingSubtree = false;

  @Input() virtualItemSize = 30;
  @Input() height: SafeStyle;
  @Input() virtualScroll = false;
  @Input() virtualItemsNumber = 10;

  /**
   * Works only for Tree without virtual scroll
   */
  @Input() maxHeight: SafeStyle;

  /**
   * Works only for Tree without virtual scroll
   */
  @Input() minHeight: SafeStyle;

  @Input() hideTreeIcon = false;
  @Input() set expandAll(value: boolean) {
    this.flatData.forEach((item) => this[value ? 'expand' : 'collapse'](item.node));

    this.#expandAll = value;
  }
  @Input() lazy = false;
  @Input() loadMore: (item: TreeElement) => Observable<TreeElement[]>;
  @Input() loadMoreNotLazy: (item: TreeElement) => Observable<TreeElement[]>;
  @Input() getItems: (item: TreeElement) => Observable<TreeElement[]>;
  @Input() childrenManualControl = false;

  /**
   * If `false` - Tree will not expand children elements on click on parent.
   *
   *
   * You should use `expand`, `collapse` and `toggle` methods in this case
   */
  @Input() toggleOnClick = true;

  /**
   * If `false` - Tree will not close children elements on click on parent.
   * You should use `expand`, `collapse` and `toggle` methods in this case
   */
  @Input() closeOnClick = true;

  @Input() customDisableText = '';
  @Input() disableChildren = false;

  @Input() itemClasses = ''
  @Input() selectClass = '-selected';
  @Input() selectable = false;
  @Input() hover = false;
  @Input() mode: 'text' | 'checkbox' | 'file' = 'text';

  @Input() set dontSelectChildren(value: boolean) {
    this.recursiveCheck = !value;
  }

  /**
   * @deprecated since 1.2
   * use `recursiveCheck` instead
   */
  get dontSelectChildren(): boolean {
    return !this.recursiveCheck;
  }

  @Input() recursiveCheck = true;
  @Input() offCheckAndIndeterminate = false;

  @Input() findKey = 'id';

  template: TemplateRef<any>;

  @ContentChild(TreeElementDirective, { static: false, read: TreeElementDirective })
  public set elementTemplate(value: TreeElementDirective) {
    if (isNil(this.template) && value) {
      this.template = value.template;
    }
  }

  hashTreeClasses: { [key: string]: { levelClass: string; otherClasses: string; isDisabled: boolean } } = {};

  appendTemplate: TemplateRef<any>;
  @ContentChild(TreeAppendDirective, { static: false, read: TreeAppendDirective })
  public set elementAppendTemplate(value: TreeAppendDirective) {
    if (isNil(this.appendTemplate) && value) {
      this.appendTemplate = value.template;
    }
  }

  loadMoreTemplate: TemplateRef<any>;
  @ContentChild(TreeLoadMoreDirective, { static: false, read: TreeLoadMoreDirective })
  public set elementLoadMoreTemplate(value: TreeLoadMoreDirective) {
    if (isNil(this.loadMoreTemplate) && value) {
      this.loadMoreTemplate = value.template;
    }
  }

  /**
   * Emits on change in any checkbox, only in `checkbox mode`
   */
  @Output() mbsChange: EventEmitter<TreeElement> = new EventEmitter<TreeElement>();

  /**
   * Emits on click on element (not expander arrow), only in `text mode`
   */
  @Output() mbsClick: EventEmitter<{ event; item: TreeElement }> = new EventEmitter<{ event; item: TreeElement }>();

  /**
   * Emits on click on element (not expander arrow), only in `lazy mode` and if clickEmitBeforeLoad = true
   */
  @Output() mbsPreClick: EventEmitter<{ event; item: TreeElement }> = new EventEmitter<{ event; item: TreeElement }>();

  /**
   * Emits only once - after Tree init. Can be used for `checkIndeterminate` and some similar actions;
   */
  @Output() init = new EventEmitter<void>();

  @ViewChild('viewport', { static: false, read: CdkVirtualScrollViewport }) virtualViewport: CdkVirtualScrollViewport;

  @ViewChild('mbsTree', { static: false }) mbsTree: ElementRef<HTMLDivElement>;

  constructor(private cdRef: ChangeDetectorRef) {
    // Populates our flattened data into the tree control
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, treeFlattener);
    this.dataChange.subscribe((data) => {
      this.dataSource.data = data;
    });
  }

  /**
   * @ignore
   */
  updateHashItemsClasses(): void {
    let classes = this.hover ? '-hover' : '';

    if (this.mode === 'checkbox' || this.mode === 'file') {
      classes += ' -checkbox';
    }

    this.flatData.forEach((item) => {
      if (this.hashTreeClasses[item.node.id]) return;

      this.hashTreeClasses[item.node.id] = {
        levelClass: '-level-' + item.level.toString(),
        otherClasses: classes,
        isDisabled: this.isDisabled(item)
      };
    });
  }

  mapParents(root: TreeElement[], parent = this.root): void {
    root.forEach((el) => {
      el.parent = parent;
      el.children && this.mapParents(el.children, el);
    });
  }

  getVirtualScrollViewportSize(): string {
    if (this.height) {
      return this.height.toString();
    }

    return `${this.virtualItemSize * this.virtualItemsNumber}px`;
  }

  virtualTrackBy(index: number, item: FlatTreeElement): string {
    return item.node.id;
  }

  ngOnInit(): void {
    this.mapParents(this.#nestedData);

    if (this.virtualScroll) {
      this.updateVisibleData();
    }

    this.init.emit();
  }

  /**
   * @ignore
   */
  updateVisibleData(): void {
    // TODO: this have to be refactored to not check whole data array on each toggle
    this.visibleFlatData = this.flatData.filter((item) => this.needToRender(item));
  }

  handleClick(event: MouseEvent, item: FlatTreeElement): void {
    if (this.virtualScroll) this.updateVisibleData();

    this.mbsClick.emit({ event, item: item.node });
  }

  getFlatNodeAndSubject(nestedItem: TreeElement): { flatNode: FlatTreeElement; subject: Subject<TreeElement> } {
    this.loadingSubtree = true;

    const subject: Subject<TreeElement> = new Subject();
    const flatNode = this.getFlatByNested(nestedItem);

    if (flatNode?.node) flatNode.node.loadingChildren = true;

    return { flatNode, subject };
  }

  endUpdateTreeElements(nestedItem: TreeElement, flatNode: FlatTreeElement, subject: Subject<TreeElement>, event: MouseEvent): void {
    nestedItem.loadingChildren = false;

    if (flatNode) flatNode.node = nestedItem;

    this.data = cloneDeep(this.data);

    const flatSubtree = this.flatData.filter((flatItem) => flatItem.node.parent && flatItem.node.parent.id === nestedItem.id);
    const root = this.getFlatByNested(nestedItem);

    if (root) this.handleGotSubtree(root, flatSubtree, true, event);

    this.loadingSubtree = false;

    if (root) subject.next(root.node);
  }

  handleClickLoadMore(nestedItem: TreeElement, event: MouseEvent, isNotLazy = false): void {
    event?.stopPropagation();

    const { flatNode, subject } = this.getFlatNodeAndSubject(nestedItem);
    const loadMore = isNotLazy ? this.loadMoreNotLazy : this.loadMore;

    loadMore(nestedItem)
      .pipe(filter(Boolean), untilDestroyed(this))
      .subscribe({
        next: (subtree: TreeElement[]) => {
          subtree.forEach((child) => (child.parent = nestedItem));

          nestedItem.children = unionBy(nestedItem.children, subtree, 'id');
          nestedItem.isLoadMore = true;

          this.endUpdateTreeElements(nestedItem, flatNode, subject, event);
        },
        error: () => {
          this.loadingSubtree = false;
          flatNode.node.loadingChildren = false;
        }
      });
  }

  getSubtree(nestedItem: TreeElement, forceToggle?: boolean, event?: MouseEvent): Observable<TreeElement> {
    const { flatNode, subject } = this.getFlatNodeAndSubject(nestedItem);

    if (this.clickEmitBeforeLoad) this.mbsPreClick.emit({ event, item: nestedItem });

    this.getItems(nestedItem)
      .pipe(filter(Boolean), untilDestroyed(this))
      .subscribe(
        (subtree: TreeElement[]) => {
          subtree.forEach((child) => (child.parent = nestedItem));

          nestedItem.children = subtree;
          nestedItem.gotChildren = true;

          this.endUpdateTreeElements(nestedItem, flatNode, subject, event);
        },
        () => {
          this.loadingSubtree = false;
          flatNode.node.loadingChildren = false;
        }
      );

    return subject.asObservable();
  }

  handleClickItem(flatNode: FlatTreeElement, event: MouseEvent, forceToggle: boolean): void {
    event?.stopPropagation();

    if (!this.lazy || flatNode.node.gotChildren) {
      if ((this.toggleOnClick || forceToggle) && (this.closeOnClick || !flatNode.node.expanded || forceToggle)) {
        this.toggle(flatNode.node);
      }

      this.handleClick(event, flatNode);
    } else {
      this.getSubtree(flatNode.node, forceToggle, event);
    }
  }

  handleGotSubtree(root: FlatTreeElement, subtree: FlatTreeElement[], forceToggle?: boolean, event?: MouseEvent): void {
    if ((this.toggleOnClick || forceToggle) && (this.closeOnClick || !root.node.expanded || forceToggle)) {
      root.node.expanded = true;
    }

    this.handleClick(event, root);

    if (this.mode === 'checkbox' && this.recursiveCheck) {
      this.updateChildren(root.node.checked, root);
    }

    if (this.mode === 'file' && this.recursiveCheck) {
      this.updateFileChildren(root);
    }

    if (this.mode === 'checkbox' || this.mode === 'file') {
      this.bubbleStateToRoot(subtree[0]);
    }

    /**
     * If you have performance issues with "Load more", try ignoring the "this.cdRef.detectChanges()" for your mod.
     * Just check all cases of interaction with the tree for functionality.
     * */
    if (this.mode !== 'text') this.cdRef.detectChanges();
  }

  updateFileChildren(root: FlatTreeElement): void {
    const treeItems = this.treeControl.getDescendants(root);
    const allChecked = root.node?.onlyOneSelect ? treeItems.every((i) => i.node.checked) : false;

    treeItems.forEach((child) => {
      child.node.indeterminate = root.node?.onlyOneSelect ? false : root.node.indeterminate && child.node.indeterminate;
      child.node.checked =
        (root.node.checked || root.node.indeterminate) &&
        (!root.node?.onlyOneSelect ? child.node.checked : allChecked ? child?.node?.id === root.node?.children[0].id : child.node.checked);
    });
  }

  isDisabled(item: FlatTreeElement): boolean {
    if (!this.disableChildren) return false;

    const parent = this.findParent(item);

    return parent && !parent.node.checked;
  }

  updateChildren(event: boolean, node: FlatTreeElement, selectedItem: FlatTreeElement = null): void {
    if (this.recursiveCheck) {
      if (selectedItem) {
        this.treeControl.getDescendants(node).forEach((child) => {
          child.node.indeterminate = false;
          child.node.checked =
            event &&
            (child.node.id === selectedItem.node.id ||
              (selectedItem.node.children && selectedItem.node.children.length
                ? child.node.id === selectedItem.node.children[0].id
                : false));
        });
      } else {
        this.treeControl.getDescendants(node).forEach((child) => {
          child.node.indeterminate = false;
          child.node.checked = event && (!child.node?.parent?.onlyOneSelect || child?.node?.id === child.node?.parent?.children[0].id);
        });
      }
    }
  }

  handleCheck(event: boolean, item: FlatTreeElement): void {
    if (event) {
      if (this.onlyOneSelect) {
        this.uncheckAllElements();
      }

      const parent = this.findParent(item);

      if (parent && parent.node.onlyOneSelect) {
        this.updateChildren(event, parent, item);
      }
    }

    item.node.checked = event;

    if (this.mode !== 'file' || !event || this.recursiveCheck) {
      item.node.indeterminate = false;
    } else {
      this.updateParentNode(item, true);
    }

    if (item.node.onlyOneSelect) {
      this.updateChildren(event, item, item);
    } else {
      this.updateChildren(event, item);
    }

    this.bubbleStateToRoot(item, true);
    this.mbsChange.emit(item.node);
  }

  uncheckAllElements(): void {
    this.flatData.forEach((el) => {
      if (el.node.checked || el.node.indeterminate) {
        el.node.checked = false;
        el.node.indeterminate = false;
        this.updateChildren(false, el);
      }
    });
  }

  private bubbleStateToRoot(node: FlatTreeElement, needCheck = false): void {
    if (isNil(node) || node.level === 0 || (!this.recursiveCheck && this.mode !== 'file')) return;
    const parent = this.findParent(node);
    this.updateParentNode(parent, needCheck);
    if (parent.level !== 0) {
      this.bubbleStateToRoot(parent);
    }
  }

  updateParentNode(parent: FlatTreeElement, needCheck = false) {
    const children = this.getChildren(parent);
    const same = children.every(
      (child: FlatTreeElement) => !child.node.indeterminate && !!child.node.checked === !!children[0].node.checked
    );

    if (this.mode !== 'file') {
      if (same) {
        parent.node.checked = children[0].node.checked;
        parent.node.indeterminate = false;
      } else {
        parent.node.indeterminate = true;
      }
    } else {
      this.updateParentFileNode(parent, children, same, needCheck);
    }
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  updateParentFileNode(parent: FlatTreeElement, children: FlatTreeElement[], same: boolean, needCheck = false) {
    const childrenChecked = children?.length && children[0].node.checked;

    if (parent.node.onlyOneSelect || this.onlyOneSelect) {
      parent.node.checked = !same || (children?.length === 1 && childrenChecked);
    } else if (this.offCheckAndIndeterminate || (needCheck && same && childrenChecked)) {
      parent.node.checked = same && childrenChecked;
    }

    parent.node.indeterminate = same
      ? children.length && (parent.node.checked ? !this.offCheckAndIndeterminate && !children[0].node.checked : children[0].node.checked)
      : !this.offCheckAndIndeterminate || (!parent.node.checked && parent.node.children.some((c) => c.checked || c.indeterminate));
  }

  private getChildren(node: FlatTreeElement): FlatTreeElement[] {
    const currentIndex = this.flatData.findIndex((item) => item.node[this.findKey] === node.node[this.findKey]) + 1;
    const children: FlatTreeElement[] = [];

    for (let i = currentIndex; i < this.flatData.length; i++) {
      if (this.flatData[i].level - node.level === 1) {
        children.push(this.flatData[i]);
      }

      if (this.flatData[i].level <= node.level) {
        break;
      }
    }

    return children;
  }

  private findParent(node: FlatTreeElement): FlatTreeElement {
    let currentIndex = this.flatData.findIndex((item) => item.node[this.findKey] === node.node[this.findKey]) - 1;

    while (currentIndex >= 0) {
      if (this.flatData[currentIndex].level < node.level) {
        return this.flatData[currentIndex];
      }

      currentIndex--;
    }

    return null;
  }

  needToRender(item: FlatTreeElement): boolean {
    if (item.level === 0) {
      return true;
    }

    const parent = this.findParent(item);

    if (isNil(parent?.node?.expanded) && this.#expandAll) {
      return true;
    }

    return parent?.node?.expanded && this.needToRender(parent);
  }

  /**
   * Set item's selected state into given state;
   * @param {boolean} state
   * @param {TreeElement} item
   */
  doSelect(state: boolean, item: TreeElement): void {
    const flatNode = this.flatData.find((flatNode) => item[this.findKey] === flatNode.node[this.findKey]);

    if (flatNode?.node) {
      flatNode.node.selected = state;
    }
  }

  /**
   * Return all currently selected items in one array;
   * @return {TreeElement[]}
   */
  getSelected(): TreeElement[] {
    return this.flatData.filter((item) => item.node.selected).map((item) => item.node);
  }

  /**
   * Find and mark as selected `TreeItem` with `findKey` equals `value`;
   * Then expand all parents of element that was found.
   * @param {any} value
   * @return {TreeElement}
   */
  findAndSelect(value: any): TreeElement {
    const flatNode = this.flatData.find((node) => node.node[this.findKey] === value);

    if (isNil(flatNode)) return null;

    flatNode.node.selected = true;
    let parent = this.findParent(flatNode);

    while (parent) {
      this.expand(parent.node);
      parent = this.findParent(parent);
    }

    if (this.virtualScroll) {
      this.updateVisibleData();
    }

    this.cdRef.detectChanges();

    return flatNode.node;
  }

  goToItem(item: TreeElement): void {
    if (this.virtualScroll) {
      const findIndex = this.visibleFlatData.findIndex((flatNode) => flatNode.node.id === item.id);

      this.virtualViewport.scrollToIndex(findIndex);
    } else {
      const findIndex = this.flatData.findIndex((node) => node.node[this.findKey] === item.id);
      const treeNativeElement = this.mbsTree.nativeElement;

      if (treeNativeElement && findIndex >= 0) {
        const treeNativeItems = treeNativeElement.getElementsByClassName('mbs-tree_item');
        const nativeElement = treeNativeItems[findIndex];

        nativeElement?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
      }
    }
  }

  getItemById(id): TreeElement {
    return this.flatData.find((flatNode) => flatNode.node.id === id)?.node;
  }

  /**
   * Find all Tree nodes according to predicate;
   * @param {Function} predicate
   * @param {TreeElement[]} childrenArray
   * @return {TreeElement[]}
   */
  findAll(predicate: (node: TreeElement) => boolean, childrenArray: TreeElement[] = this.#nestedData): TreeElement[] {
    let found: TreeElement[] = [];

    for (const node of childrenArray) {
      if (predicate(node)) {
        found.push(node);
      }

      if (node.children?.length > 0) {
        found = found.concat(this.findAll(predicate, node.children));
      }
    }

    return found;
  }

  /**
   * Find all Tree nodes according to predicate and select them;
   * @param {Function} predicate
   * @return {TreeElement[]}
   */
  findAndSelectAll(predicate: (item: TreeElement) => boolean): TreeElement[] {
    const found = this.findAll(predicate);

    if (found.length === 0) return null;

    const flatFound = this.flatData.filter((node) => found.some((item) => item[this.findKey] === node.node[this.findKey]));

    flatFound.forEach((item: FlatTreeElement) => {
      item.node.selected = true;
      let parent = this.findParent(item);

      while (parent) {
        parent.node.expanded = true;
        parent = this.findParent(parent);
      }
    });

    if (this.virtualScroll) {
      this.updateVisibleData();
    }

    this.cdRef.detectChanges();

    return found;
  }

  /**
   * Reset selection;
   */
  resetSelection(): void {
    this.flatData.forEach((item) => (item.node.selected = false));
    this.cdRef.detectChanges();
  }

  /**
   * Select all Tree nodes, that currently are in the tree.
   * Nodes that will be lazy requested will not be selected;
   */
  public selectAll(): void {
    this.flatData.forEach((item) => (item.node.selected = true));
    this.cdRef.detectChanges();
  }

  /**
   * Set node's `checked` into given state. If `recursiveCheck` is `true` (or `dontSelectChildren` is `false`) - also checks all children of given node
   * and checks parent node's `indeterminate` status;
   *
   * @param {TreeElement} item
   * @param {boolean} state
   */
  public checkItem(item: TreeElement, state: boolean): void {
    item.checked = state;

    const index = this.flatData.findIndex((node) => node.node[this.findKey] === item[this.findKey]);

    this.flatData[index].node.checked = state;

    if (this.recursiveCheck) {
      for (let i = index + 1; i < this.flatData.length; i++) {
        if (this.flatData[i].level > this.flatData[index].level) {
          this.flatData[i].node.checked = state;
        } else {
          break;
        }
      }

      this.checkParentState(item);
    }

    this.cdRef.detectChanges();
  }

  /**
   * Sets given item's parents into right `indeterminate` and `checked` state; according to children states.
   * @param {TreeElement} node
   */
  public checkParentState(node: TreeElement): void {
    const flatNode = this.flatData.find((item) => item.node[this.findKey] === node[this.findKey]);

    this.bubbleStateToRoot(flatNode);
    this.cdRef.detectChanges();
  }

  /**
   * Same as `checkParentState` but for a whole tree.
   */
  checkIndeterminate(): void {
    const leafArray = this.flatData.filter((item) => {
      return isNil(item.node.children) || item.node.children.length === 0;
    });

    leafArray.forEach((item) => {
      this.bubbleStateToRoot(item);
    });
  }

  getFlatByNested(nestedItem: TreeElement): FlatTreeElement {
    return this.flatData.find((flat) => flat.node[this.findKey] === nestedItem[this.findKey]);
  }

  expand(item: TreeElement): void {
    const flatNode = this.getFlatByNested(item);

    if (!isNil(flatNode)) {
      flatNode.node.expanded = true;

      if (this.virtualScroll) {
        this.updateVisibleData();
      }

      this.cdRef.detectChanges();
    }
  }

  collapse(item: TreeElement): void {
    const flatNode = this.getFlatByNested(item);

    if (!isNil(flatNode)) {
      flatNode.node.expanded = false;

      if (this.virtualScroll) {
        this.updateVisibleData();
      }

      this.cdRef.detectChanges();
    }
  }

  toggle(item: TreeElement): void {
    const flatNode = this.getFlatByNested(item);

    if (!isNil(flatNode)) {
      flatNode.node.expanded = !flatNode.node.expanded;

      if (this.virtualScroll) {
        this.updateVisibleData();
        this.cdRef.detectChanges();
      } else if (item.totalChildren === -1 || item.totalChildren === Number.MAX_SAFE_INTEGER) this.cdRef.markForCheck();
      else this.cdRef.detectChanges();
    }
  }
}
