import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { RoutePath, WINDOW } from '@common';
import { AuthService } from '@common/services';
import { ModalService, ModalSettings } from 'mbs-ui-kit';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

const sessionExpiredTemplate = `
<div class="row flex-nowrap align-items-center">
  <div class="col-auto">
    <div class="modal_content-ico text-primary bg-primary -circle">
      <span class="fa fa-info text-white"></span>
    </div>
  </div>
  <div class="mbs-modal_content-text col-10">
    Your session has expired. Please log in again.
  </div>
</div>`;

@Injectable()
export class SessionInterceptor implements HttpInterceptor {
  private isModalOpen = false;
  private readonly excludePaths = [`/${RoutePath.Login}`, `/${RoutePath.AuthCallback}`, `/${RoutePath.Account}`, `/${RoutePath.NotFound}`];

  constructor(@Inject(WINDOW) private window: Window, private authService: AuthService, private modalService: ModalService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.excludePaths.some((path) => this.window.location.pathname.endsWith(path))) {
      return next.handle(req);
    } else {
      return next.handle(req).pipe(
        catchError((error, caught) => {
          if (!(error instanceof HttpErrorResponse)) return caught;

          const settings: ModalSettings = {
            header: null,
            footer: {
              okButton: { text: 'Login' },
              cancelButton: { show: false }
            }
          };

          if (error?.status === 401 && !this.isModalOpen) {
            settings.header = { title: 'Session Expired' };

            this.showModal(settings, sessionExpiredTemplate);
          }

          return throwError(() => error);
        })
      );
    }
  }

  private showModal(settings: ModalSettings, body: string): void {
    this.isModalOpen = true;

    this.modalService.open(settings, body).finally(() => {
      this.authService.logout();
      this.isModalOpen = false;
    });
  }
}
