import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ActiveRoutePipe } from '@common/pipes/active-route.pipe';
import { BHistoryPanelIdPipe } from '@common/pipes/backup-history';
import { InfoColumnLayoutPipe, MainColumnLayoutPipe, NavColumnLayoutPipe, ResizeColumnLayoutPipe } from '@common/pipes/column-layout';
import { GetDownloadLoadingPipe } from '@common/pipes/get-download-loading.pipe';
import { InvoiceStateToDisplayNamePipe } from '@common/pipes/invoice-state-to-display-name.pipe';
import { InvoiceStatusPipe } from '@common/pipes/invoice-status.pipe';
import { OperationTypeToDisplayNamePipe } from '@common/pipes/operation-type-to-display-name.pipe';
import { PriceToDisplayPipe } from '@common/pipes/price-to-display.pipe';
import { BackupStatusPipe, UserStatusPipe } from '@common/pipes/report-status';
import { ServiceTypeToDisplayNamePipe } from '@common/pipes/service-type-to-display-name.pipe';
import { SmartSearchMaxWidthPipe } from '@common/pipes/smart-search-max-width.pipe';
import { SortOrderByPipe } from '@common/pipes/sort-order-by.pipe';
import { SrcMimeCategorySbPipe } from '@common/pipes/src-mime-category-sb.pipe';
import { SrcMimeCategoryPipe } from '@common/pipes/src-mime-category.pipe';
import { TaskStatusIconPipe, TaskStatusTypePipe } from '@common/pipes/task-status';
import { FolderIconPipe, FoldersLayoutPipe, MimeCategoryIconPipe } from '@common/pipes/teams';
import { TransformTextByPipe } from '@common/pipes/transform-text-by.pipe';
import { UserActionTypeAreaPipe } from '@common/pipes/user-action-type-area.pipe';
import { UserActionTypePipe } from '@common/pipes/user-action-type.pipe';
import { FilterServiceBackupColorPipe, FilterServiceBackupTextPipe, StatusFilterBackupPipe } from '@common/pipes/users';

const pipes = [
  ActiveRoutePipe,
  BHistoryPanelIdPipe,
  BackupStatusPipe,
  FilterServiceBackupColorPipe,
  FilterServiceBackupTextPipe,
  FolderIconPipe,
  FoldersLayoutPipe,
  GetDownloadLoadingPipe,
  InfoColumnLayoutPipe,
  InvoiceStateToDisplayNamePipe,
  InvoiceStatusPipe,
  MainColumnLayoutPipe,
  MimeCategoryIconPipe,
  NavColumnLayoutPipe,
  OperationTypeToDisplayNamePipe,
  PriceToDisplayPipe,
  ResizeColumnLayoutPipe,
  ServiceTypeToDisplayNamePipe,
  SmartSearchMaxWidthPipe,
  SortOrderByPipe,
  SrcMimeCategoryPipe,
  SrcMimeCategorySbPipe,
  StatusFilterBackupPipe,
  TaskStatusIconPipe,
  TaskStatusTypePipe,
  TransformTextByPipe,
  UserActionTypeAreaPipe,
  UserActionTypePipe,
  UserStatusPipe
];

@NgModule({
  imports: [CommonModule],
  declarations: pipes,
  exports: pipes
})
export class PipesModule {}
