import { HttpErrorResponse } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { ActivatedRoute, NavigationExtras, Params, Router } from '@angular/router';
import { InvoiceState, MIN_SCREEN_HEIGHT, PaymentType, WINDOW } from '@common';
import { InvoiceOData, PayInvoicePayload, PlanInfoOdata, hasSingleUserRole } from '@common/models';
import { AuthService, InvoicesService, TariffsService } from '@common/services';
import { SmartSearchModelTemplatePaymentsService, isSmartSearchPersist } from '@common/services/smart-search';
import {
  canAbilityCdRef,
  getDefaultPaginationOptions,
  getErrorText,
  getMaxHeight,
  getOdataOrderBy,
  hasErrorResponseText
} from '@common/utils';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  ComparePlansModalComponent,
  ModePlansCompareEnum,
  REDIRECT_TO_EDIT_SUBSCRIPTION_MODAL
} from '@pages/payments/compare-plans-modal/compare-plans-modal.component';
import { I18NextPipe } from 'angular-i18next';
import { I18_NAMESPACE_APPS_UI } from 'i18n';
import { noop } from 'lodash';
import {
  DateFormat,
  MbsSize,
  ModalService,
  ModalSettings,
  ModelTemplate,
  PaginationOptions,
  SharedPersistentStateEnum,
  SmartSearchModel,
  SortEvent,
  TableHeader,
  ToastService
} from 'mbs-ui-kit';
import { BehaviorSubject, EMPTY, Observable, from, of, throwError } from 'rxjs';
import { catchError, finalize, shareReplay, switchMap } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaymentsComponent implements OnInit, OnDestroy {
  @ViewChild('mbsTableGridRef', { static: false, read: ElementRef }) mbsTableElRef: ElementRef;
  @ViewChild('confirmSubscriptionMessageBodyTemplate', { static: true, read: TemplateRef }) messageBodyTemplate: TemplateRef<any>;

  #isSmartSearchPersist = isSmartSearchPersist();

  readonly #toastTitleSuccess = this.i18nPipe.transform('toast.success.title', { format: 'title' });
  readonly #toastTitleError = this.i18nPipe.transform('toast.error.title', { format: 'title' });

  public readonly modulePayments = I18_NAMESPACE_APPS_UI.payments;
  public readonly viewMode: SharedPersistentStateEnum = SharedPersistentStateEnum.table;
  public readonly MbsSize = MbsSize;
  public readonly DateFormat = DateFormat;
  public readonly PaymentType = PaymentType;
  public readonly InvoiceState = InvoiceState;
  public readonly ModePlansCompareEnum = ModePlansCompareEnum;

  public readonly isAvailableEditMode$: Observable<boolean>;
  public readonly planInfo$: Observable<PlanInfoOdata>;
  public readonly invoices$: BehaviorSubject<InvoiceOData[]> = new BehaviorSubject([]);
  public readonly loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public readonly contentAvailable$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public readonly maxHeight$: BehaviorSubject<string> = new BehaviorSubject('');

  public headers: TableHeader[] = [];
  public orderBy: SortEvent = { column: 'Date', direction: 'desc' };
  public paginationOptions: PaginationOptions = getDefaultPaginationOptions();
  public searchTemplates: ModelTemplate<InvoiceOData | string>[] = [];

  public invoiceAmount = 0;
  public invoiceNextPayment = 0;

  get queryParams() {
    return new URLSearchParams(this.window.location.search);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    if (event.target instanceof Window) {
      this.updateMaxHeight();
    }
  }

  constructor(
    @Inject(WINDOW) private window: Window,
    private router: Router,
    private route: ActivatedRoute,
    private cdRef: ChangeDetectorRef,
    private i18nPipe: I18NextPipe,
    private modalService: ModalService,
    private toastService: ToastService,
    private invoicesService: InvoicesService,
    private tariffsService: TariffsService,
    templates: SmartSearchModelTemplatePaymentsService,
    authService: AuthService
  ) {
    this.searchTemplates = [
      templates.StateTag,
      templates.QuantityTag,
      templates.PriceTag,
      templates.AmountTag,
      templates.DateTag,
      templates.ExpireDateTag,
      templates.InvoiceIdTag
    ];
    this.headers = this.getTableHeaders();
    this.planInfo$ = this.tariffsService.getPlanInfo().pipe(shareReplay(1));
    this.isAvailableEditMode$ = authService.getRoles().pipe(switchMap((roles) => of(!hasSingleUserRole(roles))));
  }

  ngOnInit(): void {
    this.initCustomOrderBy();
    this.fetchData();

    if (this.queryParams.get('plan')?.includes('select')) {
      this.handlePlan(ModePlansCompareEnum.select);
    }

    if (this.queryParams.get('plan')?.includes('edit')) {
      this.handlePlan(ModePlansCompareEnum.edit);
    }
  }

  ngOnDestroy(): void {
    this.invoicesService.clearCache();
  }

  ngAfterViewInit(): void {
    queueMicrotask(() => {
      this.updateMaxHeight();
      canAbilityCdRef.call(this) && this.cdRef.detectChanges();
    });
  }

  private initCustomOrderBy(): void {
    this.invoicesService.orderBy = getOdataOrderBy(this.orderBy.column, this.orderBy.direction);
  }

  fetchData(): void {
    this.loading$.next(true);

    this.invoicesService
      .getInvoices()
      .pipe(
        catchError(() => of({ count: 0, data: [] })),
        finalize(() => this.loading$.next(false)),
        untilDestroyed(this)
      )
      .subscribe({
        next: ({ count, data }) => {
          this.updatePaginationOptionsDataSize(count);
          this.invoices$.next(data);
        }
      });
  }

  private updatePaginationOptionsDataSize(count: number): void {
    this.paginationOptions = {
      ...this.paginationOptions,
      dataSize: count
    };
  }

  handleUpdateFilter(searchObj: SmartSearchModel): void {
    if (this.#isSmartSearchPersist(searchObj)) return;

    this.invoicesService.updateFilter(searchObj);
    this.resetPagePagination();

    this.fetchData();
  }

  private resetPagePagination(): void {
    this.paginationOptions.page = 1;
    this.invoicesService.page = 1;
  }

  private updateMaxHeight(): void {
    const screenHeight: number = this.window.screen.height;

    this.maxHeight$.next(screenHeight > MIN_SCREEN_HEIGHT && this.mbsTableElRef ? getMaxHeight(this.mbsTableElRef.nativeElement) : '');
  }

  handleSort({ column, direction }: SortEvent): void {
    this.orderBy = { column, direction };
    this.invoicesService.orderBy = getOdataOrderBy(column, direction);

    this.fetchData();
  }

  pageChange(page: number): void {
    this.paginationOptions.page = page;
    this.invoicesService.page = page;

    this.fetchData();
  }

  pageSizeChange(options: PaginationOptions): void {
    this.paginationOptions = { ...options, page: 1 };
    this.invoicesService.page = 1;
    this.invoicesService.pageSize = this.paginationOptions.pageSize;

    this.fetchData();
  }

  handlePlan(mode: ModePlansCompareEnum): void {
    this.updateQueryParams({ plan: mode });

    const settings: ModalSettings = {
      size: MbsSize.lg,
      collapsing: true,
      data: { mode },
      footer: {
        okButton: {
          show: false
        },
        cancelButton: {
          text: this.i18nPipe.transform(this.modulePayments + ':modal.button.cancel', { format: 'title' })
        }
      }
    };

    this.modalService
      .openCustom(ComparePlansModalComponent, settings)
      .then((data) => {
        if (data === REDIRECT_TO_EDIT_SUBSCRIPTION_MODAL) {
          settings.data.mode = ModePlansCompareEnum.edit;

          return this.modalService.openCustom(ComparePlansModalComponent, settings);
        }

        return EMPTY;
      })
      .catch(() => noop)
      .finally(() => this.updateQueryParams({ plan: null }));
  }

  private updateQueryParams(params: Params): void {
    const queryParams = {
      relativeTo: this.route,
      queryParams: params,
      queryParamsHandling: 'merge'
    } as NavigationExtras;

    void this.router.navigate([], queryParams);
  }

  handleSubscriptionUpdate(invoice: InvoiceOData): void {
    const toastBody = this.i18nPipe.transform(this.modulePayments + ':toast.body.confirmSubscriptionUpdate', { format: 'capitalize' });
    const payload: PayInvoicePayload = {
      invoiceId: invoice.Id
    };

    from(this.showConfirmSubscriptionUpdatedModal(invoice))
      .pipe(
        switchMap((confirmed: boolean) => {
          this.loading$.next(true);
          return confirmed ? this.invoicesService.payInvoice(payload) : EMPTY;
        }),
        switchMap(() => this.invoicesService.getInvoices()),
        catchError((err: HttpErrorResponse) => (err instanceof HttpErrorResponse ? throwError(() => err) : EMPTY)),
        finalize(() => this.loading$.next(false)),
        untilDestroyed(this)
      )
      .subscribe({
        next: ({ count, data }) => {
          this.toastService.success(toastBody, this.#toastTitleSuccess);
          this.updatePaginationOptionsDataSize(count);
          this.invoices$.next(data);
        },
        error: (res: HttpErrorResponse) => hasErrorResponseText(res) && this.toastService.error(getErrorText(res), this.#toastTitleError)
      });
  }

  private showConfirmSubscriptionUpdatedModal(invoice: InvoiceOData): Promise<boolean> {
    this.invoiceAmount = invoice.Amount;
    this.invoiceNextPayment = invoice.Amount + invoice.Price;

    const settings: ModalSettings = {
      header: {
        title: this.i18nPipe.transform(this.modulePayments + ':modal.title.confirmSubscriptionUpdate', { format: 'title' })
      },
      size: MbsSize.sm,
      responsive: true,
      footer: {
        okButton: {
          text: this.i18nPipe.transform(this.modulePayments + ':modal.button.confirm', { format: 'title' }),
          type: 'primary'
        },
        cancelButton: {
          text: this.i18nPipe.transform(this.modulePayments + ':modal.button.cancel', { format: 'title' })
        }
      }
    };

    return this.modalService.open(settings, this.messageBodyTemplate) as Promise<boolean>;
  }

  private getTableHeaders(): TableHeader[] {
    return [
      {
        name: '',
        class: '-center',
        gridColSize: '52px',
        gridColMin: '52px'
      },
      {
        name: this.i18nPipe.transform(this.modulePayments + ':grid.quantity', { format: 'title' }),
        gridColSize: '1.5fr',
        gridColMin: '100px',
        class: '-end',
        overflow: true,
        sort: 'Quantity'
      },
      {
        name: this.i18nPipe.transform(this.modulePayments + ':grid.price', { format: 'title' }),
        gridColSize: '1.5fr',
        gridColMin: '100px',
        class: '-end',
        overflow: true,
        sort: 'Price'
      },
      {
        name: this.i18nPipe.transform(this.modulePayments + ':grid.amount', { format: 'title' }),
        gridColSize: '1.5fr',
        gridColMin: '100px',
        class: '-end',
        overflow: true,
        sort: 'Amount'
      },
      {
        name: this.i18nPipe.transform(this.modulePayments + ':grid.date', { format: 'title' }),
        gridColSize: '2fr',
        gridColMin: '100px',
        overflow: true,
        sort: 'Date'
      },
      {
        name: this.i18nPipe.transform(this.modulePayments + ':grid.expireDate', { format: 'title' }),
        gridColSize: '2fr',
        gridColMin: '100px',
        overflow: true,
        sort: 'ExpireDate'
      },
      {
        name: this.i18nPipe.transform(this.modulePayments + ':grid.invoiceId', { format: 'title' }),
        gridColSize: '1.5fr',
        gridColMin: '70px',
        sort: 'OrderId'
      },
      {
        name: '',
        gridColMin: '45px',
        gridColSize: '45px',
        isGridColumn: false,
        class: '-end'
      }
    ];
  }
}
