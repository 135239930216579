import { Component } from '@angular/core';
import { STANDALONE_ICON } from '@common';
import { ProductMode, SystemInfo } from '@common/models';
import { AuthService } from '@common/services';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-footer',
  templateUrl: './app-footer.component.html'
})
export class AppFooterComponent {
  public systemInfo$: Observable<SystemInfo>;

  constructor(private authService: AuthService) {
    this.systemInfo$ = authService.getSystemInfo().pipe(
      map((systemInfo: SystemInfo) => {
        if (systemInfo.ProductMode === ProductMode.resale) {
          systemInfo.LogoUrl = STANDALONE_ICON.logo;
        }

        return systemInfo;
      })
    );
  }
}
