import { AttachedPolicies, AttachedPolicy } from '@common/models';
import { cloneDeep, isArray } from 'lodash';

export function extendedPolicies(p: AttachedPolicies): AttachedPolicies {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  const policies = cloneDeep(removeOdataContext(p));

  for (const key in policies) {
    if (!Object.prototype.hasOwnProperty.call(policies, key) || key === '@odata.context') continue;

    const p = policies[key] as AttachedPolicy[];

    p.sort((a, b) => (a.Text > b.Text ? -1 : 1));
    p.unshift(
      ...p.splice(
        p.findIndex((i) => i.IsDefault),
        1
      )
    );

    if (isArray(p) && p.length > 0 && p.some((p) => p.Selected)) {
      p.unshift(getEmptyAttachedPolicy());
    }

    if (isArray(p) && p.length > 0 && p.some((p) => p.IsDefault)) {
      p.forEach((policy: AttachedPolicy) => (policy.IsDefault ? (policy.Text = policy.Text + ' (Default)') : policy));
    }

    if (isArray(p) && p.length > 0 && p.some((p) => p.HasLegalHold)) {
      p.forEach((policy: AttachedPolicy) => (policy.HasLegalHold ? (policy.Text = policy.Text + ' (Disabled)') : policy));
    }
  }

  return policies;
}

function removeOdataContext(policies: AttachedPolicies) {
  const { '@odata.context': context, ...rest } = policies;

  return rest;
}

function getEmptyAttachedPolicy(): AttachedPolicy {
  return {
    Disabled: false,
    HasLegalHold: false,
    IsDefault: false,
    Selected: false,
    Text: 'No Retention Policy / Domain Default',
    Value: undefined // Don't use `null` because null is reserved for no value at all in method `getSelectedPolicyId(policies)`.
  };
}
