<form [formGroup]="formGroup"
      autocomplete="off">

  <mbs-form-section>

    <mbs-form-group>

      <div class="form-row align-items-center">

        <div class="col-2">
          <label class="mbs-form_label font-weight-bold mb-0">
            {{ moduleAccount + ':sidepanel.label.email' | i18next: { format: 'title' } }}
          </label>
        </div>

        <div class="col-10">
          {{ formGroup.get('email').value }}
        </div>

      </div>

    </mbs-form-group>

    <mbs-form-group>

      <div class="form-row align-items-center">

        <div class="col-2">
          <label for="phone"
                 class="mbs-form_label font-weight-bold mb-0">
            {{ moduleAccount + ':sidepanel.label.phone' | i18next: { format: 'title' } }}
          </label>
        </div>

        <div class="col-10">
          <mbs-input formControlName="phone"
                     [id]="'phone'"
                     type="tel"
                     (blur)="handleBlurPhoneInput()"></mbs-input>
        </div>

      </div>

    </mbs-form-group>

    <mbs-form-group class="mb-0">

      <div class="form-row align-items-center">

        <div class="col-2">
          <label class="mbs-form_label font-weight-bold mb-0">
            {{ moduleAccount + ':sidepanel.label.build' | i18next: { format: 'title' } }}
          </label>
        </div>

        <div class="col-10">
          {{ formGroup.get('version').value }}
        </div>

      </div>

    </mbs-form-group>

  </mbs-form-section>

  <mbs-form-section formGroupName="exportSettings">

    <mbs-alert *ngIf="!(isAvailableExportSettings$ | async)"
               [type]="MbsPopupType.info"
               [icon]="true">
      {{ moduleAccount + ':sidepanel.alert.followingExportToPdfSettings' | i18next: { format: 'capitalize' } }}
    </mbs-alert>

    <mbs-form-group formGroupName="keepAllowed">

      <mbs-checkbox [label]="labelAllowTemplateRef"
                    [disabledSelf]="!(isAvailableExportSettings$ | async)"
                    (change)="handleChange($event)"
                    formControlName="checkbox"
                    [descriptionOutside]="false">

        <ng-template checkboxDescription>
          <mbs-button *ngIf="keepAllowedControl.get('checkbox').value && keepAllowedControl.get('password').value"
                      [disabled]="!(isAvailableExportSettings$ | async)"
                      [isCtrl]="true"
                      [size]="MbsSize.md"
                      (click)="handlePassword()">
            {{ keepAllowedControl.get('password').value | transformTextBy: '*' }}
            <span class="fa fa-pencil ml-2"></span>
          </mbs-button>
        </ng-template>

      </mbs-checkbox>

      <mbs-input [hidden]="true"
                 formControlName="password"></mbs-input>

    </mbs-form-group>

    <mbs-form-group class="mb-0"
                    formGroupName="keepCntUnits">

      <mbs-checkbox [label]="'exportToPST.label.numberOfDaysToKeepPSTFiles' | i18next: { format: 'title' }"
                    [disabledSelf]="!(isAvailableExportSettings$ | async)"
                    formControlName="checkbox"
                    [descriptionOutside]="false">

        <ng-template checkboxDescription>

          <div *ngIf="keepCntUnitsControl.get('checkbox').value"
               class="form-row align-items-center mt-3">

            <div class="col-3">
              <mbs-number [min]="1"
                          [max]="999"
                          [id]="'label-for-KeepCntUnits'"
                          [autocomplete]="'off'"
                          [disabled]="!(isAvailableExportSettings$ | async)"
                          formControlName="number"></mbs-number>
            </div>

            <div class="col-3">
              <mbs-select [clearable]="false"
                          [searchable]="false"
                          [items]="periodFilter"
                          [ngStyle]="{'pointer-events': !(isAvailableExportSettings$ | async) ? 'none' : 'initial'}"
                          [ngClass]="{'ng-select-disabled': !(isAvailableExportSettings$ | async) }"
                          [disabled]="!(isAvailableExportSettings$ | async)"
                          formControlName="filter"></mbs-select>
            </div>

          </div>

        </ng-template>

      </mbs-checkbox>

    </mbs-form-group>

  </mbs-form-section>

  <mbs-form-section style="padding-bottom: 80px">

    <div class="form-row">

      <div class="col">
        <mbs-button *ngIf="!($isDomainUser$ | async)"
                    type="outline-danger"
                    tooltipClass="tooltip-lg text-left"
                    [disabled]="isImmutability"
                    [tooltipData]="isImmutability ? [moduleAccount + ':toast.body.immutability' | i18next: { format: 'capitalize' }] : null"
                    [tooltipAlways]="true"
                    [tooltipPlacement]="['top-left', 'auto']"
                    [tooltipTriggers]="'mouseenter:mouseleave'"
                    (click)="handleDeleteBackup()">
          {{ moduleAccount + ':sidepanel.button.deleteBackup' | i18next: { format: 'title' } }}
        </mbs-button>
      </div>

      <div class="col-auto">
        <mbs-button *ngIf="!($isDomainUser$ | async)"
                    type="primary"
                    (click)="handleAssignRetentionPolicy()">
          {{ moduleAccount + ':sidepanel.button.assignRetentionPolicy' | i18next: { format: 'title' } }}
        </mbs-button>
      </div>

      <div class="col-auto"
           *ngIf="isAvailableExportToPST$ | async">
        <mbs-button type="primary"
                    (click)="handleExportToPST()">
          {{ moduleAccount + ':sidepanel.button.exportToPST' | i18next: { format: 'title' } }}
        </mbs-button>
      </div>

    </div>

  </mbs-form-section>

</form>

<ng-template #labelAllowTemplateRef>
  {{ 'exportToPST.label.allowDownload' | i18next: { format: 'title' } }}
  <span class="ico ico-InfoCircle ml-1 cursor-help text-info"
        container="body"
        tooltipClass="tooltip-lg"
        placement="top-right auto"
        [ngbTooltip]="moduleAccount + ':sidepanel.tooltip.thisSettingAllows' | i18next: { format: 'capitalize' }"></span>
</ng-template>
