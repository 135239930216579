import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AddAlternateEmail, AlternateEmail, EditAlternate, User } from '@common/models';
import { AuthService } from '@common/services';
import { I18_NAMESPACE_APPS_UI } from 'i18n';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-user-permissions-control',
  templateUrl: './user-permissions-control.component.html'
})
export class UserPermissionsControlComponent {
  #user = {
    SignInEnable: false,
    RestoreEnable: false
  } as User;

  public readonly isProviderSignIn$: Observable<boolean>;
  // public readonly restorePermissionDisable$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public readonly moduleUsers = I18_NAMESPACE_APPS_UI.users;

  @Input() set user(u: User) {
    this.#user = u;
    // this.updateRestoreDisableState(u);
  }

  get user(): User {
    return this.#user;
  }

  @Input() forManyUsers = false;

  @Input() alternateEmailInfo: AlternateEmail;
  @Input() alternateSelected: boolean;
  @Output() alternateSelectedChange = new EventEmitter<boolean>();

  @Output() formsInvalid = new EventEmitter<boolean>();
  @Output() newAlternateEmailModel = new EventEmitter<AddAlternateEmail>();
  @Output() editAlternateEmailModel = new EventEmitter<EditAlternate>();

  constructor(private authService: AuthService) {
    this.isProviderSignIn$ = this.authService.isProviderSignIn();
  }

  // private updateRestoreDisableState(user: User): void {
  //   this.restorePermissionDisable$.next(user.Id === this.authService.id);
  // }
}
