<mbs-modal [loading]="loading$ | async"
           [title]="moduleAccount + ':modal.title.twoFactorAuthentication' | i18next: { format: 'title' }">

  <ng-container modal-body
                [formGroup]="formGroup">

    <p class="mb-2 text-sm">
      {{ moduleAccount + ':modal.body.twoFactorAuthenticationToEnable' | i18next: { format: 'capitalize' } }}
      <span class="font-weight-semibold">
        {{ moduleAccount + ':modal.body.twoFactorAuthentication2FA' | i18next: { format: 'capitalize' } }}
      </span>
      {{ moduleAccount + ':modal.body.twoFactorAuthenticationProceedAsFollows' | i18next: { format: 'capitalize' } }}:
    </p>

    <ul class="mb-0 pl-4 text-sm">

      <li>
        {{ moduleAccount + ':modal.body.twoFactorAuthenticationOpen' | i18next: { format: 'capitalize' } }}
        <a href="https://play.google.com/store"
                target="_blank">
          {{ moduleAccount + ':modal.button.googlePlay' | i18next: { format: 'title' } }}
        </a>
        /
        <a href="https://www.apple.com/app-store/"
           target="_blank">
          {{ moduleAccount + ':modal.button.appleAppStore' | i18next: { format: 'title' } }}
        </a>
        {{ moduleAccount + ':modal.body.twoFactorAuthenticationOnYour' | i18next: { format: 'capitalize' } }}
      </li>

      <li>
        {{ moduleAccount + ':modal.body.twoFactorAuthenticationSearchFor' | i18next: { format: 'capitalize' } }}
      </li>

      <li>
        {{ moduleAccount + ':modal.body.twoFactorAuthenticationDownloadAnd' | i18next: { format: 'capitalize' } }}
      </li>

      <li>
        {{ moduleAccount + ':modal.body.twoFactorAuthenticationOpenThe' | i18next: { format: 'capitalize' } }}
      </li>

      <li>
        {{ moduleAccount + ':modal.body.twoFactorAuthenticationScanThe' | i18next: { format: 'capitalize' } }}
        <div class="form-row align-items-end my-3">
          <div class="col-auto">
            <qrcode *ngIf="twoStepQRURL"
                    [scale]="4"
                    [margin]="0"
                    [width]="120"
                    [allowEmptyString]="true"
                    [elementType]="'svg'"
                    [qrdata]="twoStepQRURL"
                    [alt]="moduleAccount + ':modal.label.altTwoStepQRUrl' | i18next: { format: 'capitalize' }"
                    [errorCorrectionLevel]="'M'"></qrcode>
          </div>
          <div class="col">
            <span class="text-md font-weight-semibold">{{ secret }}</span>
          </div>
        </div>
      </li>

      <li>
        {{ moduleAccount + ':modal.body.twoFactorAuthenticationEnterThe2FA' | i18next: { format: 'capitalize' } }}

        <mbs-form-group class="mt-3">
          <mbs-input formControlName="responseCode"
                     mbsAutoFocus
                     autocomplete="off"
                     type="number"
                     [boldLabel]="true"
                     [label]="moduleAccount + ':modal.label.verificationCode' | i18next: { format: 'title' }">
          </mbs-input>
        </mbs-form-group>

        <mbs-form-group class="mb-0">
          <mbs-input formControlName="password"
                     [label]="moduleAccount + ':modal.label.alternateEmailPassword' | i18next: { format: 'title' }"
                     [boldLabel]="true"
                     [type]="passwordType$ | async"
                     [appendButtons]="[
                        {
                          id: 'append-password',
                          type: 'secondary',
                          icon: getAppendButtonsIcon(passwordType$ | async),
                          btnType: 'button',
                          loading: false,
                          disabled: false
                        }
                     ]"
                     (buttonClick)="handleChangePasswordType()"
                     autocomplete="off">
          </mbs-input>
        </mbs-form-group>
      </li>

    </ul>

  </ng-container>

  <ng-container modalFooter>

    <mbs-button (click)="handleValidate()"
                [loading]="validateLoading$ | async"
                [disabled]="formGroup.invalid"
                type="primary">
      {{ moduleAccount + ':modal.button.validate' | i18next: { format: 'title' } }}
    </mbs-button>

    <mbs-button (click)="handleClose()">
      {{ moduleAccount + ':modal.button.cancel' | i18next: { format: 'title' } }}
    </mbs-button>

  </ng-container>

</mbs-modal>
