import { Pipe, PipeTransform } from '@angular/core';
import { PolicyType } from '@common';
import { User } from '@common/models';
import { AuthService } from '@common/services';

@Pipe({
  name: 'policyType',
  pure: true
})
export class PolicyTypePipe implements PipeTransform {
  constructor(private authService: AuthService) {}
  transform(user: User): PolicyType {
    if (!user) throw new Error('Not a user passed for policyType pipe');

    const policyTypes = this.getPolicyTypes(user);
    const someOneCustom = policyTypes.some((type) => type === PolicyType.custom);

    if (
      policyTypes.every((type) => type === PolicyType.default) ||
      (policyTypes.some((type) => type === PolicyType.default) && !someOneCustom)
    ) {
      return PolicyType.default;
    }

    if (policyTypes.every((type) => type === PolicyType.none)) {
      return PolicyType.none;
    }

    if (someOneCustom) {
      return PolicyType.custom;
    }

    return null;
  }

  private getPolicyTypes(user: User): PolicyType[] {
    const policyTypes = [user.MailPolicyType, user.DrivePolicyType, user.ContactPolicyType, user.CalendarPolicyType];

    if (this.authService.isGoogle) {
      policyTypes.push(user.TeamDrivePolicyType);
    }

    if (this.authService.isOffice) {
      policyTypes.push(user.SharePointPolicyType);
    }

    return policyTypes;
  }
}
