<div class="input-group mbs-smart-search"
     [ngClass]="size ? ('input-group-' + size) : ''">

  <div [class]="'form-control row no-gutters ' + getValidationStateClasses()"
       [ngClass]="[
         isFocus ? '-focus' : '',
         size ? ('form-control-' + size) : '',
         disabled ? 'disabled' : ''
       ]"
       #formControlRef>

    <div ngbDropdown
         class="col"
         container="auto"
         [autoClose]="'outside'"
         [placement]="placementNgbDropdown">

      <div ngbDropdownToggle
           contenteditable="true"
           id="smart-search"
           class="-no-arrow"
           spellcheck="false"
           [class]="elementSelectors.css.input"
           [ngClass]="size ? ('mbs-smart-search_content-' + size) : ''"
           (blur)="handleBlur()"
           (drop)="handleDrop($event)"
           #smartSearch></div>

      <div [class]="elementSelectors.css.placeholder"
           [ngClass]="[
             size ? (elementSelectors.css.placeholder + '-' + size) : '',
             smartSearch.firstChild ? 'd-none': ''
           ]"
           (click)="placeholderClick($event)">
        {{ placeholder }}
      </div>

      <div ngbDropdownMenu
           aria-labelledby="smart-search"
           class="mbs-smart-search_dropdown-menu"
           [class.show]="(showDropdown$ | async) && isOpenedDropdownMenu(ngbDropdownMenu)"
           (mouseleave)="selectedItemIndex = -1;"
           #ngbDropdownMenuRef>

        <ng-container *ngFor="let item of items; let i = index">

          <button class="dropdown-item mbs-smart-search_dropdown-item"
                  [class.active]="selectedItemIndex === i"
                  (mousedown)="handleClickSelectedValue(item);"
                  (mouseenter)="selectedItemIndex = (i || 0)">

            <ng-container [ngTemplateOutlet]="item.template ? item.template : defaultTemplate"
                          [ngTemplateOutletContext]="{ $implicit: item, formatter: item.formatter }">
            </ng-container>

            <ng-template #defaultTemplate
                         let-formatter="formatter">

              <ngb-highlight [result]="formatter(item)"
                             [term]="state.leftCaretClearValue">
                {{ formatter(item) }}
              </ngb-highlight>

            </ng-template>

          </button>

        </ng-container>

      </div>

    </div>

    <div class="col-auto d-flex align-items-center justify-content-center">

      <div *ngIf="(loading$ | async) || (searching$ | async)"
           class="px-2">
        <span class="loader"></span>
      </div>

      <mbs-button *ngIf="!(loading$ | async) && !(searching$ | async) && searchContent.length > 0"
                  title="Clear"
                  icon="ico ico-Delete-text mr-0 px-2"
                  [isCtrl]="true"
                  [type]="'dark'"
                  [size]="size"
                  [disabled]="disabled"
                  [ngClass]="size ? '-' + size : ''"
                  [customClasses]="'form-control_clear-btn' + (size ? ' -' + size : '')"
                  (click)="handleClear()">
      </mbs-button>

    </div>

    <div ngbDropdown
         class="col-auto"
         container="body"
         [autoClose]="false"
         [placement]="placementNgbDropdownForm">

      <div *ngIf="placementNgbDropdownForm === 'bottom-left' && searchFormTemplate"
           ngbDropdownAnchor
           [style]="{
             position: 'absolute',
             top: '0',
             left: ngbDropdownAnchorLeftStyle,
             right: '0',
             bottom: '0',
             opacity: '0',
             pointerEvents: 'none'
           }">
      </div>

      <button *ngIf="searchFormTemplate"
              class="ctrl ctrl-dark h-100 d-flex align-items-center px-2"
              ngbDropdownToggle
              #toggleButtonRef>
      </button>

      <div ngbDropdownMenu
           class="mbs-smart-search_dropdown"
           #ngbDropdownMenuFormRef>
        <ng-container *ngTemplateOutlet="searchFormTemplate"></ng-container>
      </div>

    </div>

  </div>

  <div class="input-group-append">

    <mbs-button name="smartSearch-search"
                [type]="'secondary'"
                [disabled]="disabled"
                [size]="size"
                [customClasses]="getValidationStateClasses()"
                (click)="handleUpdateModel()">
      <span [ngClass]="searchIcon"></span>
    </mbs-button>

  </div>

</div>
