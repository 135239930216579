import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ContentChildren,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  SimpleChange,
  TemplateRef
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { cloneDeep, isNil } from 'lodash';
import { Observable } from 'rxjs';
import { AppendTopControlsLabel, PrependTopControlsLabel } from '../list-select';
import { SortDirection, SortEvent } from '../models/sort';
import { SharedPersistentStateEnum } from '../services/storage/shared-persistent-state-enum';
import { SHARED_PERSISTENT_STATE } from '../services/storage/shared-persistent-state.provider';
import { SharedPersistentStateService, ViewMode } from '../services/storage/shared-persistent-state.service';
import { BootstrapTheme, MbsSize, removeSelection } from '../utils';
import { GridTileDirective } from './directives/grid-tile.directive';
import { TableHeaderNameDirective } from './directives/headerFor.directive';
import { TableCellDirective } from './directives/table-cell.directive';
import { TableControlDirective } from './directives/table-control.directive';
import { TableFilterDirective } from './directives/table-filter.directive';
import { TableNavContentDirective } from './directives/table-nav-content.directive';
import { PaginationOptions } from './models/pagination-options';
import { TableHeader } from './models/table-header';
import { ClassRowObject, ExtendedTableRow, MultipleSelectType } from './table/table.component';

/**
 * A component that helps build table or grid template with auto change `myViewMode`
 */
@UntilDestroy()
@Component({
  selector: 'app-table-grid,mbs-table-grid',
  templateUrl: './table-grid.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableGridComponent implements OnInit, OnChanges {
  public readonly sharedPersistentStateEnum = SharedPersistentStateEnum;

  private _clickedTableElement: unknown;
  private _clickTimerTableElement;
  private _paginationOptions: PaginationOptions;
  private _data: unknown[];
  private _displayData: unknown[] = [];

  @Input() invalid = false;
  @Input() loading = false;
  @Input() loaderType: BootstrapTheme = 'light';
  @Input() keepState = false;

  /**
   * If showTopControls = true main control block will be shown above the table
   */
  @Input() public showTopControls = false;
  @Input() public topControlsLabel = false;
  @Input() public topControlsLabelSelected = false;
  @Input() public topControlsDescription = '';
  @Input() public useFixedDocumentSpinner = false;

  public checkedTop: boolean;
  public indeterminateTop: boolean;

  /**
   * predicate. If it returns `true` - table will not execute getItems for this root.
   *
   * If this root has children - arrow still will be shown
   */
  @Input() loadedChildren: (root: any) => boolean;

  /**
   * Headers table
   */
  @Input() headers: TableHeader[];

  /**
   * Headers that used for building columns in subtitle row
   */
  @Input() subtitleHeaders: TableHeader[];

  /**
   * Source input data
   * @param {unknown[]} data
   */
  @Input() set data(data: unknown[]) {
    this._data = data;
  }

  get data(): unknown[] {
    return this._data;
  }

  /**
   * Source display data
   */
  get displayData(): unknown[] {
    if (this.paginationOptions && !this.serverSidePagination) {
      return this._displayData;
    }

    return this.data;
  }
  /**
   * Can manually switch view mode;
   */
  @Input() switcherView = false;
  /**
   * For group select in table
   */
  @Input() showTableCheckboxes = false;
  /**
   * For group select in children tables.
   * Works only if showTableCheckboxes = true;
   */
  @Input() showCheckboxesForChildren = true;

  @Input() checkboxCellWidth = '50px';
  @Input() toggleCellWidth = '50px';
  @Input() needToggleOnEndRow = false;
  @Input() removeSelectionAfterDoubleClick = false;

  private _selectedItems: any[] = [];
  /**
   * Check given items
   * @param {any[]} items
   */
  @Input() set selectedItems(items: any[]) {
    this._selectedItems = items ? Array.from(items) : [];
  }

  get selectedItems(): any[] {
    return this._selectedItems;
  }

  /**
   * If true - getItems function will be called after click on row arrow in collapsible mode;
   */
  @Input() lazy = false;

  /**
   * Function that runs to load array of child elements. Only for `lazy` mode;
   * @argument parent - parent element for new children array.
   */
  @Input() getItems: (parent: any) => Observable<any[]>;

  /**
   * Table's content max height.
   */
  @Input() maxHeight: string;

  /**
   * Table's content min height.
   */
  @Input() minHeight: string;

  @Input() height: string | number;

  /**
   * Make the table header sticky.
   */
  @Input() stickyHeader = false;

  /**
   * Message that appears if no `data` provided or provided data is empty
   */
  @Input() noDataMessage: string | TemplateRef<any>;
  @Input() selectedCountText: string | TemplateRef<any>;
  @Input() selectAllButtonText: string;
  @Input() cancelSelectionText: string;

  /**
   * Add infinite scroll
   * @see https://www.npmjs.com/package/ngx-infinite-scroll
   * to ngx-infinite-scroll docs
   */
  @Input() infiniteScroll: boolean;
  @Input() infiniteScrollDistance: number;
  @Input() infiniteScrollThrottle: number;
  @Input() infiniteScrollLoading: boolean;
  @Input() scrollWindow = false;
  @Input() totalItems: number;

  /**
   * Key of `data` element for compare and select in `showTableCheckboxes` mode
   */
  @Input() bindSelected = 'id';
  @Input() bindSelectedChildren = 'id';
  enableSwitcher = true;

  /**
   * Have to use `serverSidePagination = true` if server side pagination
   */
  @Input() serverSidePagination = false;

  @Input() totalSelectedItems: number;
  @Input() entityName: string;
  @Input() showSelectAllHint = false;

  /**
   * If true - select row only if click on checkbox
   */
  @Input() selectOnlyOnCheckboxClick = false;

  /**
   * @ignore
   */
  private paginationOptionsDefault: PaginationOptions = {
    maxSize: 3,
    rotate: false,
    pageSize: 20,
    dataSize: 0,
    pageSizeList: null,
    page: 1
  };

  /**
   * Pagination options.
   *
   * @param {PaginationOptions} options
   *
   * For disable pagination set `pageSize=0` or `dataSize=0`
   * `maxSize` - Visible pages number. Default: `3`
   * `rotate` - Whether to rotate pages when `maxSize` > number of pages.
   * The current page always stays in the middle if true. Default: `false`
   * `pageSize` - The number of items per page. Default: `20`
   * `dataSize` - Total number of items.
   * `pageSizeList` - Array of numbers to choose `pageSize` from. Default: `null`
   * `page` - current page. Default: `1`;
   */
  @Input() set paginationOptions(options: PaginationOptions) {
    this._paginationOptions = Object.assign({}, this.paginationOptionsDefault, options);
  }

  get paginationOptions(): PaginationOptions {
    return this._paginationOptions || this.paginationOptionsDefault;
  }

  @Input() changeSortState: SortEvent;

  public get needToShowPaginationColumn(): boolean {
    return (
      this.showRefreshButton ||
      (this.paginationOptions.pageSize < this.paginationOptions.dataSize && (this.data || []).length > 0) ||
      (this.enableSwitcher && this.switcherView)
    );
  }

  /**
   * @param {ViewMode} mode
   * @default ['table']
   */
  @Input() set viewMode(mode: ViewMode) {
    this.myViewMode = mode;
    this.viewChange.emit(this.myViewMode);
  }

  /**
   * Disable switching from selected view to different 'table' or 'grid'
   */
  @Input() disableViewSwitch = false;

  @Input() showHeaders = true;
  @Input() stripedTable = false;
  @Input() hoverTable = true;
  @Input() borderedTable = true;

  /**
   * Table size
   */
  @Input() sizeTable: MbsSize.sm | MbsSize.lg = null;
  /**
   * Custom class applied to mbs-table-grid
   */
  @Input() classesTable: string;

  /**
   * Sort state sequence.
   */
  @Input() rotateSequence: { [key: string]: SortDirection } = {
    asc: 'desc',
    desc: '',
    '': 'asc'
  };

  @Input() getCustomRowClasses: (row: any) => string;
  @Input() rowClasses = '';

  /**
   * @Deprecated since shared 1.1.369
   */
  @Input() isNeedSelectRow = false;
  /**
   * Need to use this property instead of `isNeedSelectRow` since shared 1.1.369
   */
  private _selectable = false;
  @Input() set selectable(value: boolean) {
    this._selectable = this.isNeedSelectRow || value;
  }
  get selectable(): boolean {
    return this._selectable || this.isNeedSelectRow;
  }

  /**
   * Enable Card selection
   */
  @Input() enableCardSelection = true;

  @Input() multipleSelect = false;
  @Input() showSelectAllCheckbox = true;
  @Input() dependenceChildren = false;
  @Input() canSelectParent = true;
  @Input() disableChildren = false;
  @Input() multipleSelectType = MultipleSelectType.Mouse;
  @Input() selectRowClass = '-selected';
  @Input() selectCardClass = '-selected';

  @Input() arrayRowsWithClasses: ClassRowObject[] = [];
  @Input() bindKeyForRowClass = 'id';
  @Input() childHeaderClasses: string;
  @Input() childRowsClasses: string;

  @Input() highlightOpened: boolean | string = false;

  @Input() bindDisabledValues: { key: string; value: any };

  @Output() sort: EventEmitter<SortEvent> = new EventEmitter<SortEvent>();
  /**
   * @deprecated
   **/
  @Output() pageChange: EventEmitter<number> = new EventEmitter<number>();
  /**
   * replacement for `pageChange`
   * */
  @Output() pageSwitch: EventEmitter<PaginationOptions> = new EventEmitter<PaginationOptions>();
  @Output() pageSizeChange: EventEmitter<PaginationOptions> = new EventEmitter<PaginationOptions>();
  @Output() viewChange: EventEmitter<SharedPersistentStateEnum> = new EventEmitter<SharedPersistentStateEnum>();
  @Output() changeSelected: EventEmitter<any> = new EventEmitter<any>();
  @Output() rowClick: EventEmitter<any> = new EventEmitter<any>();
  /**
   * Returned the data of row or tile that was clicked. Not  work if did double click.
   * */
  @Output() clickTableElement: EventEmitter<any> = new EventEmitter<any>();
  /**
   * Returned the data of row or tile that was double clicked.
   * */
  @Output() doubleClickTableElement: EventEmitter<any> = new EventEmitter<any>();

  @Output() selectAllOnAllPages: EventEmitter<boolean> = new EventEmitter<boolean>();

  public myViewMode: ViewMode = !this.disableViewSwitch ? this.sharedPersistent.data.viewTableGridMode : SharedPersistentStateEnum.table;
  public lastViewMode: ViewMode = this.myViewMode;

  @Input() showNav: boolean;
  @Input() bindChildren: string;

  /**
   * Key that allows to distinguish one parent from the other, like id, GUID and so on.
   * uses to save collapsing status on data update.
   */
  @Input() bindParentKey: string;
  @Input() collapsibleMode = false;
  @Input() cardMode = false;
  @Input() needChildrenPaddingLeft = true;
  @Input() selectAllIgnoreDisabled = false;
  /**
   *  function used in every row "for in".
   */
  // eslint-disable-next-line @typescript-eslint/ban-types
  @Input() myTrackBy: (index: number, item: ExtendedTableRow) => {};

  /**
   * If true - show headers for children in collapsible mode.
   */
  @Input() showChildrenHeaders = true;

  /**
   * Add virtual scrolling
   */
  @Input() virtualScrolling = false;

  /**
   * Setting custom virtual height for row in px.
   */
  @Input() virtualItemSize = 50;

  /**
   * Setting number of visible rows in table
   */
  @Input() virtualItemsNumber = 20;

  @Input() staticHeightIfVoidData = false;

  /* eslint-disable sonarjs/no-identical-functions */
  cellTemplates: TemplateRef<any>[] = [];

  /**
   * @deprecated since release/4.7
   * @param {QueryList<TemplateRef<any>>} value
   */
  @ContentChildren('templateCell') set cellRefTemplates(value: QueryList<TemplateRef<any>>) {
    if (value.length > 0) {
      this.cellTemplates = value.toArray();
    }
  }

  @ContentChildren(TableFilterDirective, {
    read: TableFilterDirective,
    descendants: false
  })
  filterTemplates: QueryList<TableFilterDirective>;

  public get filters(): QueryList<TableFilterDirective> {
    return this.filterTemplates;
  }

  @ContentChild(TableNavContentDirective, {
    read: TableNavContentDirective,
    static: false
  })
  customContentTemplate: TableNavContentDirective;

  public get navContent(): TableNavContentDirective {
    return this.customContentTemplate;
  }

  @ContentChildren(TableControlDirective, {
    read: TableControlDirective,
    descendants: false
  })
  controlTemplates: QueryList<TableControlDirective>;

  public get customControls(): QueryList<TableControlDirective> {
    return this.controlTemplates;
  }

  childrenTemplates: TemplateRef<any>[] = [];

  /**
   * @deprecated since release/4.7
   * @param {QueryList<TemplateRef<any>>} value
   */
  @ContentChildren('childTemplateCell') set childrenRefTemplates(value: QueryList<TemplateRef<any>>) {
    if (value.length > 0) {
      this.childrenTemplates = value.toArray();
    }
  }

  @ContentChildren(TableCellDirective, { read: TableCellDirective, descendants: true }) set contentTemplates(
    value: QueryList<TableCellDirective>
  ) {
    const cells = value.toArray();

    const childTemplates = cells.filter((c) => c.group === 'child');
    this.childrenTemplates = childTemplates.length > 0 ? childTemplates.map((c) => c.template) : this.childrenTemplates;

    const templates = cells.filter((c) => isNil(c.group));
    this.cellTemplates = templates.length > 0 ? templates.map((c) => c.template) : this.cellTemplates;
  }

  @ContentChild(GridTileDirective, { read: TemplateRef, static: false }) tileTemplate: TemplateRef<any>;

  @ContentChildren(TableHeaderNameDirective) headerTemplates: QueryList<TableHeaderNameDirective>;

  @ContentChild('gridHeader', { static: true }) gridHeaderTemplate: TemplateRef<any>;

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    if (event.target instanceof Window && !this.disableViewSwitch) {
      queueMicrotask(() => {
        if ((event.target as Window).innerWidth < 576) {
          this.enableSwitcher = false;
          this.myViewMode = SharedPersistentStateEnum.grid;
        } else {
          this.enableSwitcher = true;
          this.myViewMode = this.lastViewMode;
        }

        this.viewChange.emit(this.myViewMode);
      });
    }
  }

  @Input() showRefreshButton = false;
  @Input() wideContentMode = false;
  @Input() fullContentWidth = false;
  @Input() showExportToCSVButton = false;

  /**
   * Emits on refresh button click with no data;
   */
  @Output() refresh: EventEmitter<any> = new EventEmitter<any>();
  @Output() export: EventEmitter<any> = new EventEmitter<any>();
  @Output() scrolled: EventEmitter<null> = new EventEmitter<null>();
  @Output() scroll: EventEmitter<Event> = new EventEmitter<Event>();

  @ContentChild(PrependTopControlsLabel, { static: true, read: PrependTopControlsLabel }) prependTopControlsLabel: PrependTopControlsLabel;
  @ContentChild(AppendTopControlsLabel, { static: true, read: AppendTopControlsLabel }) appendTopControlsLabel: AppendTopControlsLabel;

  constructor(@Inject(SHARED_PERSISTENT_STATE) private sharedPersistent: SharedPersistentStateService, private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    if (this.showTopControls) this.updateMainCheckboxData();

    if (!this.disableViewSwitch) {
      this.sharedPersistent.change.pipe(untilDestroyed(this)).subscribe((mode) => {
        this.onViewChange(mode.viewTableGridMode);
        this.cdr.detectChanges();
      });
    }

    if (
      this.paginationOptions?.pageSize &&
      !isNil(this.paginationOptions.pageSizeList) &&
      !this.paginationOptions.pageSizeList.includes(this.paginationOptions.pageSize)
    ) {
      this.paginationOptions.pageSize = this.paginationOptions.pageSizeList[0];
    }

    if (window.innerWidth < 576 && !this.disableViewSwitch) {
      this.enableSwitcher = false;
      this.myViewMode = SharedPersistentStateEnum.grid;
    }

    this.viewChange.emit(this.myViewMode);
  }

  ngOnChanges(changes: { [propKey: string]: SimpleChange }): void {
    if (changes.paginationOptions || changes.serverSidePagination || changes.data) {
      this.updateDisplayData();
    }

    if (changes.viewMode) {
      this.lastViewMode = this.myViewMode;
    }

    if (
      this.showNav !== false &&
      ((changes.showRefreshButton && !changes.showRefreshButton.firstChange) ||
        (changes.switcherView && !changes.switcherView.firstChange) ||
        (changes.paginationOptions && !changes.paginationOptions.firstChange) ||
        (changes.data && !changes.data.firstChange))
    ) {
      this.showNav = this.needToShowNav();
    }

    if (this.showTopControls && changes.selectedItems) {
      this.updateMainCheckboxData();
    }
  }

  ngAfterViewInit(): void {
    if (isNil(this.showNav)) {
      this.updateShowingNavAfterChanges();
    }

    this.filterTemplates.changes.subscribe(() => {
      this.updateShowingNavAfterChanges();
    });

    this.controlTemplates.changes.subscribe(() => {
      this.updateShowingNavAfterChanges();
    });
  }

  needToShowNav(): boolean {
    return (
      this.needToShowPaginationColumn ||
      !!this.customContentTemplate ||
      this.filterTemplates?.length > 0 ||
      this.controlTemplates?.length > 0
    );
  }

  updateShowingNavAfterChanges() {
    this.showNav = this.needToShowNav();
    this.cdr.detectChanges();
  }

  getFlatData(): any[] {
    return (this.displayData as any[]).reduce((acc, next) => {
      acc.push(next);

      if (next[this.bindChildren]?.length) {
        acc.push(...next[this.bindChildren]);
      }

      return acc;
    }, []);
  }

  updateMainCheckboxData(items?: any): void {
    const selectedLength = items ? items.length : this.selectedItems?.length || 0;
    const flatAllLength = this.getFlatData()?.length || 0;

    this.checkedTop = selectedLength === flatAllLength && selectedLength !== 0;
    this.indeterminateTop = selectedLength !== 0 && selectedLength !== flatAllLength;

    this.cdr.detectChanges();
  }

  topControlsCheckboxChangeHandler(event: boolean): void {
    if (!event) {
      this.selectedItems = [];
      this.handleSelectedRow([]);

      return;
    }

    const flatData = Array.from(this.getFlatData());

    this.selectedItems = flatData;
    this.handleSelectedRow(flatData);
    this._displayData = cloneDeep(this._displayData);

    this.cdr.detectChanges();
  }

  onPageChange(page: number): void {
    this.updateDisplayData();
    this.pageChange.emit(page);
    this.paginationOptions = Object.assign({}, this.paginationOptions, { page });
    this.pageSwitch.emit(this.paginationOptions);
    this.setTotalSelectedItems(0);
  }

  onPageSwitch(options: PaginationOptions): void {
    this.updateDisplayData();
    this.paginationOptions = options;
    this.pageSwitch.emit(this.paginationOptions);
    this.setTotalSelectedItems(0);
  }

  onViewChange(mode: SharedPersistentStateEnum): void {
    this.myViewMode = window.innerWidth < 576 ? SharedPersistentStateEnum.grid : mode;
    this.lastViewMode = this.myViewMode;
    this.viewChange.emit(this.myViewMode);
  }

  handleSort(event: SortEvent): void {
    this.sort.emit(event);
    this.setTotalSelectedItems(0);
  }

  handleSelectedRow(item: any | any[]): void {
    this.changeSelected.emit(item);

    if (this.showTopControls) {
      this.updateMainCheckboxData(item);
    }
  }

  handleClickTableElement(row: any): void {
    if (
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      this._clickedTableElement?.[this.bindSelected] === row[this.bindSelected] &&
      this._clickTimerTableElement
    ) {
      this._clickedTableElement = null;
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      clearTimeout(this._clickTimerTableElement);
      this.removeSelectionAfterDoubleClick && removeSelection();
      // double click
      this.doubleClickTableElement.emit(row);
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      this._clickedTableElement = row;
      this._clickTimerTableElement = setTimeout(() => {
        this._clickTimerTableElement = null;
        this._clickedTableElement = null;
        // single click
        this.clickTableElement.emit(row);
      }, 300);
    }
  }

  handleChangePageSize(options: PaginationOptions): void {
    this.paginationOptions = options;
    this.pageSizeChange.emit(this.paginationOptions);
    this.setTotalSelectedItems(0);
  }

  public setTotalSelectedItems(itemsCount: number) {
    this.totalSelectedItems = itemsCount;
    const totalItems = this.paginationOptions?.dataSize ? this.paginationOptions?.dataSize : this.totalItems;
    this.selectAllOnAllPages.emit(itemsCount === totalItems);
  }

  private updateDisplayData(): void {
    const isNeedClipData: boolean =
      this.paginationOptions.pageSize < this.paginationOptions.dataSize && (this.data || []).length > 0 && !this.serverSidePagination;

    this._displayData = isNeedClipData ? this.getClippedInitData() : Array.from(this.data || []);
  }

  private getClippedInitData(): unknown[] {
    return this.data.slice(
      this.paginationOptions.pageSize * (this.paginationOptions.page - 1),
      this.paginationOptions.pageSize * (this.paginationOptions.page - 1) + this.paginationOptions.pageSize
    );
  }
}
