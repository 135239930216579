import { Pipe, PipeTransform } from '@angular/core';
import { GServiceBHistoryDetails, UserBHistoryDetails } from '@common/models/backup-history';

@Pipe({
  name: 'BHistoryPanelId',
  pure: true
})
export class BHistoryPanelIdPipe implements PipeTransform {
  readonly #prefixId = 'results-panel-';

  transform(item: UserBHistoryDetails | GServiceBHistoryDetails, idx: number): string {
    return this.#prefixId + (item?.['Id'] || item.ServiceType + '_' + idx);
  }
}
