import { FormControl, UntypedFormGroup } from '@angular/forms';
import { isValidDate } from '@common/utils';

export function validateSearchFilterForm(form: UntypedFormGroup, dateFromControl: FormControl, dateToControl: FormControl): boolean {
  let valid = true;

  if (
    new Date(dateFromControl.value).setHours(0, 0, 0, 0) > new Date(dateToControl.value).setHours(0, 0, 0, 0) &&
    isValidDate(dateFromControl.value) &&
    isValidDate(dateToControl.value)
  ) {
    dateFromControl.setErrors({ greaterOrEqualToDate: true });
    valid = false;
  } else if (dateFromControl.errors && dateFromControl.errors.greaterOrEqualToDate) {
    dateFromControl.setErrors(null);
  }

  if (!valid) {
    form.markAsDirty();
  }

  return valid;
}
