import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { AutoActivationMode, AzureGroup, NewGroupSettings, OrganizationalUnit } from '@common/models';
import { AuthService, AzureGroupsService } from '@common/services';
import { OrganizationalUnitsService } from '@common/services/organizational-units.service';
import { getErrorText, hasErrorResponseText } from '@common/utils';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { I18NextPipe } from 'angular-i18next';
import { I18_NAMESPACE_APPS_UI } from 'i18n';
import { isArray, isNil } from 'lodash';
import { ModalComponent, ToastService } from 'mbs-ui-kit';
import { BehaviorSubject } from 'rxjs';
import { finalize } from 'rxjs/operators';

type SingleGroupType = AzureGroup | OrganizationalUnit;
type MultiGroupType = AzureGroup[] | OrganizationalUnit[];
type BaseModalDataType = {
  selected: SingleGroupType | MultiGroupType;
  ids?: string;
  selectedCount?: number;
};

@UntilDestroy()
@Component({
  templateUrl: './edit-group-setting-modal.component.html',
  styleUrls: ['./edit-group-setting-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditGroupSettingModalComponent implements OnInit {
  @ViewChild(ModalComponent, { static: true }) baseModal: ModalComponent;

  readonly #toastTitleSuccess = this.i18nPipe.transform('toast.success.title', { format: 'title' });
  readonly #toastTitleError = this.i18nPipe.transform('toast.error.title', { format: 'title' });

  public readonly loading$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  public readonly AutoActivationMode = AutoActivationMode;
  public readonly moduleUsers = I18_NAMESPACE_APPS_UI.users;
  public readonly moduleButton = I18_NAMESPACE_APPS_UI.button;
  public readonly saveLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  public formGroup: FormGroup;

  get baseModalData(): BaseModalDataType {
    return this.baseModal.data as BaseModalDataType;
  }

  get isGroupAction(): boolean {
    return isArray(this.baseModalData.selected);
  }

  get autoActivationModeCtrl(): FormControl<AutoActivationMode> {
    return <FormControl>this.formGroup.controls.autoActivationMode;
  }

  get isGoogle(): boolean {
    return this.authService.isGoogle;
  }

  get isNotChanged(): boolean {
    return this.isGroupAction
      ? isNil(this.autoActivationModeCtrl.value)
      : (this.baseModalData.selected as SingleGroupType)?.AutoActivationMode === this.autoActivationModeCtrl.value;
  }

  get names(): string | number {
    return this.isGroupAction ? this.baseModalData.selectedCount : (this.baseModalData.selected as SingleGroupType).Name;
  }

  constructor(
    private authService: AuthService,
    private i18nPipe: I18NextPipe,
    private fb: FormBuilder,
    private azureGroupsService: AzureGroupsService,
    private orgUnitsService: OrganizationalUnitsService,
    private toastService: ToastService
  ) {}

  ngOnInit(): void {
    this.initForm();
  }

  initForm(): void {
    this.formGroup = this.fb.group({
      autoActivationMode: [
        this.isGroupAction
          ? this.baseModalData.selectedCount === 1
            ? this.baseModalData.selected?.[0].AutoActivationMode
            : null
          : (this.baseModalData.selected as SingleGroupType).AutoActivationMode
      ]
    });
  }

  private groupService(): OrganizationalUnitsService | AzureGroupsService {
    return this.isGoogle ? this.orgUnitsService : this.azureGroupsService;
  }

  handleSave(): void {
    const payload: NewGroupSettings = {
      AutoActivationMode: this.autoActivationModeCtrl.value,
      Ids: this.isGroupAction ? (this.baseModalData.ids as unknown as string[]) : [(this.baseModalData.selected as SingleGroupType).Id]
    };

    this.saveLoading$.next(true);
    this.groupService()
      .updateAutoActivation(payload)
      .pipe(
        finalize(() => this.saveLoading$.next(false)),
        untilDestroyed(this)
      )
      .subscribe({
        next: () => {
          this.toastService.success(
            this.i18nPipe.transform(this.moduleUsers + ':toast.body.settings', { format: 'capitalize' }),
            this.#toastTitleSuccess
          );
          this.baseModal.save();
        },
        error: (res: HttpErrorResponse) => hasErrorResponseText(res) && this.toastService.error(getErrorText(res), this.#toastTitleError)
      });
  }

  handleCancel(): void {
    this.baseModal.close();
  }
}
