import { Injectable } from '@angular/core';
import { SINGLE_QUOTES_REGEX } from '@common';
import { CalendarEventModel } from '@common/models';
import { SortOrderByPipe } from '@common/pipes';
import { CalendarOdataService } from '@common/services';
import { getUniqueListByProp } from '@common/services/smart-search';
import { CalendarServiceTag, CalendarServiceTagsMap } from '@common/services/smart-search/calendar/smart-search-tags-calendar.model';
import SmartSearchTemplatesBase from '@common/services/smart-search/smart-search-template-base';
import { smartSearchModelFactory } from '@common/utils/functions/search';
import { isNil } from 'lodash';
import { ModelTemplate, SmartSearchState } from 'mbs-ui-kit';
import { Observable, asapScheduler, scheduled } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class SmartSearchModelTemplateCalendarService extends SmartSearchTemplatesBase {
  readonly #event = CalendarServiceTagsMap.get(CalendarServiceTag.Event);
  // readonly #description = CalendarServiceTagsMap.get(CalendarServiceTag.Descr);

  constructor(private calendarOdataService: CalendarOdataService, private sortOrderByPipe: SortOrderByPipe<CalendarEventModel>) {
    super();
  }

  public readonly EventTag: ModelTemplate<CalendarEventModel> = {
    tag: this.#event.tag,
    items: (state: SmartSearchState): Observable<CalendarEventModel[]> => {
      const term = state.leftCaretValue?.replace(SINGLE_QUOTES_REGEX, '');
      const searchObj = smartSearchModelFactory(this.#event.tag, term);
      const filter = this.calendarOdataService.updateFilter(searchObj);

      return scheduled(
        this.calendarOdataService.searchEvents(filter).pipe(
          map((events) => {
            const filtered = events.filter((event) => !isNil(event.Name) && event.Name.includes(term));
            const uniqueList = getUniqueListByProp(filtered, this.#event.prop).slice(0, 10);

            return this.sortOrderByPipe.transform(uniqueList, this.#event.prop);
          })
        ),
        asapScheduler
      );
    },
    itemFormatter: ({ Name }): string => Name,
    addGroupBrackets: true
  };

  // public readonly DescriptionTag: ModelTemplate<CalendarEventModel> = {
  //   tag: this.#description.tag,
  //   items: (state: SmartSearchState): Observable<CalendarEventModel[]> => {
  //     const term = state.leftCaretValue?.replace(SINGLE_QUOTES_REGEX, '');
  //     const searchObj = smartSearchModelFactory(this.#description.tag, term);
  //     const filter = this.calendarOdataService.updateFilter(searchObj);
  //
  //     return scheduled(
  //       this.calendarOdataService.searchEvents(filter).pipe(
  //         map((events) => {
  //           const filtered = events.filter((event) => !isNil(event.Descr) && event.Descr.includes(term));
  //           const uniqueList = getUniqueListByProp(filtered, this.#description.prop).slice(0, 10);
  //
  //           return this.sortOrderByPipe.transform(uniqueList, this.#description.prop);
  //         })
  //       ),
  //       asapScheduler
  //     );
  //   },
  //   itemFormatter: ({ Descr }): string => Descr,
  //   addGroupBrackets: true
  // };
}
