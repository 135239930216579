import * as EXCLUSIONS_TYPES_JSON from '../assets/locales/en/grammar/grammar-types.json';
import * as PHRASAL_VERBS_JSON from '../assets/locales/en/grammar/phrasal-verbs.json';
import { isUndefined, pipe } from 'lodash/fp';

// TODO: MBS-23305
const EXCLUSIONS_TYPES: { [key: string]: string[] } = (EXCLUSIONS_TYPES_JSON as any).default || EXCLUSIONS_TYPES_JSON;
const PHRASAL_VERBS: string[] = (PHRASAL_VERBS_JSON as any).default || PHRASAL_VERBS_JSON;
/*
 * union array of exlusions
 */
const ALL_EXCLUSIONS = Object.values(EXCLUSIONS_TYPES).flat();
/*
 * check to word by exclusions
 * @param word
 * @returns boolean
 */
const isExclusion = (word: string): boolean => ALL_EXCLUSIONS.includes(word);
/*
 * capitalize word
 * @param word
 * @returns
 */
export const capitalize = (word: string): string => word.charAt(0).toUpperCase() + word.slice(1);
/*
 * transform word case by rule
 * @param word
 * @param index
 * @param accum source words array
 * @returns
 */
const transformEnTitle = (word: string, index: number, accum: string[]): string => {
  const byPrev = isUndefined(accum[index - 1]) || accum[index - 1].endsWith(':');
  const isLast = accum.length - 1 === index;

  if (byPrev || isLast || word.length > 4) {
    return capitalize(word);
  }

  return isExclusion(word) ? word : capitalize(word);
};
/*
 * Capitalize title by rule MBS-A-165
 * @param value
 * @returns
 */
export const capitalizeEnTitle = (value: string): string => {
  PHRASAL_VERBS.forEach(pv => {
    value = value.replace(new RegExp(pv, 'ig'), pv);
  });
  const words = value.split(/\s/);

  return words.map(transformEnTitle).join(' ');
};
/*
 * Drop last char if is dot
 * @param value
 * @returns
 */
export const dropDot = (value: string): string => value.replace(/\.$/g, '');
/*
 * Formating title by current locale
 * @param value
 * @param lng
 * @returns
 */
export const formatTitle = (value: string, lng: string) => {
  switch (lng) {
    case 'en': {
      return pipe(capitalizeEnTitle, dropDot)(value);
    }
    case 'any':
    default:
      return dropDot(value);
  }
};
/*
 * Token
 */
export const FOMAT_TOKEN = 'title';
