import { Injectable } from '@angular/core';
import { Mail } from '@common/models';
import { MailOdataService } from '@common/services';
import { getCustomSizeItems, getUniqueListByProp, MailTagsEnum, MailTagsMap } from '@common/services/smart-search';
import SmartSearchTemplatesBase from '@common/services/smart-search/smart-search-template-base';
import { I18NextPipe } from 'angular-i18next';
import { ModelTemplate, naturalSort, SmartSearchState } from 'mbs-ui-kit';
import { asapScheduler, Observable, of, scheduled } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable()
export class SmartSearchModelTemplateMailService extends SmartSearchTemplatesBase {
  readonly #from = MailTagsMap.get(MailTagsEnum.from);
  readonly #to = MailTagsMap.get(MailTagsEnum.to);
  readonly #subject = MailTagsMap.get(MailTagsEnum.subject);
  readonly #sizeMore = MailTagsMap.get(MailTagsEnum.sizeMore);
  readonly #sizeLess = MailTagsMap.get(MailTagsEnum.sizeLess);
  readonly #withAttachment = MailTagsMap.get(MailTagsEnum.withAttachment);
  readonly #backupArchive = MailTagsMap.get(MailTagsEnum.backupArchive);
  readonly #dateTo = MailTagsMap.get(MailTagsEnum.dateTo);
  readonly #dateFrom = MailTagsMap.get(MailTagsEnum.dateFrom);

  readonly #optionYes = this.i18nPipe.transform('common.yes', { format: 'title' });
  readonly #optionNo = this.i18nPipe.transform('common.no', { format: 'title' });

  private keyCache = 'mail-name_';

  constructor(private mailService: MailOdataService, private i18nPipe: I18NextPipe) {
    super();
  }

  public readonly FromTag: ModelTemplate<Mail> = {
    tag: this.#from.tag,
    items: (state: SmartSearchState): Observable<Mail[]> => this.getSearchMails(state, this.#from.prop),
    itemFormatter: ({ From }): string => From,
    addGroupBrackets: true
  };

  public readonly ToTag: ModelTemplate<Mail> = {
    tag: this.#to.tag,
    items: (state: SmartSearchState): Observable<Mail[]> => this.getSearchMails(state, this.#to.prop),
    itemFormatter: ({ To }): string => To,
    addGroupBrackets: true
  };

  public readonly SubjectTag: ModelTemplate<Mail> = {
    tag: this.#subject.tag,
    items: (state: SmartSearchState): Observable<Mail[]> => this.getSearchMails(state, this.#subject.prop),
    itemFormatter: ({ Subject }): string => Subject,
    addGroupBrackets: true
  };

  public readonly SizeMoreThanTag: ModelTemplate<string> = {
    tag: this.#sizeMore.tag,
    items: (state: SmartSearchState): Observable<string[]> => getCustomSizeItems(state, this.updateMailFilter.bind(this)),
    itemFormatter: (value: string): string => value,
    addGroupBrackets: true
  };

  public readonly SizeMoreLessTag: ModelTemplate<string> = {
    tag: this.#sizeLess.tag,
    items: (state: SmartSearchState): Observable<string[]> => getCustomSizeItems(state, this.updateMailFilter.bind(this)),
    itemFormatter: (value: string): string => value,
    addGroupBrackets: true
  };

  public readonly WithAttachmentTag: ModelTemplate<string> = {
    tag: this.#withAttachment.tag,
    items: (): Observable<string[]> => of([this.#optionYes, this.#optionNo]) as Observable<string[]>,
    itemFormatter: (model): string => model
  };

  public readonly BackupArchiveTag: ModelTemplate<string> = {
    tag: this.#backupArchive.tag,
    items: (): Observable<string[]> => of([this.#optionYes, this.#optionNo]) as Observable<string[]>,
    itemFormatter: (model): string => model
  };

  public readonly DateToTag: ModelTemplate<Mail> = {
    tag: this.#dateTo.tag,
    items: (): Observable<Mail[]> => of([]) as Observable<Mail[]>,
    addGroupBrackets: true
  };

  public readonly DateFromTag: ModelTemplate<Mail> = {
    tag: this.#dateFrom.tag,
    items: (): Observable<Mail[]> => of([]) as Observable<Mail[]>,
    addGroupBrackets: true
  };

  private getSearchMails(state: SmartSearchState, prop: string): Observable<Mail[]> {
    const term: string = state.leftCaretValue;
    const request = this.getSearchRequest(term, prop);

    return scheduled(
      request.pipe(
        tap(() => this.writeToCache(this.keyCache + term)),
        map((statistics: Mail[]) => {
          const filtered = statistics.filter((stat) => (stat[prop] as string).toLowerCase().includes(term));
          return getUniqueListByProp(filtered, prop)
            .sort((a, b) => naturalSort((a[prop] as string).toLowerCase(), (b[prop] as string).toLowerCase()))
            .slice(0, 10);
        })
      ),
      asapScheduler
    );
  }

  private getSearchRequest(term: string, prop: string): Observable<any[]> {
    switch (prop) {
      case MailTagsEnum.from:
        return this.mailService.getEmailsInFromSection(term);
      case MailTagsEnum.to:
        return this.mailService.getEmailsInToSection(term);
      case MailTagsEnum.subject:
        return this.mailService.getListOfSubjects(term);
      default:
        return of([]);
    }
  }

  private updateMailFilter(term: string): void {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.mailService.filter = term || '';
  }
}
