import {
  AfterViewInit,
  ApplicationRef,
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  ElementRef,
  EventEmitter,
  Injector,
  Input,
  OnInit,
  Output,
  Renderer2,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, Subject, fromEvent, merge } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

let i = 0;

@Component({
  selector: 'mbs-permanent-tooltip-internal',
  template: `
    <ng-template #tooltipTemplate>
      <div class="tooltip_content text-left">
        <span *ngIf="title" class="d-block font-weight-bold mb-1">{{ title }}</span>
        <p class="mb-2">{{ text }}</p>
        <div class="d-flex align-items-center justify-content-start">
          <mbs-button size="xs" (click)="okClick.emit()">{{ okText }}</mbs-button>
          <mbs-button *ngIf="skipText" customClasses="ml-2" size="xs" type="light" [isCtrl]="true" (click)="skipClick.emit()">{{
            skipText
          }}</mbs-button>
        </div>
      </div>
    </ng-template>

    <div
      #tooltip="ngbTooltip"
      [ngbTooltip]="tooltipTemplate"
      [animation]="animation"
      [autoClose]="autoClose"
      [placement]="placement"
      [triggers]="triggers"
      [container]="container"
      [disableTooltip]="disableTooltip"
      [tooltipClass]="tooltipClass"
      [openDelay]="openDelay"
      [closeDelay]="closeDelay"></div>
  `
})
export class PermanentTooltipInternalComponent implements AfterViewInit {
  @Input() title: string | string[] | undefined;
  @Input() text: string | string[] | undefined;
  @Input() okText: string | string[] | undefined;
  @Input() skipText: string | string[] | undefined;
  @Input() animation: typeof NgbTooltip.prototype.animation;
  @Input() autoClose: typeof NgbTooltip.prototype.autoClose;
  @Input() placement: typeof NgbTooltip.prototype.placement;
  @Input() triggers: typeof NgbTooltip.prototype.triggers;
  @Input() container: typeof NgbTooltip.prototype.container;
  @Input() disableTooltip: typeof NgbTooltip.prototype.disableTooltip;
  @Input() tooltipClass: typeof NgbTooltip.prototype.tooltipClass;
  @Input() openDelay: typeof NgbTooltip.prototype.openDelay;
  @Input() closeDelay: typeof NgbTooltip.prototype.closeDelay;

  @Output() okClick: EventEmitter<null> = new EventEmitter<null>();
  @Output() skipClick: EventEmitter<null> = new EventEmitter<null>();

  @ViewChild('tooltip', { static: false }) tooltip: NgbTooltip;

  private suggestedView: null | 'open' | 'close';

  ngAfterViewInit(): void {
    if (this.suggestedView) {
      this[this.suggestedView]();
    }
  }

  open(context?: any): void {
    this.suggestedView = 'open';

    this.tooltip?.open(context);
  }

  close(): void {
    this.suggestedView = 'close';

    this.tooltip?.close();
  }
}

@UntilDestroy()
@Directive({
  selector: '[mbsPermanentTooltip]',
  exportAs: 'ngbTooltip'
})
export class PermanentTooltipDirective implements OnInit, AfterViewInit {
  @Input() mbsPermanentTooltip: string | TemplateRef<any> | null | undefined;
  @Input() title: string | string[] | undefined;
  @Input() text: string | string[] | undefined = 'Ok';
  @Input() okText: string | string[] | undefined;
  @Input() skipText: string | string[] | undefined;
  @Input() animation: typeof NgbTooltip.prototype.animation;
  @Input() autoClose: typeof NgbTooltip.prototype.autoClose;
  @Input() placement: typeof NgbTooltip.prototype.placement;
  @Input() triggers: typeof NgbTooltip.prototype.triggers;
  @Input() container: typeof NgbTooltip.prototype.container;
  @Input() disableTooltip: typeof NgbTooltip.prototype.disableTooltip;
  @Input() tooltipClass: typeof NgbTooltip.prototype.tooltipClass;
  @Input() openDelay: typeof NgbTooltip.prototype.openDelay;
  @Input() closeDelay: typeof NgbTooltip.prototype.closeDelay;
  @Input() debounceInterval = 300;
  @Input() minWidth = 0;
  @Input() offsetTop = 0;
  @Input() offsetLeft = 0;

  @Output() okClick: EventEmitter<null> = new EventEmitter<null>();
  @Output() skipClick: EventEmitter<null> = new EventEmitter<null>();

  private parking: HTMLElement;
  private componentRef: ComponentRef<PermanentTooltipInternalComponent>;
  private id = `mbsPermanentTooltip-${i++}`;
  private mutationObserver: MutationObserver;
  private destroyed = false;
  private close$ = new Subject();

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private injector: Injector,
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef
  ) {}

  ngOnInit() {}

  ngAfterViewInit() {
    this.parkTooltip();
  }

  open(context?: any): void {
    this.componentRef?.instance?.open(context);

    this.startTrackingPosition();
  }

  close(): void {
    this.close$.next(null);
    this.mutationObserver?.disconnect?.();

    this.componentRef?.instance?.close();
  }

  private parkTooltip(): void {
    const containerElement = this.container ? document.querySelector(this.container) : this.el.nativeElement.parentNode;

    this.parking = this.renderer.createElement('div');

    this.updateParkingPosition();
    this.renderer.setAttribute(this.parking, 'id', this.id);
    this.renderer.setStyle(this.parking, 'position', 'absolute');
    this.renderer.setStyle(this.parking, 'pointer-events', 'none');
    this.renderer.appendChild(containerElement, this.parking);

    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(PermanentTooltipInternalComponent);
    this.componentRef = componentFactory.create(this.injector);

    this.componentRef.instance.okClick.pipe(untilDestroyed(this)).subscribe(() => this.okClick.emit());
    this.componentRef.instance.skipClick.pipe(untilDestroyed(this)).subscribe(() => this.skipClick.emit());

    [
      'title',
      'text',
      'okText',
      'skipText',
      'autoClose',
      'placement',
      'triggers',
      'container',
      'disableTooltip',
      'tooltipClass',
      'openDelay',
      'closeDelay'
    ].forEach((key) => {
      this.componentRef.instance[key] = this[key];
    });

    this.renderer.appendChild(this.parking, this.componentRef.location.nativeElement);

    this.appRef.attachView(this.componentRef.hostView);
  }

  private updateParkingPosition(): void {
    if (this.destroyed) {
      return;
    }

    let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const containerElement = this.container ? document.querySelector(this.container) : this.el.nativeElement.parentNode;
    const rect = this.el.nativeElement.getBoundingClientRect();
    const fixed =
      window.getComputedStyle(this.el.nativeElement).position === 'fixed' || window.getComputedStyle(containerElement).position === 'fixed';

    if (fixed) {
      scrollTop = 0;
    }

    this.renderer.setStyle(this.parking, 'top', `${rect.top + this.offsetTop + scrollTop}px`);
    this.renderer.setStyle(this.parking, 'left', `${rect.left + this.offsetLeft}px`);
    this.renderer.setStyle(this.parking, 'width', this.minWidth ? `${this.minWidth}px` : '0');
    this.renderer.setStyle(this.parking, 'height', `0`);
  }

  private startTrackingPosition(): void {
    let i = 0;

    const updatePosition = () => {
      this.updateParkingPosition();
    };

    const scroll$ = fromEvent(window, 'scroll');
    const resize$ = fromEvent(window, 'resize');
    const mutationEl = new BehaviorSubject<number>(null);

    this.mutationObserver = new MutationObserver(() => mutationEl.next(i++));
    this.mutationObserver.observe(this.el.nativeElement, { attributes: true, childList: true, subtree: true });

    merge(scroll$, resize$, mutationEl)
      .pipe(takeUntil(this.close$), debounceTime(this.debounceInterval), untilDestroyed(this))
      .subscribe(updatePosition);

    mutationEl.next(0);
  }

  ngOnDestroy() {
    this.destroyed = true;

    if (this.parking?.parentNode?.removeChild) {
      this.parking.parentNode.removeChild(this.parking);
    }

    this.mutationObserver?.disconnect?.();
    this.componentRef?.destroy?.();
  }
}
