import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ButtonModule } from '../button/button.module';
import { SharedPipesModule } from '../utils/Pipes/pipes.module';
import { AlertContainerComponent } from './alert-container/alert-container.component';
import { AlertComponent } from './alert/alert.component';

@NgModule({
  imports: [CommonModule, ButtonModule, SharedPipesModule],
  declarations: [AlertComponent, AlertContainerComponent],
  exports: [AlertComponent, AlertContainerComponent]
})
export class AlertModule {}
