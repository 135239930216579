<mbs-modal [loading]="loading$ | async"
           [title]="moduleAccount + ':modal.title.exportToPST' | i18next: { format: 'title' }">

  <ng-container modal-body>
    <app-export-to-PST-control [hidden]="loading$ | async"
                               (exportServices)="handleUpdatePayloadForExportToPST($event)"
                               (formValid)="invalidForms($event)">
    </app-export-to-PST-control>
  </ng-container>

  <ng-container modalFooter>

    <mbs-button (click)="handleExport()"
                [disabled]="disabled$ | async"
                [loading]="exportLoading$ | async"
                type="primary">
      {{ moduleAccount + ':modal.button.exportToPST' | i18next: { format: 'title' } }}
    </mbs-button>

    <mbs-button (click)="handleClose()">
      {{ moduleAccount + ':modal.button.cancel' | i18next: { format: 'title' } }}
    </mbs-button>

  </ng-container>

</mbs-modal>

<ng-template #exportJobTransferredTemplateRef>
  {{ moduleAccount + ':toast.body.exportJobTransferred' | i18next: { format: 'capitalize' } }}
  <a [routerLink]="[prefix + RoutePath.TaskManager]"
     class="d-inline">
    {{ moduleAccount + ':toast.body.taskManager' | i18next: { format: 'title' } }}
  </a>
</ng-template>
