import { UserInfoForService } from '@common/models';
import { GuidEmpty } from 'mbs-ui-kit';

export function getAllUserSelectItem(name: string): UserInfoForService {
  return {
    Id: GuidEmpty,
    Name: name,
    Email: '',
    LastBackupDate: null,
    TotalSize: 0
  };
}
