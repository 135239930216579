import { Pipe, PipeTransform } from '@angular/core';
import { StatusFilterBackup } from '@common/enums/users/status-filter-backup.enum';
import { ServiceType, User } from '@common/models';
import { UserFiltersEnum } from '@common/services';

@Pipe({
  name: 'statusFilterBackup',
  pure: true
})
export class StatusFilterBackupPipe implements PipeTransform {
  transform(user: User, serviceType: ServiceType, disabledServices: ServiceType[] = []): StatusFilterBackup {
    const { InBackup: inBackup, UserProviderState: providerState, IsPublicFolder: isPublicFolder } = user;
    const serviceEnabled = this.getServiceEnabled(user, serviceType);
    const lastBackupStopReason = this.getLastBackupStopReason(user, serviceType);

    if (
      serviceEnabled === false ||
      inBackup === false ||
      providerState === UserFiltersEnum.notInDomain ||
      disabledServices.includes(serviceType) ||
      (isPublicFolder && serviceType === ServiceType.Drive)
    ) {
      return StatusFilterBackup.Disabled;
    }

    if (lastBackupStopReason === 'NoLicensesAvailable') {
      return StatusFilterBackup.NotAvailable;
    }

    return StatusFilterBackup.Enabled;
  }

  private getServiceEnabled(user: User, serviceType: ServiceType): boolean {
    switch (serviceType) {
      case ServiceType.Drive:
        return user.DriveEnable;
      case ServiceType.Calendar:
        return user.CalendarEnable;
      case ServiceType.Mail:
        return user.MailEnable;
      case ServiceType.Contacts:
        return user.ContactsEnable;
      default:
        throw new Error('Caught error manually: Cannot get "ServiceEnabled"');
    }
  }

  private getLastBackupStopReason(user: User, serviceType: ServiceType): string {
    switch (serviceType) {
      case ServiceType.Drive:
        return user.DriveLastBackupStopReason;
      case ServiceType.Calendar:
        return user.CalendarLastBackupStopReason;
      case ServiceType.Mail:
        return user.EmailLastBackupStopReason;
      case ServiceType.Contacts:
        return user.ContactLastBackupStopReason;
      default:
        throw new Error('Caught error manually: Cannot get "LastBackupStopReason"');
    }
  }
}
