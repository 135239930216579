import { Inject, Injectable } from '@angular/core';
import { WINDOW } from '@common';

@Injectable({
  providedIn: 'root'
})
export class BrowserService {
  constructor(@Inject(WINDOW) private window: Window) {}

  isSupportedBrowser(): boolean {
    const { name, version } = this.detectBrowser();
    const isSafari = name.toLowerCase().includes('safari') && version > '16.4';
    const supported = ['edge', 'chrome', 'firefox', 'safari'];

    return supported.includes(name.toLowerCase()) || isSafari;
  }

  detectBrowser(): { name: string; version: string } {
    let { userAgent: browserAgent, appName: browserName, appVersion } = navigator;
    let browserVersion = '' + parseFloat(appVersion);
    let Offset;
    let OffsetVersion;
    let ix;

    // For Chrome
    if ((OffsetVersion = browserAgent.indexOf('Chrome')) != -1) {
      browserName = 'Chrome';
      browserVersion = browserAgent.substring(OffsetVersion + 7);
    }

    // For Microsoft internet explorer
    else if ((OffsetVersion = browserAgent.indexOf('MSIE')) != -1) {
      browserName = 'Microsoft Internet Explorer';
      browserVersion = browserAgent.substring(OffsetVersion + 5);
    }

    // For Firefox
    else if ((OffsetVersion = browserAgent.indexOf('Firefox')) != -1) {
      browserName = 'Firefox';
    }

    // For Safari
    else if ((OffsetVersion = browserAgent.indexOf('Safari')) != -1) {
      browserName = 'Safari';
      browserVersion = browserAgent.substring(OffsetVersion + 7);
      if ((OffsetVersion = browserAgent.indexOf('Version')) != -1) browserVersion = browserAgent.substring(OffsetVersion + 8);
    }

    // For other browser "name/version" is at the end of userAgent
    else if ((Offset = browserAgent.lastIndexOf(' ') + 1) < (OffsetVersion = browserAgent.lastIndexOf('/'))) {
      browserName = browserAgent.substring(Offset, OffsetVersion);
      browserVersion = browserAgent.substring(OffsetVersion + 1);
      if (browserName.toLowerCase() == browserName.toUpperCase()) {
        browserName = navigator.appName;
      }
    }

    // Trimming the fullVersion string at
    // semicolon/space if present
    if ((ix = browserVersion.indexOf(';')) != -1) browserVersion = browserVersion.substring(0, ix);
    if ((ix = browserVersion.indexOf(' ')) != -1) browserVersion = browserVersion.substring(0, ix);

    return {
      name: browserName,
      version: browserVersion
    };
  }
}
