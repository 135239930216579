import { SmartSearchValues } from '@common/models';
import { SmartSearchModel, SmartSearchModelField } from 'mbs-ui-kit';
import { SPACE_REGEX } from 'mbs-ui-kit/utils/constants';

export class SmartSearchHelper {
  public static getValues(model: SmartSearchModel, separator = ' '): SmartSearchValues {
    return Object.fromEntries(
      Object.entries(model).map(([key, value]) => [
        key,
        (value as SmartSearchModelField[]).map((d) => (d.condition || '') + d.value.replace(SPACE_REGEX, separator))
      ])
    );
  }

  public static getJoinedValue(value: SmartSearchValues, key: string, separator = ' '): string {
    return value?.[key]?.join(separator) || '';
  }
}
