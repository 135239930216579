import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Optional,
  Output,
  QueryList,
  Self,
  SimpleChanges,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { NgControl, Validators } from '@angular/forms';
import { NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import IMask from 'imask';
import { Observable, of } from 'rxjs';
import { isTemplate } from '../../utils';
import { MbsValidators } from '../../utils/mbs-validators';
import { InputButton } from '../index';
import { InputBase } from '../input-base/input-base';
import { DOMEvent, InputClasses, ValidClasses } from '../input-base/input-base.model';
import {IMaskDirective} from "angular-imask";

export type MaskedOptions = IMask.AnyMaskedOptions;
export type MaskElement = IMask.MaskElement;

@Component({
  selector: 'mbs-input',
  templateUrl: './input.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InputComponent extends InputBase<any> implements OnInit, OnChanges, AfterViewInit {
  @Input() maxHeight?: string;
  @Input() imaskOpts?: MaskedOptions;
  @Input() unmask?: boolean | 'typed';
  @Input() imaskElement: (elementRef: ElementRef<MaskElement>, directiveRef: any) => MaskElement = (elementRef) => elementRef.nativeElement;

  @Input() filePlaceholderOverflow = true;

  @Input() public customClasses = ''

  /**
   * @ignore
   */
  public get isLabelTemplate(): boolean {
    return isTemplate(this.label);
  }

  /**
   * @ignore
   */
  public get bindClasses(): string[] {
    const classesObject: InputClasses = Object.assign<Record<string, boolean>, ValidClasses>(
      { '-clearable': this.clearButton || this.loading },
      this.validClasses
    );
    return Object.entries(classesObject)
      .filter(([k, v]) => !!v)
      .map(([k]) => k)
      .concat([this.sizeClass])
      .concat([this.customClasses])
  }

  constructor(@Optional() @Self() ngControl: NgControl, protected cd: ChangeDetectorRef) {
    super(ngControl, cd);
  }
  /**
   * Set maxLength
   */
  @Input() maxLength = 1000;

  /**
   * Set maxLength
   */
  @Input() minLength = 0;

  /**
   * Show clear button
   */
  @Input() clearButton = false;
  /**
   * Show spinner
   */
  @Input() loading = false;

  /**
   * If `type=textarea` then bind rows count
   */
  @Input() rows = 1;
  /**
   * If `type=textarea` then bind style
   */
  @Input() resize: 'none' | 'both' | 'horizontal' | 'vertical' | 'initial';

  @Input() public trim = true;
  /**
   * `type="file"` only <br>
   * If `true` allows to upload multiple files at a time.
   */
  @Input() multiple = true;

  /**
   * Any support HTML type.
   * Please don't use non-text types (e.g. checkbox, radio etc)
   */
  @Input() public type = 'text';

  private myValidationWhitespace = false;

  @Input() set validationWhitespace(value: boolean) {
    this.myValidationWhitespace = String(value) !== 'false';
    this.handleWhitespaceValidation(this.myValidationWhitespace);
  }

  /**
   * Add font-weight-bold;
   */
  @Input() public boldLabel = true;

  /**
   * It defines the file types the file input should accept.
   * This string is a comma-separated list of unique file type specifiers
   *
   * @example
   * <mbs-input type="file" accept=".doc,.docx,application/msword"></mbs-input>
   */
  @Input() public accept: string;

  @Input() buttonClickOnEnter = false;
  @Input() buttonClickOnEnterIndex = 0;

  @Input() showErrorsInTooltip = false;
  @Input() errorTooltipTitle = '';

  @Input() container = 'body';
  @Input() placement = 'bottom-left bottom-right top-left top-right';
  @Input() tooltipClass = 'tooltip-xl';

  @Input() autoClose = false;

  @Input() public typeahead: (text: Observable<string>) => Observable<readonly any[]> = () => of([]);

  /**
   * Emit event after clear button click.
   * Work until `clearButton = true`
   */
  @Output() clear = new EventEmitter<string>();
  @Output() buttonClick = new EventEmitter();

  /**
   * File input control
   * `ViewChild` don't work after `ngIf`
   */
  @ViewChildren('fileInputElement', { read: ElementRef }) fileInputElement: QueryList<ElementRef<HTMLInputElement>>;

  @ViewChild('errorsTooltip', { static: false }) errorsTooltip: any;
  @ViewChild(IMaskDirective, { read: IMaskDirective, static: false }) maskDirective: IMaskDirective<MaskedOptions>;

  @HostListener('document:keyup.enter', ['$event'])
  enterClickHandler(event: DOMEvent<HTMLInputElement>): void {
    if (this.buttonClickOnEnter && event.target && event.target.value) {
      if (this['trim'] && this.value && (this.value as string)['trim']) {
        this.myValue = (this.value as string).trim();
        if (this.onChange) this.onChange(this.value);
      }
      if (this.appendButtons && !!this.appendButtons.length) {
        this.buttonClick.emit(this.appendButtons[this.buttonClickOnEnterIndex]);
      }
    }
  }

  ngOnInit(): void {
    if (this.ngControl.valueChanges) {
      this.ngControl.valueChanges.subscribe(() => {
        if (this.type === 'file') {
          this.onTouched(); // fix event touched field for safari
        }
        this.cd.markForCheck();
      });
    }
    if (this.ngControl.statusChanges) {
      this.ngControl.statusChanges.subscribe((status) => {
        if (this.showErrorsInTooltip && this.errorsTooltip) {
          if (this.ngControl.errors) {
            const hasMessage = Object.keys(this.ngControl.errors).some((key) => this.ngControl.errors[key].message);
            if (status === 'INVALID' && hasMessage) this.errorsTooltip.open();
            else this.errorsTooltip.close();
          } else this.errorsTooltip.close();
        }
        this.cd.markForCheck();
      });
    }
  }

  getErrorsArray(): string[] {
    return this.ngControl?.errors ? Object.values(this.ngControl.errors) : [];
  }

  ngAfterViewInit(): void {
    this.handleWhitespaceValidation(this.myValidationWhitespace);
  }

  /**
   * Raise event and clear `value`
   * @param {MouseEvent} event
   * @param {HTMLInputElement} elem
   */
  handleClear(event: MouseEvent = null, elem: HTMLInputElement = null): void {
    if (event && elem) {
      event.preventDefault();
      elem.value = '';
    }
    this.value = '';
    this.clear.emit(this.value as string);
    this.ngControl.control.markAsPristine();
  }

  handleChange(event: DOMEvent<HTMLInputElement>, fileValue = null): void {
    this.value = this.type === 'file' ? event.target.files : event;
  }

  handleTypeaheadItemSelected(event: NgbTypeaheadSelectItemEvent) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    this.value = event.item;
  }

  loadFileHandler(event: InputButton, elem: HTMLInputElement): void {
    elem.click();
    this.buttonClick.emit(event);
  }

  handleWhitespaceValidation(state: boolean): void {
    if (this.ngControl?.control && state !== false) {
      const extendValidators = Validators.compose([this.ngControl.control.validator, MbsValidators.whitespaceValidator]);
      this.ngControl.control.setValidators(extendValidators);
    }
  }

  writeValue(obj: any): void {
    super.writeValue(obj);
    if (!obj && this.type === 'file' && this.fileInputElement) {
      this.fileInputElement.first.nativeElement.value = '';
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.showErrorsInTooltip && this.errorsTooltip) {
      if (!changes?.showErrorsInTooltip?.currentValue) this.errorsTooltip.close();
      else if (this.ngControl.errors && Object.keys(this.ngControl.errors).some((key) => this.ngControl.errors[key].message)) {
        this.errorsTooltip.open();
      }
    }
  }
}
