import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  AuthUser,
  BackupDestination,
  ExportSettings,
  MyAccountContactInfo,
  MyAccountPhone,
  OdataObject,
  Role,
  StorageStateEnum,
  hasAdminRole,
  hasDomainAdminRole,
  hasDomainUserRole,
  hasSingleUserRole
} from '@common/models';
import { ODataService, ODataServiceFactory } from '@common/odata';
import { AuthService } from '@common/services';
import { isNil } from 'lodash';
import { Observable, forkJoin, of, throwError } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MyAccountService {
  private errorRole$: Observable<string> = throwError(() => 'Forbidden role');
  private user$: Observable<AuthUser>;
  private roles$: Observable<Role[]>;
  private authData: Observable<[user: AuthUser, roles: Role[]]>;
  private odataDomains: ODataService<any>;
  private odataSingleUsers: ODataService<any>;
  private odataExportSettings: ODataService<ExportSettings>;

  constructor(private odataFactory: ODataServiceFactory, private http: HttpClient, private authService: AuthService) {
    this.odataDomains = odataFactory.CreateService('Domains');
    this.odataSingleUsers = odataFactory.CreateService('SingleUsers');
    this.odataExportSettings = odataFactory.CreateService('ExportSettings');
    this.user$ = authService.getAuthUser();
    this.roles$ = authService.getRoles();
    this.authData = forkJoin([this.user$, this.roles$]).pipe(filter(([user, roles]) => !isNil(user) || !isNil(roles)));
  }

  getContactInfo(): Observable<MyAccountContactInfo> {
    return this.authService.getAuthUser().pipe(
      switchMap((user) => {
        const odataService = this.odataFactory.CreateService<MyAccountContactInfo>(`/BackupUsers(${user.Id})/GetContactInfo`);
        return this.http.get<MyAccountContactInfo>(odataService.Query().GetUrl());
      })
    );
  }

  setContactInfo(payload: MyAccountPhone): Observable<MyAccountPhone> {
    return this.authService.getAuthUser().pipe(
      switchMap((user) => {
        const odataService = this.odataFactory.CreateService<MyAccountPhone>(`/BackupUsers(${user.Id})/SetContactInfo`);
        return this.http.post<MyAccountPhone>(odataService.Query().GetUrl(), payload);
      })
    );
  }

  deleteAccount(): Observable<any | string> {
    return this.authData.pipe(
      switchMap(([user, roles]) => {
        const isDomainAdmin = hasDomainAdminRole(roles);
        const isSingleUser = hasSingleUserRole(roles);
        if (isDomainAdmin) {
          return this.deleteAccountDomainAdmin(user.DomainId);
        }
        if (isSingleUser) {
          return this.deleteAccountSingleUser(user.Id);
        }
        return this.errorRole$;
      })
    );
  }

  private deleteAccountDomainAdmin(id: string): Observable<any> {
    const odataService = this.odataFactory.CreateService(`/Domains(${id})/DeleteAccount`);
    return odataService.Post(null).Exec();
  }

  private deleteAccountSingleUser(id: string): Observable<any> {
    const odataService = this.odataFactory.CreateService(`/SingleUsers(${id})/DeleteAccount`);
    return odataService.Post(null).Exec();
  }

  getStorage(): Observable<BackupDestination | string> {
    return this.authData.pipe(
      switchMap(([user, roles]) => {
        const isAdmin = hasAdminRole(roles);
        const isSingleUser = hasSingleUserRole(roles);
        if (isAdmin) {
          return this.getStorageAdmin(user.DomainId);
        }
        if (isSingleUser) {
          return this.getStorageSingleUser(user.Id);
        }
        return this.errorRole$;
      })
    );
  }

  private getStorageAdmin(id: string): Observable<BackupDestination> {
    return this.odataDomains.CustomFunction(id, 'GetStorage').pipe(map((res: HttpResponse<BackupDestination>) => res.body));
  }

  private getStorageSingleUser(id: string): Observable<BackupDestination> {
    return this.odataSingleUsers.CustomFunction(id, 'GetStorage').pipe(map((res: HttpResponse<BackupDestination>) => res.body));
  }

  setStorage(payload: BackupDestination): Observable<BackupDestination | string> {
    return this.authData.pipe(
      switchMap(([user, roles]) => {
        const isAdmin = hasAdminRole(roles);
        const isSingleUser = hasSingleUserRole(roles);
        if (isAdmin) {
          return this.setStorageAdmin(user.DomainId, payload);
        }
        if (isSingleUser) {
          return this.setStorageSingleUser(user.Id, payload);
        }
        return this.errorRole$;
      })
    );
  }

  private setStorageAdmin(id: string, payload: BackupDestination): Observable<BackupDestination> {
    return this.odataDomains.CustomAction(id, 'SetStorage', payload).pipe(map((res: HttpResponse<BackupDestination>) => res.body));
  }

  private setStorageSingleUser(id: string, payload: BackupDestination): Observable<BackupDestination> {
    return this.odataSingleUsers.CustomAction(id, 'SetStorage', payload).pipe(map((res: HttpResponse<BackupDestination>) => res.body));
  }

  testStorage(payload: BackupDestination): Observable<OdataObject<StorageStateEnum>> {
    return this.authData.pipe(
      switchMap(([user, roles]) => {
        const isAdmin = hasAdminRole(roles);
        const isSingleUser = hasSingleUserRole(roles);
        if (isAdmin) {
          return this.testStorageAdmin(user.DomainId, payload);
        }
        if (isSingleUser) {
          return this.testStorageSingleUser(user.Id, payload);
        }

        return null;
      })
    );
  }

  private testStorageAdmin(id: string, payload: BackupDestination): Observable<OdataObject<StorageStateEnum>> {
    return this.odataDomains
      .CustomAction(id, 'TestStorage', payload)
      .pipe(map((res: HttpResponse<OdataObject<StorageStateEnum>>) => res.body));
  }

  private testStorageSingleUser(id: string, payload: BackupDestination): Observable<OdataObject<StorageStateEnum>> {
    return this.odataSingleUsers
      .CustomAction(id, 'TestStorage', payload)
      .pipe(map((res: HttpResponse<OdataObject<StorageStateEnum>>) => res.body));
  }

  deleteStorage(): Observable<any | string> {
    return this.authData.pipe(
      switchMap(([user, roles]) => {
        const isAdmin = hasAdminRole(roles);
        const isSingleUser = hasSingleUserRole(roles);

        if (isAdmin) {
          return this.deleteStorageAdmin(user.DomainId);
        }

        if (isSingleUser) {
          return this.deleteStorageSingleUser(user.Id);
        }

        return this.errorRole$;
      })
    );
  }

  private deleteStorageAdmin(id: string): Observable<any> {
    return this.odataDomains.CustomAction(id, 'DeleteStorage', null);
  }

  private deleteStorageSingleUser(id: string): Observable<any> {
    return this.odataSingleUsers.CustomAction(id, 'DeleteStorage', null);
  }

  resetAlternatePassword(payload: { Email: string }): Observable<void> {
    return this.authService.getAuthUser().pipe(
      switchMap((user) => {
        const odataService = this.odataFactory.CreateService(`/BackupUsers(${user.Id})/ResetAlternatePassword`);
        return this.http.post<void>(odataService.Query().GetUrl(), payload);
      })
    );
  }

  getExportSettings(): Observable<ExportSettings> {
    return this.authData.pipe(
      switchMap(([user, roles]) => {
        const isAdmin = hasAdminRole(roles);
        const isDomainUser = hasDomainUserRole(roles);
        const isSingleUser = hasSingleUserRole(roles);

        if (isAdmin || isDomainUser) {
          return this.getExportSettingsCommon(user.DomainId);
        }

        if (isSingleUser) {
          return this.getExportSettingsCommon(user.Id);
        }

        return of(null) as Observable<ExportSettings>;
      })
    );
  }

  private getExportSettingsCommon(id: string): Observable<ExportSettings> {
    return this.odataExportSettings.Get(id).Exec();
  }

  setExportSettings(payload: ExportSettings): Observable<ExportSettings | string> {
    return this.authData.pipe(
      switchMap(([user, roles]) => {
        const isAdmin = hasAdminRole(roles);
        const isSingleUser = hasSingleUserRole(roles);

        if (isAdmin) {
          return this.setExportSettingsCommon(user.DomainId, payload);
        }

        if (isSingleUser) {
          return this.setExportSettingsCommon(user.Id, payload);
        }

        return this.errorRole$;
      })
    );
  }

  private setExportSettingsCommon(id: string, payload: ExportSettings): Observable<ExportSettings> {
    return this.odataExportSettings.Put<ExportSettings>(payload, id).Exec();
  }
}
