<table class="table border">
  <colgroup>
    <col style="width: 85%;">
    <col style="width: 15%;">
  </colgroup>
  <thead>
    <tr class="background-light">
      <th>{{ moduleUsers + ':sidepanel.grid.alternateAccount' | i18next: { format: 'title' } }}</th>
      <th class="text-right py-2">
        <mbs-switcher [labelPosition]="'left'"
                      [statusPosition]="'right'"
                      [showStatus]="true"
                      [(ngModel)]="alternateSelected"
                      (change)="useAlternateSwitcherChange($event.target.checked)"></mbs-switcher>
      </th>
    </tr>
  </thead>
  <tbody>
    <tr [ngbCollapse]="!alternateSelected">
      <td colspan="2"
          *ngIf="!alternateEmailInfo; else editForms">
        <form [formGroup]="addForm"
              class="py-3">
          <mbs-form-group>
            <mbs-input [label]="moduleUsers + ':sidepanel.label.email' | i18next: { format: 'title' }"
                       formControlName="Email"
                       [boldLabel]="true"
                       [size]="MbsSize.sm"
                       [labelClasses]="'text-' + MbsSize.sm"
                       type="email"></mbs-input>
          </mbs-form-group>

          <mbs-form-group>
            <mbs-input [label]="moduleUsers + ':sidepanel.label.password' | i18next: { format: 'title' }"
                       formControlName="Password"
                       [boldLabel]="true"
                       [size]="MbsSize.sm"
                       [labelClasses]="'text-' + MbsSize.sm"
                       (buttonClick)="handleChangePasswordType($event)"
                       [appendButtons]="[
                          {
                            id: 'append-password',
                            type: 'secondary',
                            icon: getAppendButtonsIcon(passwordType$ | async),
                            btnType: 'button',
                            loading: false,
                            disabled: false
                          }
                        ]"
                       [type]="passwordType$ | async"></mbs-input>
          </mbs-form-group>

          <mbs-form-group class="mb-0">
            <mbs-input [label]="moduleUsers + ':sidepanel.label.confirmPassword' | i18next: { format: 'title' }"
                       formControlName="ConfirmPassword"
                       [boldLabel]="true"
                       [size]="MbsSize.sm"
                       [labelClasses]="'text-' + MbsSize.sm"
                       (buttonClick)="handleChangePasswordType($event)"
                       [appendButtons]="[
                          {
                            id: 'append-confirm-password',
                            type: 'secondary',
                            icon: getAppendButtonsIcon(confirmPasswordType$ | async),
                            btnType: 'button',
                            loading: false,
                            disabled: false
                          }
                        ]"
                       [type]="confirmPasswordType$ | async"></mbs-input>
          </mbs-form-group>
        </form>
      </td>

      <ng-template #editForms>
        <td colspan="2">
          <div class="py-3">
            <div class="row mb-2"
                 *ngIf="alternateEmailInfo?.Email && !alternateEmailInfo?.Verified">
              <div class="col">
                <span class="text-danger">
                  {{ moduleUsers + ':sidepanel.error.pleaseCheck' | i18next: { format: 'capitalize' } }}.
                </span>
                <br>
                <span class="text-danger">
                  {{ moduleUsers + ':sidepanel.error.clickTheLink' | i18next: { format: 'capitalize' } }}
                </span>
              </div>
            </div>
            <div class="row align-items-center mb-2">
              <div class="col-5">
                {{ moduleUsers + ':sidepanel.label.alternateEmail' | i18next: { format: 'title' } }}:
              </div>
              <div class="col text-right">
                <mbs-button [isCtrl]="true"
                            type="primary"
                            [size]="MbsSize.sm"
                            (click)="openChangeEmailModal()">
                  <span *ngIf="alternateEmailInfo?.Verified"
                        [ngbTooltip]="moduleUsers + ':sidepanel.button.emailVerified' | i18next: { format: 'title' }"
                        class="ico ico-success text-success mr-1">
                  </span>
                  <b class="mr-2">{{ alternateEmailInfo?.Email }}</b>
                  <span class="fa fa-pencil"
                        container="body"
                        [ngbTooltip]="moduleUsers + ':sidepanel.button.edit' | i18next: { format: 'title' }"></span>
                </mbs-button>
              </div>
            </div>
            <div class="row mb-2">
              <div class="col">
                <mbs-button customClasses="p-0"
                            [isCtrl]="true"
                            [size]="MbsSize.sm"
                            (click)="openChangePasswordModal()">
                  {{ moduleUsers + ':sidepanel.button.changePassword' | i18next: { format: 'title' } }}
                </mbs-button>
              </div>
            </div>

            <form [formGroup]="twoFactorForm">
              <mbs-form-group class="mb-0">
                <div class="row align-items-center justify-content-between">
                  <div class="col-auto nowrap">
                    <div class="form-inline">
                      {{ moduleUsers + ':sidepanel.label.verification' | i18next: { format: 'title' } }}
                      <span class="tooltip-btn ico ico-InfoCircle text-primary"
                            [ngbPopover]="twoStepInfoPopover"
                            triggers="mouseenter:mouseleave"
                            placement="left"
                            container="body"></span>
                    </div>
                  </div>
                  <div class="col-auto text-right">
                    <mbs-switcher formControlName="enabled"
                                  [labelPosition]="'left'"
                                  [statusPosition]="'right'"
                                  [showStatus]="true"
                                  (change)="twoStepEnable($event.target.checked)"></mbs-switcher>
                  </div>
                </div>
              </mbs-form-group>

              <ng-container *ngIf="showTwoStepResponseCode">
                <mbs-form-group class="mt-2">
                  <div class="row">
                    <div class="col-5">
                      {{ moduleUsers + ':sidepanel.label.secret' | i18next: { format: 'title' } }}:
                    </div>
                    <div class="col">
                      {{ secret }}
                      <qrcode *ngIf="twoStepQRURL"
                              [scale]="4"
                              [margin]="0"
                              [width]="120"
                              [allowEmptyString]="true"
                              [elementType]="'svg'"
                              [qrdata]="twoStepQRURL"
                              [alt]="moduleUsers + ':sidepanel.label.altTwoStepQRUrl' | i18next: { format: 'capitalize' }"
                              [errorCorrectionLevel]="'M'"></qrcode>
                    </div>
                  </div>
                </mbs-form-group>
                <mbs-form-group>
                  <mbs-input [label]="moduleUsers + ':sidepanel.label.enterTheNumber' | i18next: { format: 'title' }"
                             [size]="MbsSize.sm"
                             [labelClasses]="'text-' + MbsSize.sm"
                             type="number"
                             formControlName="responseCode"></mbs-input>
                </mbs-form-group>
              </ng-container>

              <mbs-form-group *ngIf="twoStepPasswordShow"
                              [ngClass]="{'mt-2': !showTwoStepResponseCode}"
                              class="mb-0">
                <mbs-input [label]="moduleUsers + ':sidepanel.label.alternatePassword' | i18next: { format: 'title' }"
                           [size]="MbsSize.sm"
                           [labelClasses]="'text-' + MbsSize.sm"
                           (buttonClick)="handleChangePasswordType($event)"
                           [appendButtons]="[
                              {
                                id: 'append-password',
                                type: 'secondary',
                                icon: getAppendButtonsIcon(passwordType$ | async),
                                btnType: 'button',
                                loading: false,
                                disabled: false
                              }
                            ]"
                           [type]="passwordType$ | async"
                           formControlName="password"
                           type="password"></mbs-input>
              </mbs-form-group>
            </form>
          </div>
        </td>
      </ng-template>

    </tr>
  </tbody>
</table>

<ng-template #twoStepInfoPopover>
  <h6 class="mb-2">{{ moduleUsers + ':sidepanel.twoStepPopover.setUp' | i18next: { format: 'capitalize' } }}</h6>

  <p class="mb-2">{{ moduleUsers + ':sidepanel.twoStepPopover.pleaseFollow' | i18next: { format: 'capitalize' } }}</p>
  <ul class="mb-2">
    <li>{{ moduleUsers + ':sidepanel.twoStepPopover.onOurPhone' | i18next: { format: 'capitalize' } }}</li>
    <li>{{ moduleUsers + ':sidepanel.twoStepPopover.searchFor' | i18next: { format: 'capitalize' } }}</li>
    <li>{{ moduleUsers + ':sidepanel.twoStepPopover.downloadAnd' | i18next: { format: 'capitalize' } }}</li>
  </ul>

  <p class="mb-2">{{ moduleUsers + ':sidepanel.twoStepPopover.nowOpenAnd' | i18next: { format: 'capitalize' } }}</p>
  <ul class="mb-2">
    <li>{{ moduleUsers + ':sidepanel.twoStepPopover.inGoogle' | i18next: { format: 'capitalize' } }}</li>
    <li>{{ moduleUsers + ':sidepanel.twoStepPopover.selectScan' | i18next: { format: 'capitalize' } }}</li>
    <li>{{ moduleUsers + ':sidepanel.twoStepPopover.useYourPhone' | i18next: { format: 'capitalize' } }}</li>
  </ul>

  <p class="mb-1">{{ moduleUsers + ':sidepanel.twoStepPopover.onceYouHave' | i18next: { format: 'capitalize' } }}</p>
</ng-template>
