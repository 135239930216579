import { inject, NgModule } from '@angular/core';
import { ActivatedRouteSnapshot, Route, RouterModule, RouterStateSnapshot, Routes } from '@angular/router';
import { ProviderPath, RoutePath } from '@common';
import { AppLayoutComponent } from '@common/_layout/app-layout/app-layout.component';
import { AuthCallbackComponent } from '@common/components/login/auth-callback/auth-callback.component';
import { LoginComponent } from '@common/components/login/login.component';
import { AuthGuard } from '@common/guards/auth.guard';
import { AvailableServicesGuard } from '@common/guards/available-services.guard';
import { DataChangeWatcherGuard } from '@common/guards/data-change-watcher.guard';
import { DomainUserGuard } from '@common/guards/domain-user.guard';
import { PageData, ServiceType } from '@common/models';
import { partialRoutingDataByTitle } from '@common/utils';
import { AccountComponent } from '@pages/account/component/account.component';
import { NotFoundComponent } from '@pages/not-found/component/not-found.component';
import { I18NEXT_NAMESPACE_RESOLVER } from 'angular-i18next';
import { I18_NAMESPACE_APPS_UI } from 'i18n';

const defaultRoutes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: RoutePath.Dashboard
  },
  {
    path: RoutePath.AuditLog,
    loadChildren: () => import('@pages/audit-log/audit-log.module').then((m) => m.AuditLogModule),
    data: {
      ...partialRoutingDataByTitle('Audit'),
      i18nextNamespaces: [I18_NAMESPACE_APPS_UI.audit, I18_NAMESPACE_APPS_UI.policy, I18_NAMESPACE_APPS_UI.reports]
    } as PageData,
    resolve: {
      i18next: I18NEXT_NAMESPACE_RESOLVER
    }
  },
  {
    path: RoutePath.Dashboard,
    loadChildren: () => import('@pages/dashboard/dashboard.module').then((m) => m.DashboardModule),
    data: {
      ...partialRoutingDataByTitle('Dashboard'),
      i18nextNamespaces: [I18_NAMESPACE_APPS_UI.chart, I18_NAMESPACE_APPS_UI.dashboard]
    } as PageData,
    resolve: {
      i18next: I18NEXT_NAMESPACE_RESOLVER
    }
  },
  {
    path: RoutePath.Users,
    canActivate: [(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => inject(AvailableServicesGuard).canActivate(next, state)],
    loadChildren: () => import('@pages/users/user.module').then((m) => m.UserModule),
    data: {
      ...partialRoutingDataByTitle(ServiceType.Users),
      i18nextNamespaces: [I18_NAMESPACE_APPS_UI.users, I18_NAMESPACE_APPS_UI.policy]
    } as PageData,
    resolve: {
      i18next: I18NEXT_NAMESPACE_RESOLVER
    }
  },
  {
    path: RoutePath.Payments,
    canActivate: [(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => inject(AvailableServicesGuard).canActivate(next, state)],
    loadChildren: () => import('@pages/payments/payments.module').then((m) => m.PaymentsModule),
    data: {
      ...partialRoutingDataByTitle(ServiceType.Payments),
      i18nextNamespaces: [I18_NAMESPACE_APPS_UI.payments]
    } as PageData,
    resolve: {
      i18next: I18NEXT_NAMESPACE_RESOLVER
    }
  },
  {
    path: RoutePath.Mail,
    canActivate: [(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => inject(AvailableServicesGuard).canActivate(next, state)],
    canDeactivate: [() => inject(DataChangeWatcherGuard).canDeactivate()],
    loadChildren: () => import('@pages/mail/mail.module').then((m) => m.MailModule),
    data: {
      ...partialRoutingDataByTitle(ServiceType.Mail),
      i18nextNamespaces: [I18_NAMESPACE_APPS_UI.mail, I18_NAMESPACE_APPS_UI.dontHavePermission]
    } as PageData,
    resolve: {
      i18next: I18NEXT_NAMESPACE_RESOLVER
    }
  },
  {
    path: RoutePath.Drive,
    canActivate: [(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => inject(AvailableServicesGuard).canActivate(next, state)],
    loadChildren: () => import('@pages/drive/drive.module').then((m) => m.DriveModule),
    data: {
      ...partialRoutingDataByTitle(ServiceType.Drive),
      i18nextNamespaces: [I18_NAMESPACE_APPS_UI.drive, I18_NAMESPACE_APPS_UI.dontHavePermission]
    } as PageData,
    resolve: {
      i18next: I18NEXT_NAMESPACE_RESOLVER
    }
  },
  {
    path: RoutePath.History,
    canActivate: [(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => inject(AvailableServicesGuard).canActivate(next, state)],
    loadChildren: () => import('@pages/history/history.module').then((m) => m.HistoryModule),
    data: {
      ...partialRoutingDataByTitle('History'),
      i18nextNamespaces: [I18_NAMESPACE_APPS_UI.chart, I18_NAMESPACE_APPS_UI.history]
    } as PageData,
    resolve: {
      i18next: I18NEXT_NAMESPACE_RESOLVER
    }
  },
  {
    path: RoutePath.SharedDrives,
    canActivate: [(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => inject(AvailableServicesGuard).canActivate(next, state)],
    loadChildren: () => import('@pages/team-drive/team-drive.module').then((m) => m.TeamDriveModule),
    data: {
      ...partialRoutingDataByTitle(ServiceType.TeamDrives),
      i18nextNamespaces: [I18_NAMESPACE_APPS_UI.drive, I18_NAMESPACE_APPS_UI.dontHavePermission]
    } as PageData,
    resolve: {
      i18next: I18NEXT_NAMESPACE_RESOLVER
    }
  },
  {
    path: RoutePath.Sharepoint,
    canActivate: [(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => inject(AvailableServicesGuard).canActivate(next, state)],
    loadChildren: () => import('@pages/share-point/share-point.module').then((m) => m.SharePointModule),
    data: {
      ...partialRoutingDataByTitle(ServiceType.SharePoint),
      i18nextNamespaces: [I18_NAMESPACE_APPS_UI.drive, I18_NAMESPACE_APPS_UI.dontHavePermission]
    } as PageData,
    resolve: {
      i18next: I18NEXT_NAMESPACE_RESOLVER
    }
  },
  {
    path: RoutePath.Teams,
    canActivate: [(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => inject(AvailableServicesGuard).canActivate(next, state)],
    loadChildren: () => import('@pages/teams/teams.module').then((m) => m.TeamsPageModule),
    data: {
      ...partialRoutingDataByTitle(ServiceType.Teams),
      i18nextNamespaces: [I18_NAMESPACE_APPS_UI.drive, I18_NAMESPACE_APPS_UI.dontHavePermission]
    } as PageData,
    resolve: {
      i18next: I18NEXT_NAMESPACE_RESOLVER
    }
  },
  {
    path: RoutePath.Contacts,
    canActivate: [(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => inject(AvailableServicesGuard).canActivate(next, state)],
    loadChildren: () => import('@pages/contacts/contacts.module').then((m) => m.ContactsModule),
    data: {
      ...partialRoutingDataByTitle(ServiceType.Contacts),
      i18nextNamespaces: [I18_NAMESPACE_APPS_UI.contacts, I18_NAMESPACE_APPS_UI.dontHavePermission]
    } as PageData,
    resolve: {
      i18next: I18NEXT_NAMESPACE_RESOLVER
    }
  },
  {
    path: RoutePath.Calendar,
    canActivate: [(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => inject(AvailableServicesGuard).canActivate(next, state)],
    loadChildren: () => import('@pages/calendar/calendar.module').then((m) => m.CalendarModule),
    data: {
      ...partialRoutingDataByTitle(ServiceType.Calendar),
      i18nextNamespaces: [I18_NAMESPACE_APPS_UI.calendar, I18_NAMESPACE_APPS_UI.dontHavePermission]
    } as PageData,
    resolve: {
      i18next: I18NEXT_NAMESPACE_RESOLVER
    }
  },
  {
    path: RoutePath.RetentionPolicy,
    loadChildren: () => import('@pages/retention-policy/retention-policy.module').then((m) => m.RetentionPolicyModule),
    data: {
      ...partialRoutingDataByTitle('Retention Policy'),
      i18nextNamespaces: [I18_NAMESPACE_APPS_UI.policy]
    } as PageData,
    resolve: {
      i18next: I18NEXT_NAMESPACE_RESOLVER
    }
  },
  {
    path: RoutePath.TaskManager,
    loadChildren: () => import('@pages/task-manager/task-manager.module').then((m) => m.TaskManagerModule),
    data: {
      ...partialRoutingDataByTitle('Task Manager'),
      i18nextNamespaces: [I18_NAMESPACE_APPS_UI.tasks]
    } as PageData,
    resolve: {
      i18next: I18NEXT_NAMESPACE_RESOLVER
    }
  },
  {
    path: RoutePath.ExportResults,
    loadChildren: () => import('@pages/export-results/export-results.module').then((m) => m.ExportResultsModule),
    data: {
      ...partialRoutingDataByTitle(ServiceType.Export),
      i18nextNamespaces: [I18_NAMESPACE_APPS_UI.results]
    } as PageData,
    resolve: {
      i18next: I18NEXT_NAMESPACE_RESOLVER
    }
  },
  {
    path: RoutePath.Reports,
    loadChildren: () => import('@pages/reports/reports.module').then((m) => m.ReportsModule),
    data: {
      ...partialRoutingDataByTitle('Reports'),
      i18nextNamespaces: [I18_NAMESPACE_APPS_UI.reports]
    } as PageData,
    resolve: {
      i18next: I18NEXT_NAMESPACE_RESOLVER
    }
  },
  {
    path: RoutePath.BackupHistory,
    canActivate: [() => inject(DomainUserGuard).canActivate()],
    loadChildren: () => import('@pages/backup-history/backup-history.module').then((m) => m.BackupHistoryModule),
    data: {
      ...partialRoutingDataByTitle('Backup History'),
      i18nextNamespaces: [I18_NAMESPACE_APPS_UI.backupHistory]
    } as PageData,
    resolve: {
      i18next: I18NEXT_NAMESPACE_RESOLVER
    }
  },
  {
    path: RoutePath.SkipItems,
    loadChildren: () => import('@pages/skip-items/skip-items.module').then((m) => m.SkipItemsModule),
    data: {
      ...partialRoutingDataByTitle('Skipped Items History'),
      i18nextNamespaces: [I18_NAMESPACE_APPS_UI.skipItems]
    } as PageData,
    resolve: {
      i18next: I18NEXT_NAMESPACE_RESOLVER
    }
  },
  {
    path: RoutePath.Help,
    loadChildren: () => import('@pages/help/help.module').then((m) => m.HelpModule),
    data: { ...partialRoutingDataByTitle('Help') } as PageData
  },
  {
    path: RoutePath.NotFound,
    component: NotFoundComponent
  }
];

const getSubsystemRoute = (path: string) => {
  const route: Route = {
    path: path,
    children: [
      {
        path: RoutePath.AuthCallback,
        data: { ...partialRoutingDataByTitle('Auth Callback') },
        component: AuthCallbackComponent,
        resolve: {
          i18next: I18NEXT_NAMESPACE_RESOLVER
        }
      },
      {
        path: '',
        canActivate: [(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => inject(AuthGuard).canActivate(next, state)],
        component: AppLayoutComponent,
        children: defaultRoutes
      },
      {
        path: '**',
        redirectTo: `/${path}/${RoutePath.NotFound}`
      }
    ]
  };

  return route;
};

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: RoutePath.Login
  },
  {
    path: RoutePath.Account,
    component: AccountComponent,
    data: { ...partialRoutingDataByTitle('Account') },
    resolve: {
      i18next: I18NEXT_NAMESPACE_RESOLVER
    }
  },
  {
    path: RoutePath.Login,
    component: LoginComponent,
    data: { ...partialRoutingDataByTitle('Login') },
    resolve: {
      i18next: I18NEXT_NAMESPACE_RESOLVER
    }
  },
  { ...getSubsystemRoute(ProviderPath.Office) },
  { ...getSubsystemRoute(ProviderPath.Google) },
  { ...getSubsystemRoute(ProviderPath.Alternate) },
  {
    path: '**',
    redirectTo: RoutePath.Login
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
