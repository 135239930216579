import { Injectable } from '@angular/core';
import { RetentionPolicyOdata, ServiceTypeByAPIEnum } from '@common/models';
import { ODataPagedResult } from '@common/odata';
import { ServiceTypeToDisplayNamePipe } from '@common/pipes';
import { RetentionPolicyService } from '@common/services';
import { RetentionPolicyTagsEnum, RetentionPolicyTagsMap, getNestedObjectByPath, getUniqueListByProp } from '@common/services/smart-search';
import SmartSearchTemplatesBase from '@common/services/smart-search/smart-search-template-base';
import { FilterOptions, getFilterByContains, getFilterByEq, getFilterByEqFromEnum } from '@common/utils/functions/search';
import { I18NextPipe } from 'angular-i18next';
import { ModelTemplate, SmartSearchState, naturalSort } from 'mbs-ui-kit';
import { Observable, asapScheduler, scheduled } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class SmartSearchModelTemplateRetentionPolicyService extends SmartSearchTemplatesBase {
  readonly #serviceType = RetentionPolicyTagsMap.get(RetentionPolicyTagsEnum.serviceType);
  readonly #policyName = RetentionPolicyTagsMap.get(RetentionPolicyTagsEnum.policyName);
  readonly #legalHold = RetentionPolicyTagsMap.get(RetentionPolicyTagsEnum.legalHold);

  readonly #optionYes = this.i18nPipe.transform('common.yes', { format: 'title' });
  readonly #optionNo = this.i18nPipe.transform('common.no', { format: 'title' });

  private readonly retentionPolicy$: Observable<ODataPagedResult<RetentionPolicyOdata>>;
  private readonly serviceTypeToDisplayNamePipe: ServiceTypeToDisplayNamePipe = new ServiceTypeToDisplayNamePipe();

  constructor(private retentionPolicyService: RetentionPolicyService, private i18nPipe: I18NextPipe) {
    super();
    this.retentionPolicy$ = retentionPolicyService.getPolicies();
  }

  public readonly NameTag: ModelTemplate<RetentionPolicyOdata> = {
    tag: this.#policyName.tag,
    items: (state: SmartSearchState): Observable<RetentionPolicyOdata[]> => {
      const term = state.leftCaretValue;
      const options: FilterOptions = {
        model: [{ value: term }],
        prop: this.#policyName.prop
      };

      this.retentionPolicyService.filter = term ? getFilterByContains(options) : '';

      return scheduled(
        this.retentionPolicy$.pipe(
          map((res) => res.data),
          map((policies) => {
            const filtered = policies.filter((policy) => policy.Name.toLowerCase().includes(term));
            const uniqueList = getUniqueListByProp(filtered, this.#policyName.prop).slice(0, 10);

            return uniqueList.sort((a, b) =>
              naturalSort(
                getNestedObjectByPath(a, this.#policyName.prop).toLowerCase(),
                getNestedObjectByPath(b, this.#policyName.prop).toLowerCase()
              )
            );
          })
        ),
        asapScheduler
      ) as Observable<RetentionPolicyOdata[]>;
    },
    itemFormatter: ({ Name }): string => Name,
    addGroupBrackets: true
  };

  public readonly TypeTag: ModelTemplate<RetentionPolicyOdata> = {
    tag: this.#serviceType.tag,
    items: (state: SmartSearchState): Observable<RetentionPolicyOdata[]> => {
      const term = state.leftCaretValue;
      const options: FilterOptions = {
        model: [{ value: term }],
        prop: this.#serviceType.prop,
        enumItems: ServiceTypeByAPIEnum
      };

      this.retentionPolicyService.filter = term ? getFilterByEqFromEnum(options) : '';

      return scheduled(
        this.retentionPolicy$.pipe(
          map((res) => res.data),
          map((policies) => {
            const filtered = policies.filter((policy: RetentionPolicyOdata) => policy.ServiceType.toLowerCase().includes(term));
            const uniqueList = getUniqueListByProp(filtered, this.#serviceType.prop).slice(0, 10);

            return uniqueList.sort((a, b) =>
              naturalSort(
                getNestedObjectByPath(a, this.#serviceType.prop).toLowerCase(),
                getNestedObjectByPath(b, this.#serviceType.prop).toLowerCase()
              )
            );
          })
        ),
        asapScheduler
      ) as Observable<RetentionPolicyOdata[]>;
    },
    itemFormatter: ({ ServiceType }): string => this.serviceTypeToDisplayNamePipe.transform(ServiceType),
    addGroupBrackets: true
  };

  public readonly LegalHoldTag: ModelTemplate<RetentionPolicyOdata> = {
    tag: this.#legalHold.tag,
    items: (state: SmartSearchState): Observable<RetentionPolicyOdata[]> => {
      const term = state.leftCaretValue;
      const options: FilterOptions = {
        model: [{ value: term }],
        prop: this.#legalHold.prop
      };

      this.retentionPolicyService.filter = term ? getFilterByEq(options, false) : '';

      return scheduled(
        this.retentionPolicy$.pipe(
          map((res) => res.data),
          map((policies) => {
            const filtered = policies.filter((policy: RetentionPolicyOdata) => String(policy.HasLegalHold).includes(term));
            const uniqueList = getUniqueListByProp(filtered, this.#legalHold.prop).slice(0, 10);

            return uniqueList.sort((a, b) =>
              naturalSort(
                getNestedObjectByPath(a, this.#legalHold.prop).toLowerCase(),
                getNestedObjectByPath(b, this.#legalHold.prop).toLowerCase()
              )
            );
          })
        ),
        asapScheduler
      ) as Observable<RetentionPolicyOdata[]>;
    },
    itemFormatter: ({ HasLegalHold }): string => (HasLegalHold ? this.#optionYes : this.#optionNo),
    addGroupBrackets: true
  };
}
