import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BOOLEAN_OPERATOR, SINGLE_QUOTES_REGEX } from '@common';
import { CalendarEventModel, CalendarGroupModel, CalendarItemModel, OdataObject } from '@common/models';
import { ODataService, ODataServiceFactory } from '@common/odata';
import { CalendarServiceTag, CalendarServiceTagsMap } from '@common/services/smart-search/calendar';
import { filterByWords, hasActionsQueue } from '@common/utils';
import { FilterOptions, containsWrapper, getFilterByContains } from '@common/utils/functions/search';
import { I18NextPipe } from 'angular-i18next';
import { SmartSearchModel, SmartSearchModelField } from 'mbs-ui-kit';
import { BehaviorSubject, Observable } from 'rxjs';
import { finalize, map, shareReplay } from 'rxjs/operators';

@Injectable()
export class CalendarOdataService {
  #userId: string;
  #keys: string[];

  readonly #event = CalendarServiceTagsMap.get(CalendarServiceTag.Event);
  // readonly #description = CalendarServiceTagsMap.get(CalendarServiceTag.Descr);

  private odataUsersService: ODataService<any>;
  private odataCalendarGroupService: ODataService<CalendarGroupModel>;
  private odataCalendarEventsService: ODataService<CalendarEventModel>;
  private odataCalendarAttachmentsService: ODataService<string>;
  private odataMethods: ODataService<any>;
  private _loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public requestPending$: Observable<boolean>;

  set userId(id: string) {
    this.#userId = id;
  }

  set keys(ids: string[]) {
    this.#keys = ids;
  }

  get keys(): string[] {
    return this.#keys;
  }

  constructor(private odataFactory: ODataServiceFactory, private http: HttpClient, private i18nPipe: I18NextPipe) {
    this.odataUsersService = odataFactory.CreateService('BackupUsers');
    this.odataCalendarGroupService = odataFactory.CreateService('CalendarGroups');
    this.odataCalendarEventsService = odataFactory.CreateService('CalendarEvents');
    this.odataCalendarAttachmentsService = odataFactory.CreateService('CalendarAttachments');
    this.odataMethods = odataFactory.CreateService('');

    this.requestPending$ = this._loading$.pipe(hasActionsQueue(), shareReplay(1));
  }

  hasAccessToEvents(userId: string): Observable<any> {
    this._loading$.next(true);
    const url = this.odataUsersService.Get(userId).GetUrl() + '/Touch ';
    return this.http.get<OdataObject<string>>(url).pipe(finalize(() => this._loading$.next(false)));
  }

  getGroups(): Observable<CalendarGroupModel[]> {
    this._loading$.next(true);
    return this.odataUsersService.ItemProperty<OdataObject<CalendarGroupModel[]>>(this.#userId, 'CalendarGroups').pipe(
      map((result) => result.value),
      finalize(() => this._loading$.next(false))
    );
  }

  getItems(groupId: string): Observable<CalendarItemModel[]> {
    this._loading$.next(true);
    return this.odataCalendarGroupService.ItemProperty<OdataObject<CalendarItemModel[]>>(groupId, 'CalendarItems').pipe(
      map((result) => result.value),
      finalize(() => this._loading$.next(false))
    );
  }

  getEvents(startDate: Date, endDate: Date): Observable<CalendarEventModel[]> {
    const filter = `(RecurrenceRule ne null or (EndDate gt ${startDate.toISOString()} and StartDate lt ${endDate.toISOString()}))`;
    const odataUsersService = this.odataFactory.CreateService(`/BackupUsers(${this.#userId})/GetBulkCalendarEvents?$filter=${filter}`);

    this._loading$.next(true);
    return this.http.post(odataUsersService.Query().GetUrl(), { ItemIds: this.keys }).pipe(
      map((res: OdataObject<CalendarEventModel[]>) => res.value),
      finalize(() => this._loading$.next(false))
    );
  }

  getEventRevisions(eventId: string): Observable<CalendarEventModel[]> {
    return this.odataCalendarEventsService
      .ItemProperty<OdataObject<CalendarEventModel[]>>(eventId, 'Revisions')
      .pipe(map((result) => result.value));
  }

  searchEvents(filter: string): Observable<CalendarEventModel[]> {
    const odataUsersService = this.odataFactory.CreateService(`/BackupUsers(${this.#userId})/GetBulkCalendarEvents?$filter=${filter}`);

    this._loading$.next(true);
    return this.http.post(odataUsersService.Query().GetUrl(), { ItemIds: this.keys }).pipe(
      map((res: OdataObject<CalendarEventModel[]>) => res.value),
      finalize(() => this._loading$.next(false))
    );
  }

  updateFilter(searchObj: SmartSearchModel): string {
    const filter: string[] = [];

    // if (searchObj[this.#description.tag]) {
    //   const options: FilterOptions = {
    //     model: searchObj[this.#description.tag] as SmartSearchModelField[],
    //     prop: this.#description.prop
    //   };
    //
    //   filter.push(getFilterByContains(options));
    // }

    if (searchObj[this.#event.tag]) {
      const options: FilterOptions = {
        model: searchObj[this.#event.tag] as SmartSearchModelField[],
        prop: this.#event.prop
      };

      filter.push(getFilterByContains(options));
    }

    if (searchObj.words?.filter(Boolean)) {
      const term = filterByWords(searchObj).replace(SINGLE_QUOTES_REGEX, '');
      // const keys = [this.#event.prop, this.#description.prop];
      const keys = [this.#event.prop];
      const values = keys.map((k) => containsWrapper(k, term));

      filter.push('('.concat(values.join(` ${BOOLEAN_OPERATOR.or} `), ')'));
    }

    return filter.length > 0 ? filter.join(` ${BOOLEAN_OPERATOR.and} `) : '';
  }

  restoreAll(): Observable<any> {
    return this.odataMethods.CustomCollectionAction('RestoreAllCalendars', { UserId: this.#userId });
  }

  restoreGroup(Ids: string[]): Observable<any> {
    return this.odataMethods.CustomCollectionAction('RestoreCalendarGroups', { Ids });
  }

  restoreEvent(eventId: string): Observable<any> {
    this._loading$.next(true);
    return this.odataMethods
      .CustomCollectionAction('RestoreCalendarEvent', { EventId: eventId })
      .pipe(finalize(() => this._loading$.next(false)));
  }

  restoreCalendar(Ids: string[]): Observable<any> {
    return this.odataMethods.CustomCollectionAction('RestoreCalendars', { Ids });
  }

  deleteEvent(eventId: string, password: string): Observable<any> {
    this._loading$.next(true);
    return this.odataMethods
      .CustomCollectionAction('DeleteCalendarEvents', { Ids: [eventId], Password: password, WithAllRevisions: true })
      .pipe(finalize(() => this._loading$.next(false)));
  }

  deleteCalendar(calendarIds: string[], password: string): Observable<any> {
    this._loading$.next(true);
    return this.odataMethods
      .CustomCollectionAction('DeleteCalendarItems', { Ids: calendarIds, Password: password })
      .pipe(finalize(() => this._loading$.next(false)));
  }

  deleteGroup(groupIds: string[], password: string): Observable<any> {
    this._loading$.next(true);
    return this.odataMethods
      .CustomCollectionAction('DeleteCalendarGroups', { Ids: groupIds, Password: password })
      .pipe(finalize(() => this._loading$.next(false)));
  }
}
