import { Injectable } from '@angular/core';
import { COMPARISON_OPERATOR } from '@common';
import { DriveOdataItem } from '@common/models';
import { ODataPagedResult, ODataService, ODataServiceFactory } from '@common/odata';
import { SharedOdataService } from '@common/services';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class DriveTreeOdataService extends SharedOdataService<DriveOdataItem> {
  private readonly odataDriveItems: ODataService<any>;

  constructor(odataFactory: ODataServiceFactory) {
    super(odataFactory, 'DriveItems');
    this.odataDriveItems = odataFactory.CreateService('DriveItems');
    this.setDefaultOdataOptions();
  }

  fetchItems(): Observable<ODataPagedResult<DriveOdataItem>> {
    return this.fetchData(this.odataDriveItems);
  }

  updateCustomOptions(options: { key: string; value?: any }, isDelete = false): void {
    const { key, value } = options || {};

    if (isDelete) {
      this.removeCustomQueryOption(key);
    } else {
      this.addCustomQueryOption(key, value);
    }
  }

  setDefaultOdataOptions(): void {
    this.page = 1;
    this.pageSize = 100;
    this.orderBy = 'Type asc';
    this.filter = `Type ${COMPARISON_OPERATOR.eq} false`;
  }
}
