import { PolicyType } from '@common';
import { AutoActivationMode } from '@common/models/user';
import { Provider } from './domain.model';

export interface User {
  AddDate: string;
  AlternativeAccountExists: boolean;
  CalendarCnt: number;
  CalendarEnable: boolean;
  CalendarLastBackupStopReason: string;
  CalendarPolicyType: PolicyType;
  CalendarSize: number;
  ContactCnt: number;
  ContactsEnable: boolean;
  ContactLastBackupStopReason: string;
  ContactPolicyType: PolicyType;
  DomainId: string;
  DomainName: string;
  DriveCnt: number;
  DriveEnable: boolean;
  DriveLastBackupStopReason: null;
  DrivePolicyType: PolicyType;
  DriveSize: number;
  Email: string;
  EmailCnt: number;
  MailEnable: boolean;
  EmailLastBackupStopReason: null;
  MailPolicyType: PolicyType;
  EmailSize: number;
  ExistLicense: boolean;
  ExistRefreshToken: boolean;
  ExistRetentionPolicy: boolean;
  Id: string;
  InBackup: boolean;
  IsMainUser: boolean;
  IsPublicFolder: boolean;
  LastBackupDate: string;
  LastBackupService: string;
  MsgsCount: number;
  Name: string;
  NameEmail: string;
  PersonalSiteEnable: boolean;
  RestoreEnable: boolean;
  SignInEnable: boolean;
  TotalSize: number;
  UserProviderState: string;
  SharePointPolicyType: PolicyType;
  TeamDrivePolicyType: PolicyType;
  LastCalendarBackupDate: string;
  LastContactBackupDate: string;
  LastDriveBackupDate: string;
  LastEmailBackupDate: string;
  OrgUnitId: string | number;
  Provider: Provider;
}

export interface UsersServices {
  CalendarEnabled: boolean;
  CalendarOn: boolean;
  ContactsEnabled: boolean;
  ContactsOn: boolean;
  DriveEnabled: boolean;
  DriveOn: boolean;
  MailEnabled: boolean;
  MailOn: boolean;
}

export interface UsersPermissions {
  RestoreEnable: boolean;
  SignInEnable: boolean;
}

export interface BackupStatistic {
  Type: string;
  ItemCount: number;
  Size: number;
}

export interface DeleteBackup {
  UserIds: string[];
  Email: boolean;
  Drive: boolean;
  Contact: boolean;
  Calendar: boolean;
  TeamDrive: boolean;
  SharePoint: boolean;
  Password: string;
}

export interface TasksStateResponse {
  TasksStatistic: {
    Id: number;
    DomainId: string | null;
    UserId: string | null;
    WorkerId: string | null;
    Name: string;
    UserName: string;
    BatchId: string;
    ErrorComment: string;
    TotalItemsCount: number;
    ItemsCountByStates: { [key: string]: number };
    DateAdded: Date | string;
    DateModified: Date | string;
    ServiceType: ServiceType;
    OperationType: OperationType;
    Duration: Date;
    LastDuration: Date;
    DurationStr: string;
    LastDurationStr: string;
    QueueDate: Date | string | null;
  }[];
  LastBackupDates: {
    MailLastBackupDate: string;
    DriveLastBackupDate: string;
    ContactsLastBackupDate: string;
    CalendarLastBackupDate: string;
    TeamDriveLastBackupDate: string;
    SharePointLastBackupDate: string;
  };
}

export interface AlternateEmail {
  Email: string;
  Verified: boolean;
  TwoStepEnabled: boolean;
}

export interface AddAlternateEmail {
  userId: string;
  email: string;
  password: string;
  confirmPassword: string;
}

export interface AddAlternateEmailCamelCase {
  UserId: string;
  Email: string;
  Password: string;
  ConfirmPassword: string;
}

export interface TwoFactor {
  password: string;
  enabled: boolean;
  responseCode: string;
}

export interface EditAlternate {
  editTwoStep?: { password: string; responseCode: string };
  deleteTwoStep?: { password: string };
}

export interface AzureGroup {
  AutoActivationMode?: AutoActivationMode;
  Id: string;
  Name: string;
  Order?: number;
}

export interface OrganizationalUnit {
  AutoActivationMode?: AutoActivationMode;
  DbId: string;
  Deleted: boolean;
  Path: string;
  HasChild: boolean;
  Id: string;
  Name: string;
  Order: number; // 0 (for 'Not in Units' only) or 1 (for others). Internal prop for sorting on BE
}

export enum AutoActiveActionType {
  All = 'all',
  EditAutoActivationMode = 'editAutoActivationMode'
}

export interface AutoActivationSettings {
  AutoActivationMode: AutoActivationMode;
}

export interface NewGroupSettings {
  AutoActivationMode: AutoActivationMode;
  Ids?: string[];
}

export enum ServiceType {
  Mail = 0,
  Drive = 1,
  Contacts = 2,
  Calendar = 3,
  SharePoint = 4,
  TeamDrives = 5,
  Users = 6,
  PublicFolder = 7,
  Export = 8,
  PersonalSite = 9,
  PublicFolderMail = 10,
  PublicFolderCalendar = 11,
  PublicFolderContact = 12,
  Teams = 13,
  CalculateProviderSize = 14,
  Payments = 15,
  History = 16,
  All = 255
}

export const ServiceTypeByAPIEnum = {
  Mail: {
    serviceType: ServiceType.Mail,
    displayName: 'Mail'
  },
  Drive: {
    serviceType: ServiceType.Drive,
    displayName: 'Drive'
  },
  Contacts: {
    serviceType: ServiceType.Contacts,
    displayName: 'Contacts'
  },
  Calendar: {
    serviceType: ServiceType.Calendar,
    displayName: 'Calendar'
  },
  SharePoint: {
    serviceType: ServiceType.SharePoint,
    displayName: 'SharePoint'
  },
  TeamDrives: {
    serviceType: ServiceType.TeamDrives,
    displayName: 'Shared Drives'
  },
  Users: {
    serviceType: ServiceType.Users,
    displayName: 'Users'
  },
  PublicFolder: {
    serviceType: ServiceType.PublicFolder,
    displayName: 'Public Folder'
  },
  Export: {
    serviceType: ServiceType.Export,
    displayName: 'Specified Services'
  },
  PersonalSite: {
    serviceType: ServiceType.PersonalSite,
    displayName: 'Personal Site'
  },
  PublicFolderMail: {
    serviceType: ServiceType.PublicFolderMail,
    displayName: 'Public Folder Mail'
  },
  PublicFolderCalendar: {
    serviceType: ServiceType.PublicFolderCalendar,
    displayName: 'Public Folder Calendar'
  },
  PublicFolderContact: {
    serviceType: ServiceType.PublicFolderContact,
    displayName: 'Public Folder Contact'
  },
  Teams: {
    serviceType: ServiceType.Teams,
    displayName: 'Teams'
  },
  CalculateProviderSize: {
    serviceType: ServiceType.CalculateProviderSize,
    displayName: 'Calculate Provider Size'
  },
  Payments: {
    serviceType: ServiceType.Payments,
    displayName: 'Payments'
  },
  History: {
    serviceType: ServiceType.History,
    displayName: 'History'
  },
  All: {
    serviceType: ServiceType.All,
    displayName: 'All'
  }
};

export enum OperationType {
  None = 0,
  Backup = 1,
  Restore = 2,
  Archive = 3,
  MarkingForDelete = 4,
  DeleteItems = 5,
  ProcessRetention = 6,
  DeleteFromStorage = 7,
  Sync = 8,
  Export = 9,
  ClearCustomer = 10,
  DeleteUser = 11,
  CalculateUsersProviderSize = 12,
  Test = 255
}

export const OperationTypeByAPIEnum = {
  None: {
    operationType: OperationType.None,
    displayName: 'None'
  },
  Backup: {
    operationType: OperationType.Backup,
    displayName: 'Backup'
  },
  Restore: {
    operationType: OperationType.Restore,
    displayName: 'Restore'
  },
  Archive: {
    operationType: OperationType.Archive,
    displayName: 'Archive'
  },
  MarkingForDelete: {
    operationType: OperationType.MarkingForDelete,
    displayName: 'Flagging for deletion'
  },
  DeleteItems: {
    operationType: OperationType.DeleteItems,
    displayName: 'Deleting items'
  },
  ProcessRetention: {
    operationType: OperationType.ProcessRetention,
    displayName: 'Retention policy'
  },
  DeleteFromStorage: {
    operationType: OperationType.DeleteFromStorage,
    displayName: 'Deleting data'
  },
  Sync: {
    operationType: OperationType.Sync,
    displayName: 'Sync'
  },
  Export: {
    operationType: OperationType.Export,
    displayName: 'Export'
  },
  ClearCustomer: {
    operationType: OperationType.ClearCustomer,
    displayName: 'Clearing customer data'
  },
  DeleteUser: {
    operationType: OperationType.DeleteUser,
    displayName: 'Deleting user'
  },
  CalculateUsersProviderSize: {
    operationType: OperationType.CalculateUsersProviderSize,
    displayName: 'Calculate Users Size'
  },
  Test: {
    operationType: OperationType.Test,
    displayName: ''
  }
};

export interface UserServiceBackupInfo {
  LastBackupDate: string;
  TotalSize: number;
}

export interface UserInfoForService extends UserServiceBackupInfo {
  Id: string;
  Name: string;
  Email: string;
}

export class ServiceFields {
  LastBackupDate = '';
  Size = '';
  constructor(serviceType: ServiceType) {
    if (serviceType == ServiceType.Mail) {
      this.LastBackupDate = 'LastEmailBackupDate';
      this.Size = 'EmailSize';
    }
    if (serviceType == ServiceType.Drive) {
      this.LastBackupDate = 'LastDriveBackupDate';
      this.Size = 'DriveSize';
    }
    if (serviceType == ServiceType.Calendar) {
      this.LastBackupDate = 'LastCalendarBackupDate';
      this.Size = 'CalendarSize';
    }
    if (serviceType == ServiceType.Contacts) {
      this.LastBackupDate = 'LastContactBackupDate';
      this.Size = '';
    }
  }

  filteredArrayValues(): string[] {
    return [this.LastBackupDate, this.Size].filter((i) => i !== '');
  }
}
