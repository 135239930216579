<mbs-form-group [formGroup]="form"
                *ngIf="!hidden"
                class="mb-0 text-sm">

  <mbs-alert [type]="MbsPopupType.info"
             [icon]="true">
    {{ 'exportToPST.alert.consideredRestoreJob_1' | i18next: { format: 'capitalize' } }} <br />
    {{ 'exportToPST.alert.consideredRestoreJob_2' | i18next: { format: 'capitalize' } }}
  </mbs-alert>

  <!-- Mail -->
  <ng-container formGroupName="mail">
    <mbs-checkbox formControlName="checked"
                  [label]="'exportToPST.label.mail' | i18next: { format: 'title' }"
                  [id]="'export-mail'"
                  [descriptionOutside]="false">
      <ng-template checkboxDescription>
        <mbs-radio [label]="'exportToPST.label.all' | i18next: { format: 'title' }"
                   [value]="false"
                   [(ngModel)]="isMailFilter"
                   [ngModelOptions]="{ standalone: true }"
                   (ngModelChange)="mailFilterChange($event)"
                   name="export-mails"
                   [disabled]="!mailCheckedControl.value"
                   [id]="'export-mail-all'">
        </mbs-radio>
        <mbs-radio [label]="'exportToPST.label.forCustomPeriod' | i18next: { format: 'title' }"
                   [value]="true"
                   [(ngModel)]="isMailFilter"
                   [ngModelOptions]="{ standalone: true }"
                   (ngModelChange)="mailFilterChange($event)"
                   name="export-mails"
                   [disabled]="!mailCheckedControl.value"
                   [id]="'export-mail-custom'"
                   [descriptionOutside]="false">
          <ng-template radioDescription>
            <div class="form-row"
                 formGroupName="filter"
                 *ngIf="isMailFilter">

              <div class="col">
                <mbs-datepicker formControlName="from"
                                [needSetCurrent]="true"
                                placement="bottom"
                                [disabled]="!mailCheckedControl.value"
                                [maxDate]="currentDay"
                                [labelSize]="MbsSize.sm"
                                [id]="'date-from'"></mbs-datepicker>
              </div>
              <div class="col-auto d-inline-flex align-items-center justify-content-center">&#x2015;</div>
              <div class="col">
                <mbs-datepicker formControlName="to"
                                [needSetCurrent]="true"
                                placement="bottom"
                                [disabled]="!mailCheckedControl.value"
                                [maxDate]="currentDay"
                                [labelSize]="MbsSize.sm"
                                [id]="'date-to'"></mbs-datepicker>
              </div>
              <div class="col-12 text-danger mt-2"
                   *ngIf="form.dirty && form.errors?.datepicker">
                {{ form.errors?.datepicker.message }}
              </div>
            </div>
          </ng-template>
        </mbs-radio>
      </ng-template>
    </mbs-checkbox>
  </ng-container>
  <!-- / Mail -->

  <!-- Contacts -->
  <ng-container formGroupName="contacts">
    <mbs-checkbox formControlName="checked"
                  [label]="'exportToPST.label.contacts' | i18next: { format: 'title' }"
                  [id]="'export-contacts'"></mbs-checkbox>
  </ng-container>
  <!-- / Contacts -->

  <!-- Calendar -->
  <ng-container formGroupName="calendar">
    <mbs-checkbox formControlName="checked"
                  [label]="'exportToPST.label.calendar' | i18next: { format: 'title' }"
                  [id]="'export-calendar'">
    </mbs-checkbox>
  </ng-container>
  <!-- / Calendar -->

</mbs-form-group>
