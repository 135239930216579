import {
  Component,
  ContentChild,
  ContentChildren,
  Directive,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { noop } from 'rxjs';
import { AdvancedSearchModel, Template } from '../advanced-search';
import { CheckboxComponent } from '../form/checkbox/checkbox.component';
import { SortEvent } from '../table-grid';
import { TableCellDirective } from '../table-grid/directives/table-cell.directive';
import { TableColumn } from '../table-grid/table/table.component';
import { BootstrapTheme, MbsSize } from '../utils';
import { ListSelectGroupCheckDirective } from './directives/list-select-group-check.directive';
import { ListSelectGroupHeaderDirective } from './directives/list-select-group-header.directive';
import { ListSelectGroupInnerItemDirective } from './directives/list-select-group-inner-item.directive';
import { ListSelectGroupModel } from './list-select-group-model';
import { ListSelectItem } from './list-select.model';

@Directive({
  selector: '[appendTopControlsLabel]'
})
export class AppendTopControlsLabel {
  constructor(public template: TemplateRef<any>) {}
}

@Directive({
  selector: '[prependTopControlsLabel]'
})
export class PrependTopControlsLabel {
  constructor(public template: TemplateRef<any>) {}
}

export const LIST_SELECT_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => ListSelectComponent),
  multi: true
};

@Component({
  selector: 'app-list-select,mbs-list-select',
  templateUrl: './list-select.component.html',
  providers: [LIST_SELECT_VALUE_ACCESSOR]
})
export class ListSelectComponent implements ControlValueAccessor, OnChanges, OnInit {
  @Input() headers: TableColumn[] = [];
  @Input() checkboxCellClass: string;
  @Input() checkboxCellWidth = 'calc(1rem + 17px)';
  @Input() tableContentClass: string;
  @Input() striped = false;
  @Input() hover = true;
  @Input() loading: boolean;
  @Input() bordered: boolean;
  @Input() myClassesTable: string;
  @Input() loaderType: BootstrapTheme;
  @Input() multipleSelect = true;
  @Input() noDataMessage: string;
  @Input() selectRowClass = '-selected';
  @Input() isNeedSelectRow = true;
  @Input() showTopControlsCheckbox = true;
  @Input() rowClasses = '';

  @Input() keepState = false;

  /**
   * Table size
   */
  @Input() size: MbsSize.sm | MbsSize.lg = null;

  /**
   * Key of `data` element. Value of that key will be returned in `selected` array on `save` event.
   */
  @Input() bindSelected = 'id';

  @Input() changeSortState: SortEvent;

  /**
   * Identifier DOM element for mapping label and input
   */
  @Input() id = 'listSelect';

  /**
   * Search input placeholder
   */
  @Input() placeholder = '';

  /**
   * Search input label
   */
  @Input() filterLabel = '';

  /**
   * component filters data only by this field(s). Filtering is on `OR` logic.
   */
  @Input() filterFields: string | string[];

  /**
   * Table height value. More info in table component.
   * @deprecated since `release/1.1` use `maxHeight`
   * @param {string | number} value
   */
  @Input() set tableHeight(value: string | number) {
    this.maxHeight = typeof value === 'number' ? `${value}px` : value;
  }

  /**
   * Function that is used in filtering method before the main filters
   */
  @Input() prefilterFn: (data: any[], filterTerm: string, advancedSearchFilters: any) => any[];

  /**
   * Binding source data.
   */
  @Input() data: ListSelectItem[] = [];

  /**
   * Mode in which to display list-select (can be table/list)
   */
  @Input() listType: 'table' | 'list' = 'list';

  /**
   * Use advanced search instead of default mbs-input
   */
  @Input() useAdvancedSearch = false;

  /**
   * Templates for advanced search field
   */
  @Input() searchTemplates: Template<any>[] = [];

  /**
   * Virtual scrolling
   */
  @Input() virtualScrolling = false;
  @Input() virtualItemSize = 50;
  @Input() virtualItemsNumber = 20;
  @Input() minVirtualScrollItemSize = 10;
  @Input() maxVirtualScrollItemSize = 40;
  @Input() virtualScrollItemSizeDivider = 10;
  @Input() minBufferPx = 1200;
  @Input() maxBufferPx = 1200;

  /**
   * term for filtering data
   */
  public filterTerm = '';
  /**
   * @ignore
   */
  public filtered: ListSelectItem[] = [];
  public checkedTop: boolean;
  public indeterminateTop: boolean;
  public advancedSearchFilters: AdvancedSearchModel = {};

  mySelectedGroups: ListSelectGroupModel[] = [];
  myCheckToggleSelectAllId = 'select-all-check';

  // otherwise get immediately push after `handleChangeItemChecked`
  // don't know how to do better

  private selectedFiltered: ListSelectItem[];

  /**
   * Same array as `selectedData` but consists of full data objects.
   */
  public get mySelectedData() {
    if (this.loadSource) {
      return this.data.filter((item) => this.selectedData.some((el) => el[this.bindSelected] === item[this.bindSelected]));
    }

    return this.data.filter((item) => this.selectedData.includes((item[this.bindSelected] || '').toString()));
  }

  public set mySelectedData(data: ListSelectItem[]) {
    let selectedData: ListSelectItem[];

    if (this.multipleSelect) {
      selectedData = this.data.filter((item) => data.some((el) => el[this.bindSelected] === item[this.bindSelected]));
    } else {
      selectedData = data.length ? [data[0]] : [];
    }

    if (!this.loadSource) {
      selectedData = selectedData.map<ListSelectItem>((item) => item[this.bindSelected].toString());
    }

    this.selectedData = selectedData;
  }

  get classesTable(): string {
    let listClass = this.listType === 'list' ? 'mbs-list-select_list' : 'mbs-list-select_table';
    listClass += this.myClassesTable ? ` ${this.myClassesTable}` : '';

    return listClass;
  }

  /**
   * If `true` table will render search
   */
  @Input() showSearch = true;

  /**
   * If `true` table will render headers
   */
  @Input() showHeaders = this.listType === 'table';

  /**
   * If `true` counter with selected items will be rendered
   */
  @Input() showCounter = true;

  /**
   * Label for selected items counter
   */
  @Input() counterLabel = 'Selected: ';

  /**
   * If setted it will render `ctrl` with `UnselectAll` on click
   */
  @Input() unselectLabel = '';

  @Input() selectAllIgnoreDisabled = false;
  /**
   * Max height of inner table content
   */
  @Input() maxHeight: string;

  /**
   * If showTopControls = true main control block will be shown above the table
   */
  @Input() showTopControls = false;
  @Input() topControlsLabel = false;
  @Input() topControlsLabelSelected = false;
  @Input() topControlsDescription = '';

  @Input() showCheckboxes = true;

  /**
   * If true - select row only if click on checkbox
   */
  @Input() selectOnlyOnCheckboxClick = false;

  @Input() invalid = false;

  @Input() bindDisabledValues: { key: string; value: any };
  /**
   * If true return selected items as source entity otherwise return by bindSelected key
   */
  @Input() loadSource = true;
  @Input() selectedData: ListSelectItem[] = [];

  /**
   * Save event on selected items
   */
  @Output() save: EventEmitter<any[]> = new EventEmitter<any[]>();

  /**
   * Sort event on all items
   */
  @Output() sort: EventEmitter<SortEvent> = new EventEmitter<SortEvent>();

  /**
   * Selected array change event
   */
  @Output() changeSelected: EventEmitter<any[]> = new EventEmitter<any[]>();

  @Output() scrolled: EventEmitter<null> = new EventEmitter<null>();
  @Output() scrollEnd: EventEmitter<Event> = new EventEmitter<Event>();
  @Output() scrolledIndexChange: EventEmitter<number> = new EventEmitter<number>();
  @Output() scroll: EventEmitter<Event> = new EventEmitter<Event>();

  @ContentChildren(TableCellDirective, { read: TemplateRef }) tableTemplates: QueryList<TemplateRef<any>>;

  @ContentChild(ListSelectGroupHeaderDirective, {
    static: false,
    read: TemplateRef
  })
  groupHeaderTemplate: TemplateRef<any>;
  @ContentChild(ListSelectGroupInnerItemDirective, {
    static: false,
    read: TemplateRef
  })
  groupInnerItemTemplate: TemplateRef<any>;

  @ContentChild(PrependTopControlsLabel, { static: true, read: PrependTopControlsLabel }) prependTopControlsLabel: PrependTopControlsLabel;
  @ContentChild(AppendTopControlsLabel, { static: true, read: AppendTopControlsLabel }) appendTopControlsLabel: AppendTopControlsLabel;

  @ViewChildren(ListSelectGroupCheckDirective, { read: CheckboxComponent }) groupChecks: QueryList<CheckboxComponent>;
  @ViewChild('checkSelectAll', { read: CheckboxComponent, static: false }) selectAllGroupsCheck: CheckboxComponent;

  changeFn: (_: any) => void;
  touchedFn: () => void;

  ngOnInit(): void {
    this.showTopControls && this.updateMainCheckboxData();

    if (this.loadSource) {
      this.updateSelectedData();
    }
  }

  updateSelectedData(): void {
    if (Array.isArray(this.selectedData) && this.selectedData.length !== 0) {
      let newSelected: ListSelectItem[];

      if (this.selectedData.every((item) => typeof item === 'string')) {
        newSelected = this.data.filter((item) => this.selectedData.includes(item[this.bindSelected].toString()));
      } else {
        newSelected = this.data.filter((item) => this.selectedData.some((value) => value[this.bindSelected] === item[this.bindSelected]));
      }

      this.selectedData = Array.from(newSelected);
      this.mySelectedData = Array.from(newSelected);
      this.changeSelected.emit(newSelected);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedData) this.updateMainCheckboxData();

    if (changes.isNeedSelectRow || changes.showCheckboxes) {
      this.showCheckboxes || this.isNeedSelectRow
        ? noop()
        : console.warn(
            `list-select with id "${this.id}" has props 'showCheckboxes' and 'isNeedSelectRow' setting to false.
            \nYou will never see selected items in UI. Please set one of these props to true to see selection.`
          );
    }

    if (changes.data) this.filtered = changes.data.currentValue as ListSelectItem[];
  }

  updateMainCheckboxData(): void {
    const selectedLength = this.selectedData.length;
    const filteredLength = this.filtered.length;

    this.checkedTop = selectedLength === filteredLength && selectedLength !== 0;
    this.indeterminateTop = selectedLength !== 0 && selectedLength !== filteredLength;
  }

  topControlsCheckboxChangeHandler(event: boolean): void {
    if (!event) {
      this.mySelectedData = [];
      this.handleChangeItemChecked([]);

      return;
    }

    const filtered = Array.from(this.filtered);

    this.mySelectedData = filtered;
    this.handleChangeItemChecked(filtered);
  }

  /**
   * Unselect all items
   */
  unselectAll(): void {
    this.handleChangeItemChecked([]);
  }

  handleChangeItemChecked(items: ListSelectItem[]): void {
    if (this.filterTerm) {
      if (this.multipleSelect) {
        this.setSelectedItemsIfFiltered(items);
      } else {
        if (this.selectedFiltered || items.length) this.mySelectedData = Array.from(items);

        this.selectedFiltered = Array.from(items);
      }
    } else {
      this.selectedFiltered = null;
      this.mySelectedData = Array.from(items);
    }

    this.changeSelected.emit(this.selectedData);
    this.updateMainCheckboxData();

    if (this.changeFn) this.changeFn(this.selectedData);
  }

  setSelectedItemsIfFiltered(data: ListSelectItem[]): void {
    const result: ListSelectItem[] = [];
    const isString = this.selectedData?.length && typeof this.selectedData[0] === 'string';
    const needAdd = data.filter(
      (el) =>
        this.selectedData.findIndex((i) =>
          isString ? el[this.bindSelected].toString() === i : el[this.bindSelected].toString() === i[this.bindSelected].toString()
        ) === -1
    );

    if (this.selectedFiltered) {
      const same = this.selectedFiltered.filter(
        (el) =>
          ~this.selectedData.findIndex((i) => {
            return isString ? el[this.bindSelected].toString() === i : el[this.bindSelected].toString() === i[this.bindSelected].toString();
          })
      );
      const needDel = same.filter(
        (el) => data.findIndex((i) => el[this.bindSelected].toString() === i[this.bindSelected].toString()) === -1
      );

      if (needDel.length) {
        const withoutNeedDel = this.mySelectedData.filter(
          (el) => needDel.findIndex((i) => el[this.bindSelected].toString() === i[this.bindSelected].toString()) === -1
        );

        result.push(...withoutNeedDel);
      } else result.push(...this.mySelectedData);
    } else result.push(...this.mySelectedData);

    result.push(...needAdd);
    this.selectedFiltered = Array.from(data);
    this.mySelectedData = Array.from(result);
  }

  writeValue(obj: ListSelectItem[]): void {
    this.mySelectedData = obj || [];
  }

  registerOnChange(fn: (value) => void): void {
    this.changeFn = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.touchedFn = fn;
  }

  updateFilters(event: AdvancedSearchModel): void {
    if (event) {
      this.advancedSearchFilters = event;
      this.filterTerm = event?.words.join(' ') || '';
    }

    this.setFilteredData();
  }

  searchChangeHandler(event): void {
    if (typeof event === 'string') this.setFilteredData();
  }

  filterByFieldsPredicate = (item: ListSelectItem): boolean => {
    if (!this.filterTerm) return true;

    if (Array.isArray(this.filterFields)) {
      return this.filterFields.some((field) =>
        item[field] ? (item[field] as string).toLowerCase().includes(this.filterTerm.toLowerCase()) : false
      );
    } else {
      return item[this.filterFields] ? (item[this.filterFields] as string).toLowerCase().includes(this.filterTerm.toLowerCase()) : false;
    }
  };

  setFilteredData(): void {
    this.filtered = this.filterTerm ? this.data.filter(this.filterByFieldsPredicate) : this.data;
  }
}
