import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { ExportResultsOdata, ServiceTypeByAPIEnum } from '@common/models';
import { ODataPagedResult } from '@common/odata';
import { ServiceTypeToDisplayNamePipe } from '@common/pipes';
import { ExportResultsService } from '@common/services';
import { ExportResultsTagsEnum, ExportResultsTagsMap, getCustomSizeItems, getUniqueListByProp } from '@common/services/smart-search';
import SmartSearchTemplatesBase from '@common/services/smart-search/smart-search-template-base';
import { FilterOptions, getFilterByContains, getFilterByDate, getFilterByEqFromEnum } from '@common/utils/functions/search';
import { cloneDeep } from 'lodash';
import { ModelTemplate, SmartSearchState } from 'mbs-ui-kit';
import { Observable, asapScheduler, scheduled } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable()
export class SmartSearchModelTemplateExportResultsService extends SmartSearchTemplatesBase {
  readonly #userName = ExportResultsTagsMap.get(ExportResultsTagsEnum.userName);
  readonly #fileName = ExportResultsTagsMap.get(ExportResultsTagsEnum.fileName);
  readonly #serviceType = ExportResultsTagsMap.get(ExportResultsTagsEnum.serviceType);
  readonly #dateFrom = ExportResultsTagsMap.get(ExportResultsTagsEnum.dateFrom);
  readonly #dateTo = ExportResultsTagsMap.get(ExportResultsTagsEnum.dateTo);
  readonly #sizeMore = ExportResultsTagsMap.get(ExportResultsTagsEnum.sizeMore);
  readonly #sizeLess = ExportResultsTagsMap.get(ExportResultsTagsEnum.sizeLess);

  private keyCache = 'export-results-name_';

  private readonly exportResultsService$: Observable<ODataPagedResult<ExportResultsOdata>>;
  private readonly serviceTypeToDisplayNamePipe: ServiceTypeToDisplayNamePipe = new ServiceTypeToDisplayNamePipe();

  constructor(private exportResults: ExportResultsService, private date: DatePipe) {
    super();
    this.exportResultsService$ = exportResults.getExportResults();
  }

  public readonly UserNameTag: ModelTemplate<ExportResultsOdata> = {
    tag: this.#userName.tag,
    items: (state: SmartSearchState): Observable<ExportResultsOdata[]> => {
      const term = state.leftCaretValue;
      const options: FilterOptions = {
        model: [{ value: term }],
        prop: this.#userName.prop
      };

      this.exportResults.filter = term ? getFilterByContains(options) : '';

      // if (this.cache[this.keyCache + term]) {
      //   return of(this.cache[this.keyCache + term]) as Observable<ExportResultsOdata[]>;
      // }

      return scheduled(
        this.exportResultsService$.pipe(
          map((res) => res.data),
          map((results: ExportResultsOdata[]) => {
            const filtered = results.filter((result) => result.UserName.toLowerCase().includes(term));

            return getUniqueListByProp(filtered, this.#userName.prop).slice(0, 10);
          }),
          tap<ExportResultsOdata[]>(this.writeToCache(this.keyCache + term))
        ),
        asapScheduler
      );
    },
    itemFormatter: ({ UserName }): string => UserName,
    addGroupBrackets: true
  };

  public readonly FileNameTag: ModelTemplate<ExportResultsOdata> = {
    tag: this.#fileName.tag,
    items: (state: SmartSearchState): Observable<ExportResultsOdata[]> => {
      const term = state.leftCaretValue;
      const options: FilterOptions = {
        model: [{ value: term }],
        prop: this.#fileName.prop
      };

      this.exportResults.filter = term ? getFilterByContains(options) : '';

      // if (this.cache[this.keyCache + term]) {
      //   return of(this.cache[this.keyCache + term]) as Observable<ExportResultsOdata[]>;
      // }

      return scheduled(
        this.exportResultsService$.pipe(
          map((res) => res.data),
          map((results: ExportResultsOdata[]) => {
            const filtered = results.filter((result) => result.Name.toLowerCase().includes(term));

            return getUniqueListByProp(filtered, this.#fileName.prop).slice(0, 10);
          }),
          tap<ExportResultsOdata[]>(this.writeToCache(this.keyCache + term))
        ),
        asapScheduler
      );
    },
    itemFormatter: ({ Name }): string => Name,
    addGroupBrackets: true
  };

  public readonly ServiceTypeTag: ModelTemplate<ExportResultsOdata> = {
    tag: this.#serviceType.tag,
    items: (state: SmartSearchState): Observable<ExportResultsOdata[]> => {
      const term = state.leftCaretValue;
      const options: FilterOptions = {
        model: [{ value: term }],
        prop: this.#serviceType.prop,
        enumItems: ServiceTypeByAPIEnum
      };

      this.exportResults.filter = term ? getFilterByEqFromEnum(options) : '';

      // if (this.cache[this.keyCache + term]) {
      //   return of(this.cache[this.keyCache + term]) as Observable<ExportResultsOdata[]>;
      // }

      return scheduled(
        this.exportResultsService$.pipe(
          map((res) => res.data),
          map((results: ExportResultsOdata[]) => {
            const filtered = results.filter((result) => result.ServiceType.toLowerCase().includes(term));

            return getUniqueListByProp(filtered, this.#serviceType.prop).slice(0, 10);
          }),
          tap<ExportResultsOdata[]>(this.writeToCache(this.keyCache + term))
        ),
        asapScheduler
      );
    },
    itemFormatter: ({ ServiceType }): string => this.serviceTypeToDisplayNamePipe.transform(ServiceType),
    addGroupBrackets: true
  };

  public readonly DateFromTag: ModelTemplate<ExportResultsOdata> = {
    tag: this.#dateFrom.tag,
    items: (state: SmartSearchState): Observable<ExportResultsOdata[]> => {
      const term = state.leftCaretValue;
      const options: FilterOptions = {
        model: [{ value: term }],
        prop: this.#dateFrom.prop
      };

      this.exportResults.filter = term ? getFilterByDate(options) : '';

      // if (this.cache[this.keyCache + term]) {
      //   return of(this.cache[this.keyCache + term]) as Observable<ExportResultsOdata[]>;
      // }

      return scheduled(
        this.exportResultsService$.pipe(
          map((res) => res.data),
          map((results: ExportResultsOdata[]) => {
            const cloneResults = cloneDeep(results).map((result) => ({ ...result, CreateDate: this.date.transform(result.CreateDate) }));
            const filtered = cloneResults.filter((result) => result.CreateDate.includes(term));

            return getUniqueListByProp(filtered, this.#dateFrom.prop).slice(0, 10);
          }),
          tap<ExportResultsOdata[]>(this.writeToCache(this.keyCache + term))
        ),
        asapScheduler
      );
    },
    itemFormatter: ({ CreateDate }): string => this.date.transform(CreateDate, 'mediumDate'),
    addGroupBrackets: true
  };

  public readonly DateToTag: ModelTemplate<ExportResultsOdata> = {
    tag: this.#dateTo.tag,
    items: (state: SmartSearchState): Observable<ExportResultsOdata[]> => {
      const term = state.leftCaretValue;
      const options: FilterOptions = {
        model: [{ value: term }],
        prop: this.#dateTo.prop
      };

      this.exportResults.filter = term ? getFilterByDate(options) : '';

      // if (this.cache[this.keyCache + term]) {
      //   return of(this.cache[this.keyCache + term]) as Observable<ExportResultsOdata[]>;
      // }

      return scheduled(
        this.exportResultsService$.pipe(
          map((res) => res.data),
          map((results: ExportResultsOdata[]) => {
            const cloneResults = cloneDeep(results).map((result) => ({ ...result, CreateDate: this.date.transform(result.CreateDate) }));
            const filtered = cloneResults.filter((result) => result.CreateDate.includes(term));

            return getUniqueListByProp(filtered, this.#dateTo.prop).slice(0, 10);
          }),
          tap<ExportResultsOdata[]>(this.writeToCache(this.keyCache + term))
        ),
        asapScheduler
      );
    },
    itemFormatter: ({ CreateDate }): string => this.date.transform(CreateDate, 'mediumDate'),
    addGroupBrackets: true
  };

  public readonly SizeMoreThanTag: ModelTemplate<string> = {
    tag: this.#sizeMore.tag,
    items: (state: SmartSearchState): Observable<string[]> => getCustomSizeItems(state, this.updateExportResultsFilter.bind(this)),
    itemFormatter: (value: string): string => value,
    addGroupBrackets: true
  };

  public readonly SizeMoreLessTag: ModelTemplate<string> = {
    tag: this.#sizeLess.tag,
    items: (state: SmartSearchState): Observable<string[]> => getCustomSizeItems(state, this.updateExportResultsFilter.bind(this)),
    itemFormatter: (value: string): string => value,
    addGroupBrackets: true
  };

  private updateExportResultsFilter(term: string): void {
    this.exportResults.filter = term || '';
  }
}
