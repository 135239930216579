import { Pipe, PipeTransform } from '@angular/core';
import { RoutePath } from '@common';
import { MenuItemType } from '@common/components/app-header/app-header-item.model';
import { AuthService } from '@common/services';
import { I18NextPipe } from 'angular-i18next';

@Pipe({
  name: 'activeRoute',
  pure: true
})
export class ActiveRoutePipe implements PipeTransform {
  private reportingDropdownPages: MenuItemType[] = [
    {
      name: this.i18nPipe.transform('header.dropdown.backupHistory', { format: 'title' }),
      routeUrl: RoutePath.BackupHistory,
      iconClass: 'fa fa-edit',
      id: this.i18nPipe.transform('header.dropdown.backupHistory', { format: 'title' })
    },
    {
      name: this.i18nPipe.transform('header.dropdown.history', { format: 'title' }),
      routeUrl: RoutePath.History,
      iconClass: 'fa fa-history',
      id: this.i18nPipe.transform('header.dropdown.history', { format: 'title' })
    },
    {
      name: this.i18nPipe.transform('header.dropdown.reports', { format: 'title' }),
      routeUrl: RoutePath.Reports,
      iconClass: 'ico ico-Excel',
      id: this.i18nPipe.transform('header.dropdown.reports', { format: 'title' })
    },
    {
      name: this.i18nPipe.transform('header.dropdown.auditLog', { format: 'title' }),
      routeUrl: RoutePath.AuditLog,
      iconClass: 'fa fa-book',
      id: this.i18nPipe.transform('header.dropdown.auditLog', { format: 'title' })
    },
    {
      name: this.i18nPipe.transform('header.dropdown.exportResults', { format: 'title' }),
      routeUrl: RoutePath.ExportResults,
      iconClass: 'fa fa-download',
      id: this.i18nPipe.transform('header.dropdown.exportResults', { format: 'title' })
    },
    {
      name: this.i18nPipe.transform('header.dropdown.skipItems', { format: 'title' }),
      routeUrl: RoutePath.SkipItems,
      iconClass: 'fa fa-list-alt',
      id: this.i18nPipe.transform('header.dropdown.skipItems', { format: 'title' })
    }
  ];

  constructor(private i18nPipe: I18NextPipe, private authService: AuthService) {}

  transform(routeUrl: string): MenuItemType {
    const firstUrlSegment = this.getUrlSegment(routeUrl.replace(this.authService.prefix, '/'));
    return this.reportingDropdownPages.find((item) => item.routeUrl === firstUrlSegment);
  }

  private getUrlSegment = (url: string): string => {
    const FIRST_URL_SEGMENT_REGEX = new RegExp('^/[^/]+', 'i');
    return FIRST_URL_SEGMENT_REGEX.test(url) ? url.replace(/^\/([^/]*).*$/, '$1') : null;
  };
}
