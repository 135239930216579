import { Pipe, PipeTransform } from '@angular/core';
import { FlatTreeElement } from '../models/flatTreeElement.model';
import { TreeElement } from '../models/treeElement.model';

@Pipe({
  name: 'treeLoadMore',
  pure: true
})
export class TreeLoadMorePipe implements PipeTransform {
  transform(item: FlatTreeElement, index: number, flatData: FlatTreeElement[]): FlatTreeElement {
    const nextElement = flatData[index + 1]?.node || null;
    const nextElementParentId = nextElement?.parent?.id;

    let needLoadMore = false;
    let currentItem: TreeElement;
    let currentParent = item?.node;

    while (!needLoadMore && currentParent?.parent && currentParent?.parent?.id === item.node.parent.id) {
      currentItem = currentParent;
      currentParent = currentParent?.parent;

      if (nextElementParentId === currentParent?.id) break;

      const childrenLength = currentParent?.children
        ? currentParent.children.reduce((acc, next) => {
            if (next.shown || next.shown !== false) acc++;
            return acc;
          }, 0)
        : 0;

      needLoadMore =
        currentParent?.expanded &&
        currentParent?.totalChildren &&
        childrenLength &&
        currentParent.totalChildren > childrenLength &&
        currentParent.children[
          currentParent.children.length !== childrenLength
            ? currentItem.children
              ? childrenLength
              : currentParent.children.length - 1
            : childrenLength - 1
        ]?.id === currentItem?.id;
    }

    if (!needLoadMore) return null;

    return flatData.find(item => item.node.id === currentItem.id);
  }
}
