import { DOCUMENT } from '@angular/common';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { BrowserStorage, Claim, RoutePath, STANDALONE_ICON, WINDOW } from '@common';
import { AuthMode } from '@common/components/login/enums';
import {
  AuthUser,
  ProductMode,
  Provider,
  ProviderRole,
  Role,
  ServiceFields,
  ServiceType,
  SystemInfo,
  UserServiceBackupInfo,
  hasLimitedAdminRole,
  hasProviderRole,
  isEmergencySignIn
} from '@common/models';
import {
  AlternativeSignInPayload,
  AnotherAccountResponse,
  AuthModelBasel,
  AuthResponse,
  AuthUrlOData,
  AuthenticationPayload
} from '@common/models/auth/auth.model';
import { ODataService, ODataServiceFactory } from '@common/odata';
import { PosthogService } from '@common/posthog/posthog.service';
import { SentryService } from '@common/services/sentry.service';
import { LocalStorageService } from '@common/services/storage/local-storage.service';
import { PersistentStateService } from '@common/services/storage/persistent-state.service';
import { SessionStorageService } from '@common/services/storage/session-storage.service';
import { getPrefix, hasActionsQueue, isAlternateSignIn, isProviderGoogle, isProviderOffice } from '@common/utils';
import { isArray } from 'lodash';
import { BehaviorSubject, EMPTY, Observable, forkJoin, of, throwError } from 'rxjs';
import { catchError, finalize, map, share, shareReplay, tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AuthService {
  #modelBasel: AuthModelBasel;
  #availableServices: ServiceType[];
  #me: AuthUser;
  #roles: Role[];
  #claims: Claim[];
  #providerRoles: ProviderRole[];
  #systemInfo: SystemInfo;
  #loading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  #odataService$: ODataService<AuthUser>;
  #odata$: ODataService<any>;
  #claimService$: ODataService<Claim>;
  #isEmergencySignIn: boolean;

  public readonly loggedIn$: BehaviorSubject<boolean> = new BehaviorSubject(null);
  public readonly requestPending$: Observable<boolean>;

  public persistentStateKey: string;

  set modelBasel(model: AuthModelBasel) {
    this.sessionStorageService.set(BrowserStorage.ErrorPage, { model });
    this.#modelBasel = model;
  }

  get modelBasel(): AuthModelBasel {
    return this.#modelBasel;
  }

  get domainId(): string {
    return this.#me?.DomainId;
  }

  get domainName(): string {
    return this.#me?.DomainName;
  }

  get id(): string {
    return this.#me?.Id;
  }

  get provider(): Provider {
    return this.#me?.Provider;
  }

  get isGoogle(): boolean {
    return isProviderGoogle(this.provider);
  }

  get isOffice(): boolean {
    return isProviderOffice(this.provider);
  }

  get prefix(): string {
    return getPrefix(this.provider, this.#isEmergencySignIn);
  }

  get isGAA(): boolean {
    return this.#systemInfo?.GAA;
  }

  get isImmutability(): boolean {
    return this.#systemInfo?.IsImmutability;
  }

  constructor(
    @Inject(WINDOW) private window: Window,
    @Inject(DOCUMENT) private document: Document,
    private phService: PosthogService,
    private http: HttpClient,
    private jwtService: JwtHelperService,
    private odataFactory: ODataServiceFactory,
    private router: Router,
    private storageService: LocalStorageService,
    private persistentService: PersistentStateService,
    private sessionStorageService: SessionStorageService,
    private sentryService: SentryService
  ) {
    this.#odata$ = this.odataFactory.CreateService('');
    this.#odataService$ = this.odataFactory.CreateService<AuthUser>('me');
    this.#claimService$ = this.odataFactory.CreateService('GetClaims');
    this.requestPending$ = this.#loading$.pipe(hasActionsQueue(), shareReplay(1));
  }

  checkTokenInLocalStorage(): void {
    const token = this.storageService.get<string>(BrowserStorage.Token);

    try {
      if (token && Boolean(this.jwtService.decodeToken(token)) && !this.jwtService.isTokenExpired(token)) {
        this.loggedIn$.next(true);
      }
    } catch (e) {
      this.loggedIn$.next(false);
    }
  }

  // new endpoints
  login(provider: Provider, mode = AuthMode.None): Observable<AuthUrlOData> {
    const odataService = this.odataFactory.CreateService<AuthUrlOData>(`/Auth?provider=${provider}&mode=${mode}`);

    return this.http.get<AuthUrlOData>(odataService.Query().GetUrl());
  }

  authCallback(payload: AuthenticationPayload): Observable<AuthResponse | AnotherAccountResponse | AuthUrlOData> {
    const odataService = this.odataFactory.CreateService<AuthResponse>(`/Auth`);

    return this.http.post<AuthResponse>(odataService.Query().GetUrl(), payload);
  }

  alternativeSignIn(payload: AlternativeSignInPayload): Observable<AuthResponse> {
    const odataService = this.odataFactory.CreateService<AuthResponse>(`/Auth/AlternativeSignIn`);

    return this.http.post<AuthResponse>(odataService.Query().GetUrl(), payload);
  }

  getToken(payload: AlternativeSignInPayload): Observable<AuthResponse> {
    const odataService = this.odataFactory.CreateService<AuthResponse>(`/Auth/GetToken`);

    return this.http.post<AuthResponse>(odataService.Query().GetUrl(), payload);
  }

  getPath(res: AuthResponse): string {
    try {
      return getPrefix(res.Provider, isAlternateSignIn(res.Mode));
    } catch {
      throw new Error(`"${res.Provider}" provider does not exist`);
    }
  }

  initAuthGuard(): Observable<void> {
    return forkJoin([this.getAuthUser(), this.getAvailableServices(), this.getRoles(), this.getSystemInfo()]).pipe(
      map(([authUser, availableServices, roles, systemInfo]) => {
        this.setFavicon(systemInfo.ProductMode === ProductMode.resale ? STANDALONE_ICON.favicon : systemInfo.FaviconUrl);
        this.sentryService.init(systemInfo);
        this.sentryService.addUser(authUser);
        this.setTimeZoneOffsetInCookie(); // TODO: temporary method for entrance into admin. Have to delete before release 4.0
      })
    );
  }

  private setFavicon(faviconUrl: string): void {
    this.document.head.querySelector('#favicon').setAttribute('href', faviconUrl);
  }

  private setTimeZoneOffsetInCookie() {
    const cookieDomain = 'apps-stage.mspbackups.com';
    const options = {
      domain: cookieDomain,
      path: '/',
      expires: new Date(Date.now() + 365 * 24 * 60 * 60 * 1000).toUTCString()
    };

    let cookie = 'offset=' + new Date().getTimezoneOffset();

    for (const key of Object.keys(options)) {
      cookie += '; ' + key + '=' + options[key];
    }

    this.document.cookie = cookie;
  }
  // end new

  logout(): void {
    this.resetCache();
    this.persistentService.data[BrowserStorage.SPointDisabled] = null;
    this.persistentService.data[BrowserStorage.SPointNoSiteBackup] = null;
    this.storageService.remove(BrowserStorage.Token);
    this.sessionStorageService?.remove(BrowserStorage.ErrorPage);
    this.loggedIn$.next(false);

    void this.router.navigate([RoutePath.Login], { queryParams: null });
  }

  getSystemInfo(forceUpdate = false): Observable<SystemInfo> {
    if (this.#systemInfo && !forceUpdate) return of(this.#systemInfo);

    this.#loading$.next(true);
    return this.#odata$.CustomCollectionFunction('SystemInfo', {}).pipe(
      map((res) => res?.body),
      tap<SystemInfo>((info) => (this.#systemInfo = info)),
      finalize(() => this.#loading$.next(false))
    );
  }

  getRoles(): Observable<Role[]> {
    if (this.#roles) return of(this.#roles);

    this.#loading$.next(true);
    return this.getClaims().pipe(
      map((claims) => claims.filter((c) => c.Type == 'role')),
      map((claims) => claims.map((c) => c.Value)),
      tap<Role[]>((roles) => {
        this.#roles = roles;
        this.#isEmergencySignIn = isEmergencySignIn(roles);
      }),
      finalize(() => this.#loading$.next(false))
    );
  }

  getProviderRoles(): Observable<ProviderRole[]> {
    if (this.#providerRoles) return of(this.#providerRoles);

    this.#loading$.next(true);
    return this.getClaims().pipe(
      map((claims) => claims.filter((c) => c.Type == 'ProviderRole')),
      map((claims) => claims.map((c) => c.Value)),
      tap<ProviderRole[]>((providerRoles) => (this.#providerRoles = providerRoles)),
      finalize(() => this.#loading$.next(false))
    );
  }

  private getClaims(): Observable<Claim[]> {
    if (this.#claims) return of(this.#claims).pipe(share());

    this.#loading$.next(true);
    return this.#claimService$
      .Query()
      .Exec()
      .pipe(
        tap((result) => (this.#claims = result)),
        finalize(() => this.#loading$.next(false))
      );
  }

  getAvailableServices(): Observable<ServiceType[]> {
    if (this.#availableServices) return of(this.#availableServices);

    this.#loading$.next(true);
    return this.odataFactory
      .CreateService<string>('AvailableServices')
      .Query()
      .Exec()
      .pipe(
        map((res) => res.map((str) => ServiceType[str] as ServiceType)),
        map((services) => services.filter((s) => s !== ServiceType.History)), // `History` nav-link move to `Reporting`
        tap((services) => (this.#availableServices = services)),
        finalize(() => this.#loading$.next(false))
      );
  }

  getAuthUser(forceUpdate = false): Observable<AuthUser> {
    if (this.#me && !forceUpdate) return of(this.#me);

    this.#loading$.next(true);
    return this.#odataService$
      .Query()
      .Select(['Id', 'Email', 'Name', 'DomainId', 'Provider', 'RestoreEnable', 'SignInEnable', 'DomainName'])
      .Exec()
      .pipe(
        map((users) => (isArray(users) ? users[0] : users)),
        tap((user) => {
          if (!this.persistentStateKey) {
            this.persistentStateKey = BrowserStorage.Prefix + btoa(JSON.stringify({ Id: user.Id }));
            this.persistentService.eventEmitter$.emit(this.persistentStateKey);
          }
          this.#me = user;
        }),
        tap((user) => this.phService.initialize(user)),
        finalize(() => this.#loading$.next(false))
      );
  }

  getAuthUserServiceInfo(serviceType: ServiceType): Observable<UserServiceBackupInfo> {
    const fields = new ServiceFields(serviceType);

    this.#loading$.next(true);
    return this.#odataService$
      .Query()
      .Select(fields.filteredArrayValues())
      .Exec()
      .pipe(
        map((users) => (isArray(users) ? users[0] : users)),
        map((user) => {
          return {
            TotalSize: fields.Size ? (user[fields.Size] as number) : 0,
            LastBackupDate: user[fields.LastBackupDate] as string
          };
        }),
        finalize(() => this.#loading$.next(false))
      );
  }

  isProviderSignIn(): Observable<boolean> {
    return this.getRoles().pipe(
      map((roles) => hasProviderRole(roles)),
      shareReplay(1)
    );
  }

  restoreAvailable(): Observable<boolean> {
    return this.getAuthUser().pipe(
      map((user) => user.RestoreEnable),
      shareReplay(1)
    );
  }

  isLimitedAdmin(): Observable<boolean> {
    return forkJoin([this.getRoles(), this.getProviderRoles()]).pipe(
      map(([roles, providerRoles]) => hasLimitedAdminRole(roles, providerRoles)),
      shareReplay(1)
    );
  }

  resetCache(): void {
    this.#availableServices = null;
    this.#me = null;
    this.#claims = null;
    this.#roles = null;
    this.#providerRoles = null;
    this.#systemInfo = null;
  }

  signAsAdmin(): void {
    this.login(Provider.OfficeBusiness, AuthMode.SecondSignIn)
      .pipe(catchError((err: HttpErrorResponse) => (err instanceof HttpErrorResponse ? throwError(() => err) : EMPTY)))
      .subscribe({
        next: (data) => {
          this.window.open(data.RedirectUrl, '_top');
        },
        error: () => {
          this.logout();
        }
      });
  }

  activateAltEmail(token: string): Observable<void> {
    return this.http.get<void>(`api/ActivateEmail?token=${token}`);
  }
}
