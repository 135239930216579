import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BOOLEAN_OPERATOR, SINGLE_QUOTES_REGEX } from '@common';
import { AttachedPolicies, DefaultParams, DisplayNameItemType, RetentionPolicyOdata, ServiceTypeByAPIEnum } from '@common/models';
import { ODataPagedResult, ODataServiceFactory } from '@common/odata';
import { SharedOdataService } from '@common/services';
import { RetentionPolicyTagsEnum, RetentionPolicyTagsMap } from '@common/services/smart-search';
import { filterByWords, getLoadingState, hasActionsQueue } from '@common/utils';
import { FilterOptions, containsWrapper, getFilterByContains, getFilterByEqFromEnum } from '@common/utils/functions/search';
import { extendedPolicies } from '@common/utils/helper/attached-policies';
import { I18NextPipe } from 'angular-i18next';
import { SmartSearchModel, SmartSearchModelField } from 'mbs-ui-kit';
import { BehaviorSubject, Observable } from 'rxjs';
import { finalize, map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class RetentionPolicyService extends SharedOdataService<RetentionPolicyOdata> {
  private baseUrl: string;

  public requestPending$: Observable<boolean>;
  public loading$ = new BehaviorSubject<boolean>(false);

  private readonly tagName = RetentionPolicyTagsMap.get(RetentionPolicyTagsEnum.policyName);
  private readonly tagType = RetentionPolicyTagsMap.get(RetentionPolicyTagsEnum.serviceType);
  private readonly tagLegalHold = RetentionPolicyTagsMap.get(RetentionPolicyTagsEnum.legalHold);

  private legalHoldType: Partial<DisplayNameItemType> = {
    true: {
      displayName: this.i18nPipe.transform('common.yes', { format: 'title' })
    },
    false: {
      displayName: this.i18nPipe.transform('common.no', { format: 'title' })
    }
  };

  constructor(odataFactory: ODataServiceFactory, private http: HttpClient, private i18nPipe: I18NextPipe) {
    super(odataFactory, 'RetentionPolicy');
    this.baseUrl = `${odataFactory.CreateService('').Query().GetUrl()}/`;
    this.requestPending$ = getLoadingState([this.loading$.pipe(hasActionsQueue())]);
  }

  getPolicies(): Observable<ODataPagedResult<RetentionPolicyOdata>> {
    const odataService = this.odataFactory.CreateService<RetentionPolicyOdata>(`/RetentionPolicy`);

    return this.fetchData(odataService);
  }

  getAttachedPolicies(userIds: string[] = []): Observable<AttachedPolicies> {
    return this.http
      .get<AttachedPolicies>(`${this.baseUrl}GetAttachedPolicies?userIds=${userIds.join(',')}`)
      .pipe(map((policies) => extendedPolicies(policies)));
  }

  createPolicy(policy: RetentionPolicyOdata): Observable<RetentionPolicyOdata> {
    this.loading$.next(true);
    return this.odata
      .Post(policy)
      .Exec()
      .pipe(finalize(() => this.loading$.next(false)));
  }

  updatePolicy(policy: RetentionPolicyOdata): Observable<RetentionPolicyOdata> {
    this.loading$.next(true);
    return this.odata
      .Put(policy, policy.Id)
      .Exec()
      .pipe(finalize(() => this.loading$.next(false)));
  }

  deletePolicy(id: string): Observable<RetentionPolicyOdata> {
    this.loading$.next(true);
    return this.odata
      .Delete(id)
      .Exec()
      .pipe(finalize(() => this.loading$.next(false)));
  }

  setDefaultPolicy(policies: DefaultParams): Observable<any> {
    this.loading$.next(true);
    return this.http
      .post(this.odataFactory.CreateService(`/RetentionPolicy/SetDefault`).Query().GetUrl(), policies)
      .pipe(finalize(() => this.loading$.next(false)));
  }

  updateFilter(searchObj: SmartSearchModel): void {
    const filter: string[] = [];

    if (searchObj?.[this.tagName.tag]) {
      const options: FilterOptions = {
        model: searchObj[this.tagName.tag] as SmartSearchModelField[],
        prop: this.tagName.prop
      };

      filter.push(getFilterByContains(options));
    }

    if (searchObj?.[this.tagType.tag]) {
      const options: FilterOptions = {
        model: searchObj[this.tagType.tag] as SmartSearchModelField[],
        prop: this.tagType.prop,
        enumItems: ServiceTypeByAPIEnum
      };

      filter.push(getFilterByEqFromEnum(options));
    }

    if (searchObj?.[this.tagLegalHold.tag]) {
      const options: FilterOptions = {
        model: searchObj[this.tagLegalHold.tag] as SmartSearchModelField[],
        prop: this.tagLegalHold.prop,
        enumItems: this.legalHoldType
      };

      filter.push(getFilterByEqFromEnum(options, false));
    }

    if (searchObj.words?.filter(Boolean)) {
      const term = filterByWords(searchObj).replace(SINGLE_QUOTES_REGEX, '');
      const keys = [this.tagName.prop];
      const values = keys.map((k) => containsWrapper(k, term));

      filter.push('('.concat(values.join(` ${BOOLEAN_OPERATOR.or} `), ')'));
    }

    this.filter = filter.length > 0 ? filter.join(` ${BOOLEAN_OPERATOR.and} `) : '';
  }
}
