import { AfterViewInit, Component, HostListener, Input } from '@angular/core';
import { BootstrapTheme } from '../utils';

/**
 * Spinner animation for some works
 */
@Component({
  selector: 'app-loader, mbs-loader',
  template: `
    <div class="mbs-loader" [ngClass]="bgClass">
      <div class="mbs-loader_spinner" [ngClass]="[spinnerSizeClass, spinnerColorClass, fixedSpinnerClass]"></div>
    </div>
  `
})
export class LoaderComponent implements AfterViewInit {
  @Input() theme: BootstrapTheme;
  @Input() type: BootstrapTheme;
  @Input() useFixedDocumentSpinner = false;

  private _useFixedSize = false;
  private _hasVerticalScroll = false;
  @Input()
  public set useFixedSize(value: boolean) {
    this._useFixedSize = value;
    this.checkSpinnerPosition();
  }

  public spinnerSizeClass = '';

  private documentHasVerticalScrollbar = false;

  public get bgClass(): string {
    return this.type ? `bg-transparent-${this.type}` : '';
  }

  public get spinnerColorClass(): string {
    return this.theme ? `mbs-loader_spinner-${this.theme}` : '';
  }

  public get fixedSpinnerClass(): string {
    return this.useFixedDocumentSpinner && this.documentHasVerticalScrollbar ? 'mbs-loader_spinner_fixed' : '';
  }

  @Input()
  public set updatePosition(hasVerticalScroll: boolean) {
    this._hasVerticalScroll = hasVerticalScroll;
    this.checkSpinnerPosition();
  }

  /**
   * Spinner size.
   * * 'xsmall' - 35px * 35px
   * * 'small' - 75px * 75px
   * * 'default' - 150px * 150px
   * @param {'small' | 'default' | 'xsmall'} value
   */
  @Input()
  public set size(value: 'small' | 'default' | 'xsmall') {
    switch (value) {
      case 'small':
        this.spinnerSizeClass = 'mbs-loader_spinner-small';
        break;
      case 'xsmall':
        this.spinnerSizeClass = 'mbs-loader_spinner-xsmall';
        break;
      case 'default':
      default:
        break;
    }
  }

  @HostListener('window:resize', ['$event'])
  public onResize() {
    this.checkSpinnerPosition();
  }

  public ngAfterViewInit(): void {
    this.checkSpinnerPosition();
  }

  private checkSpinnerPosition(): void {
    if (!this.useFixedDocumentSpinner) return;

    if (this._useFixedSize) {
      this.documentHasVerticalScrollbar = this._hasVerticalScroll;
    } else {
      this.documentHasVerticalScrollbar = document.documentElement.scrollHeight > document.documentElement.clientHeight;
    }
  }
}
