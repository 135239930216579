import { CurrencyPipe, DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { InvoiceOData } from '@common/models';
import { ODataPagedResult } from '@common/odata';
import { InvoiceStateToDisplayNamePipe, SortOrderByPipe } from '@common/pipes';
import { InvoicesService } from '@common/services';
import { PaymentsTagsEnum, PaymentsTagsMap, getUniqueListByProp } from '@common/services/smart-search';
import SmartSearchTemplatesBase from '@common/services/smart-search/smart-search-template-base';
import { cloneDeep, isNil } from 'lodash';
import { ModelTemplate, SmartSearchState } from 'mbs-ui-kit';
import { Observable, asapScheduler, scheduled } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable()
export class SmartSearchModelTemplatePaymentsService extends SmartSearchTemplatesBase {
  readonly #state = PaymentsTagsMap.get(PaymentsTagsEnum.state);
  readonly #quantity = PaymentsTagsMap.get(PaymentsTagsEnum.quantity);
  readonly #price = PaymentsTagsMap.get(PaymentsTagsEnum.price);
  readonly #amount = PaymentsTagsMap.get(PaymentsTagsEnum.amount);
  readonly #date = PaymentsTagsMap.get(PaymentsTagsEnum.date);
  readonly #expireDate = PaymentsTagsMap.get(PaymentsTagsEnum.expireDate);
  readonly #invoiceId = PaymentsTagsMap.get(PaymentsTagsEnum.invoiceId);

  private keyCache = 'payments-name_';
  private paymentsService$: Observable<ODataPagedResult<InvoiceOData>>;

  constructor(
    private paymentsService: InvoicesService,
    private currencyPipe: CurrencyPipe,
    private datePipe: DatePipe,
    private sortOrderByPipe: SortOrderByPipe<InvoiceOData>,
    private invoiceStateToDisplayNamePipe: InvoiceStateToDisplayNamePipe
  ) {
    super();
    this.paymentsService$ = this.paymentsService.getInvoices();
  }

  public readonly StateTag: ModelTemplate<InvoiceOData> = {
    tag: this.#state.tag,
    items: (state: SmartSearchState): Observable<InvoiceOData[]> => {
      const term = state.leftCaretValue;

      this.paymentsService.filter = term || '';

      // if (this.cache[this.keyCache + term]) {
      //   return of(this.cache[this.keyCache + term]) as Observable<InvoiceOData[]>;
      // }

      return scheduled(
        this.paymentsService$.pipe(
          map((res) => res.data),
          map((invoices: InvoiceOData[]) => {
            const filtered = invoices.filter((invoice) => !isNil(invoice.State) && invoice.State.includes(term));
            const uniqueList = getUniqueListByProp(filtered, this.#state.prop).slice(0, 10);

            return this.sortOrderByPipe.transform(uniqueList, this.#state.prop);
          }),
          tap<InvoiceOData[]>(this.writeToCache(this.keyCache + term))
        ),
        asapScheduler
      );
    },
    itemFormatter: ({ State }): string => this.invoiceStateToDisplayNamePipe.transform(State),
    addGroupBrackets: true
  };

  public readonly QuantityTag: ModelTemplate<InvoiceOData> = {
    tag: this.#quantity.tag,
    items: (state: SmartSearchState): Observable<InvoiceOData[]> => {
      const term = state.leftCaretValue;

      this.paymentsService.filter = term || '';

      // if (this.cache[this.keyCache + term]) {
      //   return of(this.cache[this.keyCache + term]) as Observable<InvoiceOData[]>;
      // }

      return scheduled(
        this.paymentsService$.pipe(
          map((res) => res.data),
          map((invoices: InvoiceOData[]) => {
            const filtered = invoices.filter((invoice) => !isNil(invoice.Quantity) && String(invoice.Quantity).includes(term));
            const uniqueList = getUniqueListByProp(filtered, this.#quantity.prop).slice(0, 10);

            return this.sortOrderByPipe.transform(uniqueList, 'Quantity', 'number');
          }),
          tap<InvoiceOData[]>(this.writeToCache(this.keyCache + term))
        ),
        asapScheduler
      );
    },
    itemFormatter: ({ Quantity }): string => `${Quantity}`,
    addGroupBrackets: true
  };

  public readonly PriceTag: ModelTemplate<InvoiceOData> = {
    tag: this.#price.tag,
    items: (state: SmartSearchState): Observable<InvoiceOData[]> => {
      const term = state.leftCaretValue;

      this.paymentsService.filter = term || '';

      // if (this.cache[this.keyCache + term]) {
      //   return of(this.cache[this.keyCache + term]) as Observable<InvoiceOData[]>;
      // }

      return scheduled(
        this.paymentsService$.pipe(
          map((res) => res.data),
          map((invoices: InvoiceOData[]) => {
            const filtered = invoices.filter((invoice) => !isNil(invoice.Price) && String(invoice.Price).includes(term));
            const uniqueList = getUniqueListByProp(filtered, this.#price.prop).slice(0, 10);

            return this.sortOrderByPipe.transform(uniqueList, 'Price', 'number');
          }),
          tap<InvoiceOData[]>(this.writeToCache(this.keyCache + term))
        ),
        asapScheduler
      );
    },
    itemFormatter: ({ Price }): string => this.currencyPipe.transform(Price / 100, 'USD', 'symbol'),
    addGroupBrackets: true
  };

  public readonly AmountTag: ModelTemplate<InvoiceOData> = {
    tag: this.#amount.tag,
    items: (state: SmartSearchState): Observable<InvoiceOData[]> => {
      const term = state.leftCaretValue;

      this.paymentsService.filter = term || '';

      // if (this.cache[this.keyCache + term]) {
      //   return of(this.cache[this.keyCache + term]) as Observable<InvoiceOData[]>;
      // }

      return scheduled(
        this.paymentsService$.pipe(
          map((res) => res.data),
          map((invoices: InvoiceOData[]) => {
            const filtered = invoices.filter((invoice) => !isNil(invoice.Amount) && String(invoice.Amount).includes(term));
            const uniqueList = getUniqueListByProp(filtered, this.#amount.prop).slice(0, 10);

            return this.sortOrderByPipe.transform(uniqueList, 'Amount', 'number');
          }),
          tap<InvoiceOData[]>(this.writeToCache(this.keyCache + term))
        ),
        asapScheduler
      );
    },
    itemFormatter: ({ Amount }): string => this.currencyPipe.transform(Amount / 100, 'USD', 'symbol'),
    addGroupBrackets: true
  };

  public readonly DateTag: ModelTemplate<InvoiceOData> = {
    tag: this.#date.tag,
    items: (state: SmartSearchState): Observable<InvoiceOData[]> => {
      const term = state.leftCaretValue;

      this.paymentsService.filter = term || '';

      // if (this.cache[this.keyCache + term]) {
      //   return of(this.cache[this.keyCache + term]) as Observable<InvoiceOData[]>;
      // }

      return scheduled(
        this.paymentsService$.pipe(
          map((res) => res.data),
          map((invoices: InvoiceOData[]) => {
            const cloneInvoices = cloneDeep(invoices).map((invoice) => ({ ...invoice, Date: this.datePipe.transform(invoice.Date) }));
            const filtered = cloneInvoices.filter((invoice) => invoice.Date && invoice.Date.includes(term));
            const uniqueList = getUniqueListByProp(filtered, this.#date.prop).slice(0, 10);

            return this.sortOrderByPipe.transform(uniqueList, 'Date', 'Date');
          }),
          tap<InvoiceOData[]>(this.writeToCache(this.keyCache + term))
        ),
        asapScheduler
      );
    },
    itemFormatter: ({ Date }): string => this.datePipe.transform(Date, 'mediumDate'),
    addGroupBrackets: true
  };

  public readonly ExpireDateTag: ModelTemplate<InvoiceOData> = {
    tag: this.#expireDate.tag,
    items: (state: SmartSearchState): Observable<InvoiceOData[]> => {
      const term = state.leftCaretValue;

      this.paymentsService.filter = term || '';

      // if (this.cache[this.keyCache + term]) {
      //   return of(this.cache[this.keyCache + term]) as Observable<InvoiceOData[]>;
      // }

      return scheduled(
        this.paymentsService$.pipe(
          map((res) => res.data),
          map((invoices: InvoiceOData[]) => {
            const cloneInvoices = cloneDeep(invoices).map((invoice) => ({
              ...invoice,
              ExpireDate: this.datePipe.transform(invoice.ExpireDate)
            }));
            const filtered = cloneInvoices.filter((invoice) => invoice.ExpireDate && invoice.ExpireDate.includes(term));
            const uniqueList = getUniqueListByProp(filtered, this.#expireDate.prop).slice(0, 10);

            return this.sortOrderByPipe.transform(uniqueList, 'ExpireDate', 'Date');
          }),
          tap<InvoiceOData[]>(this.writeToCache(this.keyCache + term))
        ),
        asapScheduler
      );
    },
    itemFormatter: ({ ExpireDate }): string => this.datePipe.transform(ExpireDate, 'mediumDate'),
    addGroupBrackets: true
  };

  public readonly InvoiceIdTag: ModelTemplate<InvoiceOData> = {
    tag: this.#invoiceId.tag,
    items: (state: SmartSearchState): Observable<InvoiceOData[]> => {
      const term = state.leftCaretValue;

      this.paymentsService.filter = term || '';

      // if (this.cache[this.keyCache + term]) {
      //   return of(this.cache[this.keyCache + term]) as Observable<InvoiceOData[]>;
      // }

      return scheduled(
        this.paymentsService$.pipe(
          map((res) => res.data),
          map((invoices: InvoiceOData[]) => {
            const filtered = invoices.filter((invoice) => invoice.OrderId?.includes(term));
            const uniqueList = getUniqueListByProp(filtered, this.#invoiceId.prop).slice(0, 10);

            return this.sortOrderByPipe.transform(uniqueList, 'OrderId');
          }),
          tap<InvoiceOData[]>(this.writeToCache(this.keyCache + term))
        ),
        asapScheduler
      );
    },
    itemFormatter: ({ OrderId }): string => OrderId,
    addGroupBrackets: true
  };
}
