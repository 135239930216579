import { DateUnitEnum } from '@common/models';

export interface MyAccountContactInfo {
  Name: string;
  Email: string;
  Phone: string;
}

export interface MyAccountPhone {
  Phone: string;
}

export enum StorageTypes {
  amazonS3 = 'AmazonS3',
  azure = 'Azure',
  backBlaze = 'BackBlaze',
  s3compatible = 'S3Compatible',
  minio = 'Minio',
  swift = 'Swift',
  azureStack = 'AzureStack',
  wasabi = 'Wasabi'
}

// TODO: fixed model after changed on BE
export interface BackupDestination {
  Name: string;
  BucketName: string;
  RootFolder: string;
  AccessKey: string;
  SecretKey: string;
  StorageType: StorageTypes;
  Endpoint: string;
  EndpointSuffix: string;
}

export interface MyAccountRecoveryPassword {
  Email: string;
}

export interface ExportSettings {
  EntityType: EntityType;
  Id: string;
  Password: string;
  ProtectedWithPassword: boolean;
  RetentionSettings: {
    Enabled: boolean;
    DateNum: number;
    DateUnit: DateUnitEnum;
  };
}

type EntityType = 'Domain' | 'User';
