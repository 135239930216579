import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormError, RoutePath } from '@common';
import { BackupStatisticGrid, DeleteUserBackupOutput } from '@common/components/delete-user-backup';
import {
  ConfirmPasswordModalComponent,
  ConfirmPasswordModalSettings,
  ConfirmPasswordModalType
} from '@common/components/modals/confirm-password-modal/confirm-password-modal.component';
import {
  AddAlternateEmail,
  AlternateEmail,
  DeleteBackup,
  EditAlternate,
  ExportToPST,
  ExportToPSTServices,
  Role,
  SelectedPolicies,
  ServiceType,
  User
} from '@common/models';
import { AuthService, ExportToPSTService, UserOdataService } from '@common/services';
import { getErrorText, hasErrorResponseText, isHomeUser } from '@common/utils';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BackupConfigSettings } from '@pages/users/backup-config-control';
import { UserRetentionPoliciesControlComponent } from '@pages/users/user-retention-policies-control/user-retention-policies-control.component';
import { I18NextPipe } from 'angular-i18next';
import { I18_NAMESPACE_APPS_UI } from 'i18n';
import { cloneDeep, isNil } from 'lodash';
import { MbsSize, ModalService, ModalSettings, SidepanelBase, SidepanelComponent, TabsetDirective, ToastService } from 'mbs-ui-kit';
import { BehaviorSubject, EMPTY, Observable, forkJoin, noop, switchMap, throwError } from 'rxjs';
import { catchError, finalize, map, startWith, take } from 'rxjs/operators';

export enum GroupActionType {
  General = 'general',
  Permissions = 'permissions',
  Policies = 'policies',
  DeleteBackup = 'delete',
  ExportToPST = 'exportToPST'
}

export interface SaveEmit {
  userIds: string[];
  attachedPolicies: SelectedPolicies;
  settings: User;
  newAlternateEmail?: AddAlternateEmail;
  editAlternate?: EditAlternate;
}

@UntilDestroy()
@Component({
  selector: 'app-user-group-action-sidepanel',
  templateUrl: './user-group-action-sidepanel.component.html',
  styleUrls: ['./user-group-action-sidepanel.component.scss']
})
export class UserGroupActionSidepanelComponent extends SidepanelBase implements OnInit {
  @Output() save: EventEmitter<SaveEmit> = new EventEmitter<SaveEmit>();
  @Output() delete: EventEmitter<any> = new EventEmitter();

  @ViewChild(TabsetDirective, { static: true }) tabs: TabsetDirective;
  @ViewChild(SidepanelComponent, { static: true }) genericPanel: SidepanelComponent;
  @ViewChild(UserRetentionPoliciesControlComponent, { static: false }) userRetentionPolicies: UserRetentionPoliciesControlComponent;
  @ViewChild('exportJobTransferredTemplateRef', { static: false, read: TemplateRef }) exportJobTransferredRef: TemplateRef<any>;

  #user = {} as User;
  #type: GroupActionType;
  #userIds: string[] = [];

  readonly #toastTitleSuccess = this.i18nPipe.transform('toast.success.title', { format: 'title' });
  readonly #toastTitleError = this.i18nPipe.transform('toast.error.title', { format: 'title' });

  public readonly RoutePath = RoutePath;
  private readonly serviceTypeUserPropMap: Map<ServiceType, string> = new Map([
    [ServiceType.Mail, 'MailEnable'],
    [ServiceType.Drive, 'DriveEnable'],
    [ServiceType.Contacts, 'ContactsEnable'],
    [ServiceType.Calendar, 'CalendarEnable']
  ]);

  private deleteBackupFormValid = false;
  private newAlternateEmail: AddAlternateEmail;
  private editAlternate: EditAlternate;
  private deleteBackup = {} as DeleteBackup;
  private payloadForExportToPST: ExportToPST;

  public isUserDeleted = false;
  public isGlobalAdmin = false;

  public disabledSave = false;
  public disabledDelete = true;
  public disabledExport = false;
  public backupServices: ServiceType[] = [];
  public roles: Role[] = [];
  public user$: BehaviorSubject<User> = new BehaviorSubject<User>(null);
  public loading: boolean;
  public userIconCssClass = 'text-info';
  public userIcon = 'fa fa-user';
  public userIdForLinks = '';
  public disabledServices: ServiceType[] = [];
  public backupConfigSettings: BackupConfigSettings;
  public backupStatistics$: Observable<BackupStatisticGrid[]>;
  public singleUser = true;
  public deleteMode = false;
  public exportMode = false;
  public alternateEmailInfo: AlternateEmail;
  public alternateSelected: boolean;
  public deleteBackupFormError: FormError = { message: '' };
  public readonly moduleUsers = I18_NAMESPACE_APPS_UI.users;

  set user(user: User) {
    this.#user = user;
    this.backupConfigSettings = {
      backupEnabled: this.#user.InBackup,
      services: [
        { serviceType: ServiceType.Mail, enabled: this.#user.MailEnable },
        { serviceType: ServiceType.Drive, enabled: this.#user.DriveEnable },
        { serviceType: ServiceType.Contacts, enabled: this.#user.ContactsEnable },
        { serviceType: ServiceType.Calendar, enabled: this.#user.CalendarEnable }
      ]
    };
  }

  get user(): User {
    return this.#user;
  }

  set type(t: GroupActionType) {
    this.#type = t;
    this.tabs.select(this.#type);
    this.deleteMode = this.#type === GroupActionType.DeleteBackup;
    this.exportMode = this.#type === GroupActionType.ExportToPST;
    this.genericPanel.showDelete = this.deleteMode && !this.exportMode;
    this.genericPanel.hideSave = this.deleteMode && !this.exportMode;
  }

  set userIds(ids: string[]) {
    this.#userIds = ids;
    this.userIdForLinks = this.#userIds[0];

    if (this.#type == GroupActionType.DeleteBackup) {
      this.loading = true;

      this.backupStatistics$ = forkJoin([
        this.authService.getAuthUser().pipe(switchMap((user) => this.userService.getBackupStatistic(ids, !isHomeUser(user)))),
        this.authService.getRoles()
      ]).pipe(
        map(([statistic, roles]) => this.userService.mapBackupStatisticToGridList(statistic, roles, this.user.Id === this.authService.id)),
        startWith([]),
        finalize(() => (this.loading = false))
      );
    }
  }

  get userIds(): string[] {
    return this.#userIds;
  }

  get prefix(): string {
    return this.authService?.prefix;
  }

  constructor(
    private exportToPSTService: ExportToPSTService,
    private cdRef: ChangeDetectorRef,
    private i18nPipe: I18NextPipe,
    private modalService: ModalService,
    private userService: UserOdataService,
    private toastService: ToastService,
    private authService: AuthService
  ) {
    super();
  }

  fetchAlternate(): void {
    this.alternateSelected = false;
    this.userService
      .getAlternateEmail(this.user.Id)
      .pipe(take(1))
      .subscribe({
        next: (a: AlternateEmail) => {
          this.alternateEmailInfo = a;
          this.alternateSelected = !!this.alternateEmailInfo;
        }
      });
  }

  invalidForms(state: boolean): void {
    if (!this.exportMode && !this.deleteMode) {
      this.disabledSave = state;
    }

    if (this.exportMode) {
      this.disabledExport = !state;
    }

    if (this.deleteMode) {
      this.disabledDelete = !state || this.backupServices.length === 0;
      this.deleteBackupFormValid = state;
    }

    this.cdRef.detectChanges();
  }

  changeNewAlternateEmailModel(value: AddAlternateEmail): void {
    this.newAlternateEmail = value;
  }

  changeEditAlternateModel(value: EditAlternate): void {
    this.editAlternate = value;
  }

  deleteBackupChange(value: DeleteUserBackupOutput): void {
    this.deleteBackup = {
      UserIds: this.userIds,
      Email: value.deleteServices.includes(ServiceType.Mail),
      Drive: value.deleteServices.includes(ServiceType.Drive),
      Contact: value.deleteServices.includes(ServiceType.Contacts),
      Calendar: value.deleteServices.includes(ServiceType.Calendar),
      TeamDrive: value.deleteServices.includes(ServiceType.TeamDrives),
      SharePoint: value.deleteServices.includes(ServiceType.SharePoint),
      Password: value.password
    };

    this.backupServices = value.deleteServices;
  }

  ngOnInit(): void {
    this.genericPanel.isCreate = false;

    this.genericPanel.saveEvent.pipe(untilDestroyed(this)).subscribe(() => {
      if (this.user.AlternativeAccountExists && !this.alternateSelected) {
        this.openConfirmDeleteAlternate();
      } else {
        this.emitSave();
      }
    });

    this.genericPanel.deleteEvent.pipe(untilDestroyed(this)).subscribe(() => {
      if (this.deleteBackupFormValid) {
        this.userService
          .deleteBackup(this.deleteBackup)
          .toPromise()
          .then(
            () => this.delete.emit(),
            (textError: string) => (this.deleteBackupFormError = { message: textError })
          );
      } else {
        const textError: string = !this.deleteBackup.Password
          ? this.i18nPipe.transform('common.validation.passwordIsEmpty', { format: 'title' })
          : this.i18nPipe.transform('common.validation.formInvalid', { format: 'title' });

        this.deleteBackupFormError = { message: textError };
      }
    });

    this.user$.pipe(untilDestroyed(this)).subscribe((user) => {
      if (!user) return;

      this.user = cloneDeep(user);
      this.userIds = [user.Id];
      this.fetchAlternate();
    });

    this.genericPanel.panelOpened.pipe(untilDestroyed(this)).subscribe(() => {
      this.resetStateDeleteBackupTab();
      this.disabledSave = false;
    });
  }

  emitSave(): void {
    const emitObject: SaveEmit = {
      userIds: this.userIds,
      attachedPolicies: null,
      settings: this.user
    };

    emitObject.newAlternateEmail = this.newAlternateEmail;
    emitObject.editAlternate = this.editAlternate;

    if (this.userRetentionPolicies && this.userRetentionPolicies.policiesChanged) {
      emitObject.attachedPolicies = this.userRetentionPolicies.selectedPolicies;
    }

    this.save.emit(emitObject);
    this.genericPanel.panelClosed.emit();
  }

  openConfirmDeleteAlternate(): void {
    const confirmSettings: ConfirmPasswordModalSettings = {
      type: ConfirmPasswordModalType.delete,
      beforeSave: (password) => {
        return new Promise((resolve) => {
          this.userService
            .deleteAlternateEmail(this.userIds[0], this.user.Id === this.authService.id ? null : password)
            .pipe(untilDestroyed(this))
            .subscribe({
              next: () => resolve({ resolve: true }),
              error: (error: HttpErrorResponse) => {
                resolve({ resolve: false, error: getErrorText(error) });
              }
            });
        });
      }
    };
    const settings: ModalSettings = {
      size: MbsSize.sm,
      responsive: true,
      header: { title: 'Delete Alternate Email' },
      data: confirmSettings
    };

    this.modalService
      .openCustom(ConfirmPasswordModalComponent, settings)
      .then((confirmed) => {
        if (confirmed) {
          this.user.AlternativeAccountExists = false;
          this.alternateSelected = false;
          this.alternateEmailInfo = null;
          this.toastService.success('Alternate email deleted successfully', this.#toastTitleSuccess);
          this.userService.getUsers();
        }
      })
      .catch(() => noop);
  }

  resetSingleUserDataToPrevious(): void {
    this.user$.next(this.user$.value);
  }

  backupConfigChanged(config: BackupConfigSettings): void {
    config.services.forEach((s) => (this.user[this.serviceTypeUserPropMap.get(s.serviceType)] = s.enabled));
    this.user.InBackup = config.backupEnabled;
  }

  private resetStateDeleteBackupTab(): void {
    this.deleteBackupFormError = { message: '' };
    this.disabledDelete = true;
  }

  handleExportToPST(): void {
    if (!isNil(this.payloadForExportToPST)) {
      this.payloadForExportToPST.UserIds = [...this.userIds];
    }

    this.loading = true;
    this.exportToPSTService
      .exportUserData(this.payloadForExportToPST)
      .pipe(
        catchError((err: HttpErrorResponse) => (err.status !== 403 ? throwError(() => err) : EMPTY)),
        finalize(() => (this.loading = false))
      )
      .subscribe({
        next: () => {
          this.toastService.success(this.exportJobTransferredRef, this.#toastTitleSuccess);
          this.genericPanel.panelClosed.emit();
        },
        error: (res: HttpErrorResponse) => hasErrorResponseText(res) && this.toastService.error(getErrorText(res), this.#toastTitleError)
      });
  }

  handleUpdatePayloadForExportToPST(services: ExportToPSTServices[]): void {
    this.payloadForExportToPST = {
      UserIds: [...this.userIds],
      Services: cloneDeep(services)
    };
  }
}
