import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BrowserStorage, RoutePath } from '@common';
import { AuthState } from '@common/components/login/enums';
import { AlternativeSignInPayload, AuthResponse } from '@common/models/auth/auth.model';
import { AuthService, LocalStorageService } from '@common/services';
import { environment } from '@env/environment';
import { I18NextPipe } from 'angular-i18next';
import { MbsPopupType, MbsValidators, ModalComponent } from 'mbs-ui-kit';
import { HOME_EMAIL_REGEXP } from 'mbs-ui-kit/utils/constants';
import { BehaviorSubject, EMPTY, Observable, of, switchMap } from 'rxjs';
import { finalize, take } from 'rxjs/operators';

@Component({
  templateUrl: './sign-in-alternate-email-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SignInAlternateEmailModalComponent implements OnInit {
  @ViewChild(ModalComponent, { static: true }) baseModal: ModalComponent;

  public readonly MbsPopupType = MbsPopupType;
  public readonly loading$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  public formGroup: FormGroup;

  get providerEmailCtrl(): FormControl<string> {
    return <FormControl>this.formGroup.get('providerEmail');
  }

  get alternateEmailCtrl(): FormControl<string> {
    return <FormControl>this.formGroup.get('alternativeEmail');
  }

  get passwordCtrl(): FormControl<string> {
    return <FormControl>this.formGroup.get('password');
  }

  get providerSignInCtrl(): FormControl<boolean> {
    return <FormControl>this.formGroup.get('providerSignIn');
  }

  get tfaCtrl(): FormControl<string> {
    return <FormControl>this.formGroup.get('tfa');
  }

  get env() {
    return environment;
  }

  constructor(
    private authService: AuthService,
    private fb: FormBuilder,
    private router: Router,
    private storageService: LocalStorageService,
    private i18nPipe: I18NextPipe
  ) {}

  ngOnInit(): void {
    this.initForm();
  }

  private initForm(): void {
    this.formGroup = this.fb.group({
      providerEmail: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(100), this.emailValidator.bind(this)]],
      alternativeEmail: [
        '',
        [Validators.required, Validators.minLength(5), Validators.maxLength(100), MbsValidators.emailValidator.bind(this)]
      ],
      password: ['', [Validators.required, Validators.minLength(3)]]
    });

    if (!this.env.production && !this.providerSignInCtrl) {
      this.formGroup.addControl('providerSignIn', this.fb.control(false));
    }
  }

  emailValidator(control: AbstractControl): ValidationErrors | null {
    if (new RegExp(HOME_EMAIL_REGEXP).test(control.value as string)) {
      return null;
    }
    return { email: { message: MbsValidators.validatorMessages.email() } };
  }

  handleSignIn(): void {
    const payload: AlternativeSignInPayload = {
      PrimaryEmail: this.providerEmailCtrl.value,
      AlternateEmail: this.alternateEmailCtrl.value,
      AlternatePassword: this.passwordCtrl.value,
      ClientMinutesOffset: new Date().getTimezoneOffset()
    };

    if (!this.env.production) {
      payload.IsProviderSignIn = this.providerSignInCtrl.value;
    }

    if (this.tfaCtrl) {
      payload.TwoStepAuthCode = this.tfaCtrl.value;
    }

    this.loading$.next(true);
    this.alternativeSignIn(payload)
      .pipe(
        switchMap((response) => this.stateProcessing(response)),
        take(1),
        finalize(() => this.loading$.next(false))
      )
      .subscribe({
        next: (response) => {
          if (!response) return;

          const prefix = this.authService.getPath(response);

          this.storageService.set<string>(BrowserStorage.AuthPrefix, prefix);
          this.storageService.set<string>(BrowserStorage.Token, response.Token);
          this.authService.loggedIn$.next(true);

          this.router.navigate([prefix]).then(() => {
            this.handleClose();
          });
        },
        error: (res: HttpErrorResponse) => {
          this.authService.loggedIn$.next(false);
          this.passwordCtrl.setValue('', { emitEvent: false });
          this.formGroup.setErrors({
            validation:
              res.error?.value ||
              res.error?.error?.message ||
              this.i18nPipe.transform('common.validation.somethingWrong', { format: 'capitalize' })
          });
        }
      });
  }

  private alternativeSignIn(payload: AlternativeSignInPayload): Observable<AuthResponse> {
    return this.env.production ? this.authService.alternativeSignIn(payload) : this.authService.getToken(payload);
  }

  private stateProcessing(res: AuthResponse): Observable<AuthResponse | void> {
    switch (res.State) {
      case AuthState.Success:
        return of(res);
      case AuthState.Approve:
      case AuthState.AccessDenied:
      case AuthState.AccessRequest:
      case AuthState.Blocked:
      case AuthState.DomainNotFound:
      case AuthState.NotGrant:
      case AuthState.ServiceUnavailable:
      case AuthState.SignInDisabled:
      case AuthState.UserAccountDisabled:
        this.baseModal.close();
        this.authService.modelBasel = res.Model;
        return of(this.router.navigate([RoutePath.Account], { queryParams: { state: res.State } })).pipe(switchMap(() => EMPTY));
      case AuthState.RequiredTwoStepAuthCode:
        return this.requiredTwoStepAuthCode(res);
      case AuthState.WrongTwoStepAuthCode:
        return this.wrongTwoStepAuthCode(res);
      case AuthState.AltAccountNotVerified:
        return this.alternativeAccountNotVerified(res);
      default:
        return EMPTY;
    }
  }

  private requiredTwoStepAuthCode(res: AuthResponse): Observable<void> {
    if (!this.tfaCtrl) {
      this.formGroup.addControl('tfa', this.fb.control(null, Validators.required));
    }

    return EMPTY;
  }

  private wrongTwoStepAuthCode(res: AuthResponse): Observable<void> {
    this.tfaCtrl.setValue(null);
    this.tfaCtrl.setErrors({ tfa: { message: this.i18nPipe.transform('common.validation.invalidCode', { format: 'capitalize' }) } });

    return EMPTY;
  }

  private alternativeAccountNotVerified(res: AuthResponse): Observable<void> {
    this.alternateEmailCtrl.setValue(null);
    this.alternateEmailCtrl.setErrors({
      tfa: { message: this.i18nPipe.transform('common.validation.invalidAlternativeAccount', { format: 'capitalize' }) }
    });

    return EMPTY;
  }

  handleClose(): void {
    this.baseModal.close();
  }
}
