import { MimeCategory } from '@common';

export enum TreeItemTypes {
  SiteItem = 'SiteItem',
  SiteBlock = 'SiteBlock',
  SiteBlockItem = 'SiteBlockItem',
  File = 'File',
  Root = 'Root' // only for manual create root node
}

export interface SiteItemODataModel {
  AppName: string;
  BackupOn: boolean;
  BackupOnChanged?: boolean; // UI property for inside calculations
  BaseTemplate: string;
  BlockId: string;
  CssClass: string;
  DomainId: string;
  DomainName: string;
  DriveId: string;
  HasChildren: boolean;
  HierarchicalId: string;
  Id: string;
  IsDriveItem: boolean;
  MimeTypeId: string;
  MimeCategory: MimeCategory;
  Name: string;
  ParentIdStr: string;
  SiteName: string;
  Size: number;
  TreeItemType: TreeItemTypes;
  Type: boolean;
  checked: boolean;
}

export interface SiteItemDetails {
  '@odata.context': string;
  Description?: string;
  IconM?: string;
  IsDriveItem?: boolean;
  Raw?: string;
  children?: any[];
  Title?: string;
  Revisions?: SiteItemDetailsRevision[];
  value?: string;
  Path?: string[];
}

export interface SiteItemDetailsRevision {
  FileName: string;
  Id: string;
  ModifyDate: string; // "/Date(1578502905000)/"
  Raw: string;
}

export interface Site {
  '@odata.context': string;
  AutoAddSiteToBackup: boolean;
  EnabledAddSites: boolean;
  Id: string;
  LastBackupDate: string; // '2020-10-21T14:18:55.8262097Z'
  Size: number;
}

export interface PageSiteItemsPayload {
  siteId: string;
}

export interface PageDriveItemsPayload {
  siteBlockId: string;
  parentId: string;
  driveItemId: string;
}

export interface RestoreSiteItemsPayload {
  DestinationId?: string;
  Id: string;
  Name?: string;
  Overwrite?: boolean;
  Timeline?: string; // "2020-08-26T21:00:00.000Z";
}

export interface SetupSitesBackupPayload {
  sites: Partial<SiteItemODataModel>[]; // Id: string; BackupOn: boolean;
}

export enum SearchType {
  SiteItems,
  Files,
  FilesInSiteItem
}

export type SearchODataOptions = {
  filter: string;
  orderBy: string;
  page: number;
  pageSize: number;
};

export type ItemSiteItemDetails = { site: SiteItemODataModel; details: SiteItemDetails };
